import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { SampleFilterBar, SampleFilterForm } from "./SampleFilterBar";
import {
  Sample,
  SampleFilters,
  SampleFiltersTranslator,
  samplesConstants,
  SampleSearchTermOptions,
} from "../../api/Samples";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { ExclusiveDropdown } from "../../common/buttons/ExclusiveDropdown/ExclusiveDropdown";
import { GenericModalWrapper } from "../../common/modals/Modal/GenericModal";
import { SamplesBulkEditDropDownWrapper } from "./SamplesBulkEditDropDown";
import { GetPersons, TableArrayRenderer } from "../../common/misc/EntityRenders/EntityRenderer";
import { ExportCsvButton } from "../../common/tables/ExportCsvButton/ExportCsvButton";
import { AddCustomTypeEntity } from "../../Customization/CustomTypes/generics/AddCustomTypeEntity";
import { useCustomTypesEntityTable } from "../../Customization/CustomTypes/generics/useCustomTypesEntityTable";
import { NotSet } from "../../common/misc/UIconstants";
import SearchInputWithOptions from "../../common/forms/SearchInputWithOptions/SearchInputWithOptions";
import { renderCustomTypeColumn } from "../../Customization/CustomTypes/generics/CustomTypeRenderer";
import {
  UseEntityTableDefaultProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
  UseITypedEntityTableProps,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableRestoreButton } from "../../common/entity/entityComponents/EntityTableRestoreButton";
import { EntityTableSoftDeletableButton } from "../../common/entity/entityComponents/EntityTableSoftDeletableButton";

export const switchSamplesDefaultSortState = (
  sortState: SampleFilters["orderBy"]
): SortState<SampleFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "PREPARATION_DATE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-preparedOn",
        orderBy: sortState,
      };
    case "PREPARATION_DATE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-preparedOn",
        orderBy: sortState,
      };
    case "DISCARDED_DATE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-discardedAt",
        orderBy: sortState,
      };
    case "DISCARDED_DATE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-discardedAt",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-type",
        orderBy: sortState,
      };
    case "TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-type",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "DESC",
        headerId: "default-preparedOn",
        orderBy: sortState,
      };
  }
};

const defaultFilter: SampleFilters = {
  orderBy: "PREPARATION_DATE_DESC",
  searchTermIncludeNames: true,
  searchTermIncludeIds: true,
  searchTermIncludeUuids: true,
  searchTermIncludeNotes: false,
};

export const useSamplesTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"samples">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<Sample> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-legacyId": {
        pos: 1,
        active: false,
        header: fieldLabels.sequenceNumber,
        property: "sequenceNumber",
      },
      "default-name": { pos: 2, active: true, header: fieldLabels.name, property: "name" },
      "default-preparedOn": { pos: 3, active: true, header: fieldLabels.preparedAt, property: "preparedAt" },
      "default-preparedBy": { pos: 4, active: true, header: fieldLabels.preparedBy, property: "preparedBy" },
      "default-discardedAt": { pos: 5, active: false, header: fieldLabels.discardedAt, property: "discardedAt" },
      "default-discardedBy": { pos: 6, active: false, header: fieldLabels.discardedBy, property: "discardedBy" },
      "default-type": { pos: 7, active: true, header: fieldLabels.customType, property: "customType" },
      "default-projects": { pos: 8, active: true, header: fieldLabels.projects, property: "projects" },
      "default-organizations": {
        pos: 9,
        active: true,
        header: fieldLabels.organizations,
        property: "organizations",
      },
      "default-createdOn": { pos: 10, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 11, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 12, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 13, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
    }),
    [
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.customType,
      fieldLabels.discardedAt,
      fieldLabels.discardedBy,
      fieldLabels.id,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.organizations,
      fieldLabels.preparedAt,
      fieldLabels.preparedBy,
      fieldLabels.projects,
      fieldLabels.sequenceNumber,
    ]
  );

  const tabStoreDefaults: TableTabsDict<Sample, SampleFilters, SampleFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: { includeSoftDeleted: false, excludeDiscarded: false },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      mydata: {
        tabId: "mydata",
        type: "fixed",
        label: `My ${samplesConstants.entityPlural}`,
        title: `My ${samplesConstants.entityPlural}`,
        icon: "user",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { ownerIds: session?.person.id ? [session.person.id] : null },
          sidebarFilters: {},
        },
      },
      trash: {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 3,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true },
          sidebarFilters: {},
        },
      },
    }),
    [session?.person.id]
  );
  return { defaults, tabStoreDefaults };
};

export const useSamplesTableColumns = ({
  entityConstants,
  fieldLabels,
  defaults,
  filters,
  sort,
  setSort,
}: UseITypedEntityTableProps<"samples">) => {
  // Custom Fields
  const { columnSettings, customFieldHeaders } = useCustomTypesEntityTable({
    defaultColumnSettings: defaults,
    entityType: "Sample",
    customTypeIds: filters?.customTypeIds ?? undefined,
  });

  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Sample> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => (
          <span style={{ color: "var(--gray-400)" }}>
            <samp>{row.id}</samp>
          </span>
        ),
        width: 120,
        minWidth: 120,
        align: "right",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden", alignItems: "center" }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name}
              </span>
            </div>
            {row.discardedAt && (
              <div>
                <label className="label label-soft-danger">Discarded</label>
              </div>
            )}
            {row.isDeleted && (
              <div>
                <label className="label label-soft-warning" title={"Trashed"}>
                  <LucideIcon name="trash" />
                </label>
              </div>
            )}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-legacyId",
        Header: fieldLabels.sequenceNumber,
        accessor: (row) =>
          !!row.sequenceNumber ? (
            <span style={{ color: "var(--gray-400)" }}>
              <samp>{`#${row.sequenceNumber}`}</samp>
            </span>
          ) : (
            NotSet
          ),
        width: 120,
        minWidth: 120,
        align: "right",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-preparedOn",
        Header: fieldLabels.preparedAt,
        accessor: (row) => <DateTimeRenderer date={row.preparedAt} includeTime={false} includeElapsed={false} />,
        width: 170,
        minWidth: 170,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-preparedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "PREPARATION_DATE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "PREPARATION_DATE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-preparedOn", sortDirection: "ASC", orderBy: "PREPARATION_DATE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-preparedBy",
        Header: fieldLabels.preparedBy,
        accessor: (row) => <GetPersons persons={row.preparedBy} createLinks={false} />,
        width: 170,
        minWidth: 170,
        align: "left",
      },
      {
        Header: fieldLabels.projects,
        id: "default-projects",
        accessor: (row) => <TableArrayRenderer values={row.projects}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        Header: fieldLabels.organizations,
        id: "default-organizations",
        accessor: (row) => <TableArrayRenderer values={row.organizations}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={[row.createdBy]} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-discardedAt",
        Header: fieldLabels.discardedAt,
        accessor: (row) =>
          row.discardedAt ? (
            <DateTimeRenderer date={row.discardedAt} includeElapsed={false} includeTime={false} />
          ) : (
            NotSet
          ),
        width: 170,
        minWidth: 170,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-discardedAt") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "DISCARDED_DATE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "DISCARDED_DATE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-discardedAt", sortDirection: "ASC", orderBy: "DISCARDED_DATE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-discardedBy",
        Header: fieldLabels.discardedBy,
        accessor: (row) => <GetPersons persons={row.discardedBy} createLinks={false} />,
        width: 200,
        align: "left",
      },
    ];

    // Add custom field headers
    headers.push(
      renderCustomTypeColumn<Sample, SampleFilters["orderBy"], SampleFilters>(entityConstants, sort, setSort)
    );
    headers = headers.concat(customFieldHeaders);
    return headers;
  }, [
    customFieldHeaders,
    entityConstants,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.discardedAt,
    fieldLabels.discardedBy,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.organizations,
    fieldLabels.preparedAt,
    fieldLabels.preparedBy,
    fieldLabels.projects,
    fieldLabels.sequenceNumber,
    setSort,
    sort,
  ]);

  return { columns, columnSettings };
};

export const SamplesTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"samples">) => {
  const { session } = useContext(SessionContext);
  const {
    bulkRestoreMutationAsync,
    bulkDeleteMutationAsync,
    isLoadingBulkDeleteMutation,
    isLoadingBulkRestoreMutation,
  } = entityApi;
  const [showModal, setShowModal] = useState(false);

  const { defaults, tabStoreDefaults } = useSamplesTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Sample>();

  const {
    filters,
    forcedFilters,
    sidebarFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<Sample, SampleFilters, SampleFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue, debouncedSearchValue } =
    useGenericVirtualizedTableTabs({
      currentTab,
      tabsLoading,
      filters,
      switchSortState: switchSamplesDefaultSortState,
      dispatchTabStore,
    });

  const { columns, columnSettings } = useSamplesTableColumns({
    fieldLabels,
    entityConstants,
    defaults,
    filters,
    sort,
    setSort,
  });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          {/* <AddSampleButton /> */}
          <AddCustomTypeEntity
            entityConstants={entityConstants}
            canCreateEntityPermission={session?.permissions.add_samples}
            entityType={"Sample"}
          />
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <SampleFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            temporaryTabs={temporaryTabs}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<Sample, SampleFilters>
                filters={filters}
                translatorConsts={SampleFiltersTranslator}
                excludeFilters={{
                  includeSoftDeleted: (value) => value === true,
                  isSoftDeleted: () => false,
                  participatedPersonIds: () => false,
                  searchTermIncludeIds: () => false,
                  searchTermIncludeNames: () => false,
                  searchTermIncludeNotes: () => false,
                  searchTermIncludeUuids: () => false,
                }}
              />
              {/* <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" /> */}
              <SearchInputWithOptions
                searchTermOptions={SampleSearchTermOptions}
                filters={filters}
                setFilters={async (newFilters) => {
                  await dispatchTabStore({
                    type: "setTab",
                    options: { keepPrevious: true },
                    payload: { settings: { filters: { searchTerm: debouncedSearchValue, ...newFilters } } },
                  });
                }}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              <div
                className="flex row-nowrap align-center gap-5"
                style={{ height: "min-content", marginBottom: "auto" }}
              >
                {currentTab !== "trash" && (
                  <GenericModalWrapper>
                    {({ showModal, setShowModal }) => (
                      <ExclusiveDropdown
                        show={showModal}
                        setShow={setShowModal}
                        disabled={
                          selection.size === 0 ||
                          (session?.features.development_mode ? false : selection.size > 1000) ||
                          !permissions.canBulkEdit(selectionPermissions)
                        }
                        title={`${
                          !permissions.canBulkEdit(selectionPermissions)
                            ? `Insufficient permissions to edit selected ${entityConstants.entityPlural}`
                            : selection.size > 1000
                            ? `Editing is only allowed for max. 1000 ${entityConstants.entityPlural} at once`
                            : `Edit`
                        }`}
                        btnCls={"btn btn-default"}
                        btnLabel={"Edit"}
                        icon={<LucideIcon name="square-pen" />}
                      >
                        <SamplesBulkEditDropDownWrapper
                          selection={selection}
                          onSuccess={() => {
                            functionRef.current?.resetSelection();
                            setShowModal(false);
                          }}
                          onClose={() => {
                            setShowModal(false);
                          }}
                          filters={filters}
                          entityConstants={entityConstants}
                        />
                      </ExclusiveDropdown>
                    )}
                  </GenericModalWrapper>
                )}
                <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                  <li>
                    <EntityTableCloneButton
                      entityConstants={entityConstants}
                      routes={routes}
                      permissions={permissions}
                      selection={selection}
                    />
                  </li>
                  <li>
                    <ExportCsvButton
                      entityConstants={entityConstants}
                      columnSettings={columnSetting}
                      filters={{
                        ids: selection.size > 0 ? Array.from(selection) : undefined,
                        ...memoizedFilters,
                        searchTerm: debouncedSearchValue,
                      }}
                      disabled={selection.size === 0}
                    />
                  </li>
                  {currentTab === "trash" && (
                    <li>
                      <EntityTableRestoreButton
                        entityConstants={entityConstants}
                        selection={selection}
                        permissions={permissions}
                        selectionPermissions={selectionPermissions}
                        onClick={async () =>
                          await bulkRestoreMutationAsync({ ids: Array.from(selection) }).catch(() => {})
                        }
                        loading={isLoadingBulkRestoreMutation}
                      />
                    </li>
                  )}
                  <li>
                    <EntityTableSoftDeletableButton
                      currentTab={currentTab}
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={() => setShowModal(true)}
                    />
                  </li>
                </MoreDropdown>
              </div>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entitySingular}?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entityPlural}?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? `Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`
                    : `Proceeding will move the selected ${entityConstants.entityPlural} into trash.`
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await bulkDeleteMutationAsync({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.entityPlural,
                    params: { deletePermanently: currentTab === "trash" },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<Sample, SampleFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={columnSettings}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
