import React, { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  EntityConstants,
  GenericEntity,
  IEntityPermissions,
  IGenericRequestParameters,
  StringIndexedDict,
} from "../../../api/GenericTypes";
import { UseTabActions } from "../Tabs/TableTabsTypes";
import { GenericVirtualizedTableFunctionRef, SortState } from "./GenericVirtualizedTableTypes";
import { useDebouncedValue } from "../../helperfunctions/useDebouncedValue";
import { ColumnsSettings } from "../ColumnsSelector/ColumnsSelector";
import {
  EntityByEntityTypeId,
  FiltersByEntityTypeId,
  GenericEntityConstant,
  GenericEntityConstantsEntities,
} from "../../../api/GenericConstants";
import { set } from "d3";

export interface UseEntityTableDefaultProps<EntityTypeId extends GenericEntityConstantsEntities> {
  fieldLabels: GenericEntityConstant<EntityTypeId>["fieldLabels"];
}
export interface UseEntityTableProps<EntityTypeId extends GenericEntityConstantsEntities>
  extends UseEntityTableDefaultProps<EntityTypeId> {
  entityConstants: EntityConstants<EntityByEntityTypeId<EntityTypeId>, FiltersByEntityTypeId<EntityTypeId>>;
  sort: SortState<FiltersByEntityTypeId<EntityTypeId>["orderBy"]>;
  setSort: React.Dispatch<React.SetStateAction<SortState<FiltersByEntityTypeId<EntityTypeId>["orderBy"]>>>;
}

export interface UseITypedEntityTableProps<EntityTypeId extends GenericEntityConstantsEntities>
  extends UseEntityTableProps<EntityTypeId> {
  defaults: ColumnsSettings<EntityByEntityTypeId<EntityTypeId>>;
  filters: FiltersByEntityTypeId<EntityTypeId>;
}

/**
 * Custom hook to manage the state of a generic virtualized table.
 */
export const useGenericVirtualizedTable = <Entity extends GenericEntity>() => {
  const [selection, setSelection] = useState<Set<Entity["id"]>>(new Set()); // Selected elements in the table will be held here
  const [resultsCount, setResultsCount] = useState<number>();
  const [selectionPermissions, setSelectionPermissions] = useState<IEntityPermissions>();

  const onCountChange = useCallback((count: number | undefined) => {
    setResultsCount(count);
  }, []);

  const onSelectionChange = useCallback((selection: Set<Entity["id"]>) => {
    setSelection(selection);
  }, []);

  const onSelectionPermissions = useCallback((permissions?: IEntityPermissions) => {
    setSelectionPermissions(permissions);
  }, []);

  return {
    selection,
    resultsCount,
    selectionPermissions,
    onCountChange,
    onSelectionChange,
    onSelectionPermissions,
  };
};

/**
 * Custom hook to manage the state of a generic virtualized table tabs.
 */
interface UseGenericVirtualizedTableTabsProps<
  Entity extends GenericEntity,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>,
  FilterForm extends StringIndexedDict
> {
  currentTab: string;
  tabsLoading: boolean;
  filters: Filters;
  switchSortState: (sortState: Filters["orderBy"]) => SortState<Filters["orderBy"]>;
  dispatchTabStore: (action: UseTabActions<Entity, Filters, FilterForm>) => Promise<void>;
}
export const useGenericVirtualizedTableTabs = <
  Entity extends GenericEntity,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>,
  FilterForm extends StringIndexedDict
>({
  currentTab,
  tabsLoading,
  filters,
  switchSortState,
  dispatchTabStore,
}: UseGenericVirtualizedTableTabsProps<Entity, Filters, FilterForm>) => {
  const functionRef = useRef<GenericVirtualizedTableFunctionRef<Entity>>(null);
  const [sort, setSort] = useState<SortState<Filters["orderBy"]>>(switchSortState(filters.orderBy));
  const [searchValue, setSearchValue] = useState(""); //SearchTerm
  const debouncedSearchValue = useDebouncedValue(searchValue, 300); // Debounced searchTerm used for filtering
  const memoizedsearchTerm = useMemo(() => filters.searchTerm, [filters.searchTerm]);
  const memoizedOrderBy = useMemo(() => filters.orderBy, [filters.orderBy]);
  const memoizedCurrentTab = useMemo(() => currentTab, [currentTab]);
  const [tab, setTab] = useState<string>();
  const initDebouncedSearchValue = useRef(false);

  const onTabChange = useCallback(
    async (currentTabId: string) => {
      if (!tabsLoading) {
        await dispatchTabStore({ type: "setCurrentTab", tabId: currentTabId });
        functionRef.current?.resetSelection();
      }
    },
    [dispatchTabStore, tabsLoading]
  );

  useEffect(() => {
    if (!tabsLoading && tab !== memoizedCurrentTab) {
      setTab(memoizedCurrentTab);
      setSearchValue((prev) => (prev !== memoizedsearchTerm ? memoizedsearchTerm ?? "" : prev));
    }
  }, [memoizedCurrentTab, memoizedsearchTerm, tab, tabsLoading]);

  // Hook to update searchTerm or orderBy if filter changes; (e.g. triggered by the sidebar)
  useEffect(() => {
    if (!tabsLoading && memoizedOrderBy !== undefined) {
      setSort(switchSortState(memoizedOrderBy));
    }
  }, [memoizedOrderBy, switchSortState, tabsLoading]);

  // Init debounced search value once on startup to avoid unnecessary re-renders
  useEffect(() => {
    if (!initDebouncedSearchValue.current && !tabsLoading && memoizedsearchTerm === debouncedSearchValue) {
      (async () =>
        await dispatchTabStore({
          type: "setTab",
          payload: { settings: { filters: { searchTerm: debouncedSearchValue } as Filters } },
          options: { keepPrevious: true },
        }))().then(() => {
        initDebouncedSearchValue.current = true;
      });
    }
  }, [debouncedSearchValue, dispatchTabStore, memoizedsearchTerm, tabsLoading]);

  useEffect(() => {
    if (!tabsLoading && initDebouncedSearchValue.current) {
      (async () =>
        await dispatchTabStore({
          type: "setTab",
          payload: { settings: { filters: { searchTerm: debouncedSearchValue } as Filters } },
          options: { keepPrevious: true },
        }))();
    }
  }, [debouncedSearchValue, dispatchTabStore, tabsLoading]);

  useEffect(() => {
    if (sort.orderBy && !tabsLoading) {
      (async () =>
        await dispatchTabStore({
          type: "setTab",
          payload: { settings: { filters: { orderBy: sort.orderBy } as Filters } },
          options: { keepPrevious: true },
        }))();
    }
  }, [dispatchTabStore, sort.orderBy, tabsLoading]);

  return { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue, debouncedSearchValue };
};
