import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import { Experiment, ExperimentFilters, ExperimentFieldLabels } from "../../api/Experiments";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { MethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";

export interface ExperimentFilterForm {
  methodIds?: IEntityMinimalModel[] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}
const defaultValues: DefinedPropertyObject<ExperimentFilterForm> = {
  methodIds: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};
export const experimentsFormToFilters = (form: ExperimentFilterForm): ExperimentFilters => {
  let filters: ExperimentFilters = {};
  filters.methodIds =
    Array.isArray(form.methodIds) && form.methodIds.length > 0 ? form.methodIds?.map((d) => d.id) : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;

  return filters;
};

/**
 * Converts ExperimentFilters to ExperimentFilterForm
 * @param filters ExperimentFilters
 * @returns ExperimentFilterForm
 * @author @CorradoSurmanowicz
 */
export const filtersToExperimentsForm = (filters: ExperimentFilters): ExperimentFilterForm => {
  let form: ExperimentFilterForm = {};
  form.methodIds = filters.methodIds ? filters.methodIds.map((id) => ({ id, name: "" })) : null;
  form.createdByIds = filters.createdByIds ? filters.createdByIds.map((id) => ({ id, name: "" })) : null;
  form.createdFrom = filters.createdFrom ? new Date(filters.createdFrom) : null;
  form.createdTo = filters.createdTo ? new Date(filters.createdTo) : null;
  form.modifiedByIds = filters.modifiedByIds ? filters.modifiedByIds.map((id) => ({ id, name: "" })) : null;
  form.modifiedFrom = filters.modifiedFrom ? new Date(filters.modifiedFrom) : null;
  form.modifiedTo = filters.modifiedTo ? new Date(filters.modifiedTo) : null;

  return form;
};
const defaultFilters: ExperimentFilters = { orderBy: "NAME_ASC" };

export const ExperimentFilterBar = ({
  initialValues,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: TabStoreFilterSidebarProps<Experiment, ExperimentFilterForm, ExperimentFilters>) => {
  const { control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: experimentsFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <MethodsVirtualizedSelectForm id="methodIds" label={ExperimentFieldLabels.method} control={control} isMulti />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
