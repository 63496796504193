import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import { Parser, ParserFilters, ParserFieldLabels } from "../../api/Parsers";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { ParserMethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ParserMethodsVirtualizedSelectForm";
import { VendorsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/VendorsVirtualizedSelectForm";
import { ParserInstrumentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ParserInstrumentsVirtualizedSelectForm";
import { useContext } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { SelectFormField } from "../../common/formfields/SelectFormField";

interface BooleanSelectOption {
  id: number;
  name: string;
  value: boolean | null;
}
const IsBetaSelectOptions: BooleanSelectOption[] = [
  { id: 1, name: "Production only", value: false },
  { id: 2, name: "Development only", value: true },
];
const IsCustomSelectOptions: BooleanSelectOption[] = [
  { id: 1, name: "Default format", value: false },
  { id: 2, name: "Custom format", value: true },
];
const HasVisualizationSelectOptions: BooleanSelectOption[] = [
  { id: 1, name: "No visualization", value: false },
  { id: 2, name: "Has visualization", value: true },
];
export interface ParserFilterForm {
  methods?: IEntityMinimalModel<string>[] | undefined | null;
  vendors?: IEntityMinimalModel<string>[] | undefined | null;
  instruments?: IEntityMinimalModel<string>[] | undefined | null;
  isBeta?: BooleanSelectOption | undefined | null;
  isCustom?: BooleanSelectOption | undefined | null;
  hasVisualization?: BooleanSelectOption | undefined | null;
}
const defaultValues: DefinedPropertyObject<ParserFilterForm> = {
  methods: null,
  vendors: null,
  instruments: null,
  isBeta: null,
  isCustom: null,
  hasVisualization: null,
};
export const parsersFormToFilters = (form: ParserFilterForm): ParserFilters => {
  let filters: ParserFilters = {};
  filters.methods =
    Array.isArray(form.methods) && form.methods.length > 0 ? form.methods?.map((d) => d.name || null) : null;
  filters.vendors =
    Array.isArray(form.vendors) && form.vendors.length > 0 ? form.vendors?.map((d) => d.name || null) : null;
  filters.instruments =
    Array.isArray(form.instruments) && form.instruments.length > 0
      ? form.instruments?.map((d) => d.name || null)
      : null;

  filters.isBeta = form.isBeta?.value ?? null;
  filters.isCustom = form.isCustom?.value ?? null;
  filters.hasVisualization = form.hasVisualization?.value ?? null;

  return filters;
};
/**
 * Converts ParserFilters to ParserFilterForm
 * @param filters ParserFilters
 * @returns ParserFilterForm
 * @author @CorradoSurmanowicz
 */
export const filtersToParsersForm = (filters: ParserFilters): ParserFilterForm => {
  let form: ParserFilterForm = {};

  form.methods = filters.methods?.map((name) => ({ id: name ?? "", name: name ?? "" })) || null;
  form.vendors = filters.vendors?.map((name) => ({ id: name ?? "", name: name ?? "" })) || null;
  form.instruments = filters.instruments?.map((name) => ({ id: name ?? "", name: name ?? "" })) || null;
  form.isBeta = IsBetaSelectOptions.find((option) => option.value === filters.isBeta) || null;
  form.isCustom = IsCustomSelectOptions.find((option) => option.value === filters.isCustom) || null;
  form.hasVisualization =
    HasVisualizationSelectOptions.find((option) => option.value === filters.hasVisualization) || null;

  return form;
};
const defaultFilters: ParserFilters = { orderBy: "NAME_ASC" };

export const ParserFilterBar = ({
  initialValues,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: TabStoreFilterSidebarProps<Parser, ParserFilterForm, ParserFilters>) => {
  const { session } = useContext(SessionContext);
  const { control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: parsersFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <VendorsVirtualizedSelectForm
        id="vendors"
        label={ParserFieldLabels.vendors}
        control={control}
        isMulti
        // allowUnassigned
      />
      <ParserMethodsVirtualizedSelectForm
        id="methods"
        label={ParserFieldLabels.methods}
        control={control}
        isMulti
        // allowUnassigned
      />
      <ParserInstrumentsVirtualizedSelectForm
        id="instruments"
        label={ParserFieldLabels.instruments}
        control={control}
        isMulti
        // allowUnassigned
      />

      <SelectFormField
        id="hasVisualization"
        label={ParserFieldLabels.hasVisualization}
        items={HasVisualizationSelectOptions}
        control={control}
        horizontal={false}
      />

      <SelectFormField
        id="isCustom"
        label={ParserFieldLabels.isCustom}
        items={IsCustomSelectOptions}
        control={control}
        horizontal={false}
      />

      {session?.features.development_mode && (
        <>
          <SelectFormField
            id="isBeta"
            label={ParserFieldLabels.isBeta}
            items={IsBetaSelectOptions}
            control={control}
            horizontal={false}
          />
        </>
      )}
    </EntityFilterSideBar>
  );
};
