import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import {
  Announcement,
  AnnouncementFilters,
  AnnouncementType,
  AnnouncementTypes,
  announcementFieldLabels,
} from "../../api/Announcements";
import { SelectFormField } from "../../common/formfields/SelectFormField";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import { useContext } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";

type AnnouncementTypeSelect = { id: AnnouncementType; name: AnnouncementType };

export interface AnnouncementFilterForm {
  types?: AnnouncementTypeSelect[] | undefined | null;
  current?: boolean | undefined | null;
  startDateFrom?: Date | undefined | null;
  startDateTo?: Date | undefined | null;
  endDateFrom?: Date | undefined | null;
  endDateTo?: Date | undefined | null;
  includeCurrentUserHasRead?: boolean | undefined | null;
  includeSystemAnnouncements?: boolean | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}

const defaultValues: DefinedPropertyObject<AnnouncementFilterForm> = {
  types: null,
  current: false,
  startDateFrom: null,
  startDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  includeCurrentUserHasRead: true,
  includeSystemAnnouncements: false,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};
export const announcementsFormToFilters = (form: AnnouncementFilterForm): AnnouncementFilters => {
  let filters: AnnouncementFilters = {};

  filters.types = Array.isArray(form.types) && form.types.length > 0 ? form.types.map((t) => t.id) : null;
  filters.current = form.current || false;
  filters.startDateFrom = form.startDateFrom ? form.startDateFrom.toISOString() : null;
  filters.startDateTo = form.startDateTo ? form.startDateTo.toISOString() : null;
  filters.endDateFrom = form.endDateFrom ? form.endDateFrom.toISOString() : null;
  filters.endDateTo = form.endDateTo ? form.endDateTo.toISOString() : null;
  filters.includeCurrentUserHasRead = form.includeCurrentUserHasRead || true;
  filters.includeSystemAnnouncements = form.includeSystemAnnouncements || false;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  return filters;
};

/**
 * Converts AnnouncementFilters to AnnouncementFilterForm
 * @param filters - The filters to convert
 * @returns The converted form
 * @author @CorradoSurmanowicz
 */
export const announcementsFiltersToForm = (filters: AnnouncementFilters): AnnouncementFilterForm => {
  let form: AnnouncementFilterForm = {};

  form.types = filters.types ? filters.types.map((id) => ({ id, name: id })) : null;
  form.current = filters.current || false;
  form.startDateFrom = filters.startDateFrom ? new Date(filters.startDateFrom) : null;
  form.startDateTo = filters.startDateTo ? new Date(filters.startDateTo) : null;
  form.endDateFrom = filters.endDateFrom ? new Date(filters.endDateFrom) : null;
  form.endDateTo = filters.endDateTo ? new Date(filters.endDateTo) : null;
  form.includeCurrentUserHasRead = filters.includeCurrentUserHasRead || true;
  form.includeSystemAnnouncements = filters.includeSystemAnnouncements || false;
  form.createdByIds = filters.createdByIds ? filters.createdByIds.map((id) => ({ id, name: "" })) : null;
  form.createdFrom = filters.createdFrom ? new Date(filters.createdFrom) : null;
  form.createdTo = filters.createdTo ? new Date(filters.createdTo) : null;
  form.modifiedByIds = filters.modifiedByIds ? filters.modifiedByIds.map((id) => ({ id, name: "" })) : null;
  form.modifiedFrom = filters.modifiedFrom ? new Date(filters.modifiedFrom) : null;
  form.modifiedTo = filters.modifiedTo ? new Date(filters.modifiedTo) : null;

  return form;
};

const defaultFilters: AnnouncementFilters = { orderBy: "TYPE_DESC", includeCurrentUserHasRead: true };

export const AnnouncementFilterBar = ({
  initialValues,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: TabStoreFilterSidebarProps<Announcement, AnnouncementFilterForm, AnnouncementFilters>) => {
  const { session } = useContext(SessionContext);
  const { control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: announcementsFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <SelectFormField
        id="types"
        label={announcementFieldLabels.type}
        control={control}
        isMulti
        horizontal={false}
        items={AnnouncementTypes?.map((x) => ({ id: x, name: x })) ?? []}
        placeholder="Select announcement type..."
      />

      <SplitDatePickerFormField
        control={control}
        idStart="startDateFrom"
        idEnd="startDateTo"
        label="Start date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      <SplitDatePickerFormField
        control={control}
        idStart="endDateFrom"
        idEnd="endDateTo"
        label="Expiration date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <ToggleFormField id="current" control={control} label="Only active" horizontal />
      <ToggleFormField id="includeCurrentUserHasRead" control={control} label="Include already read" horizontal />
      {session?.permissions.logsadmin && (
        <ToggleFormField
          id="includeSystemAnnouncements"
          control={control}
          label="Include system announcements"
          horizontal
        />
      )}
    </EntityFilterSideBar>
  );
};
