import {
  AuditLog,
  AuditLogEntityType,
  AuditLogEntityTypes,
  AuditLogEventType,
  AuditLogEventTypes,
  AuditLogFilters,
} from "../api/AuditLog";
import { DefinedPropertyObject, IdTypes, IEntityMinimalModel } from "../api/GenericTypes";
import { SelectFormField } from "../common/formfields/SelectFormField";
import { entityTypeToInfo, eventIdToInfo } from "./AuditLog";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { PersonsVirtualizedSelectForm } from "../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../common/formfields/DatePickerFormField/SplitDatePickerFormField";
// import { useHistory, useLocation } from "react-router-dom";

interface AuditLogFilterBarProps extends TabStoreFilterSidebarProps<AuditLog, AuditLogFilterForm, AuditLogFilters> {
  hideEntityTypeFilter?: boolean;
}

interface SelectOption<T> {
  id: string | number;
  name: string;
  value: T;
}

export interface AuditLogFilterForm {
  timestampFrom?: Date | undefined | null;
  timestampTo?: Date | undefined | null;
  eventIds?: SelectOption<AuditLogEventType>[] | undefined | null;
  userIds?: IEntityMinimalModel[] | undefined | null;
  apiKeyIds?: IEntityMinimalModel<IdTypes>[] | undefined | null;
  entityTypes?: SelectOption<AuditLogEntityType>[] | undefined | null;
}
const defaultValues: DefinedPropertyObject<AuditLogFilterForm> = {
  timestampFrom: null,
  timestampTo: null,
  eventIds: null,
  userIds: null,
  apiKeyIds: null,
  entityTypes: null,
};

const auditLogsFormToFilters = (form: AuditLogFilterForm): AuditLogFilters => {
  let filters: AuditLogFilters = {};
  filters.userIds = Array.isArray(form.userIds) && form.userIds.length > 0 ? form.userIds?.map((d) => d.id) : null;
  filters.timestampFrom = form.timestampFrom ? form.timestampFrom.toISOString() : null;
  filters.timestampTo = form.timestampTo ? form.timestampTo.toISOString() : null;
  filters.eventIds =
    Array.isArray(form.eventIds) && form.eventIds.length > 0 ? form.eventIds.map((e) => e.value) : null;
  filters.apiKeyIds =
    Array.isArray(form.apiKeyIds) && form.apiKeyIds.length > 0 ? form.apiKeyIds.map((a) => a.id) : null;
  filters.entityTypes =
    Array.isArray(form.entityTypes) && form.entityTypes.length > 0 ? form.entityTypes.map((e) => e.value) : null;
  return filters;
};

/**
 * Converts AuditLogFilters to AuditLogFilterForm
 * @param filters AuditLogFilters
 * @returns AuditLogFilterForm
 * @author @CorradoSurmanowicz
 */
export const auditLogsFiltersToForm = (filters: AuditLogFilters): AuditLogFilterForm => {
  let form: AuditLogFilterForm = {};
  form.userIds = filters.userIds ? filters.userIds.map((id) => ({ id, name: "" })) : null;
  form.timestampFrom = filters.timestampFrom ? new Date(filters.timestampFrom) : null;
  form.timestampTo = filters.timestampTo ? new Date(filters.timestampTo) : null;
  form.eventIds = filters.eventIds
    ? filters.eventIds.map((value) => ({ id: value, name: value.toString(), value }))
    : null;
  form.apiKeyIds = filters.apiKeyIds ? filters.apiKeyIds.map((id) => ({ id, name: "" })) : null;
  form.entityTypes = filters.entityTypes
    ? filters.entityTypes.map((value) => ({ id: value, name: value.toString(), value }))
    : null;
  return form;
};

export const AuditLogFilterBar = ({
  initialValues,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
  hideEntityTypeFilter,
  setFilters,
}: AuditLogFilterBarProps) => {
  const { register, control, onReset } = useTabStoreFilterSidebar({
    initialValues,
    defaultValues,
    formToFilters: auditLogsFormToFilters,
    onFormChange,
    dispatchTabStore,
    tabsLoading,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <SplitDatePickerFormField
        control={control}
        idStart="timestampFrom"
        idEnd="timestampTo"
        label="Timestamp (from - to)"
        horizontal={false}
        showTimeSelect
      />

      <SelectFormField
        id={"eventIds"}
        label={"Events"}
        {...register}
        items={AuditLogEventTypes.map((t) => {
          const info = eventIdToInfo(t);
          return { id: info.label, name: info?.label, value: t };
        }).sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })}
        control={control}
        horizontal={false}
        isMulti={true}
      />
      <PersonsVirtualizedSelectForm
        id="userIds"
        label="User"
        control={control}
        isMulti
        filters={{
          orderBy: "NAME_ASC",
          includeSystemUsers: true,
          hasAccount: true,
          includeSoftDeleted: true,
        }}
      />

      {/* TODO: ADD API KEYS */}
      {/* <LoadingWrapper status={personsStatus}>
    <SelectFormField
      id={"userIds"}
      label={"User"}
      {...register}
      items={persons}
      control={control}
      horizontal={false}
      isMulti={true}
    />
  </LoadingWrapper> */}

      {!hideEntityTypeFilter && (
        <SelectFormField
          id={"entityTypes"}
          label={"Entity"}
          {...register}
          items={AuditLogEntityTypes.sort().map((t) => {
            const info = entityTypeToInfo(t);
            return { id: info?.label, name: info?.label, value: t };
          })}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      )}
    </EntityFilterSideBar>
  );
};
