import { useHistory } from "react-router-dom";
import { useMemo, useState } from "react";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { EquipmentFilterBar, EquipmentFilterForm } from "./EquipmentFilterBar";
import { NotSet } from "../../common/misc/UIconstants";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { EquipmentFacility, EquipmentFacilityFilters } from "../../api/Facilities";
import { GetOrganizations, GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { ToggleIndicator } from "../../common/badges/ToggleIndicator/ToggleIndicator";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableDeleteButton } from "../../common/entity/entityComponents/EntityTableDeleteButton";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";

export const switchEquipmentsDefaultSortState = (
  sortState: EquipmentFacilityFilters["orderBy"]
): SortState<EquipmentFacilityFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    // case "METHOD_ASC":
    //   return {
    //     sortDirection: "ASC",
    //     headerId: "default-method",
    //     orderBy: sortState,
    //   };
    // case "METHOD_DESC":
    //   return {
    //     sortDirection: "DESC",
    //     headerId: "default-method",
    //     orderBy: sortState,
    //   };
    case "IS_OBSOLETE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-isObsolete",
        orderBy: sortState,
      };
    case "IS_OBSOLETE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-isObsolete",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: EquipmentFacilityFilters = { orderBy: "NAME_ASC" };

export const useEquipmentsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"equipments">) => {
  const defaults: ColumnsSettings<EquipmentFacility> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-isObsolete": { pos: 3, active: true, header: fieldLabels.isObsolete, property: "isObsolete" },
      "default-serialnumber": {
        pos: 4,
        active: true,
        header: fieldLabels.serialnumber,
        property: "serialnumber",
      },
      "default-room": { pos: 5, active: true, header: fieldLabels.room, property: "room" },
      "default-model": { pos: 6, active: true, header: fieldLabels.model, property: "model" },
      "default-localPhone": { pos: 7, active: true, header: fieldLabels.localPhone, property: "localPhone" },
      "default-homeLab": { pos: 8, active: true, header: fieldLabels.homeLab, property: "homeLab" },
      "default-homeLabContacts": {
        pos: 9,
        active: true,
        header: fieldLabels.homeLabContacts,
        property: "homeLabContacts",
      },
      "default-company": { pos: 10, active: true, header: fieldLabels.company, property: "company" },
      "default-companyContacts": {
        pos: 11,
        active: true,
        header: fieldLabels.companyContacts,
        property: "companyContacts",
      },
      "default-createdOn": { pos: 12, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      // "default-createdBy": { pos: 13, active: true, header: equipmentFieldLabels.createdBy, property: "" },
      "default-modifiedOn": { pos: 13, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      // "default-modifiedBy": { pos: 15, active: true, header: equipmentFieldLabels.modifiedBy, property: "" },
    }),
    [
      fieldLabels.company,
      fieldLabels.companyContacts,
      fieldLabels.createdOn,
      fieldLabels.homeLab,
      fieldLabels.homeLabContacts,
      fieldLabels.id,
      fieldLabels.isObsolete,
      fieldLabels.localPhone,
      fieldLabels.model,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.room,
      fieldLabels.serialnumber,
    ]
  );

  const tabStoreDefaults: TableTabsDict<EquipmentFacility, EquipmentFacilityFilters, EquipmentFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );

  return { defaults, tabStoreDefaults };
};

export const useEquipmentsTableColumns = ({
  fieldLabels,
  entityConstants,
  sort,
  setSort,
}: UseEntityTableProps<"equipments">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<EquipmentFacility> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            <LucideIcon name={entityConstants.icon} color="var(--primary)" />
            <span> {row.name}</span>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.isObsolete,
        id: "default-isObsolete",
        accessor: (row) => <ToggleIndicator enabled={!row.isObsolete} labelSet="yes/no" />,
        width: 165,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "IS_OBSOLETE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "IS_OBSOLETE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "IS_OBSOLETE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.room,
        id: "default-room",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.room || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.model,
        id: "default-model",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.model || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.serialnumber,
        id: "default-serialnumber",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.serialnumber || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        id: "default-homeLab",
        Header: fieldLabels.homeLab,
        accessor: (row) =>
          !!row.homeLab ? <GetOrganizations organizations={[row.homeLab]} createLinks={false} /> : NotSet,
        width: 300,
        align: "left",
      },
      {
        id: "default-homeLabContacts",
        Header: fieldLabels.homeLabContacts,
        accessor: (row) =>
          !!row.homeLabContacts?.length ? <GetPersons persons={row.homeLabContacts} createLinks={false} /> : NotSet,
        width: 300,
        align: "left",
      },
      {
        id: "default-company",
        Header: fieldLabels.company,
        accessor: (row) =>
          !!row.company ? <GetOrganizations organizations={[row.company]} createLinks={false} /> : NotSet,
        width: 300,
        align: "left",
      },
      {
        id: "default-companyContacts",
        Header: fieldLabels.companyContacts,
        accessor: (row) =>
          !!row.companyContacts?.length ? <GetPersons persons={row.companyContacts} createLinks={false} /> : NotSet,
        width: 300,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      // {
      //   id: "default-createdBy",
      //   Header: fieldLabels.createdBy,
      //   accessor: (row) => <GetPersons persons={[row.createdBy]} createLinks={false} />,
      //   width: 200,
      //   align: "left",
      //   sortingFn: () => {
      //     if (sort.headerId === "default-createdBy") {
      //       if (sort.sortDirection === "ASC") {
      //         setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
      //
      //       } else {
      //         setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
      //
      //       }
      //     } else {
      //       setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
      //
      //     }
      //   },
      //   sortDirection: (id) => sort.headerId === id ? sort.sortDirection : undefined,
      // },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      // {
      //   id: "default-modifiedBy",
      //   Header: fieldLabels.modifiedBy,
      //   accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
      //   width: 200,
      //   align: "left",
      //   sortingFn: () => {
      //     if (sort.headerId === "default-modifiedBy") {
      //       if (sort.sortDirection === "ASC") {
      //         setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
      //
      //       } else {
      //         setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
      //
      //       }
      //     } else {
      //       setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
      //
      //     }
      //   },
      //   sortDirection: (id) => sort.headerId === id ? sort.sortDirection : undefined,
      // },
    ];

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.company,
    fieldLabels.companyContacts,
    fieldLabels.createdOn,
    fieldLabels.homeLab,
    fieldLabels.homeLabContacts,
    fieldLabels.id,
    fieldLabels.isObsolete,
    fieldLabels.model,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.room,
    fieldLabels.serialnumber,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};

export const EquipmentsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"equipments">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync: deleteEquipment } = entityApi;

  const { defaults, tabStoreDefaults } = useEquipmentsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<EquipmentFacility>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<EquipmentFacility, EquipmentFacilityFilters, EquipmentFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    currentTab,
    tabsLoading,
    filters,
    switchSortState: switchEquipmentsDefaultSortState,
    dispatchTabStore,
  });
  const { columns } = useEquipmentsTableColumns({ entityConstants, fieldLabels, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: true }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            title="Add suppl. equipment"
            onClick={() => history.push(routes.getAddRoute)}
            disabled={!permissions.canCreate}
          >
            <LucideIcon name="plus" /> Add suppl. equipment
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <EquipmentFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            temporaryTabs={temporaryTabs}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<EquipmentFacility, EquipmentFacilityFilters>
                filters={filters}
                excludeFilters={{}}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />

              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                <li>
                  <EntityTableDeleteButton
                    entityConstants={entityConstants}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select equipment to delete`
                    : selection.size === 1
                    ? `Delete the selected equipment?`
                    : `Delete the selected equipments?`
                }`}
                description="Proceeding will permanently delete the selected equipments."
                proceedLabel="Delete"
                onProceed={async () => {
                  await deleteEquipment(
                    {
                      ids: Array.from(selection),
                      goBackOnSuccess: false,
                      showToast: false,
                      entityName: "equipments",
                      params: { deletePermanently: true },
                    },
                    {
                      onSettled: () => {
                        // functionRef.current?.resetSelection();
                      },
                    }
                  ).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
              />
            </EntityTable.Controls>
            <EntityTable.Body<EquipmentFacility, EquipmentFacilityFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
