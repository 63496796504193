import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { ToggleFormField } from "../../../common/formfields/ToggleFormField";
import { LabNotebookEntry, LabNotebookEntryFilters } from "../../types/LabNotebookEntry";
import { LabNotebookMinimalModel } from "../../types/LabNotebook";
import { LabNotebookExperimentMinimalModel } from "../../types/LabNotebookExperiment";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import { LabNotebooksVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/LabNotebooksVirtualizedSelectFormField";
import { LabNotebookExperimentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/LabNotebookExperimentsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { SplitDatePickerFormField } from "../../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { date2localDate } from "../../../common/datetime/DateTimeFormatter";
import { ProjectsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";

interface LabNotebookEntriesFilterBarProps
  extends TabStoreFilterSidebarProps<LabNotebookEntry, LabNotebookEntriesFilterForm, LabNotebookEntryFilters> {
  labNotebookId?: number;
  labNotebookExperimentId?: number;
}

export interface LabNotebookEntriesFilterForm {
  labNotebookIds?: LabNotebookMinimalModel[] | undefined | null;
  labNotebookExperimentIds?: LabNotebookExperimentMinimalModel[] | undefined | null;
  entryDateFrom?: Date | undefined | null;
  entryDateTo?: Date | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  includeSoftDeletedLabNotebookEntries?: boolean | undefined | null;
  isSoftDeletedLabNotebookEntry?: boolean | undefined | null;
  labNotebookProjectIds?: IEntityMinimalModel[] | undefined | null;
}
const defaultValues: DefinedPropertyObject<LabNotebookEntriesFilterForm> = {
  labNotebookIds: null,
  labNotebookExperimentIds: null,
  entryDateFrom: null,
  entryDateTo: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  includeSoftDeletedLabNotebookEntries: false,
  isSoftDeletedLabNotebookEntry: false,
  labNotebookProjectIds: null,
};
export const labNotebookEntriesFormToFilters = (form: LabNotebookEntriesFilterForm): LabNotebookEntryFilters => {
  let filters: LabNotebookEntryFilters = {};

  if (!!form.entryDateFrom) {
    const entryDateFrom = date2localDate(form.entryDateFrom);
    entryDateFrom.setUTCHours(0, 0, 0, 0);
    filters.entryDateFrom = entryDateFrom.toISOString();
  } else {
    filters.entryDateFrom = null;
  }

  if (!!form.entryDateTo) {
    const entryDateTo = date2localDate(form.entryDateTo);
    entryDateTo.setUTCHours(0, 0, 0, 0);
    filters.entryDateTo = entryDateTo.toISOString();
  } else {
    filters.entryDateTo = null;
  }

  filters.labNotebookIds =
    Array.isArray(form.labNotebookIds) && form.labNotebookIds.length > 0 ? form.labNotebookIds?.map((d) => d.id) : null;
  filters.labNotebookExperimentIds =
    Array.isArray(form.labNotebookExperimentIds) && form.labNotebookExperimentIds.length > 0
      ? form.labNotebookExperimentIds?.map((d) => d.id)
      : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.includeSoftDeleted = form.includeSoftDeletedLabNotebookEntries || false;
  filters.isSoftDeleted = form.isSoftDeletedLabNotebookEntry || false;
  filters.labNotebookProjectIds =
    Array.isArray(form.labNotebookProjectIds) && form.labNotebookProjectIds.length > 0
      ? form.labNotebookProjectIds?.map((d) => d.id)
      : null;
  return filters;
};

/**
 * Converts LabNotebookEntryFilters to LabNotebookEntriesFilterForm
 * @param filters LabNotebookEntryFilters
 * @returns LabNotebookEntriesFilterForm
 * @author @CorradoSurmanowicz
 */
export const filtersToLabNotebookEntriesForm = (filters: LabNotebookEntryFilters): LabNotebookEntriesFilterForm => {
  let form: LabNotebookEntriesFilterForm = {};

  if (filters.entryDateFrom) {
    form.entryDateFrom = new Date(filters.entryDateFrom);
  } else {
    form.entryDateFrom = null;
  }

  if (filters.entryDateTo) {
    form.entryDateTo = new Date(filters.entryDateTo);
  } else {
    form.entryDateTo = null;
  }

  form.labNotebookIds = filters.labNotebookIds
    ? filters.labNotebookIds.map((id) => ({ id, name: "", status: "ACTIVE" }))
    : null;
  form.labNotebookExperimentIds = filters.labNotebookExperimentIds
    ? filters.labNotebookExperimentIds.map((id) => ({ id, name: "", status: "RUNNING" }))
    : null;
  form.createdByIds = filters.createdByIds ? filters.createdByIds.map((id) => ({ id, name: "" })) : null;
  form.createdFrom = filters.createdFrom ? new Date(filters.createdFrom) : null;
  form.createdTo = filters.createdTo ? new Date(filters.createdTo) : null;
  form.modifiedByIds = filters.modifiedByIds ? filters.modifiedByIds.map((id) => ({ id, name: "" })) : null;
  form.modifiedFrom = filters.modifiedFrom ? new Date(filters.modifiedFrom) : null;
  form.modifiedTo = filters.modifiedTo ? new Date(filters.modifiedTo) : null;
  form.includeSoftDeletedLabNotebookEntries = filters.includeSoftDeleted || false;
  form.isSoftDeletedLabNotebookEntry = filters.isSoftDeleted || false;
  form.labNotebookProjectIds = filters.labNotebookProjectIds
    ? filters.labNotebookProjectIds.map((id) => ({ id, name: "" }))
    : null;

  return form;
};

const defaultFilters: LabNotebookEntryFilters = { orderBy: "MODIFIED_ON_DESC" };

export const LabNotebookEntriesFilterBar = ({
  initialValues,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
  labNotebookId,
  labNotebookExperimentId,
}: LabNotebookEntriesFilterBarProps) => {
  const { control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: labNotebookEntriesFormToFilters,
    onFormChange,
    dispatchTabStore,
  });
  return (
    <EntityFilterSideBar onReset={onReset}>
      {!labNotebookId && (
        <LabNotebooksVirtualizedSelectForm id="labNotebookIds" label="Notebook" control={control} isMulti />
      )}
      {!labNotebookExperimentId && (
        <LabNotebookExperimentsVirtualizedSelectForm
          id="labNotebookExperimentIds"
          label="Experiment"
          control={control}
          filters={{ labNotebookIds: !!labNotebookId ? [labNotebookId] : null }}
          isMulti
        />
      )}

      {!labNotebookId && !labNotebookExperimentId && (
        <ProjectsVirtualizedSelectForm id="labNotebookProjectIds" control={control} showControls={false} isMulti />
      )}

      <SplitDatePickerFormField
        control={control}
        idStart="entryDateFrom"
        idEnd="entryDateTo"
        label="Entry date (from - to)"
        horizontal={false}
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      {currentTab !== "trash" && (
        <ToggleFormField
          id={"includeSoftDeletedLabNotebookEntries"}
          control={control}
          label={"Include trashed"}
          horizontal
        />
      )}
      {currentTab !== "trash" && (
        <ToggleFormField id={"isSoftDeletedLabNotebookEntry"} control={control} label={"Is trashed"} horizontal />
      )}
    </EntityFilterSideBar>
  );
};
