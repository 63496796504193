import { FindExtension } from "@remirror/extension-find";
import { TableComponents } from "@remirror/extension-react-tables";
import { Mark, Node } from "@remirror/pm/model";
import { Plugin } from "@remirror/pm/state";
import { ReactComponentExtension, Remirror, ThemeProvider, useRemirror } from "@remirror/react";
import {
  Component,
  Dispatch,
  ErrorInfo,
  MutableRefObject,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { InvalidContentHandler, RemirrorJSON, uniqueId } from "remirror";
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CalloutExtension,
  CodeExtension,
  HeadingExtension,
  HorizontalRuleExtension,
  ItalicExtension,
  LinkExtension,
  NodeFormattingExtension,
  OrderedListExtension,
  ParagraphExtension,
  PlaceholderExtension,
  TableExtension as SimpleTableExtension,
  StrikeExtension,
  SubExtension,
  SupExtension,
  TaskListExtension,
  TextColorExtension,
  TextHighlightExtension,
  TrailingNodeExtension,
  UnderlineExtension,
} from "remirror/extensions";
import "remirror/styles/all.css";
import { useManualUpload } from "../../../Dataset/ManualUpload/hook/useManualUpload";
import { useEntityDetail } from "../../../api/BaseEntityApi";
import { Dataset, datasetsConstants } from "../../../api/Datasets";
import { LinkButton } from "../../../common/buttons/LinkButton/LinkButton";
import { SessionContext } from "../../../common/contexts/SessionContext";
import useIntersectionObserver from "../../../common/helperfunctions/useIntersectionObserver";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import { Alert } from "../../../common/overlays/Alert/Alert";
import { showtoast } from "../../../common/overlays/Toasts/showtoast";
import { getDetailLink } from "../../../main/Routing";
import { Modal } from "../ELNModal/ELNModal";
import "./TextEditorRemirror.css";
import { TextEditorEntitySelectComponent } from "./components/TextEditorEntitySelectComponent/TextEditorEntitySelectComponent";
import { TextEditorExternalEventHandler } from "./components/TextEditorExternalEventHandler/TextEditorExternalEventHandler";
import { TextEditorOnChangeHandler } from "./components/TextEditorOnChangeHandler/TextEditorOnChangeHandler";
import { TextEditorTableDropdwon } from "./components/TextEditorTableDropdown/TextEditorTableDropdown";
import { TextEditorToolbar } from "./components/TextEditorToolbar/TextEditorToolbar";
import { ContentPlaceholderNodeExtension } from "./extensions/contentPlaceholder/ContentPlaceholderExtension";
import { DropCursor } from "./extensions/customDropCursor/CustomDropCursor";
import { CustomShortcutsExtension as ShortcutsExtension } from "./extensions/customShortcutsExtension/CustomShortcutsExtension";
import { EntityExtension } from "./extensions/entity/EntityExtension";
import { TimerExtension } from "./extensions/timer/TimerExtension";
import { ViewerModal } from "./extensions/entity/entities/datasets/ViewerModal/ViewerModal";
import { attachmentsConstants } from "../../../api/Attachments";
import { EntityMentionExtension } from "./extensions/entityMention/EntityMentionExtension";

export interface ELNSaveStatus {
  isSaved: boolean;
  isSaving: boolean;
  isError: boolean;
}

export interface ELNSaveParameters {
  content: RemirrorJSON;
  setSaveStatus: Dispatch<SetStateAction<ELNSaveStatus>>;
  onSave?: Function;
  evaluateVersioning?: boolean;
}

export const onClickInEditMode = () =>
  showtoast(
    "info",
    <div>
      <h4>You are currently editing.</h4>
      <div style={{ textAlign: "justify", paddingBottom: "5px" }}>
        If you still want to follow the link, you can use{" "}
        <span style={{ whiteSpace: "nowrap" }}>{"[CTRL + Click]"}</span>.
      </div>
    </div>
  );

interface TextEditorProps {
  editable?: boolean;
  initialContent?: RemirrorJSON;
  save: (params: ELNSaveParameters) => void;
  autosave?: boolean;
  placeholder?: string;
  remirrorContextRef?: MutableRefObject<any>;
  getSaveStatus?: (status: ELNSaveStatus) => void;
  generateIds?: boolean;
  ignoreIntersectionObserver?: boolean;
  toolbarRef?: RefObject<HTMLDivElement>;
  saveButtonRef?: RefObject<HTMLButtonElement>;
  saveCloseButtonRef?: RefObject<HTMLButtonElement>;
  onClose?: () => void;
  onStartEditing?: () => void;
  onEndEditing?: () => void;
}

export const TextEditor = (props: TextEditorProps) => {
  return (
    <ErrorBoundary>
      <TextEditorComponent {...props} />
    </ErrorBoundary>
  );
};

const TextEditorComponent = ({
  editable = false,
  initialContent,
  save,
  autosave = false,
  placeholder = "",
  remirrorContextRef,
  getSaveStatus,
  generateIds = true,
  ignoreIntersectionObserver = false,
  toolbarRef,
  saveButtonRef,
  saveCloseButtonRef,
  onClose,
  onStartEditing,
  onEndEditing,
}: TextEditorProps) => {
  const [initialEditable] = useState(editable);
  const [viewerModalId, setViewerModalId] = useState<number>();
  const dropRef = useRef<HTMLDivElement>(null);
  const [uploadProgress, setUploadProgress] = useState<number>();
  const { uploadedDatasets, uploadFileList, clearUpload } = useManualUpload({
    viewableEntityType: "ELN",
  });

  useEffect(() => {
    if (!!editable) onStartEditing?.();
  }, [editable, onStartEditing]);

  useEffect(() => {
    if (!!initialEditable && !editable) onEndEditing?.();
  }, [editable, initialEditable, onEndEditing]);

  const extensionsWithoutOptions = useMemo(() => {
    return [
      BlockquoteExtension,
      BoldExtension,
      BulletListExtension,
      CalloutExtension,
      CodeExtension,
      ContentPlaceholderNodeExtension,
      HeadingExtension,
      HorizontalRuleExtension,
      ItalicExtension,
      OrderedListExtension,
      ParagraphExtension,
      ReactComponentExtension,
      ShortcutsExtension,
      StrikeExtension,
      SubExtension,
      SupExtension,
      TaskListExtension,
      TextColorExtension,
      TextHighlightExtension,
      TrailingNodeExtension,
      UnderlineExtension,
    ].map((e) => {
      const defaultOptions = {
        extraAttributes: {
          id: ({ attrs }: Node | Mark) => (generateIds ? attrs.id || uniqueId() : undefined),
        },
      };

      return new e(defaultOptions);
    });
  }, [generateIds]);

  const handleUpload = useCallback(
    async (file: File, uuid: string) => {
      await uploadFileList([file], uuid);
    },
    [uploadFileList]
  );

  const extensionsWithOptions = useMemo(() => {
    const defaultOptions = {
      extraAttributes: {
        id: ({ attrs }: Node | Mark) => (generateIds ? attrs.id || uniqueId() : undefined),
      },
    };
    return [
      new EntityExtension({
        ...defaultOptions,
        onDoubleClick: (e, entity, entityTypeId) => {
          (entityTypeId === "datasets" || entityTypeId === "attachments") && setViewerModalId(entity.id);
        },
        ignoreIntersectionObserver,
        uploadHandler: handleUpload,
      }),
      new EntityMentionExtension({
        ...defaultOptions,
        openViewer: (e, entity, entityTypeId) => {
          (entityTypeId === "datasets" || entityTypeId === "attachments") && setViewerModalId(entity.id);
        },
        ignoreIntersectionObserver,
      }),
      new FindExtension({ ...defaultOptions }),
      new LinkExtension({ ...defaultOptions, autoLink: true, selectTextOnClick: true }),
      new NodeFormattingExtension({ indents: [] }),
      new PlaceholderExtension({ ...defaultOptions, placeholder: placeholder }),
      new SimpleTableExtension({
        ...defaultOptions,
        resizable: true,
        resizeableOptions: { cellMinWidth: 50 },
      }),
      new TimerExtension({ ...defaultOptions, editable: editable }),
    ];
  }, [editable, generateIds, handleUpload, ignoreIntersectionObserver, placeholder]);

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    // Automatically remove all invalid nodes and marks.
    return transformers.remove(json, invalidContent);
  }, []);

  const TransformPastePlugin = new Plugin({
    props: {
      transformPasted: (slice, view) => {
        return slice;
      },
      transformCopied: (slice, view) => {
        return slice;
      },
    },
  });

  const { manager } = useRemirror({
    extensions: () => [...extensionsWithoutOptions, ...extensionsWithOptions],
    plugins: [TransformPastePlugin, DropCursor],
    onError,
  });

  const [saveStatus, setSaveStatus] = useState<ELNSaveStatus>({
    isSaved: true,
    isSaving: false,
    isError: false,
  });

  useEffect(() => {
    getSaveStatus && getSaveStatus(saveStatus);
  }, [getSaveStatus, saveStatus]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (uploadProgress && uploadProgress >= 100) {
      timeout = setTimeout(() => {
        setUploadProgress(undefined);
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [uploadProgress]);

  const content: RemirrorJSON = useMemo(() => {
    if (!initialContent) return { type: "doc", content: [{ type: "paragraph", content: [] }] };
    return initialContent;
  }, [initialContent]);

  const initialContentMemo = useMemo(() => {
    try {
      return manager.createState({ content: content });
    } catch (error) {
      console.error("Error creating initial content", error);
      return undefined;
    }
  }, [content, manager]);

  useEffect(() => {
    try {
      initialContentMemo && manager.view.updateState(initialContentMemo);
    } catch (error) {
      console.error("Error updating initial content", error);
    }
  }, [initialContentMemo, manager]);

  const [isVisible, setIsVisible] = useState<boolean>(true);
  const entry = useIntersectionObserver(dropRef, {});
  useEffect(() => {
    if (entry) setIsVisible(entry.isIntersecting);
  }, [entry]);

  return (
    <div className={editable ? "elnEditable" : "elnFixed"} ref={dropRef}>
      {(isVisible || editable || ignoreIntersectionObserver) && (
        <>
          <ThemeProvider>
            <Remirror
              manager={manager}
              initialContent={content}
              autoRender="end"
              editable={editable}
              autoFocus={editable}
            >
              {!editable && !content?.content?.length && (
                <Alert message="No content. Click 'Edit' to start editing." type="light" fit centered />
              )}
              {editable && (
                <TextEditorToolbar
                  save={save}
                  saveStatus={saveStatus}
                  setSaveStatus={setSaveStatus}
                  uploadProgress={uploadProgress}
                  cancelUpload={clearUpload}
                  toolbarRef={toolbarRef}
                  onClose={onClose}
                />
              )}

              {editable && <TextEditorEntitySelectComponent />}

              {dropRef.current && (
                <TextEditorExternalEventHandler
                  target={dropRef.current}
                  remirrorContextRef={remirrorContextRef}
                  uploadedDatasets={uploadedDatasets}
                  editable={editable}
                />
              )}

              {editable && (
                <TextEditorOnChangeHandler
                  save={save}
                  saveStatus={saveStatus}
                  setSaveStatus={setSaveStatus}
                  autosave={autosave}
                  onChange={() => {}}
                  initialContent={content}
                  saveButtonRef={saveButtonRef}
                  saveCloseButtonRef={saveCloseButtonRef}
                />
              )}

              {editable && (
                <TableComponents
                  enableTableCellMenu={true}
                  enableTableDeleteButton={false}
                  enableTableDeleteRowColumnButton={false}
                  tableCellMenuProps={{
                    Component: TextEditorTableDropdwon,
                  }}
                />
              )}
            </Remirror>
          </ThemeProvider>

          <TextEditorViewerModal viewerModalId={viewerModalId} setViewerModalId={setViewerModalId} />
        </>
      )}
    </div>
  );
};

const TextEditorViewerModal = ({
  viewerModalId,
  setViewerModalId,
}: {
  viewerModalId?: number;
  setViewerModalId: Dispatch<SetStateAction<number | undefined>>;
}) => {
  const { route } = useContext(SessionContext);
  const { data: dataset } = useEntityDetail<Dataset>(datasetsConstants.resource, viewerModalId || 0, undefined, {
    enabled: !!viewerModalId,
  });
  if (!dataset) return null;
  return (
    <Modal
      isOpen={!!dataset}
      onClose={() => setViewerModalId(undefined)}
      controls={
        <LinkButton
          linkTo={route(
            getDetailLink(
              dataset?.isViewableEntity
                ? attachmentsConstants.frontendIndexRoute
                : datasetsConstants.frontendIndexRoute,
              dataset.id
            )
          )}
          className="btn btn-primary"
          data-toggle="tooltip"
          title={`Go to ${
            dataset?.isViewableEntity ? attachmentsConstants.entityPlural : datasetsConstants.entityPlural
          }`}
        >
          <LucideIcon name="external-link" />
          <span>
            Go to {dataset?.isViewableEntity ? attachmentsConstants.entityPlural : datasetsConstants.entityPlural}
          </span>
        </LinkButton>
      }
    >
      <ViewerModal id={viewerModalId} />
    </Modal>
  );
};

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error("Uncaught error:", error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Alert message="An error occurred while loading the text editor." type="danger" fit centered />;
    }

    return this.props.children;
  }
}
