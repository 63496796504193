import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { IconNMRHolder, IconNMRHolderFilters, IconNMRHolderStatus } from "../../../api/IconNMR";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
// import { MethodsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { SelectFormField } from "../../../common/formfields/SelectFormField";
import { useUnpaginateOrdered } from "../../../api/BaseEntityApi";
import { DataSource, DataSourceFilters, dataSourceConstants } from "../../../api/DataSource";

export interface IconNMRHoldersFilterForm {
  dataSource?: IEntityMinimalModel[] | undefined | null;
  instrument?: IEntityMinimalModel[] | undefined | null;
  //   method?: IEntityMinimalModel[] | undefined | null;
  status?: IEntityMinimalModel<IconNMRHolderStatus>[] | undefined | null;
}

export const iconNMRHoldersDefaultValues: DefinedPropertyObject<IconNMRHoldersFilterForm> = {
  dataSource: null,
  instrument: null,
  //   method: null,
  status: null,
};

const defaultFilters = { searchTerm: "", orderBy: "QUEUE_POSITION_DESC" } as IconNMRHolderFilters;

export const iconNMRHoldersFormToFilters = (form: IconNMRHoldersFilterForm): IconNMRHolderFilters => {
  let filters: IconNMRHolderFilters = {};

  filters.dataSourceIds =
    Array.isArray(form.dataSource) && form.dataSource.length > 0 ? form.dataSource.map((d) => d.id) : null;
  filters.instrumentIds =
    Array.isArray(form.instrument) && form.instrument.length > 0 ? form.instrument.map((d) => d.id) : null;
  //   filters.methodIds = Array.isArray(form.method) && form.method.length > 0 ? form.method.map((d) => d.id) : null;
  filters.statuses = Array.isArray(form.status) && form.status.length > 0 ? form.status.map((d) => d.id) : null;
  return filters;
};
/**
 * Converts filters to form values for IconNMRHolders.
 *
 * @param filters - The filters to convert.
 * @returns The form values.
 * @author @CorradoSurmanowicz
 */
export const iconNMRHoldersFiltersToForm = (filters: IconNMRHolderFilters): IconNMRHoldersFilterForm => {
  let form: IconNMRHoldersFilterForm = {};

  form.dataSource = filters.dataSourceIds?.map((id) => ({ id, name: "" })) ?? null;
  form.instrument = filters.instrumentIds?.map((id) => ({ id, name: "" })) ?? null;
  form.status = filters.statuses?.map((id) => ({ id, name: "" })) ?? null;

  return form;
};
export const IconNMRHoldersFilterbar = ({
  initialValues,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
  hideReset = false,
}: TabStoreFilterSidebarProps<IconNMRHolder, IconNMRHoldersFilterForm, IconNMRHolderFilters>) => {
  const { data: dataSources } = useUnpaginateOrdered<DataSource, DataSourceFilters>(dataSourceConstants.resource, {
    types: ["IconNMR"],
    enabled: !initialValues?.instrument,
  });

  const { register, control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues: iconNMRHoldersDefaultValues,
    defaultFilters: defaultFilters,
    formToFilters: iconNMRHoldersFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset} hideReset={hideReset}>
      <InstrumentsVirtualizedSelectForm
        id="instrument"
        control={control}
        showControls={false}
        filters={{ ids: dataSources?.filter?.((d) => !!d.instrument)?.map((d) => d.instrument?.id ?? 0) }}
        isMulti
        disabled={currentTab !== "default"}
      />

      <SelectFormField
        id={"status"}
        label={"Status"}
        {...register}
        items={[
          {
            id: "Submitted",
            name: "Submitted",
          },
          {
            id: "Running",
            name: "Running",
          },
          // {
          //   id: "Completed",
          //   name: "Completed",
          // },
        ]}
        control={control}
        horizontal={false}
        isMulti={true}
        placeholder="Select status..."
        isDisabled={currentTab !== "default"}
      />
    </EntityFilterSideBar>
  );
};
