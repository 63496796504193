import React, { useRef, useEffect, CSSProperties } from "react";
import { useEntityDetail } from "../../../api/BaseEntityApi";
import { equipmentsConstants } from "../../../api/Facilities";
import { IEntityMinimalModel, EntityConstants } from "../../../api/GenericTypes";
import { organizationsConstants } from "../../../api/Organizations";
import { Person, PersonFilters, personsConstants } from "../../../api/Person";
import { projectsConstants } from "../../../api/Projects";
import { LinkEntity } from "../LinkEntity/LinkEntity";
import { NotAvailable, NotSet } from "../UIconstants";

/**
 * Renders an array of values in a table format with a maximum length constraint.
 * If the array exceeds the maximum length, it displays a badge indicating the number of additional items.
 * @author @CorradoSurmanowicz
 * @template T - The type of the items in the array.
 * @param {TableArrayRendererProps<T>} props - The properties for the TableArrayRenderer component. * @param {T[]} props.value - The array of values to render.]} * @param {(value: T, index: number) => React.ReactNode} props.children - A function that renders each item in the array.[props.additionalChildren] - Additional children to render after the list.
 * @param {React.CSSProperties} [props.style] - Additional styles to apply to the container.
 * @returns {JSX.Element} The rendered array component.
 */

// Arrays larger than this value will be cut-off and an indicator will be shown
const maxArrayLength = 3;
interface TableArrayRendererProps<T> {
  values: T[] | undefined;
  children: (value: T, index: number) => React.ReactNode;
}
export const TableArrayRenderer = <T extends any>({ values, children }: TableArrayRendererProps<T>) => {
  return (
    <>
      {Array.isArray(values) && !!values.length ? (
        values.length > maxArrayLength ? (
          <div className="flex row-nowrap align-center" style={{ overflow: "hidden" }}>
            {values.slice(0, maxArrayLength).map((v, i) => (
              <div key={i} className="ellipsisContainer" style={{ flex: "1 1 min-content", marginRight: "5px" }}>
                <span>{children(v, i)}</span>
                {i < maxArrayLength - 1 ? ";" : ""}
              </div>
            ))}
            <span className="badge" style={{ flexShrink: 0 }}>{`+${values.length - maxArrayLength}`}</span>
          </div>
        ) : (
          <div className="flex row-nowrap align-center" style={{ overflow: "hidden" }}>
            {values.map((v, i) => (
              <div key={i} className="ellipsisContainer" style={{ flex: "1 1 min-content", marginRight: "5px" }}>
                <span>{children(v, i)}</span>
                {i < values.length - 1 ? ";" : ""}
              </div>
            ))}
          </div>
        )
      ) : (
        NotSet
      )}
    </>
  );
};

/**
 * Renders an array of values using a provided render function for each item.
 * @author @CorradoSurmanowicz
 * @template T - The type of the items in the array.
 * @param {RenderArrayProps<T>} props - The properties for the component.
 * @param {T[]} props.values - The array of values to render.
 * @param {(value: T, index: number) => React.ReactNode} props.children - The render function for each item in the array.
 * @param {React.ReactNode} [props.additionalChildren] - Additional children to render after the list.
 * @param {React.CSSProperties} [props.style] - Additional styles to apply to the container.
 * @returns {JSX.Element} The rendered array component.
 */
interface RenderArrayProps<T> {
  values: T[];
  children?: (value: T, index: number) => JSX.Element;
  additionalChildren?: React.ReactNode;
  style?: React.CSSProperties;
}

export const RenderArray = <T extends any>({ values, children, additionalChildren, style }: RenderArrayProps<T>) => {
  return (
    <div className="flex row-nowrap gap-5" style={{ width: "100%", alignItems: "flex-start", ...style }}>
      {Array.isArray(values) ? (
        <div className="flex col-nowrap" style={{ width: "100%" }}>
          {values?.map((value, index) => (
            <React.Fragment key={index}>{children?.(value, index)}</React.Fragment>
          ))}
        </div>
      ) : (
        NotSet
      )}
      {additionalChildren}
    </div>
  );
};

interface RenderMinimalEntityProps {
  entities: IEntityMinimalModel[] | undefined;
  entityConstants: EntityConstants;
  routeOverride?: string;
  labelOverride?: (entity: IEntityMinimalModel) => IEntityMinimalModel;
  showLinks?: boolean;
  style?: CSSProperties;
}
export const RenderMinimalEntity = ({
  entities,
  entityConstants,
  labelOverride,
  routeOverride,
  showLinks,
  style,
}: RenderMinimalEntityProps) => {
  return (
    <>
      {Array.isArray(entities) && entities.length ? (
        <RenderArray values={entities} style={style}>
          {(value, index) => (
            <LinkEntity
              key={index}
              entityConstants={entityConstants}
              property={labelOverride?.(value) ?? value}
              routeOverride={routeOverride}
              showLink={showLinks}
            />
          )}
        </RenderArray>
      ) : (
        NotSet
      )}
    </>
  );
};

interface getProjectProps {
  projects: IEntityMinimalModel[] | undefined;
  createLinks?: boolean;
}

export const GetProjects = ({ projects, createLinks = true }: getProjectProps) => {
  if (!projects) return NotSet;
  return <RenderMinimalEntity entities={projects} entityConstants={projectsConstants} showLinks={createLinks} />;
};

interface getEquipmentProps {
  equipments: IEntityMinimalModel[] | undefined;
  createLinks?: boolean;
}

export const GetEquipments = ({ equipments, createLinks = true }: getEquipmentProps) => {
  if (!equipments) return NotSet;
  return <RenderMinimalEntity entities={equipments} entityConstants={equipmentsConstants} showLinks={createLinks} />;
};

interface getOrganizationsProps {
  organizations: IEntityMinimalModel[] | undefined;
  createLinks?: boolean;
}
export const GetOrganizations = ({ organizations, createLinks = true }: getOrganizationsProps) => {
  if (!organizations) return NotSet;
  return (
    <RenderMinimalEntity entities={organizations} entityConstants={organizationsConstants} showLinks={createLinks} />
  );
};

const formatPerson = (person: Person) => {
  return `${person.lastName ?? ""}${person.firstName ? ", " + person.firstName.charAt(0).toUpperCase() + "." : ""}`;
};
interface Props {
  personId: number;
  createLinks?: boolean;
  style?: React.CSSProperties;
}
export const ShowPerson = ({ personId, createLinks = true, style }: Props) => {
  const { data: person } = useEntityDetail<Person, PersonFilters>("persons", personId!, undefined, {
    enabled: !!personId,
  });

  return (
    <>
      {person ? (
        createLinks ? (
          <LinkEntity
            iconOverride={person.isSystemUser ? "monitor-cog" : undefined}
            entityConstants={personsConstants}
            property={person}
            labelOverride={formatPerson(person)}
            style={style}
          />
        ) : (
          // <Link onClick={goToPersonDetailView} to={route(getDetailLink("persons", personId))}>
          //   {formatPerson(person)}
          // </Link>
          formatPerson(person)
        )
      ) : (
        NotAvailable
      )}
    </>
  );

  // return <>{person && <a href={route(getDetailLink("persons", personId))}>{formatPerson(person)}</a>}</>;
};

const MemoizedPerson = React.memo(ShowPerson);

interface GetPersonsProps<T extends IEntityMinimalModel> {
  persons: T[] | T | undefined | null;
  createLinks?: boolean;
  style?: React.CSSProperties;
}
export const GetPersons = <T extends IEntityMinimalModel>({
  persons,
  createLinks = true,
  style,
}: GetPersonsProps<T>) => {
  const mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  if (!persons || (Array.isArray(persons) && !persons.length)) return NotSet;
  return (
    <>
      {mounted.current ? (
        <>
          {createLinks ? (
            <div
              key={"operators"}
              style={{
                display: "flex",
                flexFlow: "row wrap",
                gap: 0,
                width: "100%",
                height: "100%",
                alignItems: "center",
                overflow: "auto",
                color: "var(--petrolblue)",
                whiteSpace: "nowrap",
              }}
            >
              <>
                {Array.isArray(persons) ? (
                  persons
                    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                    .map((person, index) => (
                      <React.Fragment key={index}>
                        <MemoizedPerson key={index} personId={person.id} createLinks={createLinks} style={style} />
                        {persons.length > 1 && index < persons.length - 1 ? <span>;&nbsp;</span> : ""}
                      </React.Fragment>
                    ))
                ) : (
                  <MemoizedPerson personId={persons.id} createLinks={createLinks} style={style} />
                )}
              </>
            </div>
          ) : (
            <div
              key={"persons"}
              style={{
                display: "flex",
                flexFlow: "row wrap",
                gap: 0,
                width: "100%",
                height: "100%",
                alignItems: "center",

                color: "var(--petrolblue)",
              }}
            >
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {Array.isArray(persons) ? (
                  persons
                    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                    .map((person, index) => (
                      <React.Fragment key={index}>
                        {/* <MemoizedPerson key={index} personId={operator.id} createLinks={createLinks} /> */}
                        {person.name}
                        {persons.length > 1 && index < persons.length - 1 ? <span>;&nbsp;</span> : ""}
                      </React.Fragment>
                    ))
                ) : (
                  <>{persons.name}</>
                )}
              </span>
            </div>
          )}
        </>
      ) : (
        NotSet
      )}
    </>
  );
};
