import { useCallback, useEffect, useMemo } from "react";
import {
  IconNMRHistoryEntry,
  IconNMRHistoryEntryFilters,
  IconNMRHistoryEntryFiltersTranslator,
  iconNMRHoldersConstants,
  iconNMRStatusConstants,
} from "../../../api/IconNMR";
import { ColumnsSettings } from "../../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { TableTabsDict } from "../../../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../../../common/tables/Tabs/useTabStore";
import { DateTimeRenderer } from "../../../common/datetime/DateTimeFormatter";
import { NotAvailable, NotSet } from "../../../common/misc/UIconstants";
import { EntityTableTabs } from "../../../common/tables/Tabs/EntityTableTabs";
import { EntityTable } from "../../../common/tables/EntityTable/EntityTable";
import { SearchInput } from "../../../common/forms/SearchInput/SearchInput";
import styles from "../../../common/tables/GenericVirtualizedTable/commons.module.css";
import { DataSource, DataSourceFilters, dataSourceConstants } from "../../../api/DataSource";
import { IconNMRHistoryFilterForm, IconNMRHistoryFilterbar } from "./IconNMRHistoryFilterBar";
import { instrumentsConstants } from "../../../api/Facilities";
import { LoadingWrapper } from "../../../common/LoadingWrapper";
import { EntityFilterIndicator } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import TableView from "../../../common/panels/TableView/TableView";
import { useUnpaginateOrdered } from "../../../api/BaseEntityApi";
import { useQueryClient } from "@tanstack/react-query";
import { LucideIcon } from "../../../common/icon/LucideIcon";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../../common/entity/EntityInterfaces";

const calculateDuration = (completionTime?: string, startTime?: string) => {
  if (!completionTime || !startTime) return NotAvailable;
  let end: any = new Date(completionTime);
  let start: any = new Date(startTime);
  let diff = Math.abs(end - start);
  let hours = Math.floor(diff / 1000 / 60 / 60);
  let min = Math.floor(diff / 1000 / 60) - hours * 60;
  let s = Math.floor(diff / 1000) - hours * 60 * 60 - min * 60;
  return `${hours < 10 ? "0" + hours : hours}:${min < 10 ? "0" + min : min}:${s < 10 ? "0" + s : s}`;
};

export const switchPersonsDefaultSortState = (
  sortState: IconNMRHistoryEntryFilters["orderBy"]
): SortState<IconNMRHistoryEntryFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "START_TIME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-startTime",
        orderBy: sortState,
      };
    case "START_TIME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-startTime",
        orderBy: sortState,
      };
    case "COMPLETION_TIME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-completionTime",
        orderBy: sortState,
      };
    case "COMPLETION_TIME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-completionTime",
        orderBy: sortState,
      };
    case "USER_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-user",
        orderBy: sortState,
      };
    case "USER_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-user",
        orderBy: sortState,
      };
    case "HOLDER_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-holder",
        orderBy: sortState,
      };
    case "HOLDER_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-holder",
        orderBy: sortState,
      };
    case "DISK_UNIT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-diskUnit",
        orderBy: sortState,
      };
    case "DISK_UNIT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-diskUnit",
        orderBy: sortState,
      };
    case "EXPNO_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-expno",
        orderBy: sortState,
      };
    case "EXPNO_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-expno",
        orderBy: sortState,
      };
    case "DATA_SOURCE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-dataSource",
        orderBy: sortState,
      };
    case "DATA_SOURCE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-dataSource",
        orderBy: sortState,
      };
    case "INSTRUMENT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-instrument",
        orderBy: sortState,
      };
    case "INSTRUMENT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-instrument",
        orderBy: sortState,
      };
    case "METHOD_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-method",
        orderBy: sortState,
      };
    case "METHOD_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-method",
        orderBy: sortState,
      };

    default:
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
  }
};

// const defaultFilter: IconNMRHistoryEntryFilters = { orderBy: "START_TIME_DESC" };

export const useIconNMRHistoryTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"iconNMRHistoryEntry">) => {
  const {
    data: dataSources,
    status,
    fetchStatus,
    error,
  } = useUnpaginateOrdered<DataSource, DataSourceFilters>(dataSourceConstants.resource, {
    types: ["IconNMR"],
    enabled: true,
  });

  const defaults: ColumnsSettings<IconNMRHistoryEntry> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id },
      "default-startTime": { pos: 1, active: true, header: fieldLabels.startTime },
      "default-completionTime": { pos: 2, active: true, header: fieldLabels.completionTime },
      "default-holder": { pos: 3, active: true, header: fieldLabels.holder },
      "default-dataset": { pos: 4, active: true, header: fieldLabels.datasetName },
      "default-expno": { pos: 5, active: true, header: fieldLabels.experimentNo },
      "default-experiment": { pos: 6, active: true, header: fieldLabels.experimentName },
      "default-duration": { pos: 7, active: true, header: fieldLabels.duration },
      "default-originatorTitle": { pos: 8, active: true, header: fieldLabels.originatorTitle },
      "default-instrument": { pos: 9, active: true, header: fieldLabels.instrument },
      "default-method": { pos: 10, active: false, header: fieldLabels.method },
      "default-sampleLoad": { pos: 11, active: true, header: fieldLabels.sampleLoad },
      "default-lock": { pos: 12, active: true, header: fieldLabels.lock },
      "default-shim": { pos: 13, active: true, header: fieldLabels.shim },
      "default-acquisition": { pos: 14, active: true, header: fieldLabels.acquisition },
      "default-processing": { pos: 15, active: true, header: fieldLabels.processing },
      "default-remarks": { pos: 16, active: true, header: fieldLabels.remarks },
      "default-dataSource": { pos: 17, active: false, header: fieldLabels.dataSource },
      "default-user": { pos: 18, active: false, header: fieldLabels.user },
      "default-diskUnit": { pos: 19, active: false, header: fieldLabels.diskUnit },
      "default-statusInformationForATM": { pos: 20, active: false, header: fieldLabels.statusInformationForATM },
      "default-rotation": { pos: 21, active: false, header: fieldLabels.rotation },
      "default-tubeId": { pos: 22, active: false, header: fieldLabels.tubeId },
      "default-analysisResult": { pos: 23, active: false, header: fieldLabels.analysisResult },
      "default-analysisOutput": { pos: 24, active: false, header: fieldLabels.analysisOutput },
      "default-sBase": { pos: 25, active: false, header: fieldLabels.sBase },
      "default-setupRow": { pos: 26, active: false, header: fieldLabels.setupRow },
      "default-consistency": { pos: 27, active: false, header: fieldLabels.consistency },
    }),
    [fieldLabels]
  );

  const tabStoreDefaults = useMemo(() => {
    let def: TableTabsDict<IconNMRHistoryEntry, IconNMRHistoryEntryFilters, IconNMRHistoryFilterForm> = {
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: { orderBy: "START_TIME_DESC" },
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    };
    if (dataSources) {
      dataSources.forEach((d, i) => {
        if (!!d.instrument && d.enabled)
          def[`instrument-${d.instrument.id}`] = {
            tabId: `instrument-${d.instrument.id}`,
            type: "fixed",
            label: d.instrument?.name ?? d.name,
            title: d.instrument?.name ?? d.name,
            icon: instrumentsConstants.icon,
            align: "left",
            xPos: 2 + i,
            settings: {
              columnSettings: { ...defaults, "default-instrument": { pos: 10, active: false, header: "Instrument" } },
              columnWidths: {},
              filters: {
                instrumentIds: [d.instrument.id],
                orderBy: "START_TIME_DESC",
              },
              sidebarFilters: {
                instrument: [d.instrument],
              },
            },
            forcedSettings: {
              columnSettings: {},
              columnWidths: {},
              filters: { instrumentIds: [d.instrument.id] },
              sidebarFilters: {},
            },
          };
      });
    }
    return def as TableTabsDict<IconNMRHistoryEntry, IconNMRHistoryEntryFilters, IconNMRHistoryFilterForm>;
  }, [dataSources, defaults]);

  return { defaults, tabStoreDefaults, status, fetchStatus, error };
};

export const useIconNMRHistoryEntriesTableColumns = ({
  sort,
  setSort,
  fieldLabels,
}: UseEntityTableProps<"iconNMRHistoryEntry">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<IconNMRHistoryEntry> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.startTime,
        id: "default-startTime",
        accessor: (row) => <DateTimeRenderer date={row.startTime} includeElapsed={false} />,
        minWidth: 120,
        width: 180,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "START_TIME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "START_TIME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "START_TIME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.completionTime,
        id: "default-completionTime",
        accessor: (row) => <DateTimeRenderer date={row.completionTime} includeElapsed={false} />,
        minWidth: 120,
        width: 180,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "COMPLETION_TIME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "COMPLETION_TIME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "COMPLETION_TIME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.duration,
        id: "default-duration",
        accessor: (row) => calculateDuration(row.completionTime, row.startTime),
        minWidth: 120,
        width: 180,
        align: "left",
      },
      {
        Header: fieldLabels.experimentName,
        id: "default-experiment",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.experimentName ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.experimentNo,
        id: "default-expno",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.experimentNo ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "EXPNO_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "EXPNO_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "EXPNO_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.user,
        id: "default-user",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.user ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 150,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "USER_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "USER_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "USER_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.instrument,
        id: "default-instrument",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.dataSource.instrument.name}</span>
          </div>
        ),
        minWidth: 120,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "INSTRUMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "INSTRUMENT_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "INSTRUMENT_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.method,
        id: "default-method",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.dataSource.method.name}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "METHOD_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "METHOD_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "METHOD_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.holder,
        id: "default-holder",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.holder ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "HOLDER_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "HOLDER_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "HOLDER_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.datasetName,
        id: "default-dataset",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.datasetName ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.acquisition,
        id: "default-acquisition",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.acquisition ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 120,
        align: "left",
      },
      {
        Header: fieldLabels.originatorTitle,
        id: "default-originatorTitle",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.originatorTitle ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.diskUnit,
        id: "default-diskUnit",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.diskUnit ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "DISK_UNIT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "DISK_UNIT_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "DISK_UNIT_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.statusInformationForATM,
        id: "default-statusInformationForATM",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.statusInformationForATM ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.rotation,
        id: "default-rotation",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.rotation ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.lock,
        id: "default-lock",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.lock ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.shim,
        id: "default-shim",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.shim ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.processing,
        id: "default-processing",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.processing ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.remarks,
        id: "default-remarks",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.remarks ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.sampleLoad,
        id: "default-sampleLoad",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.sampleLoad ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.tubeId,
        id: "default-tubeId",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.tubeId ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.analysisResult,
        id: "default-analysisResult",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.analysisResult ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.analysisOutput,
        id: "default-analysisOutput",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.analysisOutput ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.sBase,
        id: "default-sBase",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.sBase ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.setupRow,
        id: "default-setupRow",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.setupRow ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.consistency,
        id: "default-consistency",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>{row.consistency ?? NotSet}</span>
          </div>
        ),
        minWidth: 120,
        width: 100,
        align: "left",
      },
      {
        Header: fieldLabels.dataSource,
        id: "default-dataSource",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis}`}>
            <span>
              <LucideIcon name={dataSourceConstants.icon} /> {row.dataSource.name}
            </span>
          </div>
        ),
        minWidth: 120,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "DATA_SOURCE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "DATA_SOURCE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "DATA_SOURCE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [setSort, sort.headerId, sort.sortDirection, fieldLabels]);
  return { columns };
};

export const IconNMRHistoryTableWrapper = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"iconNMRHistoryEntry">) => {
  const { defaults, tabStoreDefaults, status, fetchStatus, error } = useIconNMRHistoryTableDefaults({ fieldLabels });

  if (!tabStoreDefaults)
    return (
      <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
        Fetching IconNMR instruments...
      </LoadingWrapper>
    );
  return (
    <IconNMRHistoryTable
      defaults={defaults}
      tabStoreDefaults={tabStoreDefaults}
      entityApi={entityApi}
      entityConstants={entityConstants}
      fieldLabels={fieldLabels}
      permissions={permissions}
      routes={routes}
    />
  );
};

export const IconNMRHistoryTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
  defaults,
  tabStoreDefaults,
}: {
  defaults: ColumnsSettings<IconNMRHistoryEntry>;
  tabStoreDefaults: TableTabsDict<IconNMRHistoryEntry, IconNMRHistoryEntryFilters, IconNMRHistoryFilterForm>;
} & EntityTableProps<"iconNMRHistoryEntry">) => {
  const { resultsCount, onCountChange } = useGenericVirtualizedTable<IconNMRHistoryEntry>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<IconNMRHistoryEntry, IconNMRHistoryEntryFilters, IconNMRHistoryFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    currentTab,
    tabsLoading,
    filters,
    switchSortState: switchPersonsDefaultSortState,
    dispatchTabStore,
  });
  const { columns } = useIconNMRHistoryEntriesTableColumns({ fieldLabels, entityConstants, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: true }),
    [filters, forcedFilters]
  );

  const queryClient = useQueryClient();

  const refetchData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [iconNMRStatusConstants.resource] });
    queryClient.invalidateQueries({ queryKey: [iconNMRHoldersConstants.resource] });
    queryClient.invalidateQueries({ queryKey: [entityConstants.resource] });
  }, [entityConstants.resource, queryClient]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, [refetchData]);

  return (
    <TableView style={{ marginTop: 0, paddingTop: 0 }}>
      <TableView.Body>
        <TableView.Body.Sidebar defaultSize={0}>
          <IconNMRHistoryFilterbar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
            hideReset={currentTab !== "default"}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            temporaryTabs={temporaryTabs}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
            showAddTab={false}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<IconNMRHistoryEntry, IconNMRHistoryEntryFilters>
                filters={filters}
                excludeFilters={{}}
                translatorConsts={IconNMRHistoryEntryFiltersTranslator}
                showAlways
              />

              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />

              <div className="flex align-center gap-5">
                <span style={{ color: "var(--gray-300)", paddingRight: "5px" }}>|</span>
                <span style={{ color: "var(--gray-500)", whiteSpace: "nowrap" }}>
                  Results with <LucideIcon name="filter" />:
                </span>
                <span className="badge">{resultsCount ?? NotAvailable}</span>
                <span style={{ color: "var(--gray-300)", paddingLeft: "5px" }}>|</span>
              </div>
              <button onClick={refetchData} className="btn btn-ghost-primary btn-xs flex align-center gap-5">
                <LucideIcon name="refresh-cw" />
                Refresh
              </button>
            </EntityTable.Controls>
            <EntityTable.Body<IconNMRHistoryEntry, IconNMRHistoryEntryFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              // onSelectionChange={onSelectionChange}
              //   onSelectionPermissions={onSelectionPermissions}
              //   showPermissionColumn
              loading={tabsLoading}
              onRowClick={() => {}}
              linkTo={() => "#"}
              disableCheckboxes
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
