import { CSSProperties, useCallback, useMemo, useState } from "react";
import { AuditLog, AuditLogFilters } from "../api/AuditLog";
import { useInfiniteListEntity } from "../api/BaseEntityApi";
import { Tile } from "../common/panels/Tile/Tile";
import { LoadingWrapper } from "../common/LoadingWrapper";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import ContentRow from "../common/tables/ContentRow/ContentRow";
import styles from "./AuditLog.module.css";
import { LucideIcon } from "../common/icon/LucideIcon";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { Alert } from "../common/overlays/Alert/Alert";
import { Skeleton } from "../common/loaders/Skeleton/Skeleton";
import { ResourceName } from "../main/Routing";
import { entityTypeToInfo, eventIdToInfo } from "./AuditLog";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import { useDebouncedValue } from "../common/helperfunctions/useDebouncedValue";
import { toUppercase } from "../common/helperfunctions/stringFunctions";

export const AuditLogTile = ({
  title = "Events",
  resource,
  id,
  filter,
  foldable = false,
}: {
  title?: string;
  resource?: ResourceName;
  id?: string | number;
  filter?: AuditLogFilters;
  foldable?: boolean;
}) => {
  const pageSize = 10;

  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchTerm = useDebouncedValue(searchValue, 300);

  const { data, isFetching, hasNextPage, fetchNextPage, isFetched, fetchStatus, status, error } = useInfiniteListEntity<
    AuditLog,
    AuditLogFilters
  >(
    resource ? (id ? (`${resource}/${id}/audit_logs` as any) : (`${resource}/audit_logs` as any)) : "audit_logs",
    {
      page: 1,
      pageSize,
      searchTerm: debouncedSearchTerm || undefined,
      orderBy: "ID_DESC",
      ...filter,
    },
    {},
    "post"
  );

  const auditLogs = useMemo(() => {
    return data?.pages.map((d) => d.results).flat() || [];
  }, [data?.pages]);

  const fetchNext = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? auditLogs.length + 1 : auditLogs.length;
  const loadMoreItems = isFetching ? loadMoreCallback : fetchNext;
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < auditLogs.length,
    [hasNextPage, auditLogs.length]
  );

  const Item = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const current_element = auditLogs[index];
      if (!isItemLoaded(index) || !isFetched) {
        return (
          <div style={{ ...style, paddingBottom: "5px" }} key={index}>
            <span className="skeleton-block btn-lg" style={{ height: 51 }} />
          </div>
        );
      } else {
        const eventInfo = eventIdToInfo(current_element.eventId);
        const entityTypeInfo = entityTypeToInfo(current_element.entityType);
        return (
          <div style={{ ...style, paddingBottom: "5px" }} key={index}>
            <ContentRow
              key={index}
              date={
                <div
                  style={{ fontSize: "12px", color: "var(--gray-400)", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {entityTypeInfo?.label ? toUppercase(entityTypeInfo.label) : "-"}
                </div>
              }
              info={
                <div style={{ color: "var(--gray-800)" }}>
                  <DateTimeRenderer date={current_element.timestamp} includeElapsed={true} includeTime={true} />
                </div>
              }
              id={<div>by {current_element.user?.name || "-"}</div>}
              label={
                <div className="flex align-center gap-5">
                  <div className={`label label-soft-${eventInfo.color}`}>
                    {eventInfo.icon && <LucideIcon name={eventInfo.icon} />}
                    <span> {eventInfo.label}</span>
                  </div>
                </div>
              }
            />
          </div>
        );
      }
    },
    [auditLogs, isFetched, isItemLoaded]
  );

  return (
    <Tile foldable={foldable}>
      <Tile.Head title={title}>
        <Tile.Head.Controls>
          <Tile.Head.Controls.Unfolded>
            <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search..." />
          </Tile.Head.Controls.Unfolded>
        </Tile.Head.Controls>
      </Tile.Head>
      <Tile.Body>
        <div style={{ width: "100%" }}>
          <LoadingWrapper status={status} error={error} fetchStatus={fetchStatus} type="skeleton-rows">
            <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
              {auditLogs && auditLogs.length > 0 ? (
                <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadMoreItems as any}>
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      itemCount={itemCount}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                      width="100%"
                      height={auditLogs.length > pageSize ? pageSize * 59 : auditLogs.length * 59}
                      itemSize={59}
                    >
                      {Item}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              ) : (
                <>
                  {isFetching ? (
                    <Skeleton type="rows" />
                  ) : (
                    <>
                      {/* {debouncedSearchTerm ? (
                        <Alert type="light" message={`No results for: "${debouncedSearchTerm}"`} fit centered />
                      ) : ( */}
                      <Alert type="light" message="No events" fit centered />
                      {/* )} */}
                    </>
                  )}
                </>
              )}
            </div>
          </LoadingWrapper>
        </div>
      </Tile.Body>
    </Tile>
  );
};
