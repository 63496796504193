import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { Project, ProjectFilters, ProjectFiltersTranslator, projectsConstants } from "../../api/Projects";
import { ProjectFilterBar, ProjectFilterForm } from "./ProjectFilterBar";
import { NotSet } from "../../common/misc/UIconstants";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
// import { datasetsConstants } from "../../api/Datasets";
// import { samplesConstants } from "../../api/Samples";
// import { labNoteBooksConstants } from "../../ELN/types/LabNotebook";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { ExportCsvButton } from "../../common/tables/ExportCsvButton/ExportCsvButton";
import { AddCustomTypeEntity } from "../../Customization/CustomTypes/generics/AddCustomTypeEntity";
import { useCustomTypesEntityTable } from "../../Customization/CustomTypes/generics/useCustomTypesEntityTable";
import { renderCustomTypeColumn } from "../../Customization/CustomTypes/generics/CustomTypeRenderer";
import {
  UseEntityTableDefaultProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
  UseITypedEntityTableProps,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableDeleteButton } from "../../common/entity/entityComponents/EntityTableDeleteButton";

export const switchProjectsDefaultSortState = (
  sortState: ProjectFilters["orderBy"]
): SortState<ProjectFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "DATASET_COUNT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-stats-datasets",
        orderBy: sortState,
      };
    case "DATASET_COUNT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-stats-datasets",
        orderBy: sortState,
      };
    case "SAMPLE_COUNT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-stats-samples",
        orderBy: sortState,
      };
    case "SAMPLE_COUNT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-stats-samples",
        orderBy: sortState,
      };
    case "DOCUMENT_COUNT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-stats-documnets",
        orderBy: sortState,
      };
    case "DOCUMENT_COUNT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-stats-documnets",
        orderBy: sortState,
      };
    case "LAB_NOTEBOOK_COUNT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-stats-lab-notebooks",
        orderBy: sortState,
      };
    case "LAB_NOTEBOOK_COUNT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-stats-lab-notebooks",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-type",
        orderBy: sortState,
      };
    case "TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-type",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: ProjectFilters = { orderBy: "NAME_ASC" };

export const useProjectsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"projects">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<Project> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      ...(session?.features.enable_project_custom_types && {
        "default-type": { pos: 2, active: true, header: fieldLabels.customType, property: "customType" },
      }),
      "default-projectTags": { pos: 3, active: true, header: fieldLabels.projectTags, property: "projectTags" },
      // "default-stats-datasets": { pos: 3, active: true, header: "Datasets" , property: ""},
      // "default-stats-samples": { pos: 4, active: true, header: "Samples" , property: ""},
      // "default-stats-documents": { pos: 5, active: true, header: "Documents" , property: ""},
      // "default-stats-lab-notebooks": { pos: 6, active: true, header: "Notebooks" , property: ""},
      "default-createdOn": { pos: 4, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 5, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 6, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 7, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
    }),
    [
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.customType,
      fieldLabels.id,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.projectTags,
      session?.features.enable_project_custom_types,
    ]
  );

  const tabStoreDefaults: TableTabsDict<Project, ProjectFilters, ProjectFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      mydata: {
        tabId: "mydata",
        type: "fixed",
        label: `My ${projectsConstants.entityPlural}`,
        title: `My ${projectsConstants.entityPlural}`,
        icon: "user",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { createdByIds: session?.person.id ? [session.person.id] : null },
          sidebarFilters: {},
        },
      },
    }),
    [session?.person.id]
  );

  return { defaults, tabStoreDefaults };
};

export const useProjectsTableColumns = ({
  entityConstants,
  fieldLabels,
  defaults,
  filters,
  sort,
  setSort,
}: UseITypedEntityTableProps<"projects">) => {
  const { session } = useContext(SessionContext);

  // Custom Fields
  const { columnSettings, customFieldHeaders } = useCustomTypesEntityTable({
    defaultColumnSettings: defaults,
    entityType: "Project",
    featureFlag: session?.features.enable_project_custom_types,
    customTypeIds: filters?.customTypeIds ?? undefined,
  });

  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Project> = [
      {
        id: "default-id",
        Header: "Project-ID",
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            <LucideIcon name={entityConstants.icon} color="var(--primary)" />
            <span> {row.name}</span>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-projectTags",
        Header: fieldLabels.projectTags,
        accessor: (row) => (
          <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {!!row.projectTags?.length
              ? row.projectTags.map((t, i) => (
                  <span key={i} className="label label-soft-secondary" style={{ marginRight: "5px" }}>
                    {t.name}
                  </span>
                ))
              : NotSet}
          </div>
        ),
        minWidth: 100,
        width: 400,
        align: "left",
      },
      // {
      //   id: "default-stats-datasets",
      //   Header: (
      //     <div>
      //       <FeatherIcon name={datasetsConstants.icon} />
      //       <span> Datasets</span>
      //     </div>
      //   ),
      //   accessor: (row) => (
      //     <div style={{ display: "flex", flexFlow: "column nowrap", gap: 0 }}>
      //       <span className="badge">{row.relations?.datasets.count}</span>
      //     </div>
      //   ),
      //   width: 150,
      //   align: "left",
      //   sortingFn: () => {
      //     if (sort.headerId === "default-stats-datasets") {
      //       if (sort.sortDirection === "ASC") {
      //         setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "DATASET_COUNT_DESC" }));
      //       } else {
      //         setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "DATASET_COUNT_ASC" }));
      //       }
      //     } else {
      //       setSort({ headerId: "default-stats-datasets", sortDirection: "ASC", orderBy: "DATASET_COUNT_ASC" });
      //     }
      //   },
      //   sortDirection: (id) => sort.headerId === id ? sort.sortDirection : undefined,
      // },
      // {
      //   id: "default-stats-samples",
      //   Header: (
      //     <div>
      //       <FeatherIcon name={samplesConstants.icon} />
      //       <span> Samples</span>
      //     </div>
      //   ),
      //   accessor: (row) => (
      //     <div style={{ display: "flex", flexFlow: "column nowrap", gap: 0 }}>
      //       <span className="badge">{row.relations?.samples.count}</span>
      //     </div>
      //   ),
      //   width: 150,
      //   align: "left",
      //   sortingFn: () => {
      //     if (sort.headerId === "default-stats-samples") {
      //       if (sort.sortDirection === "ASC") {
      //         setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "SAMPLE_COUNT_DESC" }));
      //       } else {
      //         setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "SAMPLE_COUNT_ASC" }));
      //       }
      //     } else {
      //       setSort({ headerId: "default-stats-samples", sortDirection: "ASC", orderBy: "SAMPLE_COUNT_ASC" });
      //     }
      //   },
      //   sortDirection: (id) => sort.headerId === id ? sort.sortDirection : undefined,
      // },
      // {
      //   id: "default-stats-documents",
      //   Header: (
      //     <div>
      //       <FeatherIcon name="file-text" />
      //       <span> Documents</span>
      //     </div>
      //   ),
      //   accessor: (row) => (
      //     <div style={{ display: "flex", flexFlow: "column nowrap", gap: 0 }}>
      //       <span className="badge">{row.relations?.documents.count}</span>
      //     </div>
      //   ),
      //   width: 150,
      //   align: "left",
      //   sortingFn: () => {
      //     if (sort.headerId === "default-stats-documents") {
      //       if (sort.sortDirection === "ASC") {
      //         setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "DOCUMENT_COUNT_DESC" }));
      //       } else {
      //         setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "DOCUMENT_COUNT_ASC" }));
      //       }
      //     } else {
      //       setSort({ headerId: "default-stats-documents", sortDirection: "ASC", orderBy: "DOCUMENT_COUNT_ASC" });
      //     }
      //   },
      //   sortDirection: (id) => sort.headerId === id ? sort.sortDirection : undefined,
      // },
      // {
      //   id: "default-stats-lab-notebooks",
      //   Header: (
      //     <div>
      //       <FeatherIcon name={labNoteBooksConstants.icon} />
      //       <span> Notebooks</span>
      //     </div>
      //   ),
      //   accessor: (row) => (
      //     <div style={{ display: "flex", flexFlow: "column nowrap", gap: 0 }}>
      //       <span className="badge">{row.relations?.labNotebooks.count}</span>
      //     </div>
      //   ),
      //   width: 150,
      //   align: "left",
      //   sortingFn: () => {
      //     if (sort.headerId === "default-stats-lab-notebooks") {
      //       if (sort.sortDirection === "ASC") {
      //         setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "LAB_NOTEBOOK_COUNT_DESC" }));
      //       } else {
      //         setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "LAB_NOTEBOOK_COUNT_ASC" }));
      //       }
      //     } else {
      //       setSort({
      //         headerId: "default-stats-lab-notebooks",
      //         sortDirection: "ASC",
      //         orderBy: "LAB_NOTEBOOK_COUNT_ASC",
      //       });
      //     }
      //   },
      //   sortDirection: (id) => sort.headerId === id ? sort.sortDirection : undefined,
      // },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];
    if (session?.features.enable_project_custom_types) {
      // Add custom field headers
      headers.push(
        renderCustomTypeColumn<Project, ProjectFilters["orderBy"], ProjectFilters>(entityConstants, sort, setSort)
      );
      headers = headers.concat(customFieldHeaders);
    }
    return headers;
  }, [
    customFieldHeaders,
    entityConstants,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.projectTags,
    session?.features.enable_project_custom_types,
    setSort,
    sort,
  ]);

  return { columns, columnSettings };
};

export const ProjectsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"projects">) => {
  const [showModal, setShowModal] = useState(false);

  const { session } = useContext(SessionContext);

  const { bulkDeleteMutationAsync: deleteProject, isLoadingBulkDeleteMutation } = entityApi;
  const { defaults, tabStoreDefaults } = useProjectsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Project>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<Project, ProjectFilters, ProjectFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue, debouncedSearchValue } =
    useGenericVirtualizedTableTabs({
      currentTab,
      tabsLoading,
      filters,
      switchSortState: switchProjectsDefaultSortState,
      dispatchTabStore,
    });

  const { columns, columnSettings } = useProjectsTableColumns({
    fieldLabels,
    entityConstants,
    defaults,
    filters,
    sort,
    setSort,
  });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: false }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <AddCustomTypeEntity
            entityConstants={entityConstants}
            canCreateEntityPermission={permissions.canCreate}
            entityType={"Project"}
            featureFlag={session?.features.enable_project_custom_types}
          />
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <ProjectFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            temporaryTabs={temporaryTabs}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<Project, ProjectFilters>
                filters={filters}
                excludeFilters={{}}
                translatorConsts={ProjectFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                <li>
                  <ExportCsvButton
                    entityConstants={entityConstants}
                    columnSettings={columnSetting}
                    filters={{
                      ids: selection.size > 0 ? Array.from(selection) : undefined,
                      ...memoizedFilters,
                      searchTerm: debouncedSearchValue,
                    }}
                    disabled={selection.size === 0}
                  />
                </li>
                <li>
                  <EntityTableDeleteButton
                    entityConstants={entityConstants}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select ${entityConstants.entitySingular} to delete`
                    : selection.size === 1
                    ? `Delete the selected ${entityConstants.entitySingular}`
                    : `Delete the selected ${entityConstants.entityPlural}`
                }`}
                description={`Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`}
                proceedLabel="Delete"
                onProceed={async () => {
                  await deleteProject({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.entityPlural,
                    params: { deletePermanently: true },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<Project, ProjectFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={columnSettings}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
