import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { useState, useEffect, useContext, useMemo, Dispatch, SetStateAction } from "react";
import { SessionContext } from "../contexts/SessionContext";
import { DataSourceStatusChangedEvent } from "../../api/DataSourceStatus";
import { API } from "../../api/Api";

type SignalRConnectionRoutes = "subscribe" | "autoload/monitoring_hub";

export type ConnectionStatus = "idle" | "connecting" | "connected" | "reconnecting" | "disconnected";
export interface SignalRConnectionContextReturnValues {
  connectionStatus: ConnectionStatus;
  connection: HubConnection | null;
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
}

export const useSignalRConnection: ({
  api,
  route,
}: {
  api: API;
  route: SignalRConnectionRoutes;
}) => SignalRConnectionContextReturnValues = ({ api, route }) => {
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>("idle");
  const [enabled, setEnabled] = useState<boolean>(true);

  const fullUrl = useMemo(() => {
    const baseUrl = process.env.NODE_ENV === "development" ? `http://localhost:5000/${api.group}/api/0.1/` : api.url;
    return `${baseUrl}${route}`;
  }, [api.group, api.url, route]);

  useEffect(() => {
    if (enabled) {
      const connect = new HubConnectionBuilder()
        .withUrl(fullUrl)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      setConnectionStatus("connecting");
      setConnection(connect);
    } else {
      setConnectionStatus("idle");
      setConnection(null);
    }
  }, [enabled, fullUrl]);

  useEffect(() => {
    if (connection) {
      connection.onreconnecting(() => setConnectionStatus("reconnecting"));
      connection.onreconnected(() => setConnectionStatus("connected"));
      connection.onclose(() => setConnectionStatus("disconnected"));

      if (connection.state === HubConnectionState.Disconnected) {
        connection
          .start()
          .then(() => setConnectionStatus("connected"))
          .catch((error) => {
            setConnectionStatus("disconnected");
            console.log("Hubconnection error", error);
          });
      }
    }
    return () => {
      if (connection) {
        // This is a hack using the internal property to reset the onclose event handler
        // We do want to remove this to prevent spurious error messages when it is triggered
        // on an already unmounted component
        connection["_closedCallbacks"] = [];

        connection.stop();
      }
    };
  }, [connection]);

  return { connectionStatus, connection, enabled, setEnabled };
};

export interface LiveConnectionContextReturnValues extends Partial<SignalRConnectionContextReturnValues> {}

interface ICallbacks {
  [key: string]: (...args: any[]) => void;
}

export const useLiveConnectionContext: ({
  callbacks,
}: {
  callbacks: Partial<ICallbacks>;
}) => LiveConnectionContextReturnValues | undefined = ({ callbacks }) => {
  const { signalRConnection } = useContext(SessionContext);
  const connection = signalRConnection?.connection;

  const callbackFns = useMemo(() => callbacks, [callbacks]);

  // When the callback functions change we need to remove previous registrations
  // and add the new ones. Though you really should provide stable callbacks for this
  // hook to avoid reregistering on every render
  useEffect(() => {
    if (connection) {
      Object.entries(callbackFns).forEach(([key, value]) => {
        //console.log("ON", key, value);
        !!value && connection.on(key, value);
      });
    }
    return () => {
      Object.entries(callbackFns).forEach(([key, _]) => {
        connection?.off(key);
      });
    };
  }, [connection, callbackFns]);

  return { ...signalRConnection };
};

export interface ClientLiveConnectionCallbacks extends ICallbacks {
  DataSourceStatusChanged: (dataSourceId: number, data: DataSourceStatusChangedEvent) => void;
  DataSourceScheduled: (dataSourceId: number, nextScheduledDate: string) => void;
  ClientListUpdated: () => void;
  ClientConnectionChanged: (bridgeId: number, isConnected: boolean) => void;
}
export const useLiveAutoloadConnection: ({
  callbacks,
  enabled,
}: {
  callbacks: Partial<ClientLiveConnectionCallbacks>;
  enabled: boolean;
}) => SignalRConnectionContextReturnValues = ({ callbacks, enabled }) => {
  const { api } = useContext(SessionContext);
  const data = useSignalRConnection({ api, route: "autoload/monitoring_hub" });
  const { setEnabled } = data;

  useEffect(() => {
    setEnabled(enabled);
  }, [enabled, setEnabled]);

  const callbackFns = useMemo(() => callbacks, [callbacks]);

  // When the callback functions change we need to remove previous registrations
  // and add the new ones. Though you really should provide stable callbacks for this
  // hook to avoid reregistering on every render
  useEffect(() => {
    if (data.connection) {
      Object.entries(callbackFns).forEach(([key, value]) => {
        //console.log("ON", key, value);
        !!value && data.connection?.on(key, value);
      });
    }
    return () => {
      Object.entries(callbackFns).forEach(([key, _]) => {
        data.connection?.off(key);
      });
    };
  }, [data.connection, callbackFns]);

  return { ...data };
};

export interface LiveUpdateConnectionCallbacks extends ICallbacks {
  EntityLockingStateChanged: (entityId: number, publicSessionId: string, isLocked: boolean) => void;
}
export const useLiveUpdateConnection = ({ callbacks }: { callbacks: Partial<LiveUpdateConnectionCallbacks> }) => {
  return useLiveConnectionContext({ callbacks });
};
