import { Dispatch, RefObject, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useResizeDetector } from "react-resize-detector";
import { useEditMutation, useEntityDetail } from "../../api/BaseEntityApi";
import { SessionContext } from "../../common/contexts/SessionContext";
import { ELNSaveParameters, ELNSaveStatus, TextEditor } from "../common/TextEditor/TextEditor";
import { ELNModes } from "../ELNRouter/ELNRouter";
import {
  LabNotebookEntry as LabNotebookEntryReadModel,
  LabNotebookEntryFilters,
  LabNotebookEntryWriteModel,
  labNotebookEntriesConstants,
  LabNotebookEntrySuggestions,
} from "../types/LabNotebookEntry";
import styles from "./LabNotebookEntry.module.css";
import { useELNRoutes } from "../ELNRouter/useELNRoutes";
import { LAB_NOTEBOOK_TOC_QUERY_KEY } from "../common/tiles/LabNotebookTocTile/LabNotebookTocTile";
import { Alert } from "../../common/overlays/Alert/Alert";
import { GenericEntity } from "../../api/GenericTypes";
import { DateTimeRenderer, formatIsoDate } from "../../common/datetime/DateTimeFormatter";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { EntityLockingWrapper } from "../../common/entity/entityComponents/EntityLockingWrapper";

export const LabNotebookEntry = ({
  labNotebookId,
  labNotebookExperimentId,
  labNotebookEntryId,
  mode,
  labNotebookBlockId,
  remirrorContextRef,
  setSaveStatus,
  saveButtonRef,
  saveCloseButtonRef,
}: {
  labNotebookId?: number;
  labNotebookExperimentId?: number;
  labNotebookEntryId?: number;
  mode?: ELNModes;
  labNotebookBlockId?: string;
  remirrorContextRef?: any;
  setSaveStatus?: Dispatch<SetStateAction<ELNSaveStatus>>;
  saveButtonRef?: RefObject<HTMLButtonElement>;
  saveCloseButtonRef?: RefObject<HTMLButtonElement>;
}) => {
  const { session } = useContext(SessionContext);
  const { setELNRoute } = useELNRoutes();

  const [entryState, setEntryState] = useState<LabNotebookEntryReadModel>();
  const { data: entry } = useEntityDetail<LabNotebookEntryReadModel, LabNotebookEntryFilters>(
    "lab_notebook_entries",
    labNotebookEntryId!,
    { includeContent: true },
    { enabled: !!labNotebookEntryId, onSuccess: (data) => !entryState && setEntryState(data) }
  );
  useEffect(() => {
    if (entry && entry?.publicSessionId !== entryState?.publicSessionId) {
      setEntryState(entry);
    }
  }, [entry, entryState?.publicSessionId]);

  const { ref, height } = useResizeDetector();

  useEffect(() => {
    if (labNotebookBlockId) {
      let timeout = setTimeout(() => {
        document.getElementById(labNotebookBlockId)?.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [labNotebookBlockId]);

  const queryClient = useQueryClient();
  const editMutation = useEditMutation("lab_notebook_entries");
  const saveContent = useCallback(
    ({ content, onSave, setSaveStatus, evaluateVersioning }: ELNSaveParameters) => {
      if (session && entry && entry.permissions?.edit) {
        setSaveStatus((prevState) => {
          return { ...prevState, isSaving: true };
        });

        editMutation.mutate(
          {
            id: entry.id,
            body: {
              ...entry,
              labNotebookExperimentId: entry.labNotebookExperiment.id,
              content,
              concurrencyToken: entry.modifiedOn,
            } as LabNotebookEntryWriteModel & GenericEntity,
            params: { evaluateVersioning },
            entityName: "entry",
          },
          {
            onSuccess: () => {
              setSaveStatus({ isSaved: true, isSaving: false, isError: false });
              queryClient.invalidateQueries(["lab_notebook_entries"]);
              queryClient.invalidateQueries([LAB_NOTEBOOK_TOC_QUERY_KEY]);
              onSave && onSave();
            },
            onError: () => {
              setSaveStatus({ isSaved: false, isSaving: false, isError: true });
            },
          }
        );
      }
    },
    [entry, editMutation, queryClient, session]
  );

  if (!entry || !entryState) return null;
  if (!Object.hasOwn(entry, "content"))
    return <Alert fit centered message="Content could not be loaded" type="danger" />;
  return (
    <EntityLockingWrapper entityConstants={labNotebookEntriesConstants} entityId={entry.id}>
      <div className={styles.labNotebookEntryContainer}>
        <div className={styles.labNotebookEntryMainContainer}>
          <>
            <div ref={ref} />
            <div className={styles.labNotebookEntryTextEditorContainer} style={{ height: `calc(100% - ${height}px)` }}>
              <div
                className={styles.labNotebookEntryTextEditor}
                style={{ marginBottom: mode === "edit" ? "33vh" : undefined }}
              >
                <TextEditor
                  editable={mode === "edit" && entry.permissions?.edit && entry.labNotebook.status !== "CLOSED"}
                  initialContent={entryState.content}
                  save={saveContent}
                  autosave
                  remirrorContextRef={remirrorContextRef}
                  getSaveStatus={setSaveStatus}
                  toolbarRef={ref}
                  saveButtonRef={saveButtonRef}
                  saveCloseButtonRef={saveCloseButtonRef}
                  onClose={() =>
                    setELNRoute({
                      labNotebookId: entry.labNotebook.id,
                      labNotebookExperimentId: entry.labNotebookExperiment.id,
                      labNotebookEntryId: entry.id,
                      mode: "view",
                    })
                  }
                />
              </div>
            </div>
          </>
        </div>
      </div>
    </EntityLockingWrapper>
  );
};

export const NotebookEntryLabel = ({ entry }: { entry: LabNotebookEntryReadModel | LabNotebookEntrySuggestions }) => {
  return (
    <div
      className="flex align-center gap-5"
      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
    >
      <div>
        <LucideIcon name={labNotebookEntriesConstants.icon} color="var(--primary)" />
      </div>
      {!!entry.name && (
        <>
          {entry.name}
          {" - "}
        </>
      )}
      <DateTimeRenderer date={entry.entryDate} includeDate includeTime={false} includeElapsed={false} />
      {!!(entry as LabNotebookEntryReadModel).isDeleted && (
        <label className="label label-soft-warning" title="Trashed" style={{ margin: 0 }}>
          <LucideIcon name="trash" />
        </label>
      )}
    </div>
  );
};

export const getNotebookEntryStringLabel = ({
  entry,
}: {
  entry: LabNotebookEntryReadModel | LabNotebookEntrySuggestions;
}) =>
  !!entry.name
    ? `${formatIsoDate(new Date(entry.entryDate)).datestr}: ${entry.name}`
    : formatIsoDate(new Date(entry.entryDate)).datestr;

export const NotebookEntryWithBreadcrumb = ({
  entry,
}: {
  entry: LabNotebookEntryReadModel | LabNotebookEntrySuggestions;
}) => {
  return (
    <div className="flex align-center gap-5" style={{ width: "100%" }}>
      <div>
        <LucideIcon name={labNotebookEntriesConstants.icon} color="var(--primary)" />
      </div>
      <div
        style={{
          margin: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          maxWidth: "max-content",
        }}
      >
        {entry.labNotebook.name}
      </div>
      <div>
        <LucideIcon name="arrow-big-right" color="var(--primary)" />
      </div>
      <div
        style={{
          margin: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          maxWidth: "max-content",
        }}
      >
        {entry.labNotebookExperiment.name}
      </div>
      <div>
        <LucideIcon name="arrow-big-right" color="var(--primary)" />
      </div>
      <div
        style={{
          minWidth: "100px",
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {getNotebookEntryStringLabel({ entry })}
      </div>
    </div>
  );
};
