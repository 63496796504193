import { useContext } from "react";
import { Dataset, DatasetFilters } from "../../api/Datasets";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import { SessionContext } from "../../common/contexts/SessionContext";
import { SelectFormField } from "../../common/formfields/SelectFormField";
import { ToggleFormField } from "../../common/formfields/ToggleFormField";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
// import { DatasetTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DatasetTypesVirtualizedSelectForm";
import { MethodsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/MethodsVirtualizedSelectForm";
import { ExperimentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ExperimentsVirtualizedSelectForm";
import { SamplesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/SamplesVirtualizedSelectForm";
import { InstrumentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { EquipmentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/EquipmentsVirtualizedSelectForm";
import { ProjectsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";
import { OrganizationsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationsVirtualizedSelectForm";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { DataFormatsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DataFormatsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { BridgesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/BridgeVirtualizedSelectForm";
import { DataSourcesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/DataSourcesVirtualizedSelectForm";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";

export interface DatasetsFilterForm {
  operator?: IEntityMinimalModel[] | undefined | null;
  project?: IEntityMinimalModel[] | undefined | null;
  organization?: IEntityMinimalModel[] | undefined | null;
  method?: IEntityMinimalModel[] | undefined | null;
  sample?: IEntityMinimalModel[] | undefined | null;
  experiment?: IEntityMinimalModel[] | undefined | null;
  instrument?: IEntityMinimalModel[] | undefined | null;
  equipment?: IEntityMinimalModel[] | undefined | null;
  dataSourceIds?: IEntityMinimalModel[] | undefined | null;
  bridgeIds?: IEntityMinimalModel[] | undefined | null;
  acquisitionDateFrom?: Date | undefined | null;
  acquisitionDateTo?: Date | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  parsingState?: { value: any; label: any }[] | undefined | null;
  customType?: { id: number; name: string }[] | undefined | null;
  includeSoftDeleted?: boolean | undefined | null;
  includeUnclaimed?: boolean | undefined | null;
  includeViewableEntities?: boolean | undefined | null;
  formats?: IEntityMinimalModel<string>[] | undefined | null;
  claimed?: IEntityMinimalModel<string> | undefined | null;
}

export const datasetDefaultValues: DefinedPropertyObject<DatasetsFilterForm> = {
  operator: null,
  project: null,
  organization: null,
  method: null,
  sample: null,
  experiment: null,
  instrument: null,
  equipment: null,
  dataSourceIds: null,
  bridgeIds: null,
  acquisitionDateFrom: null,
  acquisitionDateTo: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  parsingState: null,
  customType: null,
  includeSoftDeleted: false,
  includeUnclaimed: true,
  includeViewableEntities: false,
  formats: null,
  claimed: { id: "all", name: "All" },
};

const defaultFilters = { searchTerm: "", orderBy: "ACQUISITION_DATE_DESC" } as DatasetFilters;

export const datasetsFormToFilters = (form: DatasetsFilterForm): DatasetFilters => {
  let filters: DatasetFilters = {};
  filters.customTypeIds =
    Array.isArray(form.customType) && form.customType.length > 0 ? form.customType.map((d) => d.id) : null;
  filters.operatorIds =
    Array.isArray(form.operator) && form.operator.length > 0 ? form.operator.map((d) => d.id) : null;
  filters.projectIds = Array.isArray(form.project) && form.project.length > 0 ? form.project?.map((d) => d.id) : null;
  filters.organizationIds =
    Array.isArray(form.organization) && form.organization.length > 0 ? form.organization?.map((d) => d.id) : null;
  filters.methodIds = Array.isArray(form.method) && form.method.length > 0 ? form.method?.map((d) => d.id) : null;
  filters.sampleIds = Array.isArray(form.sample) && form.sample.length > 0 ? form.sample?.map((d) => d.id) : null;
  filters.experimentIds =
    Array.isArray(form.experiment) && form.experiment.length > 0 ? form.experiment?.map((d) => d.id) : null;
  filters.instrumentIds =
    Array.isArray(form.instrument) && form.instrument.length > 0 ? form.instrument?.map((d) => d.id) : null;
  filters.equipmentIds =
    Array.isArray(form.equipment) && form.equipment.length > 0 ? form.equipment?.map((d) => d.id) : null;
  filters.dataSourceIds =
    Array.isArray(form.dataSourceIds) && form.dataSourceIds.length > 0 ? form.dataSourceIds?.map((d) => d.id) : null;
  filters.bridgeIds =
    Array.isArray(form.bridgeIds) && form.bridgeIds.length > 0 ? form.bridgeIds?.map((d) => d.id) : null;
  filters.acquisitionDateFrom = form.acquisitionDateFrom ? form.acquisitionDateFrom.toISOString() : null;
  filters.acquisitionDateTo = form.acquisitionDateTo ? form.acquisitionDateTo.toISOString() : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.parsingState =
    Array.isArray(form.parsingState) && form.parsingState.length > 0 ? form.parsingState?.map((d) => d.value) : null;
  filters.formatIds = Array.isArray(form.formats) && form.formats.length > 0 ? form.formats?.map((d) => d.id) : null;
  filters.includeSoftDeleted = form.includeSoftDeleted || false;
  filters.isClaimed =
    (form.claimed ? (form.claimed.id === "claimed" ? true : form.claimed.id === "unclaimed" ? false : null) : null) ??
    null;
  filters.includeUnclaimed = (form.claimed && (form.claimed.id === "all" || form.claimed.id === "unclaimed")) || false;
  filters.includeViewableEntities = form.includeViewableEntities || false;
  return filters;
};

/**
 * Converts DatasetFilters to DatasetsFilterForm
 * @param filters DatasetFilters
 * @returns DatasetsFilterForm
 * @author @CorradoSurmanowicz
 */
export const datasetsFiltersToForm = (filters: DatasetFilters): DatasetsFilterForm => {
  return {
    operator: filters.operatorIds?.map((id) => ({ id, name: "" })) || null,
    project: filters.projectIds?.map((id) => ({ id, name: "" })) || null,
    organization: filters.organizationIds?.map((id) => ({ id, name: "" })) || null,
    method: filters.methodIds?.map((id) => ({ id, name: "" })) || null,
    sample: filters.sampleIds?.map((id) => ({ id, name: "" })) || null,
    experiment: filters.experimentIds?.map((id) => ({ id, name: "" })) || null,
    instrument: filters.instrumentIds?.map((id) => ({ id, name: "" })) || null,
    equipment: filters.equipmentIds?.map((id) => ({ id, name: "" })) || null,
    dataSourceIds: filters.dataSourceIds?.map((id) => ({ id, name: "" })) || null,
    bridgeIds: filters.bridgeIds?.map((id) => ({ id, name: "" })) || null,
    acquisitionDateFrom: filters.acquisitionDateFrom ? new Date(filters.acquisitionDateFrom) : null,
    acquisitionDateTo: filters.acquisitionDateTo ? new Date(filters.acquisitionDateTo) : null,
    createdByIds: filters.createdByIds?.map((id) => ({ id, name: "" })) || null,
    createdFrom: filters.createdFrom ? new Date(filters.createdFrom) : null,
    createdTo: filters.createdTo ? new Date(filters.createdTo) : null,
    modifiedByIds: filters.modifiedByIds?.map((id) => ({ id, name: "" })) || null,
    modifiedFrom: filters.modifiedFrom ? new Date(filters.modifiedFrom) : null,
    modifiedTo: filters.modifiedTo ? new Date(filters.modifiedTo) : null,
    parsingState: filters.parsingState?.map((value) => ({ value, label: value })) || null,
    customType: filters.customTypeIds?.map((id) => ({ id, name: "" })) || null,
    includeSoftDeleted: filters.includeSoftDeleted || false,
    includeUnclaimed: filters.includeUnclaimed || false,
    includeViewableEntities: filters.includeViewableEntities || false,
    formats: filters.formatIds?.map((id) => ({ id, name: "" })) || null,
    claimed: !filters.isClaimed
      ? { id: "all", name: "All" }
      : filters.isClaimed
      ? { id: "claimed", name: "Claimed" }
      : { id: "unclaimed", name: "Unclaimed" },
  };
};

export const DatasetsFilterbar = ({
  initialValues,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: TabStoreFilterSidebarProps<Dataset, DatasetsFilterForm, DatasetFilters>) => {
  const { session } = useContext(SessionContext);

  const { register, control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues: datasetDefaultValues,
    defaultFilters: defaultFilters,
    formToFilters: datasetsFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <CustomTypesVirtualizedSelectForm
        id="customType"
        label="Dataset types"
        placeholder="Select dataset types..."
        filters={{ entityTypes: ["Dataset"] }}
        control={control}
        showControls={false}
        isMulti
        enableBasicOption
        hideIfNoTypes
      />
      <MethodsVirtualizedSelectForm id="method" control={control} showControls={false} isMulti />
      <ExperimentsVirtualizedSelectForm id="experiment" control={control} showControls={false} isMulti />
      <SamplesVirtualizedSelectForm id="sample" control={control} showControls={false} isMulti />
      <InstrumentsVirtualizedSelectForm id="instrument" control={control} showControls={false} isMulti />
      <EquipmentsVirtualizedSelectForm id="equipment" control={control} showControls={false} isMulti />
      <ProjectsVirtualizedSelectForm id="project" control={control} showControls={false} isMulti />
      <OrganizationsVirtualizedSelectForm id="organization" control={control} showControls={false} isMulti />
      <PersonsVirtualizedSelectForm id="operator" label="Operator" control={control} showControls={false} isMulti />
      <BridgesVirtualizedSelectForm id="bridgeIds" label="Bridge" control={control} showControls={false} isMulti />
      <DataSourcesVirtualizedSelectForm
        id="dataSourceIds"
        label="Data source"
        control={control}
        showControls={false}
        isMulti
      />

      <SplitDatePickerFormField
        control={control}
        idStart="acquisitionDateFrom"
        idEnd="acquisitionDateTo"
        label="Acquisition date (from - to)"
        horizontal={false}
        showTimeSelect
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <DataFormatsVirtualizedSelectForm id={"formats"} control={control} showControls={false} isMulti />

      {session?.features.development_mode && (
        <SelectFormField
          id={"parsingState"}
          label={"State"}
          {...register}
          getOptionLabel={(opt: { label: any; value: any }) => opt.label}
          getOptionValue={(opt: { label: any; value: any }) => opt.value}
          items={[
            {
              label: "Not yet parsed",
              value: "NotYetParsed",
            },
            {
              label: "Not parseable",
              value: "NotParseable",
            },
            {
              label: "Parsing failed",
              value: "ParsingFailed",
            },
            {
              label: "Parsed successfully",
              value: "ParsedSuccessfully",
            },
          ]}
          control={control}
          horizontal={false}
          isMulti={true}
        />
      )}
      {currentTab !== "unclaimed" && (
        <SelectFormField
          id={"claimed"}
          label={"Claim status"}
          {...register}
          items={[
            {
              id: "claimed",
              name: "Claimed",
            },
            {
              id: "unclaimed",
              name: "Unclaimed",
            },
            {
              id: "all",
              name: "All",
            },
          ]}
          control={control}
          horizontal={false}
          isMulti={false}
          isDisabled={currentTab === "unclaimed"}
        />
      )}

      {currentTab !== "trash" && (
        <ToggleFormField id={"includeSoftDeleted"} control={control} label={"Include trashed"} horizontal />
      )}

      {session?.features.development_mode && (
        <ToggleFormField
          id={"includeViewableEntities"}
          control={control}
          label={"Include viewable entities"}
          horizontal
        />
      )}

      {/* {currentTab !== "unclaimed" && (
        <ToggleFormField id={"includeUnclaimed"} control={control} label={"Include unclaimed"} horizontal />
      )} */}
    </EntityFilterSideBar>
  );
};
