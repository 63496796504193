import { ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import {
  EntityConstants,
  GenericEntity,
  ILockableEntity,
  ILockableEntityLockParameters,
  ILockableEntityUnlockParameters,
  IPermissionedEntity,
} from "../../../api/GenericTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { FetchStatus, QueryStatus, useQueryClient } from "@tanstack/react-query";
import { useEntityDetail } from "../../../api/BaseEntityApi";
import { Alert } from "../../overlays/Alert/Alert";
import { LoadingWrapper } from "../../LoadingWrapper";
import { AlertModal } from "../../modals/AlertModal/AlertModal";
import { GetPersons } from "../../misc/EntityRenders/EntityRenderer";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import {
  LiveConnectionContextReturnValues,
  LiveUpdateConnectionCallbacks,
  useLiveUpdateConnection,
} from "../../signalr/useLiveConnection";
import { useHistory } from "react-router-dom";

interface LockEntityReturnValues extends LiveConnectionContextReturnValues {
  lockEntity: (forceTakeOver?: boolean) => Promise<void>;
  unlockEntity: () => Promise<void>;
  isLocked: boolean;
  entity?: GenericEntity & ILockableEntity & IPermissionedEntity;
  status: QueryStatus;
  fetchStatus: FetchStatus;
}
export const useLockEntity: ({
  entityId,
  entityConstants,
}: {
  entityId: number | string;
  entityConstants: EntityConstants;
}) => LockEntityReturnValues = ({ entityId, entityConstants }) => {
  const { session, api } = useContext(SessionContext);

  // Subscribe to live updates
  const queryClient = useQueryClient();
  const callbacks: LiveUpdateConnectionCallbacks = useMemo(
    () => ({
      EntityLockingStateChanged: (entryId, publicSessionId, isLocked) => {
        queryClient.invalidateQueries({ queryKey: [entityConstants.resource, "detail", entityId] });
      },
    }),
    [entityConstants.resource, entityId, queryClient]
  );
  const liveConnectionData = useLiveUpdateConnection({ callbacks });

  // Fetch entity to get lock status
  const {
    data: entity,
    status,
    fetchStatus,
    isFetching,
  } = useEntityDetail<GenericEntity & ILockableEntity & IPermissionedEntity>(
    entityConstants.resource,
    entityId,
    undefined,
    { enabled: true, cacheTime: 0, staleTime: 0 }
  );

  const isLocked = useMemo(
    () => !entity || (!!entity?.isLocked && entity?.publicSessionId !== session?.publicSessionId),
    [entity, session?.publicSessionId]
  );

  // Helper functions
  const lockEntity = useCallback(
    async (forceTakeOver: boolean = false) => {
      if (!session?.publicSessionId) return;
      if (!liveConnectionData?.connection?.connectionId) return;
      var parameters: ILockableEntityLockParameters = {
        publicSessionId: session.publicSessionId,
        forceTakeOver,
        connectionId: liveConnectionData.connection.connectionId,
      };
      await api
        .post(`${entityConstants.resource}/${entityId}/lock`, parameters)
        .then(() => {
          queryClient.invalidateQueries({ queryKey: [entityConstants.resource, "detail", entityId] });
        })
        .catch((e) => console.error(e));
    },
    [
      api,
      entityConstants.resource,
      entityId,
      liveConnectionData?.connection?.connectionId,
      queryClient,
      session?.publicSessionId,
    ]
  );

  const unlockEntity = useCallback(async () => {
    if (!session?.publicSessionId) return;
    if (!liveConnectionData?.connection?.connectionId) return;
    var parameters: ILockableEntityUnlockParameters = { publicSessionId: session.publicSessionId };
    await api
      .post(`${entityConstants.resource}/${entityId}/unlock`, parameters)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [entityConstants.resource, "detail", entityId] });
      })
      .catch((e) => console.error(e));
  }, [
    api,
    entityConstants.resource,
    entityId,
    liveConnectionData?.connection?.connectionId,
    queryClient,
    session?.publicSessionId,
  ]);

  // Lock entity on page load
  useEffect(() => {
    if (!!entity) {
      if (!entity.publicSessionId) {
        lockEntity();
      }
    }
  }, [entity, lockEntity, session?.publicSessionId, unlockEntity]);

  // Unlock entity on page unload/refresh or going to another page
  const unlockEntityOnUnload = useCallback(
    (ev) => {
      unlockEntity();
    },
    [unlockEntity]
  );
  useEffect(() => {
    window.addEventListener("beforeunload", unlockEntityOnUnload);
    return () => {
      window.removeEventListener("beforeunload", unlockEntityOnUnload);
      unlockEntity();
    };
  }, [unlockEntity, unlockEntityOnUnload]);

  return {
    lockEntity,
    unlockEntity,
    isLocked,
    status,
    fetchStatus: isFetching ? "fetching" : fetchStatus,
    entity,
    ...liveConnectionData,
  };
};

export const EntityLockingWrapper = ({
  entityId,
  entityConstants,
  children,
}: {
  entityId: number | string;
  entityConstants: EntityConstants;
  children: ReactNode;
}) => {
  const { session } = useContext(SessionContext);
  const history = useHistory();
  const { lockEntity, isLocked, status, fetchStatus, entity } = useLockEntity({ entityConstants, entityId });

  const proceedCallback = useCallback(() => {
    lockEntity(true);
  }, [lockEntity]);

  if (!session?.publicSessionId) return <Alert fit centered message="Public session id not found" type="danger" />;
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus}>
      {isLocked ? (
        <>
          {!!entity?.isLocked ? (
            <>
              <Alert
                fit
                centered
                message={`This ${entityConstants.entitySingular} has been locked by another user`}
                type="warning"
              />
              {!!entity?.permissions?.edit && (
                <AlertModal
                  title={`This ${entityConstants.entitySingular} has been locked`}
                  description={
                    <div>
                      <div style={{ paddingBottom: entity?.lockedBy || entity?.lockedOn ? "15px" : "0" }}>
                        Proceeding will end the other user's session and allow you to edit this item. Are you sure you
                        want to proceed?
                      </div>
                      {entity?.lockedBy && (
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 3fr",
                            gap: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            paddingRight: "10px",
                            paddingBottom: entity?.lockedOn ? "5px" : undefined,
                          }}
                        >
                          <h5 style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>Locked by:</h5>
                          <div>
                            <GetPersons persons={[entity?.lockedBy]} />
                          </div>
                        </div>
                      )}
                      {entity?.lockedOn && (
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 3fr",
                            gap: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            paddingRight: "10px",
                            paddingBottom: entity?.modifiedOn ? "5px" : undefined,
                          }}
                        >
                          <h5 style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>Locked since:</h5>
                          <div>
                            <DateTimeRenderer date={entity?.lockedOn} includeDate includeTime />
                          </div>
                        </div>
                      )}
                      {entity?.modifiedOn && (
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "2fr 3fr",
                            gap: "5px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            paddingRight: "10px",
                          }}
                        >
                          <h5 style={{ whiteSpace: "nowrap", padding: 0, margin: 0 }}>Last modified on:</h5>
                          <div>
                            <DateTimeRenderer date={entity?.modifiedOn} includeDate includeTime />
                          </div>
                        </div>
                      )}
                    </div>
                  }
                  proceedLabel={`Unlock ${entityConstants.entitySingular}`}
                  showModal
                  setShowModal={() => history.goBack()}
                  onProceed={proceedCallback}
                  type="warning"
                  forceCountdown
                />
              )}
            </>
          ) : null}
        </>
      ) : (
        children
      )}
    </LoadingWrapper>
  );
};
