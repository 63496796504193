import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { IconNMRHistoryEntry, IconNMRHistoryEntryFilters } from "../../../api/IconNMR";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import { InstrumentsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/InstrumentsVirtualizedSelectForm";
import { useUnpaginateOrdered } from "../../../api/BaseEntityApi";
import { DataSource, DataSourceFilters, dataSourceConstants } from "../../../api/DataSource";

export interface IconNMRHistoryFilterForm {
  dataSource?: IEntityMinimalModel[] | undefined | null;
  instrument?: IEntityMinimalModel[] | undefined | null;
}

export const iconNMRHoldersDefaultValues: DefinedPropertyObject<IconNMRHistoryFilterForm> = {
  dataSource: null,
  instrument: null,
};

const defaultFilters = {} as IconNMRHistoryEntryFilters;

export const iconNMRHoldersFormToFilters = (form: IconNMRHistoryFilterForm): IconNMRHistoryEntryFilters => {
  let filters: IconNMRHistoryEntryFilters = {};
  filters.dataSourceIds =
    Array.isArray(form.dataSource) && form.dataSource.length > 0 ? form.dataSource.map((d) => d.id) : null;
  filters.instrumentIds =
    Array.isArray(form.instrument) && form.instrument.length > 0 ? form.instrument.map((d) => d.id) : null;

  return filters;
};
/**
 * Converts IconNMRHistoryEntryFilters to IconNMRHistoryFilterForm
 * @param filters IconNMRHistoryEntryFilters
 * @returns IconNMRHistoryFilterForm
 * @author @CorradoSurmanowicz
 */
export const iconNMRHoldersFiltersToForm = (filters: IconNMRHistoryEntryFilters): IconNMRHistoryFilterForm => {
  let form: IconNMRHistoryFilterForm = {};
  form.dataSource = filters.dataSourceIds ? filters.dataSourceIds.map((id) => ({ id, name: "" })) : null;
  form.instrument = filters.instrumentIds ? filters.instrumentIds.map((id) => ({ id, name: "" })) : null;

  return form;
};

export const IconNMRHistoryFilterbar = ({
  initialValues,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
  hideReset = false,
}: TabStoreFilterSidebarProps<IconNMRHistoryEntry, IconNMRHistoryFilterForm, IconNMRHistoryEntryFilters>) => {
  const { data: dataSources } = useUnpaginateOrdered<DataSource, DataSourceFilters>(dataSourceConstants.resource, {
    types: ["IconNMR"],
    enabled: true,
  });

  const { control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues: iconNMRHoldersDefaultValues,
    defaultFilters: defaultFilters,
    formToFilters: iconNMRHoldersFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset} hideReset={hideReset}>
      <InstrumentsVirtualizedSelectForm
        id="instrument"
        control={control}
        showControls={false}
        filters={{ ids: dataSources?.map((i) => (i as IEntityMinimalModel).id) }}
        isMulti
        disabled={currentTab !== "default"}
      />
    </EntityFilterSideBar>
  );
};
