import { useHistory } from "react-router-dom";
import { useCallback, useContext } from "react";
import {
  QueryFunctionContext,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { SessionContext } from "../common/contexts/SessionContext";
import { API } from "./Api";
import {
  GenericEntity,
  IBulkPermissionResponse,
  ICreationRecord,
  IdTypes,
  IEntityMinimalModel,
  IModificationRecord,
  IPagination,
  IPaginationParameters,
  IPermissionedEntity,
  IRelatedEntity,
  IResultModel,
  StringIndexedDict,
} from "./GenericTypes";
import { showtoast } from "../common/overlays/Toasts/showtoast";
import { ResourceName } from "../main/Routing";
import { renderError } from "../common/helperfunctions/ApiError";
import { useUnpaginateEntities } from "../common/forms/MultiEditForms/common/MultiEditUtils";
import { MultiEditMappedItemObject } from "../common/forms/MultiEditForms/common/MultiEditProvider";
import { modifyReadResponse, modifyReadResponseList, projectTo, projectToBulk } from "./BaseEntityApiHooks";

export class AbstractBaseEntityApi<Entity> {
  protected api: API;
  protected resource: string;

  constructor(api: API, resource: string) {
    this.api = api;
    this.resource = resource;
  }

  //list = async (): Promise<PaginationNew<Entity>> => await this.api.get(`${this.resource}`);

  async create(newEntity: Entity): Promise<Entity> {
    return await this.api.post(`${this.resource}`, newEntity);
  }

  async edit(id: number, updatedEntity: Entity): Promise<Entity> {
    return await this.api.put(`${this.resource}/${id}`, updatedEntity);
  }

  async delete(id: number) {
    return await this.api.delete(`${this.resource}/${id}`);
  }

  //useList = () => () => useQuery([this.resource, "list"], this.list);
}

export class BaseEntityApi<Entity> extends AbstractBaseEntityApi<Entity> {
  list = async (): Promise<IPagination<Entity>> => await this.api.get(`${this.resource}`);
  get = async (id: number): Promise<Entity> => await this.api.get(`${this.resource}/${id}`);
  useList = () => () => useQuery([this.resource, "list"], this.list);
  useDetail = (id: number) => () => useQuery([this.resource, "detail", id], () => this.get(id));
}

export class BaseDictEntityApi<Entity> extends AbstractBaseEntityApi<Entity> {
  list = async (): Promise<Record<string, Entity>> => await this.api.get(`${this.resource}`);
  get = async (id: string): Promise<Entity> => await this.api.get(`${this.resource}/${id}`);
  useList = () => () => useQuery([this.resource, "list"], this.list);
  useDetail = (id: string) => () => useQuery([this.resource, "detail", id], () => this.get(id));
}

export interface GetOptions<T> extends UseQueryOptions {
  mappingFunction?: (data: any) => T[];
}

// CS: Hook for POST usage for /autoload_clients/{id}/read_directory
export const useEntityPost = <Entity>(
  resource: ResourceName,
  id: IdTypes,
  endpoint: string,
  params: { [key: string]: any },
  options?: UseQueryOptions<any, unknown, Entity>
): UseQueryResult<Entity> => {
  const { api } = useContext(SessionContext);
  // console.log("FETCHING", resource, id, endpoint, params, options);
  return useQuery({
    queryKey: [`${resource} ${endpoint ? id + "/" + endpoint : id}`, params],
    queryFn: async ({ signal }) => await api.post(`${resource}/${endpoint ? id + "/" + endpoint : id}`, params, signal),
    ...options,
  });
};

// Depracted: Use useListEntity when all endpoints have been migrated
export const useNonPaginatedListEntityOld = <Entity>(
  resource: ResourceName,
  params?: any,
  options?: GetOptions<Entity>
): UseQueryResult<Entity[]> => {
  const { api } = useContext(SessionContext);
  var queryFunc: () => Promise<any>;
  if (options?.mappingFunction) {
    queryFunc = async () => {
      var rv = await api.get(`${resource}`, params);
      return (options.mappingFunction as (data: any) => Entity[])(rv);
    };
  } else {
    queryFunc = () => api.get(`${resource}`, params);
  }
  return useQuery([resource, "list", params], queryFunc);
};

export const useNonPaginatedListEntity = <Entity>(
  resource: ResourceName,
  params: any,
  options?: GetOptions<Entity>
): UseQueryResult<IResultModel<Entity>> => {
  const { api } = useContext(SessionContext);
  var queryFunc: () => Promise<any>;
  if (options?.mappingFunction) {
    queryFunc = async () => {
      var rv = await api.get(`${resource}`, params);
      return (options.mappingFunction as (data: any) => Entity[])(rv);
    };
  } else {
    queryFunc = () => api.get(`${resource}`, params);
  }
  return useQuery([resource, "list", params], queryFunc);
};

export const useNonPaginatedListEntityPost = <Entity>(
  resource: ResourceName,
  params: any,
  options?: GetOptions<Entity>
): UseQueryResult<IResultModel<Entity>> => {
  const { api } = useContext(SessionContext);
  var queryFunc: () => Promise<any>;
  if (options?.mappingFunction) {
    queryFunc = async () => {
      var rv = await api.post(`${resource}/list`, params);
      return (options.mappingFunction as (data: any) => Entity[])(rv);
    };
  } else {
    queryFunc = () => api.post(`${resource}/list`, params);
  }
  return useQuery([resource, "list", params], queryFunc);
};

// For non paginated lists
export const useListEntity = <Entity, T = {}>(
  resource: ResourceName,
  params?: T,
  options?: UseQueryOptions<any, unknown, IResultModel<Entity>>
): UseQueryResult<IResultModel<Entity>> & { count?: number } => {
  const { api } = useContext(SessionContext);
  const res = useQuery({
    queryKey: [resource, "list", params],
    queryFn: async ({ signal }) => await api.get(`${resource}`, params, signal),
    ...options,
  });
  // const res = useQuery([resource, "list", params], () => api.get(`${resource}`, params), options);
  return {
    ...res,
    count: res.data && res.data.count !== undefined ? res.data.count : undefined,
  };
};

// For paginated lists
export interface IPaginationBaseParams extends Omit<IPaginationParameters, "page"> {
  page: number;
}
type FilterParams<T> = T & StringIndexedDict;
type Method = "get" | "post";
export interface IPagePagination<Entity> extends IPagination<Entity> {
  next: number;
}

export const useInfiniteListEntity = <Entity, T extends StringIndexedDict = {}>(
  resource: ResourceName,
  params: FilterParams<IPaginationBaseParams> & T,
  options?: UseInfiniteQueryOptions<any, unknown, IPagination<Entity>, any>,
  method: Method = "get"
): UseInfiniteQueryResult<IPagination<Entity>> & { count?: number } => {
  const { api } = useContext(SessionContext);

  const getDataByPageParam = async (pageParam: number, signal?: AbortSignal) => {
    const response: IPagination<Entity> = await modifyReadResponseList(api.rawGet(pageParam.toString(), signal));
    return { ...response, next: +pageParam + 1 };
  };
  const getFirstLoadData = async () => {
    const response: IPagination<Entity> = await modifyReadResponseList(api.get(`${resource}`, { ...params, page: 1 }));
    return { ...response, next: 2 };
  };
  const getDataByPageParamPost = async (pageParam: number | undefined, signal?: AbortSignal) => {
    if (pageParam) {
      const response: IPagination<Entity> = await modifyReadResponseList(
        api.post(`${resource}/list`, {
          ...params,
          page: pageParam,
        })
      );

      return { ...response, next: +pageParam + 1 };
    } else {
      const response: IPagination<Entity> = await modifyReadResponseList(api.post(`${resource}/list`, params));
      return { ...response, next: 2 };
    }
  };
  const getData = async ({
    pageParam,
    signal,
  }: QueryFunctionContext<QueryKey, number>): Promise<IPagePagination<Entity>> => {
    if (method === "get") {
      if (pageParam) return await getDataByPageParam(pageParam);
      else return await getFirstLoadData();
    } else {
      return await getDataByPageParamPost(pageParam, signal);
    }
  };

  const res = useInfiniteQuery({
    queryKey: [resource, "list", params],
    queryFn: async (queryFnContext) => await getData(queryFnContext),
    getNextPageParam: (lastPage: IPagePagination<Entity>) => (lastPage.hasNext ? lastPage.next : undefined),
    cacheTime: 0, // Each new query will be considered fresh; No caching!
    ...options,
  });

  return {
    ...res,
    count: res.data && !!res.data.pages.length ? res.data.pages[0].count : undefined,
  };
};

export const useEntityDetail = <Entity, Filters = {}>(
  resource: ResourceName,
  id: IdTypes,
  params?: Filters,
  options?: UseQueryOptions<any, unknown, Entity>
): UseQueryResult<Entity> => {
  const { api } = useContext(SessionContext);
  return useQuery({
    queryKey: [resource, "detail", id, params],
    queryFn: async ({ signal }) => await modifyReadResponse(api.get(`${resource}/${id}`, params, signal)),
    ...options,
  });
};

export const useEntitySuggestions = <
  SuggestionsModel extends IEntityMinimalModel<IdTypes>,
  Filters extends StringIndexedDict
>(
  resource: ResourceName,
  params?: Filters,
  options?: UseInfiniteQueryOptions<any, unknown, IPagination<SuggestionsModel>>
): UseInfiniteQueryResult<IPagination<SuggestionsModel>> & { count?: number } => {
  const { api } = useContext(SessionContext);
  const getDataByPageParamPost = async (pageParam: number | undefined, signal?: AbortSignal) => {
    if (pageParam) {
      const response: IPagination<SuggestionsModel> = await modifyReadResponse(
        api.post(`${resource}/suggestions`, {
          ...params,
          page: pageParam,
        })
      );

      return { ...response, next: +pageParam + 1 };
    } else {
      const response: IPagination<SuggestionsModel> = await modifyReadResponse(
        api.post(`${resource}/suggestions`, params)
      );
      return { ...response, next: 2 };
    }
  };

  const res = useInfiniteQuery({
    queryKey: [resource, "suggestions", params],
    queryFn: async ({ pageParam, signal }) => await getDataByPageParamPost(pageParam, signal),
    getNextPageParam: (lastPage: IPagePagination<SuggestionsModel>) => (lastPage.hasNext ? lastPage.next : undefined),
    cacheTime: 0, // Each new query will be considered fresh; No caching!
    ...options,
  });

  return {
    ...res,
    count: res.data && !!res.data.pages.length ? res.data.pages[0].count : undefined,
  };
};

export const useIdsOnly = <IdType extends IdTypes = number, Filters extends Object = {}>(
  resource: ResourceName,
  params?: Filters,
  options?: UseQueryOptions<any, unknown, IResultModel<IdType>>
): UseQueryResult<IResultModel<IdType>> & { count?: number } => {
  const { api } = useContext(SessionContext);
  const res = useQuery({
    queryKey: [resource, "ids_only", params],
    queryFn: async ({ signal }) => await api.post(`${resource}/ids_only`, params, undefined, signal),
    ...options,
  });
  return {
    ...res,
    count: res.data?.count,
  };
};

export const useEntityCount = <Filters extends StringIndexedDict = {}>(
  resource: ResourceName,
  params?: Filters,
  options?: UseQueryOptions<any, unknown, { count: number }>
): UseQueryResult<{ count: number }> => {
  const { api } = useContext(SessionContext);
  return useQuery({
    queryKey: [resource, "count", params],
    queryFn: async ({ signal }) => await api.post(`${resource}/count`, params, undefined, signal),
    ...options,
  });
};

interface IdsOnlyPostMutationProps<Filters extends StringIndexedDict> {
  body: Filters;
}
export const useIdsOnlyMutation = <IdType, Filters extends StringIndexedDict>(resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  return useMutation<IResultModel<IdType>, unknown, IdsOnlyPostMutationProps<Filters>>(
    (options: IdsOnlyPostMutationProps<Filters>) => {
      return api.post(`${resource}/ids_only`, options.body);
    }
  );
};

export const useBulkPermissions = <IdType extends IdTypes = number, Filters extends Object = {}>(
  resource: ResourceName,
  params?: Filters,
  options?: UseQueryOptions<any, unknown, IResultModel<IBulkPermissionResponse<IdType>>>
): UseQueryResult<IResultModel<IBulkPermissionResponse<IdType>>> & { count?: number } => {
  const { api } = useContext(SessionContext);
  const res = useQuery({
    queryKey: [resource, "bulk_permissions", params],
    queryFn: async ({ signal }) => await api.post(`${resource}/bulk_permissions`, params, undefined, signal),
    ...options,
  });
  return {
    ...res,
    count: res.data?.count,
  };
};

interface BulkdPermissionsPostMutationProps<Filters extends StringIndexedDict> {
  body: Filters;
}
export const useBulkPermissionsMutation = <IdType extends IdTypes, Filters extends StringIndexedDict>(
  resource: ResourceName
) => {
  const { api } = useContext(SessionContext);
  return useMutation<
    IResultModel<IBulkPermissionResponse<IdType>>,
    unknown,
    BulkdPermissionsPostMutationProps<Filters>
  >((options: BulkdPermissionsPostMutationProps<Filters>) => {
    return api.post(`${resource}/bulk_permissions`, options.body);
  });
};

export const useUnpaginateListEntity = <Entity extends GenericEntity>(
  resource: ResourceName,
  ids: Entity["id"][],
  options?: UseQueryOptions<any, unknown, MultiEditMappedItemObject<Entity>>
): UseQueryResult<MultiEditMappedItemObject<Entity>> & { count?: number } => {
  const { unpaginateEntities } = useUnpaginateEntities({ resource: resource });
  return useQuery({
    queryKey: [resource, "list"],
    queryFn: async ({ signal }) => await unpaginateEntities(ids, signal),
    ...options,
  });
};

// Usage:
// const deleteHandler = useDeleteEntity("resource")
// To delete ID=1 and automatically go back on success:
// deleteHandler.mutate({id: 1, goBackOnSuccess: true})

export interface DeleteMutationOptions {
  id: number | string;
  goBackOnSuccess?: boolean;
  showToast?: boolean;
  disableToastOnError?: boolean;
  entityName?: string;
  params?: Record<string, any>;
}
export const useDeleteMutation = (resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation<any, unknown, DeleteMutationOptions>(
    (options: DeleteMutationOptions) => {
      return api.delete(`${resource}/${options.id}`, options.params);
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        queryClient.invalidateQueries({ queryKey: [resource, "detail", variables.id] });
        variables.showToast &&
          showtoast("success", `Deleted ${variables.entityName ?? "item"} with ID: ${variables.id}`);
        variables.goBackOnSuccess && history.goBack();
      },
      onError: (err, variables) => {
        !variables.disableToastOnError && renderError(err);
      },
    }
  );
};

export interface RestoreMutationOptions<Entity extends GenericEntity> {
  id: Entity["id"];
  goBackOnSuccess?: boolean;
  showToast?: boolean;
  disableToastOnError?: boolean;
  entityName?: string;
}
export const useRestoreMutation = <Entity extends GenericEntity>(resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation<Entity, unknown, RestoreMutationOptions<Entity>>(
    (options: RestoreMutationOptions<Entity>) => {
      return api.post(`${resource}/restore/${options.id}`, undefined);
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        queryClient.invalidateQueries({ queryKey: [resource, "detail", variables.id] });
        variables.showToast &&
          showtoast("success", `Restored ${variables.entityName ?? "item"} with ID: ${variables.id}`);
        variables.goBackOnSuccess && history.goBack();
      },
      onError: (err, variables) => {
        !variables.disableToastOnError && renderError(err);
      },
    }
  );
};

export interface CreateMutationOptions<
  Entity extends GenericEntity &
    Partial<IPermissionedEntity> &
    Partial<ICreationRecord> &
    Partial<IModificationRecord> &
    Partial<IRelatedEntity<{}>>
> {
  body: Partial<Entity>;
  goBackOnSuccess?: boolean;
  disableToastOnError?: boolean;
  entityName?: string;
}

export const useCreateMutation = <
  Entity extends GenericEntity &
    Partial<IPermissionedEntity> &
    Partial<ICreationRecord> &
    Partial<IModificationRecord> &
    Partial<IRelatedEntity<{}>>
>(
  resource: ResourceName
) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation<Entity, unknown, CreateMutationOptions<Entity>>(
    (options: CreateMutationOptions<Entity>) => {
      return modifyReadResponse(api.post(`${resource}`, projectTo(options.body)));
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        variables.goBackOnSuccess && history.goBack();
      },
      onError: (err, variables) => {
        renderError(err);
        // (variables.showToastOnError ?? true) &&
        //   showtoast(
        //     "error",
        //     `Error creating ${variables.entityName ?? "item"}\n ${
        //       (err as ServerError).message ?? (err as any).title ?? err
        //     }`
        //   );
      },
    }
  );
};
export interface EditMutationOptions<Entity extends GenericEntity> {
  id: IdTypes;
  body: Entity;
  params?: any;
  goBackOnSuccess?: boolean;
  showToast?: boolean;
  disableToastOnError?: boolean;
  entityName?: string;
}

export const useEditMutation = <Entity extends GenericEntity>(resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation<Entity, unknown, EditMutationOptions<Entity>>(
    (options: EditMutationOptions<Entity>) => {
      return modifyReadResponse(api.put(`${resource}/${options.id}`, projectTo(options.body), options.params));
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        queryClient.invalidateQueries({ queryKey: [resource, "detail", variables.id] });
        variables.showToast &&
          showtoast("success", `Edited ${variables.entityName ?? "item"} with ID: ${variables.id}`);
        variables.goBackOnSuccess && history.goBack();
      },
      onError: (err, variables) => {
        !variables.disableToastOnError && renderError(err);
      },
    }
  );
};

export interface BulkCreateOptions<
  Entity extends GenericEntity &
    Partial<IPermissionedEntity> &
    Partial<ICreationRecord> &
    Partial<IModificationRecord> &
    Partial<IRelatedEntity<{}>>
> {
  body: Partial<Entity>[];
  goBackOnSuccess?: boolean;
  showToast?: boolean;
  disableToastOnError?: boolean;
  entityName?: string;
}
export const useBulkCreate = <
  Entity extends GenericEntity &
    Partial<IPermissionedEntity> &
    Partial<ICreationRecord> &
    Partial<IModificationRecord> &
    Partial<IRelatedEntity<{}>>
>(
  resource: ResourceName
) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation<IPagination<Entity>, unknown, BulkCreateOptions<Entity>>(
    (options) => {
      return modifyReadResponseList(api.post(`${resource}/bulk_create`, projectToBulk(options.body)));
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
        variables.showToast &&
          showtoast("success", `Created ${variables.body.length} ${variables.entityName ?? "items"}`);
        variables.goBackOnSuccess && history.goBack();
      },
      onError: (err, variables) => {
        renderError(err);
        // (variables.showToastOnError ?? true) &&
        //   showtoast(
        //     "error",
        //     `Error deleting ${variables.entityName ?? "items"}\n ${
        //       (err as ServerError).message ?? (err as any).title ?? err
        //     }`
        //   );
      },
    }
  );
};

export interface BulkEditOptions<Entity> {
  body: Entity[];
  goBackOnSuccess?: boolean;
  showToast?: boolean;
  disableToastOnError?: boolean;
  entityName?: string;
}
export const useBulkEdit = <Entity extends GenericEntity>(resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation<IPagination<Entity>, unknown, BulkEditOptions<Entity>>(
    (options: BulkEditOptions<Entity>) => {
      // console.log("Mutating ID: ", entity.id);
      return modifyReadResponseList(api.post(`${resource}/bulk_edit`, projectToBulk(options.body)));
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        variables.body.forEach((id) => {
          queryClient.invalidateQueries({ queryKey: [resource, "detail", id] });
        });
        variables.showToast &&
          showtoast("success", `Edited ${variables.body.length} ${variables.entityName ?? "items"}`);
        variables.goBackOnSuccess && history.goBack();
      },
      onError: (err, variables) => {
        !variables.disableToastOnError && renderError(err);
      },
    }
  );
};

export interface BulkDeleteOptions<Entity extends GenericEntity> {
  ids: Entity["id"][];
  goBackOnSuccess?: boolean;
  showToast?: boolean;
  disableToastOnError?: boolean;
  entityName?: string;
  params?: Record<string, any>;
}
export const useBulkDelete = <Entity extends GenericEntity>(resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(
    (options: BulkDeleteOptions<Entity>) => {
      return api.post(`${resource}/bulk_delete`, options.ids, options.params);
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        variables.ids.forEach((id) => {
          queryClient.invalidateQueries({ queryKey: [resource, "detail", id] });
        });
        variables.showToast &&
          showtoast("success", `Deleted ${variables.ids.length} ${variables.entityName ?? "items"}`);
        variables.goBackOnSuccess && history.goBack();
      },
      onError: (err, variables) => {
        !variables.disableToastOnError && renderError(err);
      },
    }
  );
};

export interface BulkRestoreOptions<Entity extends GenericEntity> {
  ids: Entity["id"][];
  goBackOnSuccess?: boolean;
  showToast?: boolean;
  disableToastOnError?: boolean;
  entityName?: string;
}
export const useBulkRestore = <Entity extends GenericEntity>(resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation<IPagination<Entity>, unknown, BulkRestoreOptions<Entity>>(
    (options: BulkRestoreOptions<Entity>) => {
      return modifyReadResponseList(api.post(`${resource}/bulk_restore`, options.ids));
    },
    {
      onSuccess: (response, variables) => {
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        variables.ids.forEach((id) => {
          queryClient.invalidateQueries({ queryKey: [resource, "detail", id] });
        });
        variables.showToast &&
          showtoast("success", `Restored ${variables.ids.length} ${variables.entityName ?? "items"}`);
        variables.goBackOnSuccess && history.goBack();
      },
      onError: (err, variables) => {
        !variables.disableToastOnError && renderError(err);
      },
    }
  );
};

// Generics

// UseQuery

// For single GET (useQuery)
export const useGet = <T, Params = {} | null>(
  resource: string,
  params?: Params,
  options?: UseQueryOptions<any, unknown, T>
): UseQueryResult<T> => {
  const { api } = useContext(SessionContext);
  return useQuery({
    queryKey: [resource, params],
    queryFn: async ({ signal }) => await api.get(`${resource}`, params, signal),
    ...options,
  });
};

// For single POST (useQuery)
export const usePost = <Entity, Filters = {}>(
  resource: string,
  body?: Filters,
  options?: UseQueryOptions<any, unknown, Entity>,
  invalidateQueries?: boolean
): UseQueryResult<Entity> => {
  const { api } = useContext(SessionContext);
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: [resource, body],
    queryFn: async ({ signal }) => await api.post(`${resource}`, body, undefined, signal),
    ...options,
    ...(invalidateQueries && {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [resource, body] });
      },
    }),
  });
};

// useMutation
interface GetMutationProps<Entity extends GenericEntity, Params> {
  id: Entity["id"];
  params?: Params;
}
export const useGetMutation = <Entity extends GenericEntity, Params>(resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  return useMutation<Entity, unknown, GetMutationProps<Entity, Params>>(
    (options: GetMutationProps<Entity, Params>) => {
      // console.log("Mutating ID: ", entity.id);
      return api.get(`${resource}/${options.id}`, options.params);
    },
    {
      onError: (err, variables) => {
        renderError(err);
      },
    }
  );
};

interface PostMutationProps<Params> {
  body: Params;
}
export const usePostMutation = <ResponseType, Params>({
  resource,
  hideToast = false,
}: {
  resource: ResourceName;
  hideToast?: boolean;
}) => {
  const { api } = useContext(SessionContext);
  return useMutation<ResponseType, unknown, PostMutationProps<Params>>(
    (options: PostMutationProps<Params>) => {
      // console.log("Mutating ID: ", entity.id);
      return api.post(`${resource}`, options.body) as Promise<ResponseType>;
    },
    {
      onError: (err, variables) => {
        !hideToast && renderError(err);
      },
    }
  );
};

interface MultipartPostMutationProps {
  body: FormData;
  onProgress?: (progress: number) => void;
  showToast?: boolean;
  entityName?: string;
  goBackOnSuccess?: boolean;
}

export const useMultipartPostMutation = <ResponseType>(resource: ResourceName) => {
  const { api } = useContext(SessionContext);
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation<ResponseType, unknown, MultipartPostMutationProps>(
    (options) => {
      // console.log("Mutating ID: ", entity.id);
      return api.postMultipart(`${resource}`, options.body, options.onProgress) as Promise<ResponseType>;
    },
    {
      onSuccess: (data: ResponseType, variables) => {
        if (data) {
          variables.showToast && showtoast("success", `Created ${variables.entityName ?? "item"}`);
        }
        queryClient.invalidateQueries({ queryKey: [resource, "list"] });
        queryClient.invalidateQueries({ queryKey: [resource, "count"] });
        queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
        queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
        variables.goBackOnSuccess && history.goBack();
      },

      onError: (err, variables) => {
        renderError(err);
      },
    }
  );
};

// UseQuery version of unpaginate entities
export const useUnpaginate = <Entity extends GenericEntity, Filters = {}>(
  resource: ResourceName,
  params: Filters,
  options?: UseQueryOptions<any, unknown, MultiEditMappedItemObject<Entity>>,
  pageSize: number = 1000,
  onProgress?: (progress: number) => void
): UseQueryResult<MultiEditMappedItemObject<Entity>> => {
  const { api } = useContext(SessionContext);

  return useQuery({
    queryKey: [resource, "list", params],
    queryFn: async ({ signal }) => {
      let results: MultiEditMappedItemObject<Entity> = {};
      let page = 1;
      while (true) {
        const data: IPagination<Entity> = await modifyReadResponseList(
          api
            .post(
              `${resource}/list`,
              { page: page, pageSize: pageSize, includeCount: true, ...params },
              undefined,
              signal
            )
            .catch((e) => {
              // console.error("Error during fetching", e);
              return;
            })
        );

        if (data) {
          data.results.forEach((res) => {
            results[res.id] = res;
          });
          onProgress?.((Object.keys(results).length * 100) / (data.count ?? 9999));
          if (!data.hasNext) {
            break;
          } else {
            page += 1;
          }
        } else {
          break;
        }
      }
      return results;
      // console.log("Fetching done");
    },
    ...options,
  });
};

export const useUnpaginateOrdered = <Entity extends GenericEntity, Filters = {}>(
  resource: ResourceName,
  params: Filters,
  options?: UseQueryOptions<any, unknown, Entity[]>,
  pageSize: number = 1000,
  onProgress?: (progress: number) => void,
  limit?: number
): UseQueryResult<Entity[]> => {
  const { api } = useContext(SessionContext);

  return useQuery({
    queryKey: [resource, "list", params],
    queryFn: async ({ signal }) => {
      let results: Entity[] = [];
      let page = 1;
      while (!limit || results.length < limit) {
        const data: IPagination<Entity> = await modifyReadResponseList(
          api
            .post(
              `${resource}/list`,
              { page: page, pageSize: pageSize, includeCount: true, ...params },
              undefined,
              signal
            )
            .catch((e) => {
              // console.error("Error during fetching", e);
              return;
            })
        );

        if (data) {
          results = results.concat(data.results);
          onProgress?.((results.length * 100) / (data.count ?? 9999));
          if (!data.hasNext) {
            break;
          } else {
            page += 1;
          }
        } else {
          break;
        }
      }
      return results;
      // console.log("Fetching done");
    },
    ...options,
  });
};

// Paginated query of endpoints that deviate from the standard /list endpoint
export const useInfiniteList = <ResponseModel, Filters extends StringIndexedDict>(
  resource: ResourceName,
  params?: Filters,
  options?: UseInfiniteQueryOptions<any, unknown, IPagination<ResponseModel>>
): UseInfiniteQueryResult<IPagination<ResponseModel>> & { count?: number } => {
  const { api } = useContext(SessionContext);
  const getDataByPageParamPost = async (pageParam: number | undefined, signal?: AbortSignal) => {
    if (pageParam) {
      const response: IPagination<ResponseModel> = await api.post(`${resource}`, {
        ...params,
        page: pageParam,
      });

      return { ...response, next: +pageParam + 1 };
    } else {
      const response: IPagination<ResponseModel> = await api.post(`${resource}`, params);
      return { ...response, next: 2 };
    }
  };
  const res = useInfiniteQuery({
    queryKey: [resource, params],
    queryFn: async ({ pageParam, signal }) => await getDataByPageParamPost(pageParam, signal),
    getNextPageParam: (lastPage: IPagePagination<ResponseModel>) => (lastPage.hasNext ? lastPage.next : undefined),
    cacheTime: 0, // Each new query will be considered fresh; No caching!
    ...options,
  });
  return {
    ...res,
    count: res.data && !!res.data.pages.length ? res.data.pages[0].count : undefined,
  };
};

// Simple hook to invalidate queries
// CS: TODO use this hook across all fns
export const useInvalidateQueries = (resource: ResourceName) => {
  const queryClient = useQueryClient();
  const invalidateQueries = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [resource, "list"] });
    queryClient.invalidateQueries({ queryKey: [resource, "count"] });
    queryClient.invalidateQueries({ queryKey: [resource, "ids_only"] });
    queryClient.invalidateQueries({ queryKey: [resource, "suggestions"] });
    queryClient.invalidateQueries({ queryKey: [resource, "detail"] });
  }, [queryClient, resource]);
  return invalidateQueries;
};
