import { useHistory } from "react-router-dom";
import React, { useContext, useState, useMemo } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { ResourceName } from "../../main/Routing";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { DatasetsFilterForm, DatasetsFilterbar } from "./DatasetsFilterBar";
import { DownloadDatasetMulti } from "../common/DownloadDataset/DownloadDataset";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import {
  DatasetFilters,
  Dataset,
  DatasetFiltersTranslator,
  DatasetSearchTermOptions,
  datasetsConstants,
} from "../../api/Datasets";
// import { MultiEdit, MultiEditModal } from "../../common/tables/MultiEdit/MultiEdit";
import { ExclusiveDropdown } from "../../common/buttons/ExclusiveDropdown/ExclusiveDropdown";
import { DatasetsBulkEditDropDownWrapper } from "./DatasetsBulkEditDropDown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import styles from "../../common/tables/GenericVirtualizedTable/commons.module.css";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { SharedContentModal } from "../../common/helpers/EntityModals/SharedContentModal";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { NotAvailable, NotSet } from "../../common/misc/UIconstants";
import { GenericModalWrapper } from "../../common/modals/Modal/GenericModal";
import { GetPersons, TableArrayRenderer } from "../../common/misc/EntityRenders/EntityRenderer";
import { useUnpaginateEntities } from "../../common/forms/MultiEditForms/common/MultiEditUtils";
import { Button } from "../../common/buttons/Button/Button";
import { ExportCsvButton } from "../../common/tables/ExportCsvButton/ExportCsvButton";
import { samplesConstants } from "../../api/Samples";
import { useCustomTypesEntityTable } from "../../Customization/CustomTypes/generics/useCustomTypesEntityTable";
import { RenderParsingState } from "../common/HelperModules";
import SearchInputWithOptions from "../../common/forms/SearchInputWithOptions/SearchInputWithOptions";
import { renderCustomTypeColumn } from "../../Customization/CustomTypes/generics/CustomTypeRenderer";
import {
  UseEntityTableDefaultProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
  UseITypedEntityTableProps,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { usePostMutation } from "../../api/BaseEntityApi";
import { customImportConstants, CustomImportResult } from "../../api/CustomImports";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableSoftDeletableButton } from "../../common/entity/entityComponents/EntityTableSoftDeletableButton";
import { EntityTableRestoreButton } from "../../common/entity/entityComponents/EntityTableRestoreButton";

export const switchDatasetsDefaultSortState = (
  sortState: DatasetFilters["orderBy"]
): SortState<DatasetFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "ACQUISITION_DATE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-acqdate",
        orderBy: sortState,
      };
    case "ACQUISITION_DATE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-acqdate",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "METHOD_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-method",
        orderBy: sortState,
      };
    case "METHOD_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-method",
        orderBy: sortState,
      };
    case "EXPERIMENT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-experiment",
        orderBy: sortState,
      };
    case "EXPERIMENT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-experiment",
        orderBy: sortState,
      };
    case "INSTRUMENT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-instrument",
        orderBy: sortState,
      };
    case "INSTRUMENT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-instrument",
        orderBy: sortState,
      };
    case "SAMPLE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-sample",
        orderBy: sortState,
      };
    case "SAMPLE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-sample",
        orderBy: sortState,
      };
    case "PARSING_STATE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-parsingState",
        orderBy: sortState,
      };
    case "PARSING_STATE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-parsingState",
        orderBy: sortState,
      };
    case "TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-datasetType",
        orderBy: sortState,
      };
    case "TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-datasetType",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "DESC",
        headerId: "default-acqdate",
        orderBy: sortState,
      };
  }
};

const defaultFilter: DatasetFilters = {
  orderBy: "ACQUISITION_DATE_DESC",
  searchTermIncludeNames: true,
  searchTermIncludeIds: true,
  searchTermIncludeUuids: true,
  searchTermIncludeNotes: false,
  searchTermIncludeParameters: false,
  searchTermIncludePaths: false,
};

export const useDatasetsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"datasets">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<Dataset> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-legacyId": { pos: 1, active: false, header: fieldLabels.legacyId, property: "legacyId" },
      "default-name": { pos: 2, active: true, header: fieldLabels.name, property: "name" },
      "default-automaticName": {
        pos: 3,
        active: false,
        header: fieldLabels.automaticName,
        property: "automaticName",
      },
      "default-method": { pos: 4, active: true, header: fieldLabels.method, property: "method" },
      "default-sample": { pos: 5, active: true, header: fieldLabels.sample, property: "sample" },
      "default-instrument": { pos: 6, active: true, header: fieldLabels.instrument, property: "instrument" },
      "default-acqdate": { pos: 7, active: true, header: fieldLabels.acquisitionDate, property: "acquisitionDate" },
      "default-projects": { pos: 8, active: true, header: fieldLabels.projects, property: "projects" },
      "default-operators": { pos: 9, active: true, header: fieldLabels.operators, property: "operators" },
      "default-experiment": { pos: 10, active: true, header: fieldLabels.experiment, property: "experiment" },
      "default-createdOn": { pos: 11, active: false, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 12, active: false, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 13, active: false, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 14, active: false, header: fieldLabels.modifiedBy, property: "modifiedBy" },
      "default-type": { pos: 15, active: true, header: fieldLabels.customType, property: "customType" },
      "default-organizations": { pos: 16, active: false, header: fieldLabels.organizations, property: "organizations" },
      "default-eq": { pos: 17, active: false, header: fieldLabels.equipments, property: "equipments" },
      "default-other": { pos: 18, active: false, header: fieldLabels.other, property: "other" },
      "default-notes": { pos: 19, active: false, header: fieldLabels.notes, property: "notes" },
      "default-format": { pos: 20, active: false, header: fieldLabels.format, property: "format" },
      "default-sourcepath": { pos: 21, active: false, header: fieldLabels.path, property: "path" },
      "default-relpath": {
        pos: 22,
        active: false,
        header: fieldLabels.sourceRelativeDirectory,
        property: "sourceRelativeDirectory",
      },
      "default-uid": { pos: 23, active: false, header: fieldLabels.uid, property: "uid" },
      ...((!!session?.permissions.maintenance ||
        !!session?.permissions.view_admin_pages ||
        !!session?.features.activate_maintenance) && {
        "default-parsingState": { pos: 24, active: false, header: fieldLabels.parsingState, property: "parsingState" },
      }),
      "legacy-brukerPulseProgram": {
        pos: 25,
        active: false,
        header: "Bruker pulse program",
        property: "legacyFields.brukerPulseProgram",
      },
      "legacy-notes": { pos: 26, active: false, header: "Bruker title", property: "notes" },
    }),
    [
      session?.features.activate_maintenance,
      session?.permissions.maintenance,
      session?.permissions.view_admin_pages,
      fieldLabels,
    ]
  ); // Column defaults

  const tabStoreDefaults: TableTabsDict<Dataset, DatasetFilters, DatasetsFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: { includeUnclaimed: true, includeSoftDeleted: false, includeViewableEntities: false },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      mydata: {
        tabId: "mydata",
        type: "fixed",
        label: `My ${datasetsConstants.entityPlural}`,
        title: `My ${datasetsConstants.entityPlural}`,
        icon: "user",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { ownerIds: session?.person.id ? [session.person.id] : null },
          sidebarFilters: {},
        },
      },
      unclaimed: {
        tabId: "unclaimed",
        type: "fixed",
        label: "Unclaimed",
        title: "Unclaimed",
        icon: "triangle-alert",
        align: "left",
        xPos: 2,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {
            "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
            "default-legacyId": { pos: 1, active: false, header: fieldLabels.legacyId, property: "legacyId" },
            "default-name": { pos: 2, active: true, header: fieldLabels.name, property: "name" },
            "default-sourcepath": { pos: 3, active: true, header: fieldLabels.path, property: "path" },
            "default-method": { pos: 4, active: true, header: fieldLabels.method, property: "method" },
            "default-sample": { pos: 5, active: true, header: fieldLabels.sample, property: "sample" },
            "default-instrument": {
              pos: 6,
              active: true,
              header: fieldLabels.instrument,
              property: "instrument",
            },
            "default-acqdate": {
              pos: 7,
              active: true,
              header: fieldLabels.acquisitionDate,
              property: "acquisitionDate",
            },
            "default-projects": { pos: 8, active: true, header: fieldLabels.projects, property: "projects" },
            "default-operators": { pos: 9, active: true, header: fieldLabels.operators, property: "operators" },
            "default-experiment": {
              pos: 10,
              active: true,
              header: fieldLabels.experiment,
              property: "experiment",
            },

            "default-createdOn": { pos: 11, active: true, header: fieldLabels.createdOn, property: "createdOn" },
            "default-createdBy": { pos: 12, active: true, header: fieldLabels.createdBy, property: "createdBy" },
            "default-modifiedOn": {
              pos: 13,
              active: false,
              header: fieldLabels.modifiedOn,
              property: "modifiedOn",
            },
            "default-modifiedBy": {
              pos: 14,
              active: false,
              header: fieldLabels.modifiedBy,
              property: "modifiedBy",
            },
            "default-type": { pos: 15, active: false, header: fieldLabels.customType, property: "customType" },
            "default-organizations": {
              pos: 16,
              active: false,
              header: fieldLabels.organizations,
              property: "organizations",
            },
            "default-eq": { pos: 17, active: false, header: fieldLabels.equipments, property: "equipments" },
            "default-other": { pos: 18, active: false, header: fieldLabels.other, property: "other" },
            "default-notes": { pos: 19, active: false, header: fieldLabels.notes, property: "notes" },
            "default-format": { pos: 20, active: false, header: fieldLabels.format, property: "format" },
            "default-relpath": {
              pos: 21,
              active: false,
              header: fieldLabels.sourceRelativeDirectory,
              property: "sourceRelativeDirectory",
            },
            "default-uid": { pos: 22, active: false, header: fieldLabels.uid, property: "uid" },
            ...((!!session?.permissions.maintenance ||
              !!session?.permissions.view_admin_pages ||
              !!session?.features.activate_maintenance) && {
              "default-parsingState": {
                pos: 23,
                active: false,
                header: fieldLabels.parsingState,
                property: "parsingState",
              },
            }),
            "legacy-brukerPulseProgram": {
              pos: 24,
              active: false,
              header: "Bruker Pulse Program",
              property: "legacyFields.brukerPulseProgram",
            },
            "legacy-notes": { pos: 25, active: false, header: "Bruker Title", property: "notes" },
          },
          columnWidths: {},
          filters: {
            isClaimed: false,
          },
          sidebarFilters: { includeUnclaimed: true },
        },
      },
      trash: {
        tabId: "trash",
        type: "fixed",
        label: "Trash",
        title: "Trash",
        icon: "trash",
        align: "left",
        xPos: 3,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: { includeUnclaimed: true },
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { isSoftDeleted: true },
          sidebarFilters: {},
        },
      },
    }),
    [
      fieldLabels.acquisitionDate,
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.customType,
      fieldLabels.equipments,
      fieldLabels.experiment,
      fieldLabels.format,
      fieldLabels.id,
      fieldLabels.instrument,
      fieldLabels.legacyId,
      fieldLabels.method,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.notes,
      fieldLabels.operators,
      fieldLabels.organizations,
      fieldLabels.other,
      fieldLabels.parsingState,
      fieldLabels.path,
      fieldLabels.projects,
      fieldLabels.sample,
      fieldLabels.sourceRelativeDirectory,
      fieldLabels.uid,
      session?.features.activate_maintenance,
      session?.permissions.maintenance,
      session?.permissions.view_admin_pages,
      session?.person.id,
    ]
  );

  return { defaults, tabStoreDefaults };
};

export const useDatasetsTableColumns = ({
  entityConstants,
  fieldLabels,
  defaults,
  filters,
  sort,
  setSort,
}: UseITypedEntityTableProps<"datasets">) => {
  const { session } = useContext(SessionContext);
  // Custom Fields
  const { columnSettings, customFieldHeaders } = useCustomTypesEntityTable({
    defaultColumnSettings: defaults,
    entityType: "Dataset",
    customTypeIds: filters?.customTypeIds ?? undefined,
  });

  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<Dataset> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => (
          <span style={{ color: "var(--gray-400)" }}>
            <samp>{row.id}</samp>
          </span>
        ),
        width: 135,
        minWidth: 135,
        align: "right",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-legacyId",
        Header: fieldLabels.legacyId,
        accessor: (row) => (
          <span style={{ color: "var(--gray-400)" }}>
            <samp>{row.legacyId ?? NotAvailable}</samp>
          </span>
        ),
        width: 135,
        minWidth: 135,
        align: "right",
      },
      {
        id: "default-name",
        Header: fieldLabels.name,
        accessor: (row) => (
          <div className={`${styles.container_ellipsis} gap-5`}>
            <div className={`${styles.container_ellipsis}`}>
              <span className={styles.label_highlight}>
                <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name || NotAvailable}
              </span>
            </div>
            {!row.claimed && (
              <div>
                <label className="label label-soft-danger" title={"Unclaimed"}>
                  Unclaimed
                </label>
              </div>
            )}
            {row.isDeleted && (
              <div>
                <label className="label label-soft-warning" title={"Trashed"}>
                  <LucideIcon name="trash" />
                </label>
              </div>
            )}
            {row.isViewableEntity && (
              <div>
                <label className="label label-soft-info">Viewable Entity</label>
              </div>
            )}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-automaticName",
        Header: fieldLabels.automaticName,
        accessor: (row) =>
          !!row.automaticName ? (
            <div className={`${styles.container_ellipsis} gap-5`}>
              <div className={`${styles.container_ellipsis}`}>
                <span className={styles.label_highlight}>
                  <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.automaticName}
                </span>
              </div>
            </div>
          ) : (
            NotSet
          ),
        minWidth: 150,
        width: 250,
        align: "left",
      },

      {
        id: "default-sample",
        Header: fieldLabels.sample,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            {row.sample ? (
              <span className={styles.label_highlight}>
                <LucideIcon name={samplesConstants.icon} color={"var(--primary)"} /> {row.sample.name}
              </span>
            ) : (
              NotSet
            )}
          </div>
        ),
        minWidth: 150,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "SAMPLE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "SAMPLE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "SAMPLE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-acqdate",
        Header: fieldLabels.acquisitionDate,
        accessor: (row) => <DateTimeRenderer date={row.acquisitionDate} includeElapsed={false} />,
        width: 175,
        minWidth: 175,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ACQUISITION_DATE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ACQUISITION_DATE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ACQUISITION_DATE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-projects",
        Header: fieldLabels.projects,
        accessor: (row) => <TableArrayRenderer values={row.projects}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        id: "default-organizations",
        Header: fieldLabels.organizations,
        accessor: (row) => <TableArrayRenderer values={row.organizations}>{(value) => value.name}</TableArrayRenderer>,
        width: 200,
        align: "left",
      },
      {
        id: "default-operators",
        Header: fieldLabels.operators,
        accessor: (row) => (
          <TableArrayRenderer values={row.operators}>
            {(value) => <GetPersons persons={value} createLinks={false} />}
          </TableArrayRenderer>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-method",
        Header: fieldLabels.method,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.method ? row.method.name : NotSet}</span>
          </div>
        ),
        width: 150,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "METHOD_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "METHOD_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "METHOD_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-experiment",
        Header: fieldLabels.experiment,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.experiment ? row.experiment.name : NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "EXPERIMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "EXPERIMENT_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "EXPERIMENT_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-instrument",
        Header: fieldLabels.instrument,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.instrument?.name ?? NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "INSTRUMENT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "INSTRUMENT_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "INSTRUMENT_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-eq",
        Header: fieldLabels.equipments,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>
              {Array.isArray(row.equipments) && row.equipments.length > 0
                ? row.equipments.map((d) => d.name).join(", ")
                : NotSet}
            </span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-other",
        Header: fieldLabels.other,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.other || NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-notes",
        Header: fieldLabels.notes,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.notes || NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-format",
        Header: fieldLabels.format,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.format?.name ?? NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-sourcepath",
        Header: fieldLabels.path,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.path || NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-relpath",
        Header: fieldLabels.sourceRelativeDirectory,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.sourceRelativeDirectory || NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-uid",
        Header: fieldLabels.uid,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.uid}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "default-parsingState",
        Header: fieldLabels.parsingState,
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>
              <RenderParsingState state={row.parsingState} />
            </span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "legacy-brukerPulseProgram",
        Header: "Bruker Pulse Program",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.legacyFields?.brukerPulseProgram ?? NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
      {
        id: "legacy-notes",
        Header: "Bruker Title",
        accessor: (row) => (
          <div className={styles.container_ellipsis}>
            <span>{row.legacyFields?.brukerTitle ?? NotSet}</span>
          </div>
        ),
        width: 200,
        align: "left",
      },
    ];

    if (session?.features.development_mode) {
      headers.push({
        id: "default-parsingState",
        Header: fieldLabels.parsingState,
        accessor: (row) => (
          <div className={styles.container_ellipsis} style={{ width: "100%" }}>
            <span style={{ width: "100%" }}>
              <>
                {row.parsingState === "ParsingFailed" && (
                  <label className="flex justify-center label label-soft-danger" style={{ margin: 0, width: "100%" }}>
                    Parsing failed
                  </label>
                )}
                {row.parsingState === "ParsedSuccessfully" && (
                  <label className="flex justify-center label label-soft-success" style={{ margin: 0, width: "100%" }}>
                    Successfully parsed
                  </label>
                )}
                {row.parsingState === "NotYetParsed" && (
                  <label className="flex justify-center label label-soft-warning" style={{ margin: 0, width: "100%" }}>
                    Not yet parsed
                  </label>
                )}
                {row.parsingState === "NotParseable" && (
                  <label className="flex justify-center label label-soft-default" style={{ margin: 0, width: "100%" }}>
                    Not parseable
                  </label>
                )}
              </>
            </span>
          </div>
        ),
        width: 200,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "PARSING_STATE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "PARSING_STATE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "PARSING_STATE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      });
    }
    // Add custom field headers
    headers.push(
      renderCustomTypeColumn<Dataset, DatasetFilters["orderBy"], DatasetFilters>(entityConstants, sort, setSort)
    );
    headers = headers.concat(customFieldHeaders);
    return headers;
  }, [
    customFieldHeaders,
    entityConstants,
    fieldLabels.acquisitionDate,
    fieldLabels.automaticName,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.equipments,
    fieldLabels.experiment,
    fieldLabels.format,
    fieldLabels.id,
    fieldLabels.instrument,
    fieldLabels.legacyId,
    fieldLabels.method,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.notes,
    fieldLabels.operators,
    fieldLabels.organizations,
    fieldLabels.other,
    fieldLabels.parsingState,
    fieldLabels.path,
    fieldLabels.projects,
    fieldLabels.sample,
    fieldLabels.sourceRelativeDirectory,
    fieldLabels.uid,
    session?.features.development_mode,
    setSort,
    sort,
  ]);

  return { columns, columnSettings };
};

export const DatasetsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"datasets">) => {
  const history = useHistory();
  const { session, api } = useContext(SessionContext);
  const { unpaginateEntities } = useUnpaginateEntities({ resource: entityConstants.resource });
  const {
    bulkDeleteMutationAsync,
    bulkRestoreMutationAsync,
    bulkEditMutationAsync,
    isLoadingBulkEditMutation,
    isLoadingRestoreMutation,
    isLoadingBulkDeleteMutation,
  } = entityApi;

  const { mutateAsync: bulkApplyCustomImportMutationAsync, isLoading: isLoadingBulkApplyCustomImportMutation } =
    usePostMutation<CustomImportResult[], { datasetIds: number[] }>({
      resource: "custom_imports/datasets" as ResourceName,
    });

  const { defaults, tabStoreDefaults } = useDatasetsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<Dataset>();

  const {
    filters,
    forcedFilters,
    sidebarFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<Dataset, DatasetFilters, DatasetsFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue, debouncedSearchValue } =
    useGenericVirtualizedTableTabs({
      currentTab,
      tabsLoading,
      filters,
      switchSortState: switchDatasetsDefaultSortState,
      dispatchTabStore,
    });

  const { columns, columnSettings } = useDatasetsTableColumns({
    fieldLabels,
    entityConstants,
    defaults,
    filters,
    sort,
    setSort,
  });

  const memoizedFilters = useMemo(
    () => ({
      ...filters,
      ...forcedFilters,
      ...((!!session?.permissions.maintenance ||
        !!session?.permissions.view_admin_pages ||
        !!session?.features.activate_maintenance) && { includeParsingState: true }),
    }),
    [
      filters,
      forcedFilters,
      session?.features.activate_maintenance,
      session?.permissions.maintenance,
      session?.permissions.view_admin_pages,
    ]
  );
  const [showModal, setShowModal] = useState(false);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            onClick={() => history.push(routes.getAddRoute)}
            disabled={!permissions.canCreate}
          >
            <LucideIcon name="upload" /> Upload {entityConstants.entitySingular}
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <DatasetsFilterbar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            temporaryTabs={temporaryTabs}
            fixedTabs={fixedTabs}
            filters={{ ...filters, includeParsingState: true }}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<Dataset, DatasetFilters>
                filters={filters}
                translatorConsts={DatasetFiltersTranslator}
                excludeFilters={{
                  includeParsingState: () => false,
                  includeSoftDeleted: (value) => value === true,
                  includeViewableEntities: (value) => value === true,
                  isSoftDeleted: () => false,
                  participatedPersonIds: () => false,
                  ...(currentTab === "claimed" && { isClaimed: () => false }),
                  includeUnclaimed: (value) => false,
                  searchTermIncludeIds: () => false,
                  searchTermIncludeNames: () => false,
                  searchTermIncludeNotes: () => false,
                  searchTermIncludeParameters: () => false,
                  searchTermIncludePaths: () => false,
                  searchTermIncludeUuids: () => false,
                }}
                style={{ margin: "auto 0" }}
              />
              {/* <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" /> */}
              <SearchInputWithOptions
                searchTermOptions={DatasetSearchTermOptions}
                filters={filters}
                setFilters={async (newFilters) => {
                  await dispatchTabStore({
                    type: "setTab",
                    options: { keepPrevious: true },
                    payload: { settings: { filters: { searchTerm: debouncedSearchValue, ...newFilters } } },
                  });
                }}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              <div
                className="flex row-nowrap align-center gap-5"
                style={{ height: "min-content", marginBottom: "auto" }}
              >
                {currentTab !== "trash" && (
                  <GenericModalWrapper>
                    {({ showModal, setShowModal }) => (
                      <ExclusiveDropdown
                        show={showModal}
                        setShow={setShowModal}
                        disabled={
                          selection.size === 0 ||
                          (session?.features.development_mode ? false : selection.size > 1000) ||
                          !permissions.canBulkEdit(selectionPermissions)
                        }
                        title={`${
                          !permissions.canBulkEdit(selectionPermissions)
                            ? `Insufficient permissions to edit selected ${entityConstants.entityPlural}`
                            : selection.size > 1000
                            ? `Editing is only allowed for max. 1000 ${entityConstants.entityPlural} at once`
                            : `Edit`
                        }`}
                        btnCls={"btn btn-default"}
                        btnLabel={`${currentTab === "unclaimed" ? "Edit / Claim" : "Edit"}`}
                        icon={<LucideIcon name="square-pen" />}
                      >
                        <DatasetsBulkEditDropDownWrapper
                          selection={selection}
                          onSuccess={() => {
                            functionRef.current?.resetSelection();
                            setShowModal(false);
                          }}
                          onClose={() => {
                            setShowModal(false);
                          }}
                          filters={filters}
                          entityConstants={entityConstants}
                        />
                      </ExclusiveDropdown>
                    )}
                  </GenericModalWrapper>
                )}
                <DownloadDatasetMulti
                  api={api}
                  disabled={selection.size === 0}
                  filters={{ ids: Array.from(selection) }}
                  title={`Download the selected ${entityConstants.entityPlural}`}
                />
                <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                  {currentTab !== "trash" && (
                    <>
                      <li>
                        <SharedContentModal
                          datasetIds={Array.from(selection)}
                          disabled={selection.size === 0 || !session?.permissions.add_bundles}
                        />
                      </li>
                      {currentTab !== "unclaimed" && (
                        <li>
                          <Button
                            className="btn btn-sm btn-ghost-danger"
                            title={`${
                              !permissions.canBulkEdit(selectionPermissions)
                                ? `Insufficient permissions to unclaim selected ${entityConstants.entityPlural}`
                                : selection.size === 0
                                ? `Select ${entityConstants.entitySingular} to unclaim`
                                : selection.size === 1
                                ? `Unclaim the selected ${entityConstants.entitySingular}`
                                : `Unclaim the selected ${entityConstants.entityPlural}`
                            }`}
                            disabled={selection.size === 0 || !permissions.canBulkEdit(selectionPermissions)}
                            onClick={async () => {
                              const data = await unpaginateEntities<Dataset>(Array.from(selection));
                              await bulkEditMutationAsync({
                                body: Object.values(data).map((e) => ({ ...e, claimed: false })),
                              }).catch(() => {});
                            }}
                            loading={isLoadingBulkEditMutation}
                          >
                            <LucideIcon name="circle-x" /> Unclaim
                          </Button>
                        </li>
                      )}
                    </>
                  )}
                  <li>
                    <ExportCsvButton
                      entityConstants={entityConstants}
                      columnSettings={columnSetting}
                      filters={{
                        ids: selection.size > 0 ? Array.from(selection) : undefined,
                        ...memoizedFilters,
                        searchTerm: debouncedSearchValue,
                      }}
                      disabled={selection.size === 0}
                    />
                  </li>
                  {currentTab === "trash" && (
                    <li>
                      {/* <Button
                        className="btn btn-sm btn-ghost-success"
                        title={`${
                          !permissions.canBulkEdit(selectionPermissions)
                            ? `Insufficient permissions to restore selected ${entityConstants.entityPlural}`
                            : selection.size === 0
                            ? `Select ${entityConstants.entitySingular} to restore`
                            : selection.size === 1
                            ? `Restore the selected ${entityConstants.entitySingular}`
                            : `Restore the selected ${entityConstants.entityPlural}`
                        }`}
                        disabled={selection.size === 0 || !permissions.canBulkEdit(selectionPermissions)}
                        onClick={async () => {
                          await bulkRestoreMutationAsync({ ids: Array.from(selection) }).catch(() => {});
                        }}
                        loading={isLoadingRestoreMutation}
                      >
                        <LucideIcon name="refresh-ccw" /> Restore from trash
                      </Button> */}
                      <EntityTableRestoreButton
                        entityConstants={entityConstants}
                        selection={selection}
                        permissions={permissions}
                        selectionPermissions={selectionPermissions}
                        onClick={async () =>
                          await bulkRestoreMutationAsync({ ids: Array.from(selection) }).catch(() => {})
                        }
                        loading={isLoadingRestoreMutation}
                      />
                    </li>
                  )}
                  <li>
                    <EntityTableSoftDeletableButton
                      currentTab={currentTab}
                      entityConstants={entityConstants}
                      selection={selection}
                      permissions={permissions}
                      selectionPermissions={selectionPermissions}
                      onClick={() => setShowModal(true)}
                    />
                  </li>
                  {session?.permissions.logsadmin && (
                    <li>
                      <Button
                        className="btn btn-sm btn-ghost-success"
                        title="Bulk apply custom import"
                        onClick={async () => {
                          await bulkApplyCustomImportMutationAsync(
                            { body: { datasetIds: Array.from(selection) } },
                            {
                              onSuccess: (data) => {
                                showtoast(
                                  "success",
                                  `Successfully applied custom imports to ${data.length ?? "N/A"}/${
                                    selection.size
                                  } datasets`
                                );
                                console.log("Bulk apply custom import result: ", data);
                              },
                            }
                          ).catch(() => {});
                        }}
                        loading={isLoadingBulkApplyCustomImportMutation}
                        disabled={selection.size === 0}
                      >
                        <LucideIcon name={customImportConstants.icon} /> Apply custom import
                      </Button>
                    </li>
                  )}
                </MoreDropdown>
              </div>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entitySingular}?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entityPlural}?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? `Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`
                    : `Proceeding will move the selected ${entityConstants.entityPlural} into trash.`
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await bulkDeleteMutationAsync({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.entityPlural,
                    params: { deletePermanently: currentTab === "trash" },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<Dataset, DatasetFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={columnSettings}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
