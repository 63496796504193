import { useQuery } from "@tanstack/react-query";
import React from "react";
import { LicenseModelValidated } from "../../api/Login";
import { LoadingWrapper } from "../../common/LoadingWrapper";
import { API } from "../../api/Api";
import { Alert } from "../../common/overlays/Alert/Alert";

const dayDifference = (date1: Date, date2: Date) => {
  let utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  let utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  // Calculate the time difference in milliseconds
  let timeDiff = Math.abs(utc2 - utc1);
  // Convert milliseconds to days
  let daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysDiff;
};

interface LicenseProviderProps {
  api: API;
  children: ({ license }: { license: LicenseModelValidated }) => React.ReactNode;
}

/**
 * LicenseProvider component fetches the license data and provides it to its children.
 * It uses the `useQuery` hook to fetch the license data and handles loading, error, and success states.
 * @author @CorradoSurmanowicz
 * @param {LicenseProviderProps} props - The props for the LicenseProvider component.
 * @param {React.ReactNode} props.children - The child components that will receive the license data.
 *
 * @returns {JSX.Element} The rendered component with loading, error, and success states handled.
 */
export const LicenseProvider = ({ api, children }: LicenseProviderProps) => {
  const licenseQuery = useQuery<LicenseModelValidated>({
    queryKey: ["license", api.getGroup()],
    queryFn: async ({ signal }) => await api.get(`license`, undefined, signal),
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  const { data: license, status, fetchStatus, error } = licenseQuery;
  return (
    <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
      {license && (
        <>
          {!license.isValid && (
            <Alert
              type="danger"
              message={
                "Expired or invalid license. The application will be read-only until a valid license is provided."
              }
              fit
              centered
              style={{ marginBottom: 0 }}
              dismissible
            />
          )}
          {license.isValid && dayDifference(new Date(license.expirationDate), new Date()) <= 14 && (
            <Alert
              type="warning"
              message={`The license is about to expire in ${dayDifference(
                new Date(license.expirationDate),
                new Date()
              )} days. Please contact your local LOGS administrator or the LOGS support team.`}
              fit
              centered
              style={{ marginBottom: 0 }}
              dismissible
            />
          )}
          {children({ license })}
        </>
      )}
    </LoadingWrapper>
  );
};
