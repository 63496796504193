import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { InventoryItem, InventoryItemFilters } from "../../api/Inventories";
import { CustomTypesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/CustomTypesVirtualizedSelectForm";
import { InventoriesVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/InventoriesVirtualizedSelectForm";

export interface InventoryFilterForm {
  customTypeIds?: IEntityMinimalModel[] | undefined | null;
  childrenOfParentIds?: IEntityMinimalModel[] | undefined | null;
  descendantsOfIds?: IEntityMinimalModel[] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}
const defaultValues: DefinedPropertyObject<InventoryFilterForm> = {
  customTypeIds: null,
  childrenOfParentIds: null,
  descendantsOfIds: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};
export const inventoryItemsFormToFilters = (form: InventoryFilterForm): InventoryItemFilters => {
  let filters: InventoryItemFilters = {};
  filters.customTypeIds =
    Array.isArray(form.customTypeIds) && form.customTypeIds.length > 0 ? form.customTypeIds?.map((d) => d.id) : null;
  filters.childrenOfParentIds =
    Array.isArray(form.childrenOfParentIds) && form.childrenOfParentIds.length > 0
      ? form.childrenOfParentIds?.map((d) => d.id)
      : null;
  filters.descendantsOfIds =
    Array.isArray(form.descendantsOfIds) && form.descendantsOfIds.length > 0
      ? form.descendantsOfIds?.map((d) => d.id)
      : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;

  return filters;
};
/**
 * Converts InventoryItemFilters to InventoryFilterForm
 * @param filters InventoryItemFilters
 * @returns InventoryFilterForm
 * @author @CorradoSurmanowicz
 */
export const inventoryItemsFiltersToForm = (filters: InventoryItemFilters): InventoryFilterForm => {
  let form: InventoryFilterForm = {};
  form.customTypeIds = filters.customTypeIds?.map((id) => ({ id, name: "" })) || null;
  form.childrenOfParentIds = filters.childrenOfParentIds?.map((id) => ({ id, name: "" })) || null;
  form.descendantsOfIds = filters.descendantsOfIds?.map((id) => ({ id, name: "" })) || null;
  form.createdByIds = filters.createdByIds?.map((id) => ({ id, name: "" })) || null;
  form.createdFrom = filters.createdFrom ? new Date(filters.createdFrom) : null;
  form.createdTo = filters.createdTo ? new Date(filters.createdTo) : null;
  form.modifiedByIds = filters.modifiedByIds?.map((id) => ({ id, name: "" })) || null;
  form.modifiedFrom = filters.modifiedFrom ? new Date(filters.modifiedFrom) : null;
  form.modifiedTo = filters.modifiedTo ? new Date(filters.modifiedTo) : null;
  return form;
};

const defaultFilters: InventoryItemFilters = { orderBy: "NAME_ASC" };

interface InventoryItemsFilterBarProps
  extends TabStoreFilterSidebarProps<InventoryItem, InventoryFilterForm, InventoryItemFilters> {
  hideHierarchyFilters?: boolean;
}

export const InventoryItemsFilterBar = ({
  initialValues,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
  hideHierarchyFilters = false,
}: InventoryItemsFilterBarProps) => {
  const { control, onReset, watch } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: inventoryItemsFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  const currentCustomTypeIds = watch("customTypeIds")?.map((c) => c.id);

  return (
    <EntityFilterSideBar onReset={onReset}>
      {!hideHierarchyFilters && (
        <>
          <CustomTypesVirtualizedSelectForm
            id="customTypeIds"
            label="Inventory types"
            control={control}
            isMulti
            filters={{ entityTypes: ["Inventory"] }}
            placeholder="Select inventory types..."
            enableBasicOption
          />

          <InventoriesVirtualizedSelectForm
            id="childrenOfParentIds"
            label="Direct children of"
            control={control}
            isMulti
            filters={{ customTypeIds: !!currentCustomTypeIds?.length ? currentCustomTypeIds : undefined }}
          />
          <InventoriesVirtualizedSelectForm
            id="descendantsOfIds"
            label="All descendants of"
            control={control}
            isMulti
            filters={{ customTypeIds: !!currentCustomTypeIds?.length ? currentCustomTypeIds : undefined }}
          />
        </>
      )}

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
