import { useHistory } from "react-router-dom";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { SessionContext } from "../../common/contexts/SessionContext";
import { useContext, useMemo, useState } from "react";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { getAddRoute, getCloneLink, getDetailLink, getEditLink, getIndexRoute } from "../../main/Routing";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { Button } from "../../common/buttons/Button/Button";
import styles from "../../common/tables/GenericVirtualizedTable/commons.module.css";
import { CustomType, CustomTypeFilters, CustomTypeFiltersTranslator, customTypeConstants } from "../../api/CustomTypes";
import { CustomTypeFilterForm, CustomTypesFilterBar } from "../../Customization/CustomTypes/Table/CustomTypesFilterBar";
import { hierarchyConstants, inventoryItemsConstants } from "../../api/Inventories";
import { LinkButton } from "../../common/buttons/LinkButton/LinkButton";
import { NotSet } from "../../common/misc/UIconstants";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";

export const switchCustomTypesDefaultSortState = (
  sortState: CustomTypeFilters["orderBy"]
): SortState<CustomTypeFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "LAYOUT_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-isHierarchyRoot",
        orderBy: sortState,
      };
    case "LAYOUT_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-isHierarchyRoot",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-inventoryName",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-inventoryName",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-inventoryName",
        orderBy: sortState,
      };
  }
};
const defaultFilter: CustomTypeFilters = { orderBy: "NAME_ASC" };

export const useInventoriesTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"inventories">) => {
  const defaults: ColumnsSettings<CustomType> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-inventoryName": {
        pos: 1,
        active: true,
        header: fieldLabels.inventoryName,
        property: "inventoryName",
      },
      "default-inventoryDescription": {
        pos: 2,
        active: true,
        header: fieldLabels.inventoryDescription,
        property: "inventoryDescription",
      },
      "default-isHierarchyRoot": {
        pos: 5,
        active: true,
        header: "Layout",
        property: "isHierarchyRoot",
      },
      "default-name": { pos: 3, active: true, header: hierarchyConstants.childType, property: "name" },
      "default-description": {
        pos: 4,
        active: true,
        header: hierarchyConstants.childTypeDescription,
        property: "description",
      },
      "default-createdOn": { pos: 6, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 7, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": { pos: 8, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      "default-modifiedBy": { pos: 9, active: true, header: fieldLabels.modifiedBy, property: "modifiedBy" },
    }),
    [
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.id,
      fieldLabels.inventoryDescription,
      fieldLabels.inventoryName,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
    ]
  );

  const tabStoreDefaults: TableTabsDict<CustomType, CustomTypeFilters, CustomTypeFilterForm> = useMemo(() => {
    let def: TableTabsDict<CustomType, CustomTypeFilters, CustomTypeFilterForm> = {
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { entityTypes: ["Inventory"], isInventory: true },
          sidebarFilters: { entityType: ["Inventory"] },
        },
      },
    };
    return def;
  }, []);

  return { defaults, tabStoreDefaults };
};

export const useInventoriesTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"inventories">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<CustomType> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.inventoryName,
        id: "default-inventoryName",
        accessor: (row) =>
          row.inventoryName ? (
            <div className={`${styles.container_ellipsis} gap-5`}>
              <div className={`${styles.container_ellipsis}`}>
                <span className={styles.label_highlight}>
                  <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.inventoryName}
                </span>
              </div>
              {!row.isEnabled && (
                <div>
                  <label className="label label-soft-danger" title={"Disabled"}>
                    Disabled
                  </label>
                </div>
              )}
            </div>
          ) : (
            NotSet
          ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-inventoryName") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "INVENTORY_NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "INVENTORY_NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-inventoryName", sortDirection: "ASC", orderBy: "INVENTORY_NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.inventoryDescription,
        id: "default-inventoryDescription",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "normal",
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.inventoryDescription}
          </div>
        ),
        minWidth: 150,
        width: 300,
        align: "left",
      },
      {
        Header: hierarchyConstants.childType,
        id: "default-name",
        accessor: (row) => (
          <div className={`${styles.container_ellipsis} gap-5`}>
            <div className={`${styles.container_ellipsis}`}>
              <span className={styles.label_highlight}>
                <LucideIcon name={customTypeConstants.icon} color={"var(--primary)"} /> {row.name}
              </span>
            </div>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: hierarchyConstants.childTypeDescription,
        id: "default-description",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "normal",
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.description}
          </div>
        ),
        minWidth: 150,
        width: 300,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: "Layout",
        id: "default-isHierarchyRoot",
        accessor: (row) => (
          <div className="flex align-center gap-5">
            <LucideIcon
              name={row.isHierarchyRoot ? hierarchyConstants.hierarchyLayoutIcon : hierarchyConstants.flatLayoutIcon}
              color="var(--primary)"
            />
            {row.isHierarchyRoot ? "hierarchical" : "list"}
          </div>
        ),
        minWidth: 150,
        width: 150,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-isHierarchyRoot") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "LAYOUT_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "LAYOUT_ASC" }));
            }
          } else {
            setSort({ headerId: "default-isHierarchyRoot", sortDirection: "ASC", orderBy: "LAYOUT_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedBy") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.id,
    fieldLabels.inventoryDescription,
    fieldLabels.inventoryName,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};

export const InventoriesTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"inventories">) => {
  const history = useHistory();
  const { route, session } = useContext(SessionContext);
  const [showModal, setShowModal] = useState(false);
  const {
    bulkRestoreMutationAsync: bulkRestoreCustomType,
    bulkDeleteMutationAsync: deleteCustomType,
    isLoadingBulkRestoreMutation,
    isLoadingBulkDeleteMutation,
  } = entityApi;

  const { defaults, tabStoreDefaults } = useInventoriesTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<CustomType>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<CustomType, CustomTypeFilters, CustomTypeFilterForm>({
    resource: customTypeConstants.resource,
    defaults: tabStoreDefaults,
    keyOverride: "tableTabs-inventories",
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    currentTab,
    tabsLoading,
    filters,
    switchSortState: switchCustomTypesDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useInventoriesTableColumns({ entityConstants, fieldLabels, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: false }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <LinkButton
            className="btn btn-default"
            linkTo={route(getIndexRoute(inventoryItemsConstants.frontendIndexRoute))}
            title={`Show all ${entityConstants.entitySingular} in table`}
          >
            <LucideIcon name="table-2" /> Show all {inventoryItemsConstants.entityPlural}
          </LinkButton>
          <LinkButton
            className="btn btn-primary"
            linkTo={`${route(getAddRoute(customTypeConstants.frontendIndexRoute))}?entityType=Inventory`}
            title="Create custom types"
            disabled={!session?.permissions.administer_custom_field_schemas}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </LinkButton>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar defaultSize={0}>
          <CustomTypesFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
            hideEntityTypeFilter
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            temporaryTabs={temporaryTabs}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<CustomType, CustomTypeFilters>
                filters={filters}
                excludeFilters={{
                  entityTypes: () => false,
                  isInventory: () => false,
                  excludeDisabled: (value) => !value,
                }}
                translatorConsts={CustomTypeFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <button
                className="btn btn-default"
                title={`${
                  selection.size === 0
                    ? `Select ${entityConstants.entitySingular} to edit`
                    : selection.size === 1
                    ? `Edit the selected ${entityConstants.entitySingular}`
                    : `Edit the selected ${entityConstants.entityPlural}`
                }`}
                disabled={selection.size !== 1}
                onClick={() =>
                  history.push(route(getEditLink(customTypeConstants.frontendIndexRoute, Array.from(selection)[0])))
                }
              >
                <LucideIcon name="square-pen" /> Edit
              </button>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <button
                    className="btn btn-sm btn-ghost-secondary"
                    title={`${
                      selection.size === 0
                        ? `Select ${entityConstants.entitySingular} to clone`
                        : selection.size === 1
                        ? `Clone the selected ${entityConstants.entitySingular}`
                        : `Clone the selected ${entityConstants.entityPlural}`
                    }`}
                    disabled={selection.size !== 1 || !session?.permissions.administer_custom_field_schemas}
                    onClick={() =>
                      history.push(
                        route(getCloneLink(customTypeConstants.frontendIndexRoute, Array.from(selection)[0]))
                      )
                    }
                  >
                    <LucideIcon name="copy" /> Clone
                  </button>
                </li>
                {currentTab === "trash" && (
                  <li>
                    <Button
                      className="btn btn-sm btn-ghost-success"
                      title={`${
                        !selectionPermissions?.delete
                          ? `Insufficient permissions to delete selected ${entityConstants.entityPlural}`
                          : selection.size === 0
                          ? `Select ${entityConstants.entitySingular} to restore`
                          : selection.size === 1
                          ? `Restore the selected ${entityConstants.entitySingular}`
                          : `Restore the selected ${entityConstants.entityPlural}`
                      }`}
                      disabled={selection.size === 0 || !session?.permissions.administer_custom_field_schemas}
                      onClick={async () => {
                        await bulkRestoreCustomType({ ids: Array.from(selection) }).catch(() => {});
                      }}
                      loading={isLoadingBulkRestoreMutation}
                    >
                      <LucideIcon name="refresh-ccw" /> Restore from Trash
                    </Button>
                  </li>
                )}
                <li>
                  <button
                    className={`btn btn-sm btn-ghost-${currentTab === "trash" ? "danger" : "warning"}`}
                    title={`${
                      !(currentTab === "trash" ? selectionPermissions?.delete : selectionPermissions?.edit)
                        ? `Insufficient permissions to ${currentTab === "trash" ? "trash" : "delete"} selected ${
                            entityConstants.entityPlural
                          }`
                        : selection.size === 0
                        ? `Select ${entityConstants.entitySingular} to ${currentTab === "trash" ? "trash" : "delete"}`
                        : selection.size === 1
                        ? `${currentTab === "trash" ? "Trash" : "Delete"} the selected ${
                            entityConstants.entitySingular
                          }`
                        : `${currentTab === "trash" ? "Trash" : "Delete"} the selected ${entityConstants.entityPlural}`
                    }`}
                    disabled={selection.size === 0 || !session?.permissions.administer_custom_field_schemas}
                    onClick={() => setShowModal(true)}
                  >
                    <LucideIcon name={`${currentTab === "trash" ? "trash-2" : "trash"}`} />{" "}
                    {`${currentTab === "trash" ? "Delete" : "Move to Trash"}`}
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type={`${currentTab === "trash" ? "danger" : "warning"}`}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select dataset to ${currentTab === "trash" ? "delete" : "trash"}`
                    : selection.size === 1
                    ? `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entitySingular}?`
                    : `${currentTab === "trash" ? "Delete" : "Trash"} the selected ${entityConstants.entityPlural}?`
                }`}
                description={`${
                  currentTab === "trash"
                    ? `Proceeding will permanently delete the selected ${entityConstants.entityPlural}.`
                    : `Proceeding will move the selected ${entityConstants.entityPlural} into trash.`
                }`}
                proceedLabel={`${currentTab === "trash" ? "Delete" : "Trash"}`}
                onProceed={async () => {
                  await deleteCustomType({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: customTypeConstants.resource,
                    params: { deletePermanently: currentTab === "trash" },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<CustomType, CustomTypeFilters>
              functionRef={functionRef}
              entityConstants={customTypeConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
              onRowClick={(row) =>
                !!row.isHierarchyRoot
                  ? history.push(`${route(getDetailLink(entityConstants.frontendIndexRoute, row.id))}/hierarchy`)
                  : history.push(`${route(getDetailLink(entityConstants.frontendIndexRoute, row.id))}/items`)
              }
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
