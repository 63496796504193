import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../common/contexts/SessionContext";
import { DateTimeRenderer } from "../common/datetime/DateTimeFormatter";
import { SearchInput } from "../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../common/icon/LucideIcon";
import { ColumnsSettings } from "../common/tables/ColumnsSelector/ColumnsSelector";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { AuditLog, AuditLogFilters } from "../api/AuditLog";
import { ResourceName } from "../main/Routing";
import { Modal, ModalBody } from "react-bootstrap";
import { LoadingWrapper } from "../common/LoadingWrapper";
import { EntityTable } from "../common/tables/EntityTable/EntityTable";
import { entityTypeToInfo, eventIdToInfo } from "./AuditLog";
import { useHistory } from "react-router-dom";
import { EntityFilterIndicator } from "../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { AuditLogFilterBar, AuditLogFilterForm } from "./AuditLogFilterBar";
import { NotAvailable, NotSet } from "../common/misc/UIconstants";
import { TableTabsDict } from "../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../common/tables/Tabs/useTabStore";
import TableView, { TableViewLabel } from "../common/panels/TableView/TableView";
import { EntityTableTabs } from "../common/tables/Tabs/EntityTableTabs";
import { GetPersons } from "../common/misc/EntityRenders/EntityRenderer";
import { toUppercase } from "../common/helperfunctions/stringFunctions";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../common/entity/EntityInterfaces";

interface ModalTarget {
  resource: ResourceName;
  row: AuditLog;
  component?: JSX.Element;
}

const switchDefaultAuditLogSortState = (
  sortState: AuditLogFilters["orderBy"]
): SortState<AuditLogFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "TIMESTAMP_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-timestamp",
        orderBy: sortState,
      };
    case "TIMESTAMP_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-timestamp",
        orderBy: sortState,
      };
    case "EVENT_ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-event-id",
        orderBy: sortState,
      };
    case "EVENT_ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-event-id",
        orderBy: sortState,
      };
    case "USER_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-person",
        orderBy: sortState,
      };
    case "USER_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-person",
        orderBy: sortState,
      };
    case "API_KEY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-api-key",
        orderBy: sortState,
      };
    case "API_KEY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-api-key",
        orderBy: sortState,
      };
    case "ENTITY_TYPE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-entity-type",
        orderBy: sortState,
      };
    case "ENTITY_TYPE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-entity-type",
        orderBy: sortState,
      };
    case "ENTITY_INT_ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-entity",
        orderBy: sortState,
      };
    case "ENTITY_INT_ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-entity",
        orderBy: sortState,
      };
    case "ENTITY_STRING_ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-entity-string-id",
        orderBy: sortState,
      };
    case "ENTITY_STRING_ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-entity-string-id",
        orderBy: sortState,
      };
    case "ENTITY_VERSION_CREATED_ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-entity-version-id",
        orderBy: sortState,
      };
    case "ENTITY_VERSION_CREATED_ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-entity-version-id",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "DESC",
        headerId: "default-timestamp",
        orderBy: sortState,
      };
  }
};

const defaultFilter: AuditLogFilters = { orderBy: "TIMESTAMP_DESC" };

export const useAuditLogsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"auditLogs">) => {
  const defaults: ColumnsSettings<AuditLog> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-timestamp": { pos: 1, active: true, header: fieldLabels.timestamp, property: "timestamp" },
      "default-event-id": { pos: 2, active: true, header: fieldLabels.eventId, property: "eventId" },
      "default-person": { pos: 3, active: true, header: fieldLabels.user, property: "user" },
      "default-entity-type": { pos: 4, active: true, header: fieldLabels.entityType, property: "entityType" },
      "default-target": { pos: 5, active: true, header: fieldLabels.entityName, property: "entityName" },
      "default-entity-version-id": {
        pos: 6,
        active: true,
        header: fieldLabels.entityCreatedVersion,
        property: "entityCreatedVersion",
      },
      "default-api-key": { pos: 7, active: true, header: fieldLabels.apiKeyId, property: "apiKeyId" },
    }),
    [fieldLabels]
  );
  const tabStoreDefaults: TableTabsDict<AuditLog, AuditLogFilters, AuditLogFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );

  return { defaults, tabStoreDefaults };
};

export const useAuditLogsTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
  setModalTarget,
}: UseEntityTableProps<"auditLogs"> & {
  setModalTarget: React.Dispatch<React.SetStateAction<ModalTarget | undefined>>;
}) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<AuditLog> = [
      {
        Header: fieldLabels.id,
        id: "default-id",
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_DESC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.timestamp,
        id: "default-timestamp",
        accessor: (row) => (
          <div>
            <DateTimeRenderer date={row.timestamp} includeElapsed />
          </div>
        ),
        align: "left",
        width: 250,
        minWidth: 250,
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "TIMESTAMP_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "TIMESTAMP_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "TIMESTAMP_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.eventId,
        id: "default-event-id",
        accessor: (row) => {
          const rowInfo = eventIdToInfo(row.eventId);
          return (
            <div
              className={"label label-soft-" + rowInfo.color}
              style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}
            >
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                  {rowInfo?.icon && <LucideIcon name={rowInfo.icon} />}
                  <span> {rowInfo.label}</span>
                </span>
              </div>
            </div>
          );
        },
        align: "left",
        width: 180,
        minWidth: 180,
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "EVENT_ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "EVENT_ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "EVENT_ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.user,
        id: "default-person",
        accessor: (row) => <div>{row.user ? <GetPersons persons={[row.user]} createLinks={false} /> : NotSet}</div>,
        align: "left",
        width: 200,
        minWidth: 200,
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "USER_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "USER_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "USER_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.apiKeyId,
        id: "default-api-key",
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                {row.apiKeyId || NotSet}
              </span>
            </div>
          </div>
        ),
        align: "left",
        width: 200,
        minWidth: 200,
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "API_KEY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "API_KEY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "API_KEY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.entityType,
        id: "default-entity-type",
        accessor: (row) => {
          if (!row.entityType) return NotSet;
          const rowInfo = entityTypeToInfo(row.entityType);
          return rowInfo ? (
            <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
              <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                  {rowInfo.icon && <LucideIcon name={rowInfo.icon} />}
                  <span> {toUppercase(rowInfo.label)}</span>
                </span>
              </div>
            </div>
          ) : (
            NotAvailable
          );
        },
        align: "left",
        width: 200,
        minWidth: 200,
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ENTITY_TYPE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ENTITY_TYPE_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ENTITY_TYPE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.entityName,
        id: "default-target",
        accessor: (row) => {
          if (!(row.entityIntId || row.entityStringId)) return NotSet;
          const rowInfo = entityTypeToInfo(row.entityType);
          if (!rowInfo) return NotSet;
          if (row.entityIntId) {
            return (
              <div
                style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}
                onClick={() => setModalTarget({ resource: rowInfo.resource, row })}
              >
                <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                    <span>{row.entityName || NotSet}</span>
                  </span>
                </div>
              </div>
            );
          }
          if (row.entityStringId) {
            return (
              <div
                style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}
                onClick={() => setModalTarget({ resource: rowInfo.resource, row })}
              >
                <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                    <span>{row.entityName || row.entityStringId || NotSet}</span>
                  </span>
                </div>
              </div>
            );
          }
        },
        align: "left",
        width: 200,
        minWidth: 200,
      },
      {
        Header: fieldLabels.entityCreatedVersion,
        id: "default-entity-version-id",
        accessor: (row) => (
          <div style={{ display: "flex", flexFlow: "row nowrap", gap: "5px", overflow: "hidden" }}>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <span style={{ fontWeight: 500, whiteSpace: "nowrap", marginRight: "5px" }}>
                {!!row.entityCreatedVersion || row.entityCreatedVersion === 0 ? row.entityCreatedVersion + 1 : NotSet}
              </span>
            </div>
          </div>
        ),
        align: "left",
        width: 200,
        minWidth: 200,
      },
    ];

    return headers;
  }, [fieldLabels, setModalTarget, setSort, sort.headerId, sort.sortDirection]);
  return { columns };
};
export const AuditLogTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"auditLogs">) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const [modalTarget, setModalTarget] = useState<ModalTarget>();

  const { defaults, tabStoreDefaults } = useAuditLogsTableDefaults({ fieldLabels });

  const { selection, resultsCount, onCountChange, onSelectionChange } = useGenericVirtualizedTable<AuditLog>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<AuditLog, AuditLogFilters, AuditLogFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    currentTab,
    tabsLoading,
    filters,
    switchSortState: switchDefaultAuditLogSortState,
    dispatchTabStore,
  });

  const { columns } = useAuditLogsTableColumns({ entityConstants, fieldLabels, sort, setSort, setModalTarget });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  return (
    <>
      <TableView>
        <TableView.Head>
          <TableView.Head.Label>
            <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
          </TableView.Head.Label>
        </TableView.Head>
        <TableView.Body>
          <TableView.Body.Sidebar>
            <AuditLogFilterBar
              initialValues={sidebarFilters}
              dispatchTabStore={dispatchTabStore}
              tabsLoading={tabsLoading}
              currentTab={currentTab}
            />
          </TableView.Body.Sidebar>
          <TableView.Body.Content>
            <EntityTableTabs
              currentTab={currentTab}
              onTabChange={onTabChange}
              temporaryTabs={temporaryTabs}
              fixedTabs={fixedTabs}
              filters={filters}
              sidebarFilters={sidebarFilters}
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              customTabs={customTabs}
              dispatchTabStore={dispatchTabStore}
              tabsLoading={tabsLoading}
              tabsModified={tabsModified}
            />
            <EntityTable>
              <EntityTable.Controls
                style={{
                  borderTop: "0px",
                  borderRadius: "0px",
                }}
              >
                <EntityFilterIndicator<AuditLog, AuditLogFilters> filters={filters} excludeFilters={{}} />
                <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
                {selection.size > 0 && (
                  <div style={{ display: "flex", width: "fit-content", height: "100%", alignItems: "center" }}>
                    <span
                      style={{ color: "var(--gray-400)", fontWeight: "normal", padding: "0 5px", whiteSpace: "nowrap" }}
                    >
                      {selection.size} {selection.size === 1 ? "notebook" : "notebooks"} selected
                    </span>
                  </div>
                )}
              </EntityTable.Controls>
              <EntityTable.Body<AuditLog, AuditLogFilters>
                functionRef={functionRef}
                entityConstants={entityConstants}
                filters={memoizedFilters}
                columns={columns}
                columnSelect
                columnSetting={columnSetting}
                columnWidths={columnWidths}
                defaultColumnSettings={defaults}
                dispatchTabStore={dispatchTabStore}
                setResultsCount={onCountChange}
                onSelectionChange={onSelectionChange}
                loading={tabsLoading}
                onRowClick={(row) => {
                  if (row.entityIntId) {
                    const info = entityTypeToInfo<number>(row.entityType);
                    info && history.push(route(info.route(row.entityIntId)));
                  } else if (row.entityStringId) {
                    const info = entityTypeToInfo<string>(row.entityType);
                    info && history.push(route(info.route(row.entityStringId)));
                  } else if (row.entityType === "ApiKey" && row.apiKeyId) {
                    const info = entityTypeToInfo<number>(row.entityType);
                    info && history.push(route(info.route(row.apiKeyId)));
                  }
                }}
                disableCheckboxes
              />
            </EntityTable>
          </TableView.Body.Content>
        </TableView.Body>
      </TableView>

      <Modal bsSize="lg" show={modalTarget?.row !== undefined} onHide={() => setModalTarget(undefined)} target="body">
        <ModalBody>
          <LoadingWrapper
            status={!modalTarget?.component ? "loading" : "success"}
            fetchStatus={!modalTarget?.component ? "fetching" : "idle"}
          >
            {modalTarget?.component}
          </LoadingWrapper>
        </ModalBody>
      </Modal>
    </>
  );
};
