import { useContext, useMemo, useState } from "react";
import { SessionContext } from "../../common/contexts/SessionContext";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView from "../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { InstrumentFilterBar, InstrumentFilterForm } from "./InstrumentFilterBar";
import { NotSet } from "../../common/misc/UIconstants";
import { InstrumentFacility, InstrumentFacilityFilters } from "../../api/Facilities";
import { GetOrganizations, GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { AddCustomTypeEntity } from "../../Customization/CustomTypes/generics/AddCustomTypeEntity";
import { useCustomTypesEntityTable } from "../../Customization/CustomTypes/generics/useCustomTypesEntityTable";
import { ToggleIndicator } from "../../common/badges/ToggleIndicator/ToggleIndicator";
import { methodsConstants } from "../../api/Methods";
import {
  UseEntityTableDefaultProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
  UseITypedEntityTableProps,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { renderCustomTypeColumn } from "../../Customization/CustomTypes/generics/CustomTypeRenderer";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableDeleteButton } from "../../common/entity/entityComponents/EntityTableDeleteButton";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";

export const switchInstrumentsDefaultSortState = (
  sortState: InstrumentFacilityFilters["orderBy"]
): SortState<InstrumentFacilityFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "METHOD_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-method",
        orderBy: sortState,
      };
    case "METHOD_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-method",
        orderBy: sortState,
      };
    case "IS_OBSOLETE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-isObsolete",
        orderBy: sortState,
      };
    case "IS_OBSOLETE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-isObsolete",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    // case "CREATED_BY_ASC":
    //   return {
    //     sortDirection: "ASC",
    //     headerId: "default-createdBy",
    //     orderBy: sortState,
    //   };
    // case "CREATED_BY_DESC":
    //   return {
    //     sortDirection: "DESC",
    //     headerId: "default-createdBy",
    //     orderBy: sortState,
    //   };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    // case "MODIFIED_BY_ASC":
    //   return {
    //     sortDirection: "ASC",
    //     headerId: "default-modifiedBy",
    //     orderBy: sortState,
    //   };
    // case "MODIFIED_BY_DESC":
    //   return {
    //     sortDirection: "DESC",
    //     headerId: "default-modifiedBy",
    //     orderBy: sortState,
    //   };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};

const defaultFilter: InstrumentFacilityFilters = { orderBy: "NAME_ASC" };

export const useInstrumentsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"instruments">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<InstrumentFacility> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      ...(session?.features.enable_facility_custom_types && {
        "default-type": { pos: 2, active: true, header: fieldLabels.customType, property: "customType" },
      }),
      "default-method": { pos: 3, active: true, header: fieldLabels.method, property: "method" },
      "default-isObsolete": { pos: 4, active: true, header: fieldLabels.isObsolete, property: "isObsolete" },
      "default-serialnumber": {
        pos: 5,
        active: true,
        header: fieldLabels.serialnumber,
        property: "serialnumber",
      },
      "default-room": { pos: 6, active: true, header: fieldLabels.room, property: "room" },
      "default-model": { pos: 7, active: true, header: fieldLabels.model, property: "model" },
      "default-localPhone": { pos: 8, active: true, header: fieldLabels.localPhone, property: "localPhone" },
      "default-homeLab": { pos: 9, active: true, header: fieldLabels.homeLab, property: "homeLab" },
      "default-homeLabContacts": {
        pos: 10,
        active: true,
        header: fieldLabels.homeLabContacts,
        property: "homeLabContacts",
      },
      "default-company": { pos: 11, active: true, header: fieldLabels.company, property: "company" },
      "default-companyContacts": {
        pos: 12,
        active: true,
        header: fieldLabels.companyContacts,
        property: "companyContacts",
      },
      "default-createdOn": { pos: 13, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      // "default-createdBy": { pos: 13, active: true, header: instrumentFieldLabels.createdBy , property: ""},
      "default-modifiedOn": { pos: 14, active: true, header: fieldLabels.modifiedOn, property: "modifiedOn" },
      // "default-modifiedBy": { pos: 15, active: true, header: instrumentFieldLabels.modifiedBy , property: ""},
    }),
    [
      fieldLabels.company,
      fieldLabels.companyContacts,
      fieldLabels.createdOn,
      fieldLabels.customType,
      fieldLabels.homeLab,
      fieldLabels.homeLabContacts,
      fieldLabels.id,
      fieldLabels.isObsolete,
      fieldLabels.localPhone,
      fieldLabels.method,
      fieldLabels.model,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.room,
      fieldLabels.serialnumber,
      session?.features.enable_facility_custom_types,
    ]
  );

  const tabStoreDefaults: TableTabsDict<InstrumentFacility, InstrumentFacilityFilters, InstrumentFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );
  return { defaults, tabStoreDefaults };
};

export const useInstrumentsTableColumns = ({
  entityConstants,
  fieldLabels,
  defaults,
  filters,
  sort,
  setSort,
}: UseITypedEntityTableProps<"instruments">) => {
  const { session } = useContext(SessionContext);
  // Custom Fields
  const { columnSettings, customFieldHeaders } = useCustomTypesEntityTable({
    defaultColumnSettings: defaults,
    entityType: "Instrument",
    featureFlag: session?.features.enable_facility_custom_types,
    customTypeIds: filters?.customTypeIds ?? undefined,
  });
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<InstrumentFacility> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: () => {
          if (sort.headerId === "default-id") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: "default-id", sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            <LucideIcon name={entityConstants.icon} color="var(--primary)" />
            <span> {row.name}</span>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-name") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: "default-name", sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.method,
        id: "default-method",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            <LucideIcon name={methodsConstants.icon} color="var(--primary)" />
            <span> {row.method.name}</span>
          </div>
        ),
        minWidth: 150,
        width: 400,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-method") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "METHOD_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "METHOD_ASC" }));
            }
          } else {
            setSort({ headerId: "default-method", sortDirection: "ASC", orderBy: "METHOD_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.isObsolete,
        id: "default-isObsolete",
        accessor: (row) => <ToggleIndicator enabled={!row.isObsolete} labelSet="yes/no" />,
        width: 165,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-isObsolete") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "IS_OBSOLETE_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "IS_OBSOLETE_ASC" }));
            }
          } else {
            setSort({ headerId: "default-isObsolete", sortDirection: "ASC", orderBy: "IS_OBSOLETE_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.room,
        id: "default-room",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.room || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.model,
        id: "default-model",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.model || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        Header: fieldLabels.serialnumber,
        id: "default-serialnumber",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            {row.serialnumber || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
      },
      {
        id: "default-homeLab",
        Header: fieldLabels.homeLab,
        accessor: (row) =>
          !!row.homeLab ? <GetOrganizations organizations={[row.homeLab]} createLinks={false} /> : NotSet,
        width: 300,
        align: "left",
      },
      {
        id: "default-homeLabContacts",
        Header: fieldLabels.homeLabContacts,
        accessor: (row) =>
          !!row.homeLabContacts?.length ? <GetPersons persons={row.homeLabContacts} createLinks={false} /> : NotSet,
        width: 300,
        align: "left",
      },
      {
        id: "default-company",
        Header: fieldLabels.company,
        accessor: (row) =>
          !!row.company ? <GetOrganizations organizations={[row.company]} createLinks={false} /> : NotSet,
        width: 300,
        align: "left",
      },
      {
        id: "default-companyContacts",
        Header: fieldLabels.companyContacts,
        accessor: (row) =>
          !!row.companyContacts?.length ? <GetPersons persons={row.companyContacts} createLinks={false} /> : NotSet,
        width: 300,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-createdOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-createdOn", sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      // {
      //   id: "default-createdBy",
      //   Header: fieldLabels.createdBy,
      //   accessor: (row) =>
      //     <GetPersons persons={[row.createdBy]} createLinks={false} />,
      //   width: 200,
      //   align: "left",
      //   sortingFn: () => {
      //     if (sort.headerId === "default-createdBy") {
      //       if (sort.sortDirection === "ASC") {
      //         setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
      //
      //       } else {
      //         setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
      //
      //       }
      //     } else {
      //       setSort({ headerId: "default-createdBy", sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
      //
      //     }
      //   },
      //   sortDirection: (id) => sort.headerId === id ? sort.sortDirection : undefined,
      // },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: () => {
          if (sort.headerId === "default-modifiedOn") {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: "default-modifiedOn", sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      // {
      //   id: "default-modifiedBy",
      //   Header: fieldLabels.modifiedBy,
      //   accessor: (row) =>
      //     <GetPersons persons={row.modifiedBy} createLinks={false} />,
      //   width: 200,
      //   align: "left",
      //   sortingFn: () => {
      //     if (sort.headerId === "default-modifiedBy") {
      //       if (sort.sortDirection === "ASC") {
      //         setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
      //
      //       } else {
      //         setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
      //
      //       }
      //     } else {
      //       setSort({ headerId: "default-modifiedBy", sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
      //
      //     }
      //   },
      //   sortDirection: (id) => sort.headerId === id ? sort.sortDirection : undefined,
      // },
    ];
    if (session?.features.enable_facility_custom_types) {
      // Add custom field headers
      headers.push(
        renderCustomTypeColumn<InstrumentFacility, InstrumentFacilityFilters["orderBy"], InstrumentFacilityFilters>(
          entityConstants,
          sort,
          setSort
        )
      );
      headers = headers.concat(customFieldHeaders);
    }

    return headers;
  }, [
    customFieldHeaders,
    entityConstants,
    fieldLabels.company,
    fieldLabels.companyContacts,
    fieldLabels.createdOn,
    fieldLabels.homeLab,
    fieldLabels.homeLabContacts,
    fieldLabels.id,
    fieldLabels.isObsolete,
    fieldLabels.method,
    fieldLabels.model,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.room,
    fieldLabels.serialnumber,
    session?.features.enable_facility_custom_types,
    setSort,
    sort,
  ]);

  return { columns, columnSettings };
};
export const InstrumentsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"instruments">) => {
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync: deleteInstrument } = entityApi;
  const { session } = useContext(SessionContext);

  const { defaults, tabStoreDefaults } = useInstrumentsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<InstrumentFacility>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<InstrumentFacility, InstrumentFacilityFilters, InstrumentFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, onTabChange, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    currentTab,
    tabsLoading,
    filters,
    switchSortState: switchInstrumentsDefaultSortState,
    dispatchTabStore,
  });

  const { columns, columnSettings } = useInstrumentsTableColumns({
    entityConstants,
    fieldLabels,
    defaults,
    filters,
    sort,
    setSort,
  });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: true }),
    [filters, forcedFilters]
  );

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <div>Instruments {typeof resultsCount === "number" && <span className="badge">{resultsCount}</span>}</div>
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <AddCustomTypeEntity
            entityConstants={entityConstants}
            canCreateEntityPermission={permissions.canCreate}
            entityType={"Instrument"}
            featureFlag={session?.features.enable_facility_custom_types}
          />
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <InstrumentFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTableTabs
            currentTab={currentTab}
            onTabChange={onTabChange}
            temporaryTabs={temporaryTabs}
            fixedTabs={fixedTabs}
            filters={filters}
            sidebarFilters={sidebarFilters}
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            customTabs={customTabs}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            tabsModified={tabsModified}
          />
          <EntityTable>
            <EntityTable.Controls
              style={{
                borderTop: "0px",
                borderRadius: "0px",
              }}
            >
              <EntityFilterIndicator<InstrumentFacility, InstrumentFacilityFilters>
                filters={filters}
                excludeFilters={{}}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                <li>
                  <EntityTableDeleteButton
                    entityConstants={entityConstants}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select instrument to delete`
                    : selection.size === 1
                    ? `Delete the selected instrument?`
                    : `Delete the selected instruments?`
                }`}
                description="Proceeding will permanently delete the selected instruments."
                proceedLabel="Delete"
                onProceed={async () => {
                  await deleteInstrument(
                    {
                      ids: Array.from(selection),
                      goBackOnSuccess: false,
                      showToast: false,
                      entityName: "instruments",
                      params: { deletePermanently: true },
                    },
                    {
                      onSettled: () => {
                        // functionRef.current?.resetSelection();
                      },
                    }
                  ).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
              />
            </EntityTable.Controls>
            <EntityTable.Body<InstrumentFacility, InstrumentFacilityFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={columnSettings}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
