import { useHistory } from "react-router-dom";
import { EntityConstants, StringIndexedDict } from "../../../../api/GenericTypes";
import { SessionContext } from "../../../contexts/SessionContext";
import { useContext } from "react";
import { getIndexRoute } from "../../../../main/Routing";
import { LucideIcon } from "../../../icon/LucideIcon";

// History location utils

export interface EntityTabStoreHistory<Filters extends StringIndexedDict, SidebarForm extends StringIndexedDict> {
  [stringId: string]: {
    filters?: Filters;
    sidebarFilters?: SidebarForm;
  };
}

export interface TabStoreHistoryState<Filters extends StringIndexedDict, SidebarForm extends StringIndexedDict> {
  tabStore?: EntityTabStoreHistory<Filters, SidebarForm>;
}

export const generateTabStoreHistoryState = <Filters extends StringIndexedDict, SidebarForm extends StringIndexedDict>(
  key: string,
  filters: Filters,
  sidebarFilters: SidebarForm
): EntityTabStoreHistory<Filters, SidebarForm> => {
  return {
    [key]: {
      filters: filters,
      sidebarFilters: sidebarFilters,
    },
  };
};

// Sidebar tiles render utils
interface RenderTabStoreHistoryButtonProps<Filters extends StringIndexedDict, SidebarForm extends StringIndexedDict> {
  entityConstants: EntityConstants;
  filters: Filters;
  filtersToForm: (values: Filters) => SidebarForm;
}
export const RenderTabStoreHistoryButton = <Filters extends StringIndexedDict, SidebarForm extends StringIndexedDict>({
  entityConstants,
  filters,
  filtersToForm,
}: RenderTabStoreHistoryButtonProps<Filters, SidebarForm>) => {
  const { push } = useHistory();
  const { route } = useContext(SessionContext);
  const key = `tableTabs-${entityConstants.resource}`;
  return (
    <button
      className="btn btn-xs btn-default"
      onClick={() => {
        push(route(getIndexRoute(entityConstants.frontendIndexRoute)), {
          tabStore: generateTabStoreHistoryState(key, filters, filtersToForm(filters)),
        });
      }}
      title={`Show filtered ${entityConstants.entityPlural} in new tab`}
    >
      <LucideIcon name="table" />
    </button>
  );
};
