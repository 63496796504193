import { useContext, useMemo } from "react";
import { EntityByEntityTypeId, GenericEntityConstantsEntities } from "../../api/GenericConstants";
import { SessionContext } from "../contexts/SessionContext";
import { IEntityPermissions } from "../../api/GenericTypes";

export interface IGenericEntityPermissions<EntityTypeId extends GenericEntityConstantsEntities = any> {
  canCreate: boolean;
  canRead: (entity: EntityByEntityTypeId<EntityTypeId>) => boolean;
  canReadIndex: boolean;
  canEdit: (entity: EntityByEntityTypeId<EntityTypeId>) => boolean;
  canDelete: (entity: EntityByEntityTypeId<EntityTypeId>) => boolean;
  canRestore: (entity: EntityByEntityTypeId<EntityTypeId>) => boolean;
  canTrash: (entity: EntityByEntityTypeId<EntityTypeId>) => boolean;
  canBulkEdit: (bulkPermissions: IEntityPermissions | undefined) => boolean;
  canBulkDelete: (bulkPermissions: IEntityPermissions | undefined) => boolean;
  canBulkRestore: (bulkPermissions: IEntityPermissions | undefined) => boolean;
}

const defaultPermissions: IGenericEntityPermissions = {
  canCreate: false,
  canRead: (entity: any) => false,
  canReadIndex: false,
  canEdit: (entity: any) => false,
  canDelete: (entity: any) => false,
  canRestore: (entity: any) => false,
  canTrash: (entity: any) => false,
  canBulkEdit: (bulkPermissions: any) => false,
  canBulkDelete: (bulkPermissions: any) => false,
  canBulkRestore: (bulkPermissions: any) => false,
} as const;

/**
 * Custom hook to retrieve permissions for various entities based on the current session.
 * @author @CorradoSurmanowicz
 * @returns {Object} An object containing permissions for different entities.
 *
 * @example
 * const { permissions } = usePermissions();
 *
 * // Check if the user can create announcements
 * const canCreateAnnouncements = permissions.announcements.canCreate;
 *
 * // Check if the user can read a specific attachment
 * const canReadAttachment = permissions.attachments.canRead(someAttachment);
 *
 * @typedef {Object} IGenericEntityPermissions
 * @property {boolean} canCreate - Indicates if the user can create the entity.
 * @property {function} canRead - Function to check if the user can read a specific entity.
 * @property {boolean} canReadIndex - Indicates if the user can read the index of the entity.
 * @property {function} canEdit - Function to check if the user can edit a specific entity.
 * @property {function} canBulkEdit - Function to check if the user can bulk edit entities.
 * @property {function} canDelete - Function to check if the user can delete a specific entity.
 * @property {function} canBulkDelete - Function to check if the user can bulk delete entities.
 * @property {function} canRestore - Function to check if the user can restore a specific entity.
 * @property {function} canBulkRestore - Function to check if the user can bulk restore entities.
 * @property {function} canTrash - Function to check if the user can trash a specific entity.
 */

export const usePermissions = () => {
  const { session } = useContext(SessionContext);
  const permissions = useMemo(
    () => ({
      announcements: {
        canCreate: !!session?.permissions?.administer_announcements,
        canRead: (entity) => !!session?.permissions?.view_announcements || !!session?.permissions?.view_admin_pages,
        canReadIndex: !!session?.permissions?.view_announcements || !!session?.permissions?.view_admin_pages,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_announcements,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_announcements,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_announcements,
        canBulkDelete: (bulkPermissions) =>
          !!bulkPermissions?.delete || !!session?.permissions?.administer_announcements,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_announcements,
        canBulkRestore: (bulkPermissions) =>
          !!bulkPermissions?.edit || !!session?.permissions?.administer_announcements,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_announcements,
      } as IGenericEntityPermissions<"announcements">,
      apiKeys: {
        canCreate: true,
        canRead: (entity) => true,
        canReadIndex: true,
        canEdit: (entity) => true,
        canBulkEdit: (bulkPermissions) => true,
        canDelete: (entity) => true,
        canBulkDelete: (bulkPermissions) => true,
        canRestore: (entity) => true,
        canBulkRestore: (bulkPermissions) => true,
        canTrash: (entity) => true,
      } as IGenericEntityPermissions<"apiKeys">,
      attachments: {
        canCreate: !!session?.permissions?.add_datasets,
        canRead: (entity) => true,
        canReadIndex:
          !!session?.permissions?.logsadmin ||
          !!session?.permissions?.view_admin_pages ||
          !!session?.permissions?.view_all_attachments,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_datasets,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.edit_all_datasets,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_datasets,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_datasets,
      } as IGenericEntityPermissions<"attachments">,
      auditLogs: {
        canCreate: false,
        canRead: (entity) =>
          !!session?.permissions?.logsadmin ||
          !!session?.permissions?.view_admin_pages ||
          !!session?.permissions?.view_global_audit_log,
        canReadIndex:
          !!session?.permissions?.logsadmin ||
          !!session?.permissions?.view_admin_pages ||
          !!session?.permissions?.view_global_audit_log,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => false,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => false,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => false,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"auditLogs">,
      bridges: {
        canCreate: !!session?.permissions?.administer_autoload,
        canRead: (entity) => !!session?.permissions?.administer_autoload || !!session?.permissions?.view_admin_pages,
        canReadIndex: !!session?.permissions?.administer_autoload || !!session?.permissions?.view_admin_pages,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_autoload,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_autoload,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_autoload,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.administer_autoload,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_autoload,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_autoload,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_autoload,
      } as IGenericEntityPermissions<"bridges">,
      customFields: {
        canCreate: !!session?.permissions?.administer_custom_field_schemas,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_custom_field_schemas,
        canBulkDelete: (bulkPermissions) =>
          !!bulkPermissions?.delete || !!session?.permissions?.administer_custom_field_schemas,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
        canBulkRestore: (bulkPermissions) =>
          !!bulkPermissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
      } as IGenericEntityPermissions<"customFields">,
      customImports: {
        canCreate: false,
        canRead: (entity) =>
          !!session?.permissions?.apply_custom_imports || !!session?.permissions?.administer_autoload,
        canReadIndex: !!session?.permissions?.apply_custom_imports || !!session?.permissions?.administer_autoload,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => false,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => false,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => false,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"customImports">,
      customTypes: {
        canCreate: !!session?.permissions?.administer_custom_field_schemas,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_custom_field_schemas,
        canBulkDelete: (bulkPermissions) =>
          !!bulkPermissions?.delete || !!session?.permissions?.administer_custom_field_schemas,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
        canBulkRestore: (bulkPermissions) =>
          !!bulkPermissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
      } as IGenericEntityPermissions<"customTypes">,
      datasets: {
        canCreate: true, // We can't really do anything else here do to Project based permissions
        canRead: (entity) => true,
        canReadIndex: true,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_datasets,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.edit_all_datasets,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.delete_datasets,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.edit_all_datasets,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_datasets,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.edit_all_datasets,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_datasets,
      } as IGenericEntityPermissions<"datasets">,
      dataSources: {
        canCreate: !!session?.permissions?.administer_autoload,
        canRead: (entity) => !!session?.permissions?.administer_autoload || !!session?.permissions?.view_admin_pages,
        canReadIndex: !!session?.permissions?.administer_autoload || !!session?.permissions?.view_admin_pages,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_autoload,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_autoload,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_autoload,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.administer_autoload,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_autoload,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_autoload,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_autoload,
      } as IGenericEntityPermissions<"dataSources">,
      dataSourceStatus: {
        canCreate: false,
        canRead: (entity) => false,
        canReadIndex: false,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => false,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => false,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => false,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"dataSourceStatus">,
      equipments: {
        canCreate: !!session?.permissions?.administer_facilities,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_facilities,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_facilities,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_facilities,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.administer_facilities,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_facilities,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_facilities,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_facilities,
      } as IGenericEntityPermissions<"equipments">,
      equipmentTags: {
        canCreate: !!session?.permissions?.administer_equipment_tags,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_equipment_tags,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_equipment_tags,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_equipment_tags,
        canBulkDelete: (bulkPermissions) =>
          !!bulkPermissions?.delete || !!session?.permissions?.administer_equipment_tags,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_equipment_tags,
        canBulkRestore: (bulkPermissions) =>
          !!bulkPermissions?.edit || !!session?.permissions?.administer_equipment_tags,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_equipment_tags,
      } as IGenericEntityPermissions<"equipmentTags">,
      experiments: {
        canCreate: !!session?.permissions?.administer_experiments,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_experiments,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_experiments,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_experiments,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_experiments,
      } as IGenericEntityPermissions<"experiments">,
      iconNMRHistoryEntry: {
        canCreate: false,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => false,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => false,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => false,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"iconNMRHistoryEntry">,
      iconNMRHolders: {
        canCreate: !!session?.permissions?.administer_autoload,
        canRead: (entity) => true,
        canReadIndex: true,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => false,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => false,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => false,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"iconNMRHolders">,
      instruments: {
        canCreate: !!session?.permissions?.administer_facilities,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_facilities,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_facilities,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_facilities,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.administer_facilities,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_facilities,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_facilities,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_facilities,
      } as IGenericEntityPermissions<"instruments">,
      inventories: {
        canCreate: !!session?.permissions?.administer_custom_field_schemas,
        canRead: (entity) => true,
        canReadIndex: true,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_custom_field_schemas,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_custom_field_schemas,
      } as IGenericEntityPermissions<"inventories">,
      inventoryItems: {
        canCreate: !!session?.permissions?.add_inventory_items || !!session?.permissions?.logsadmin,
        canRead: (entity) => true,
        canReadIndex: true,
        canEdit: (entity) => !!entity?.permissions?.edit,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit,
      } as IGenericEntityPermissions<"inventoryItems">,
      methods: {
        canCreate: !!session?.permissions?.administer_measurement_methods,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_measurement_methods,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_measurement_methods,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_measurement_methods,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_measurement_methods,
      } as IGenericEntityPermissions<"methods">,
      notebooks: {
        canCreate: !!session?.permissions?.can_create_eln_content || !!session?.permissions?.logsadmin,
        canRead: (entity) => !!session?.features?.eln,
        canReadIndex: !!session?.features?.eln,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.can_create_eln_content,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
      } as IGenericEntityPermissions<"notebooks">,
      notebookExperiments: {
        canCreate: !!session?.permissions?.can_create_eln_content || !!session?.permissions?.logsadmin,
        canRead: (entity) => !!session?.features?.eln,
        canReadIndex: !!session?.features?.eln,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.can_create_eln_content,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
      } as IGenericEntityPermissions<"notebookExperiments">,
      notebookEntries: {
        canCreate: !!session?.permissions?.can_create_eln_content || !!session?.permissions?.logsadmin,
        canRead: (entity) => !!session?.features?.eln,
        canReadIndex: !!session?.features?.eln,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.can_create_eln_content,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
      } as IGenericEntityPermissions<"notebookEntries">,
      notebookTemplates: {
        canCreate: !!session?.permissions?.can_create_eln_content || !!session?.permissions?.logsadmin,
        canRead: (entity) => !!session?.features?.eln,
        canReadIndex: !!session?.features?.eln,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.can_create_eln_content,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.can_create_eln_content,
      } as IGenericEntityPermissions<"notebookTemplates">,
      organizations: {
        canCreate: !!session?.permissions?.administer_organizations,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_organizations,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_organizations,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_organizations,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_organizations,
      } as IGenericEntityPermissions<"organizations">,
      organizationTags: {
        canCreate: !!session?.permissions?.administer_organizations,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_organizations,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_organizations,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_organizations,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_organizations,
      } as IGenericEntityPermissions<"organizationTags">,
      parsers: {
        canCreate: false,
        canRead: (entity) => true,
        canReadIndex: true,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => false,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => false,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => false,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"parsers">,
      parserMethods: {
        canCreate: false,
        canRead: (entity) => true,
        canReadIndex: true,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => false,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => false,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => false,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"parserMethods">,
      parserInstruments: {
        canCreate: false,
        canRead: (entity) => true,
        canReadIndex: true,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => false,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => false,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => false,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"parserInstruments">,
      persons: {
        canCreate: !!session?.permissions?.administer_persons,
        canRead: (entity) => session?.permissions?.view_all_persons || session?.userId === entity.id,
        canReadIndex: !!session?.permissions?.view_all_persons,
        canEdit: (entity) =>
          !!entity?.permissions?.edit ||
          !!session?.permissions?.administer_persons ||
          !!(session?.userId === entity?.id && session?.permissions?.edit_own_user), // users can edit themselves
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_persons,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_persons,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_persons,
      } as IGenericEntityPermissions<"persons">,
      personTags: {
        canCreate: !!session?.permissions?.administer_persons,
        canRead: (entity) => !!session?.permissions?.view_all_persons,
        canReadIndex: !!session?.permissions?.view_all_persons,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_persons,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_persons,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_persons,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.administer_persons,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_persons,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_persons,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_persons,
      } as IGenericEntityPermissions<"personTags">,
      projects: {
        canCreate: !!session?.permissions?.administer_projects || !!session?.permissions?.add_projects,
        canRead: (entity) => true, // users can get permissions via the project
        canReadIndex: true,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_projects,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_projects,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.administer_projects,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.administer_projects,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_projects,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.administer_projects,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.administer_projects,
      } as IGenericEntityPermissions<"projects">,
      projectTags: {
        canCreate: !!session?.permissions?.administer_projects || !!session?.permissions?.add_projects,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) =>
          !!entity?.permissions?.edit ||
          !!session?.permissions?.administer_projects ||
          !!session?.permissions?.add_projects,
        canBulkEdit: (bulkPermissions) =>
          !!bulkPermissions?.edit ||
          !!session?.permissions?.administer_projects ||
          !!session?.permissions?.add_projects,
        canDelete: (entity) =>
          !!entity?.permissions?.delete ||
          !!session?.permissions?.administer_projects ||
          !!session?.permissions?.add_projects,
        canBulkDelete: (bulkPermissions) =>
          !!bulkPermissions?.delete ||
          !!session?.permissions?.administer_projects ||
          !!session?.permissions?.add_projects,
        canRestore: (entity) =>
          !!entity?.permissions?.edit ||
          !!session?.permissions?.administer_projects ||
          !!session?.permissions?.add_projects,
        canBulkRestore: (bulkPermissions) =>
          !!bulkPermissions?.edit ||
          !!session?.permissions?.administer_projects ||
          !!session?.permissions?.add_projects,
        canTrash: (entity) =>
          !!entity?.permissions?.edit ||
          !!session?.permissions?.administer_projects ||
          !!session?.permissions?.add_projects,
      } as IGenericEntityPermissions<"projectTags">,
      roles: {
        canCreate: !!session?.permissions?.logsadmin,
        canRead: (entity) => !!session?.permissions?.view_basic_content,
        canReadIndex: !!session?.permissions?.view_basic_content,
        canEdit: (entity) => !!session?.permissions?.logsadmin,
        canBulkEdit: (bulkPermissions) => !!session?.permissions?.logsadmin,
        canDelete: (entity) => !!session?.permissions?.logsadmin,
        canBulkDelete: (bulkPermissions) => !!session?.permissions?.logsadmin,
        canRestore: (entity) => !!session?.permissions?.logsadmin,
        canBulkRestore: (bulkPermissions) => !!session?.permissions?.logsadmin,
        canTrash: (entity) => !!session?.permissions?.logsadmin,
      } as IGenericEntityPermissions<"roles">,
      samples: {
        canCreate: true, // samples check for project based permissions
        canRead: (entity) => true, // samples check for project based permissions
        canReadIndex: true, // samples check for project based permissions
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_samples,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.edit_all_samples,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.delete_samples,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.delete_samples,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_samples,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.edit_all_samples,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_samples,
      } as IGenericEntityPermissions<"samples">,
      sharedContents: {
        canCreate: !!session?.permissions?.add_bundles,
        canRead: (entity) => true, // Shared content checks for owner based permissions
        canReadIndex: true,
        canEdit: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_bundles,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.edit_all_bundles,
        canDelete: (entity) => !!entity?.permissions?.delete || !!session?.permissions?.delete_bundles,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete || !!session?.permissions?.delete_bundles,
        canRestore: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_bundles,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit || !!session?.permissions?.edit_all_bundles,
        canTrash: (entity) => !!entity?.permissions?.edit || !!session?.permissions?.edit_all_bundles,
      } as IGenericEntityPermissions<"sharedContents">,
      vendors: {
        canCreate: false,
        canRead: (entity) => false,
        canReadIndex: false,
        canEdit: (entity) => false,
        canBulkEdit: (bulkPermissions) => !!bulkPermissions?.edit,
        canDelete: (entity) => false,
        canBulkDelete: (bulkPermissions) => !!bulkPermissions?.delete,
        canRestore: (entity) => false,
        canBulkRestore: (bulkPermissions) => !!bulkPermissions?.edit,
        canTrash: (entity) => false,
      } as IGenericEntityPermissions<"vendors">,
    }),
    [session]
  );
  return { permissions };
};

interface UseEntityPermissionsProps {
  entityTypeId: GenericEntityConstantsEntities;
}
/**
 * Hook to retrieve permissions for a specific entity type.
 * @author @CorradoSurmanowicz
 * @template EntityTypeId - The type of the entity.
 * @param {UseEntityPermissionsProps} props - The properties for the hook.
 * @param {GenericEntityConstantsEntities} props.entityTypeId - The ID of the entity type.
 * @returns {IGenericEntityPermissions<EntityTypeId>} The permissions for the specified entity type.
 *
 * @example
 * ```typescript
 * const permissions = useEntityPermissions({ entityTypeId: "announcements" });
 * if (permissions.canCreate) {
 *   // User can create announcements
 * }
 * ```
 */

export const useEntityPermissions = <EntityTypeId extends GenericEntityConstantsEntities>({
  entityTypeId,
}: UseEntityPermissionsProps): IGenericEntityPermissions<EntityTypeId> => {
  const { session } = useContext(SessionContext);
  const { permissions } = usePermissions();
  if (!session) return defaultPermissions;
  if (!Object.hasOwn(permissions, entityTypeId)) return defaultPermissions;
  return permissions[entityTypeId] as IGenericEntityPermissions<EntityTypeId>;
};
