import { Organization, OrganizationFilters, OrganizationFieldLabels } from "../../api/Organizations";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { PersonsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { OrganizationsTagsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/OrganizationTagsVirtualizedSelectForm";

export interface OrganizationFilterForm {
  organizationTagIds?: IEntityMinimalModel[] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}

const defaultValues: DefinedPropertyObject<OrganizationFilterForm> = {
  organizationTagIds: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};
export const organizationFormToFilters = (form: OrganizationFilterForm): OrganizationFilters => {
  let filters: OrganizationFilters = {};
  filters.organizationTagIds =
    Array.isArray(form.organizationTagIds) && form.organizationTagIds.length > 0
      ? form.organizationTagIds.map((t) => t.id)
      : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;

  return filters;
};
/**
 * Converts OrganizationFilters to OrganizationFilterForm
 * @author @CorradoSurmanowicz
 */
export const filtersToOrganizationForm = (filters: OrganizationFilters): OrganizationFilterForm => {
  let form: OrganizationFilterForm = {};
  form.organizationTagIds = filters.organizationTagIds
    ? filters.organizationTagIds.map((id) => ({ id, name: "" }))
    : null;
  form.createdByIds = filters.createdByIds ? filters.createdByIds.map((id) => ({ id, name: "" })) : null;
  form.createdFrom = filters.createdFrom ? new Date(filters.createdFrom) : null;
  form.createdTo = filters.createdTo ? new Date(filters.createdTo) : null;
  form.modifiedByIds = filters.modifiedByIds ? filters.modifiedByIds.map((id) => ({ id, name: "" })) : null;
  form.modifiedFrom = filters.modifiedFrom ? new Date(filters.modifiedFrom) : null;
  form.modifiedTo = filters.modifiedTo ? new Date(filters.modifiedTo) : null;

  return form;
};
const defaultFilters: OrganizationFilters = { orderBy: "NAME_ASC" };

export const OrganizationFilterBar = ({
  initialValues,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: TabStoreFilterSidebarProps<Organization, OrganizationFilterForm, OrganizationFilters>) => {
  const { control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: organizationFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <OrganizationsTagsVirtualizedSelectForm
        id="organizationTagIds"
        label={OrganizationFieldLabels.organizationTags}
        control={control}
        isMulti
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
