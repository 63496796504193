import { Link, useHistory } from "react-router-dom";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";

import { EntityConstants } from "../../../api/GenericTypes";
import { SessionContext } from "../../../common/contexts/SessionContext";
import { useInfiniteListEntity } from "../../../api/BaseEntityApi";
import { CustomType, CustomTypeEntityType, CustomTypeFilters, customTypeConstants } from "../../../api/CustomTypes";
import { getAddRoute } from "../../../main/Routing";
import { IconNames, LucideIcon } from "../../../common/icon/LucideIcon";
import { CustomTypeTile } from "../../../common/sidebar/tiles/CustomTypeTile";
import { IsFilteredDefinition } from "../../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { Button } from "../../../common/buttons/Button/Button";

interface AddCustomTypeEntityProps {
  entityConstants: EntityConstants;
  canCreateEntityPermission?: boolean;
  btnCls?: string;
  btnStr?: string;
  iconOverride?: IconNames;
  entityType: CustomTypeEntityType;
  onSelectCustomType?: (customType: CustomType) => void;
  defaultFilters?: CustomTypeFilters;
  excludeFilters?: IsFilteredDefinition<CustomTypeFilters>;
  disableBasicCreation?: boolean;
  autoSelectSingleResult?: boolean;
  featureFlag?: boolean;
}

/**
 * Renders a component for adding a custom type entity.
 * @author @CorradoSurmanowicz
 * @component
 * @param {AddCustomTypeEntityProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */

export const AddCustomTypeEntity = ({
  entityConstants,
  canCreateEntityPermission,
  btnCls,
  btnStr,
  iconOverride = "plus",
  entityType,
  onSelectCustomType,
  defaultFilters,
  excludeFilters,
  disableBasicCreation = false,
  autoSelectSingleResult = false,
  featureFlag = true,
}: AddCustomTypeEntityProps) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const [isOpen, setOpen] = useState(false);

  const activeFilters: CustomTypeFilters = useMemo(
    () => ({
      ...defaultFilters,
      entityTypes: [entityType],
      isEnabled: true,
      isSoftDeleted: false,
      orderBy: "NAME_ASC",
    }),
    [defaultFilters, entityType]
  );
  // only fetch max 2 items to check if there are any or more than 1 items for this query
  const { data: singleResultData, fetchStatus } = useInfiniteListEntity<CustomType>(
    customTypeConstants.resource,
    { page: 1, pageSize: 2, ...activeFilters },
    { enabled: true && featureFlag }
  );
  const items = useMemo(() => singleResultData?.pages.map((d) => d.results).flat() || [], [singleResultData]);

  // auto select single result if enabled
  useEffect(() => {
    if (autoSelectSingleResult && isOpen) {
      if (items?.length === 1) {
        var e = items[0];
        onSelectCustomType
          ? onSelectCustomType(e)
          : history.push(route(`${getAddRoute(entityConstants.frontendIndexRoute)}?type=${e.id}`));
      }
    }
  }, [
    singleResultData,
    onSelectCustomType,
    history,
    route,
    entityConstants.frontendIndexRoute,
    autoSelectSingleResult,
    isOpen,
    items,
  ]);

  const addButton = useCallback(
    (onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => (
      <Button
        className={`${btnCls ? btnCls : "btn btn-primary"}`}
        title={`Add ${entityConstants.entitySingular}`}
        onClick={onClick}
        disabled={!canCreateEntityPermission}
        loading={autoSelectSingleResult && fetchStatus === "fetching"}
      >
        <LucideIcon name={iconOverride} /> {btnStr || `Add ${entityConstants.entitySingular}`}
      </Button>
    ),
    [
      autoSelectSingleResult,
      btnCls,
      btnStr,
      canCreateEntityPermission,
      entityConstants.entitySingular,
      fetchStatus,
      iconOverride,
    ]
  );
  if (!featureFlag)
    return addButton((e) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(route(getAddRoute(entityConstants.frontendIndexRoute)));
    });
  if (!items?.length) return <Link to={route(getAddRoute(entityConstants.frontendIndexRoute))}>{addButton()}</Link>;
  return (
    <>
      {addButton(() => setOpen(true))}
      <Modal show={isOpen} onHide={() => setOpen(false)} bsSize="large">
        <Modal.Body>
          <>
            <h3>Choose a {entityConstants.entitySingular} type</h3>
            <div className="flex col-nowrap align-center gap-5">
              <CustomTypeTile
                title={`${entityType} types`}
                onRowClick={(e) =>
                  onSelectCustomType
                    ? onSelectCustomType(e)
                    : !!e.id
                    ? history.push(route(`${getAddRoute(entityConstants.frontendIndexRoute)}?type=${e.id}`))
                    : history.push(route(getAddRoute(entityConstants.frontendIndexRoute)))
                }
                linkTo={(e) =>
                  !!e.id
                    ? route(`${getAddRoute(entityConstants.frontendIndexRoute)}?type=${e.id}`)
                    : route(getAddRoute(entityConstants.frontendIndexRoute))
                }
                isFolded={false}
                foldable={false}
                defaultFilters={activeFilters}
                excludeFilters={{
                  entityTypes: () => false,
                  isEnabled: () => false,
                  ...excludeFilters,
                }}
                prependItems={
                  !onSelectCustomType && !disableBasicCreation
                    ? [{ id: 0, name: `Basic ${entityConstants.entitySingular}`, entityType: entityType } as CustomType]
                    : undefined
                }
                headless
              />

              {/* {!disableBasicCreation && (
                <>
                  <span style={{ color: "var(--gray-400)" }}>or</span>
                  <button
                    className="btn btn-block btn-primary"
                    title={`Create a basic ${entityConstants.entitySingular}`}
                    onClick={() => history.push(route(getAddRoute(entityConstants.frontendIndexRoute)))}
                  >
                    Create a basic {entityConstants.entitySingular}
                  </button>
                </>
              )} */}
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
