import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import styles from "./EntityFilterIndicator.module.css";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  GenericEntity,
  IRelationParameters,
  IPaginationParameters,
  IGenericRequestParameters,
  StringIndexedDict,
} from "../../../api/GenericTypes";
import { LucideIcon } from "../../icon/LucideIcon";
import { PropertyTranslator } from "../../../api/GenericTranslator";

export type IsFilteredDefinition<Filters extends StringIndexedDict> = {
  [property in keyof Filters]: (value: Filters[property]) => boolean;
};

type CommonFilterInterfaces<Entity extends GenericEntity> = IRelationParameters &
  IPaginationParameters &
  IGenericRequestParameters<Entity>;

export interface EntityFilterIndicatorProps<Filters extends StringIndexedDict> {
  filters: Filters;
  translatorConsts?: PropertyTranslator<Filters>;
  excludeFilters?: Partial<IsFilteredDefinition<Filters>>;
  overlayPlaycement?: "left" | "right";
  showAlways?: boolean;
  style?: CSSProperties;
}
export const EntityFilterIndicator = <Entity extends GenericEntity, Filters extends StringIndexedDict>({
  filters,
  translatorConsts,
  excludeFilters,
  overlayPlaycement = "right",
  showAlways = false,
  style,
}: EntityFilterIndicatorProps<Filters>) => {
  const memoizedFilters = useMemo(() => filters, [filters]);
  const defaultExclusions: IsFilteredDefinition<CommonFilterInterfaces<Entity>> = useMemo(
    () => ({
      page: (value) => false,
      pageSize: (value) => false,
      includeRelationCount: (value) => false,
      includeRelationLink: (value) => false,
      includeRelations: (value) => false,
      orderBy: (value) => false,
      includeCount: (value) => false,
    }),
    []
  );
  const [state, setState] = useState<FilterState>();
  const allExclusions = useMemo(
    () => ({ ...defaultExclusions, ...excludeFilters } as IsFilteredDefinition<Filters>),
    [defaultExclusions, excludeFilters]
  );
  const getActiveFilters = useCallback(
    (filters: Filters) => {
      let currentFilters = 0;
      let activeFilters: Partial<Filters> = {};
      for (const [property, value] of Object.entries(filters)) {
        if (allExclusions.hasOwnProperty(property)) {
          const isFiltering = allExclusions[property](value);
          if (isFiltering) {
            currentFilters += 1;
            // console.log("Active filter per rule:", property, value);
            activeFilters[property as keyof Filters] = value as any;
          }
        } else {
          // console.log("Property - value", property, value);
          if (typeof value === "string") {
            if (value) {
              currentFilters += 1;
              activeFilters[property as keyof Filters] = value as any;
              // console.log("Active string filter:", property, value);
            }
          } else if (Array.isArray(value) && !!value.length) {
            currentFilters += 1;
            activeFilters[property as keyof Filters] = value as any;
            // console.log("Active array filter:", property, value);
          } else {
            if (value !== null) {
              currentFilters += 1;
              activeFilters[property as keyof Filters] = value;
              // console.log("Active filter:", property, value);
            }
          }
        }
      }
      return { currentFilters, activeFilters };
    },
    [allExclusions]
  );

  interface FilterState {
    currentFilters: number;
    activeFilters: Partial<Filters>;
  }

  useEffect(() => {
    const { currentFilters, activeFilters } = getActiveFilters(memoizedFilters);
    setState({ currentFilters: currentFilters, activeFilters: activeFilters });
  }, [memoizedFilters, getActiveFilters]);

  const overlay =
    translatorConsts && state && !!Object.keys(state.activeFilters).length ? (
      <OverlayTrigger
        placement={overlayPlaycement}
        overlay={
          <Popover id="popover-activeFilters">
            Active filters:
            <ul>
              {state &&
                Object.entries(state.activeFilters).map(([key, value], i) => (
                  <li key={i}>
                    {translatorConsts[key]} : <RenderFilterValue value={value} />
                  </li>
                ))}
            </ul>
            {/* {state?.activeFilters ? JSON.stringify(state.activeFilters, null, "\t") : "none"} */}
          </Popover>
        }
      >
        <LucideIcon name="filter" />
      </OverlayTrigger>
    ) : (
      <LucideIcon name="filter" />
    );

  return (
    <>
      {(showAlways || (state && state.currentFilters > 0)) && (
        <div
          className={styles.filterIndicatorContainer}
          // title={`${currentFilters} active filters`}
          style={style}
        >
          <div title={`${state?.currentFilters || 0} active filters`}>{overlay}</div>
          {state && state.currentFilters > 0 && (
            <div className={`${styles.filterIndicatorNumber} label label-primary`}>{state.currentFilters}</div>
          )}
        </div>
      )}
    </>
  );
};

const RenderFilterValue = ({
  value,
}: {
  value: string | string[] | number | number[] | boolean | undefined | null | object;
}) => {
  if (value === null || value === undefined) {
    return <>None</>;
  }
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return (
        <>
          {value.map((v, i) => (
            <React.Fragment key={i}>
              <RenderFilterValue value={v} />
              {`${i < value.length - 1 ? ", " : ""}`}
            </React.Fragment>
          ))}
        </>
      );
    } else {
      return <>{JSON.stringify(value)}</>;
    }
  }

  return <>{JSON.stringify(value)}</>;
};
