import { Bridge, BridgeFilters, BridgeType, BridgeTypeFormValues, BridgeFieldLabels } from "../../../api/Bridges";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { SelectFormField } from "../../../common/formfields/SelectFormField";
import { DatasetsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/DatasetsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";

export interface BridgeFilterForm {
  types?: { id: BridgeType; name: string }[] | undefined | null;
  datasetIds?: IEntityMinimalModel[] | undefined | null;
}

const defaultValues: DefinedPropertyObject<BridgeFilterForm> = {
  types: null,
  datasetIds: null,
};

export const bridgesFormToFilters = (form: BridgeFilterForm): BridgeFilters => {
  let filters: BridgeFilters = {};
  filters.types = Array.isArray(form.types) && form.types.length > 0 ? form.types?.map((d) => d.id) : null;
  filters.datasetIds =
    Array.isArray(form.datasetIds) && form.datasetIds.length > 0 ? form.datasetIds?.map((d) => d.id) : null;
  return filters;
};
/**
 * Converts BridgeFilters to BridgeFilterForm
 * @param filters BridgeFilters
 * @returns BridgeFilterForm
 * @author @CorradoSurmanowicz
 */
export const bridgesFiltersToForm = (filters: BridgeFilters): BridgeFilterForm => {
  let form: BridgeFilterForm = {};
  form.types = filters.types ? filters.types.map((id) => ({ id, name: "" })) : null;
  form.datasetIds = filters.datasetIds ? filters.datasetIds.map((id) => ({ id, name: "" })) : null;
  return form;
};
const defaultFilters: BridgeFilters = { orderBy: "NAME_ASC" };

export const BridgeFilterBar = ({
  initialValues,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: TabStoreFilterSidebarProps<Bridge, BridgeFilterForm, BridgeFilters>) => {
  const { onReset, control } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: bridgesFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      <SelectFormField
        id="types"
        label={BridgeFieldLabels.type}
        control={control}
        items={BridgeTypeFormValues}
        placeholder="Select connection type..."
        horizontal={false}
        isMulti
      />

      <DatasetsVirtualizedSelectForm id="datasetIds" label={BridgeFieldLabels.datasets} control={control} isMulti />
    </EntityFilterSideBar>
  );
};
