import { SelectFormField } from "../../../common/formfields/SelectFormField";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../../api/GenericTypes";
import { ToggleFormField } from "../../../common/formfields/ToggleFormField";
import {
  LabNotebookExperiment,
  LabNotebookExperimentFilters,
  LabNotebookExperimentStatus,
  LabNotebookExperimentStatusList,
} from "../../types/LabNotebookExperiment";
import { LabNotebookMinimalModel } from "../../types/LabNotebook";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { LabNotebooksVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/LabNotebooksVirtualizedSelectFormField";
import { PersonsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/PersonsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../../common/formfields/DatePickerFormField/SplitDatePickerFormField";
import { ProjectsVirtualizedSelectForm } from "../../../common/forms/EntityForms/formsVirtualized/ProjectsVirtualizedSelectForm";

interface LabNotebookExperimentStatusObject {
  id: LabNotebookExperimentStatus;
  name: LabNotebookExperimentStatus;
}

export interface LabNotebookExperimentsFilterForm {
  labNotebookIds?: LabNotebookMinimalModel[] | undefined | null;
  status?: LabNotebookExperimentStatusObject[] | undefined | null;
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
  includeSoftDeletedLabNotebookExperiments?: boolean | undefined | null;
  isSoftDeletedLabNotebookExperiment?: boolean | undefined | null;
  labNotebookProjectIds?: IEntityMinimalModel[] | undefined | null;
}
const defaultValues: DefinedPropertyObject<LabNotebookExperimentsFilterForm> = {
  labNotebookIds: null,
  status: null,
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
  includeSoftDeletedLabNotebookExperiments: false,
  isSoftDeletedLabNotebookExperiment: false,
  labNotebookProjectIds: null,
};
export const labNotebookExperimentsFormToFilters = (
  form: LabNotebookExperimentsFilterForm
): LabNotebookExperimentFilters => {
  let filters: LabNotebookExperimentFilters = {};

  filters.labNotebookIds =
    Array.isArray(form.labNotebookIds) && form.labNotebookIds.length > 0 ? form.labNotebookIds?.map((d) => d.id) : null;
  filters.status = Array.isArray(form.status) && form.status.length > 0 ? form.status?.map((d) => d.id) : null;
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;
  filters.includeSoftDeleted = form.includeSoftDeletedLabNotebookExperiments || false;
  filters.isSoftDeleted = form.isSoftDeletedLabNotebookExperiment || false;
  filters.labNotebookProjectIds =
    Array.isArray(form.labNotebookProjectIds) && form.labNotebookProjectIds.length > 0
      ? form.labNotebookProjectIds?.map((d) => d.id)
      : null;
  return filters;
};

/**
 * Converts filters to form values
 * @param filters LabNotebookExperimentFilters
 * @returns LabNotebookExperimentsFilterForm
 * @author @CorradoSurmanowicz
 */
export const labNotebookExperimentsFiltersToForm = (
  filters: LabNotebookExperimentFilters
): LabNotebookExperimentsFilterForm => {
  return {
    labNotebookIds: filters.labNotebookIds?.map((id) => ({ id, name: "", status: "ACTIVE" })) || null,
    status: filters.status?.map((id) => ({ id, name: id })) || null,
    createdByIds: filters.createdByIds?.map((id) => ({ id, name: "" })) || null,
    createdFrom: filters.createdFrom ? new Date(filters.createdFrom) : null,
    createdTo: filters.createdTo ? new Date(filters.createdTo) : null,
    modifiedByIds: filters.modifiedByIds?.map((id) => ({ id, name: "" })) || null,
    modifiedFrom: filters.modifiedFrom ? new Date(filters.modifiedFrom) : null,
    modifiedTo: filters.modifiedTo ? new Date(filters.modifiedTo) : null,
    includeSoftDeletedLabNotebookExperiments: filters.includeSoftDeleted || false,
    isSoftDeletedLabNotebookExperiment: filters.isSoftDeleted || false,
    labNotebookProjectIds: filters.labNotebookProjectIds?.map((id) => ({ id, name: "" })) || null,
  };
};
const defaultFilters: LabNotebookExperimentFilters = { orderBy: "MODIFIED_ON_DESC" };

export const LabNotebookExperimentFilterBar = ({
  initialValues,
  onFormChange,
  dispatchTabStore,
  tabsLoading,
  currentTab,
}: TabStoreFilterSidebarProps<
  LabNotebookExperiment,
  LabNotebookExperimentsFilterForm,
  LabNotebookExperimentFilters
>) => {
  const { register, control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: labNotebookExperimentsFormToFilters,
    onFormChange,
    dispatchTabStore,
  });
  return (
    <EntityFilterSideBar onReset={onReset}>
      <LabNotebooksVirtualizedSelectForm id="labNotebookIds" label="Notebook" control={control} isMulti />

      <ProjectsVirtualizedSelectForm id="labNotebookProjectIds" control={control} showControls={false} isMulti />

      <SelectFormField
        id="status"
        label="Experiment status"
        {...register}
        items={LabNotebookExperimentStatusList.map((e) => {
          return { id: e, name: e };
        })}
        control={control}
        horizontal={false}
        isMulti={true}
      />

      {currentTab !== "mydata" && (
        <PersonsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <PersonsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      {currentTab !== "trash" && (
        <ToggleFormField
          id={"includeSoftDeletedLabNotebookExperiments"}
          control={control}
          label={"Include trashed"}
          horizontal
        />
      )}

      {currentTab !== "trash" && (
        <ToggleFormField id={"isSoftDeletedLabNotebookExperiment"} control={control} label={"Is trashed"} horizontal />
      )}
    </EntityFilterSideBar>
  );
};
