import { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./LabNotebookTemplates.module.css";
import { useELNRoutes } from "../ELNRouter/useELNRoutes";
import { ELNDeleteConfirmationModal } from "../ELNCreateOrEditForms/ELNDeleteConfirmationModal";
import { Modal } from "../common/ELNModal/ELNModal";
import { SessionContext } from "../../common/contexts/SessionContext";
import { useEditMutation, useEntityDetail, useRestoreMutation } from "../../api/BaseEntityApi";
import {
  LabNotebookTemplate,
  LabNotebookTemplateFilters,
  LabNotebookTemplateWriteModel,
  labNotebookTemplatesConstants,
} from "../types/LabNotebookTemplate";
import { ELNSaveParameters, ELNSaveStatus, TextEditor } from "../common/TextEditor/TextEditor";
import { useResizeDetector } from "react-resize-detector";
import { ELNNoContentContainer } from "../common/ELNNoContentContainer/ELNNoContentContainer";
import { ELNModes } from "../ELNRouter/ELNRouter";
import { getCloneLink, getDetailLink } from "../../main/Routing";
import { TextEditorSidebarTile } from "../common/TextEditor/components/TextEditorSidebarTile/TextEditorSidebarTile";
import { useQueryClient } from "@tanstack/react-query";
import { EntityHistory } from "../common/EntityHistory/EntityHistory";
import { formatIsoDate } from "../../common/datetime/DateTimeFormatter";
import { ELNLayoutContainer } from "../common/ELNLayoutContainer/ELNLayoutContainer";
import { QuickAddWrapper } from "../../common/forms/QuickAdd/common/QuickAddWrapper";
import { QuickAddLabNotebookTemplateForm } from "../../common/forms/QuickAdd/forms/QuickAddLabNotebookTemplate";
import { QuickEditWrapper } from "../../common/forms/QuickEdit/common/QuickEditWrapper";
import { QuickEditLabNotebookTemplateForm } from "../../common/forms/QuickEdit/forms/QuickEditLabNotebookTemplate";
import { projectsConstants } from "../../api/Projects";
import { personsConstants } from "../../api/Person";
import { Alert } from "../../common/overlays/Alert/Alert";
import Detail from "../../common/panels/Detail/Detail";
import { GenericEntity } from "../../api/GenericTypes";
import { EntityLockingWrapper } from "../../common/entity/entityComponents/EntityLockingWrapper";

export const LabNotebookTemplates = ({
  labNotebookTemplateId,
  mode,
}: {
  labNotebookTemplateId?: number;
  mode?: ELNModes;
}) => {
  const { setELNTemplateRoute, getELNTemplateRoute } = useELNRoutes();
  const { session, route } = useContext(SessionContext);

  const [showCreateLabNotebookTemplateModal, setShowCreateLabNotebookTemplateModal] = useState<boolean>(false);
  const [showEditLabNotebookTemplateModal, setShowEditLabNotebookTemplateModal] = useState<boolean>(false);
  const [showDeleteLabNotebookTemplateModal, setShowDeleteLabNotebookTemplateModal] = useState<boolean>(false);
  const [showLabNotebookTemplateDetailsHistory, setShowLabNotebookTemplateDetailsHistory] = useState<boolean>(false);

  const [saveStatus, setSaveStatus] = useState<ELNSaveStatus>({ isSaved: true, isSaving: false, isError: false });

  const remirrorContextRef: any = useRef();

  const [templateState, setTemplateState] = useState<LabNotebookTemplate | null>(null);
  const { data: template } = useEntityDetail<LabNotebookTemplate, LabNotebookTemplateFilters>(
    "lab_notebook_templates",
    labNotebookTemplateId!,
    {},
    {
      enabled: !!labNotebookTemplateId && !showDeleteLabNotebookTemplateModal,
      onSuccess: (data) => !templateState && setTemplateState(data),
    }
  );
  useEffect(() => {
    if (template && template?.publicSessionId !== templateState?.publicSessionId) {
      setTemplateState(template);
    }
  }, [template, templateState?.publicSessionId]);

  const { mutate: editLabNotebookTemplate } = useEditMutation<LabNotebookTemplateWriteModel & GenericEntity>(
    "lab_notebook_templates"
  );

  const saveTemplate = useCallback(
    ({ content, onSave, setSaveStatus, evaluateVersioning }: ELNSaveParameters) => {
      if (template && content.content) {
        setSaveStatus((prevState) => {
          return { ...prevState, isSaving: true };
        });

        if (!session || !template) return;

        editLabNotebookTemplate(
          {
            id: template.id,
            body: {
              ...template,
              content,
              concurrencyToken: template.modifiedOn,
            },
            params: { evaluateVersioning },
            entityName: "template",
          },
          {
            onSuccess: () => {
              setSaveStatus((prevState) => {
                return { ...prevState, isSaving: false, isSaved: true };
              });

              onSave && onSave();
            },
            onError: () => {
              setSaveStatus((prevState) => {
                return { isSaved: false, isSaving: false, isError: true };
              });
            },
          }
        );
      }
    },
    [template, session, editLabNotebookTemplate]
  );

  const queryClient = useQueryClient();

  // SETUP ELN HEADER CONTENT
  const saveCloseButtonRef = useRef<HTMLButtonElement>(null);

  const { ref, height } = useResizeDetector();

  const { mutate: restoreLabNotebookTemplate } = useRestoreMutation("lab_notebook_templates");

  return (
    <>
      <ELNLayoutContainer
        headerParams={{
          title: template
            ? [
                { label: "Template editor", linkTo: getELNTemplateRoute() },
                {
                  label: template.name,
                  icon: "copy",
                  linkTo: getELNTemplateRoute(template.id, "view"),
                  iconColor: "var(--primary)",
                },
              ]
            : [{ label: "Template editor", linkTo: getELNTemplateRoute() }],
          description: template
            ? mode === "edit" && template
              ? `Last saved: ${formatIsoDate(new Date(template.modifiedOn)).datestr} ${
                  formatIsoDate(new Date(template.modifiedOn)).timestr
                }`
              : template.description
            : "Create or edit templates...",
          controls: [
            {
              label: "Edit content",
              icon: "square-pen",
              color: "default",
              linkTo: getELNTemplateRoute(template?.id, "edit"),
              disabled: !template?.permissions?.edit || template.isDeleted,
              condition: !!template && mode !== "edit",
            },
            {
              label: "Edit details",
              icon: "square-pen",
              color: "default",
              onClick: () => setShowEditLabNotebookTemplateModal(true),
              disabled: !template?.permissions?.edit || template?.isDeleted,
              options: template
                ? [
                    {
                      label: "Clone",
                      icon: "copy",
                      color: "ghost-secondary",
                      linkTo: route(getCloneLink(labNotebookTemplatesConstants.frontendIndexRoute, template.id)),
                      disabled: !session?.permissions.can_create_eln_content,
                    },
                    {
                      label: "Show template history",
                      icon: "clock",
                      color: "ghost-secondary",
                      onClick: () => setShowLabNotebookTemplateDetailsHistory(true),
                      disabled: !template.permissions?.edit || template.isDeleted,
                    },
                    {
                      label: "Restore template",
                      icon: "refresh-ccw",
                      color: "ghost-success",
                      onClick: () => restoreLabNotebookTemplate({ id: template.id }, {}),
                      disabled: !template.permissions?.edit,
                      condition: template.isDeleted,
                    },
                    {
                      label: !template.isDeleted ? "Trash template" : "Delete template",
                      icon: "trash-2",
                      color: template.isDeleted ? "ghost-danger" : "ghost-warning",
                      onClick: () => setShowDeleteLabNotebookTemplateModal(true),
                      disabled: !template.permissions?.edit,
                    },
                  ]
                : undefined,
            },
          ],
          tags: template
            ? mode === "edit"
              ? [
                  {
                    label: template.createdBy.name,
                    color: "primary",
                    linkTo: route(getDetailLink(personsConstants.frontendIndexRoute, template.createdBy.id)),
                    icon: personsConstants.icon,
                  },
                  ...template.projects.map((p) => {
                    return {
                      label: p.name,
                      color: "soft-secondary" as any,
                      linkTo: route(getDetailLink(projectsConstants.frontendIndexRoute, p.id)),
                      icon: projectsConstants.icon,
                    };
                  }),
                  {
                    label: saveStatus.isSaved ? "Saved!" : saveStatus.isError ? "Failed!" : "Saving...",
                    color: saveStatus.isSaved ? "success" : saveStatus.isError ? "danger" : "secondary",
                    icon: "save",
                  },
                ]
              : [
                  {
                    label: template.createdBy.name,
                    color: "primary",
                    linkTo: route(getDetailLink(personsConstants.frontendIndexRoute, template.createdBy.id)),
                    icon: personsConstants.icon,
                  },
                  ...template.projects.map((p) => {
                    return {
                      label: p.name,
                      color: "soft-secondary" as any,
                      linkTo: route(getDetailLink(projectsConstants.frontendIndexRoute, p.id)),
                      icon: projectsConstants.icon,
                    };
                  }),
                  {
                    label: template.isDeleted ? "trashed" : "",
                    color: "soft-warning",
                    icon: template.isDeleted ? "trash-2" : undefined,
                  },
                ]
            : [],
          showDashboardButton: true,
        }}
      >
        {mode === "edit" ? (
          <Detail
            sidebarPosition="left"
            sidebar={<TextEditorSidebarTile foldable remirrorContextRef={remirrorContextRef} />}
            main={
              <div
                className={styles.LabNotebookContentContainer}
                style={{ backgroundColor: "var(--gray-100)", minWidth: "210mm", height: "100%", overflow: "hidden" }}
              >
                <div className={styles.labNotebookTemplatesMainContainer}>
                  <div ref={ref} />
                  <div
                    className={styles.labNotebookTemplatesTextEditorContainer}
                    style={{ height: `calc(100% - ${height}px)` }}
                  >
                    {template && templateState ? (
                      <>
                        <EntityLockingWrapper entityConstants={labNotebookTemplatesConstants} entityId={template.id}>
                          {!Object.hasOwn(template, "content") ? (
                            <Alert fit centered message="Content could not be loaded" type="danger" />
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                height: "max-content",
                                maxWidth: "210mm",
                                backgroundColor: "var(--white)",
                                padding: "40px",
                                border: "1px solid var(--gray-300)",
                                boxShadow: "1px 1px 1px 1px solid var(--gray-200)",
                              }}
                            >
                              <TextEditor
                                remirrorContextRef={remirrorContextRef}
                                editable={mode === "edit" && template.permissions?.edit}
                                save={saveTemplate}
                                autosave
                                initialContent={templateState.content}
                                getSaveStatus={setSaveStatus}
                                generateIds={false}
                                toolbarRef={ref}
                                saveCloseButtonRef={saveCloseButtonRef}
                                onClose={() => setELNTemplateRoute(template.id, "view")}
                              />
                            </div>
                          )}
                        </EntityLockingWrapper>
                      </>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "max-content",
                          maxWidth: "210mm",
                        }}
                      >
                        <ELNNoContentContainer
                          noContentMessage="Select an existing template in the sidebar to start editing or"
                          createButton={{
                            label: "Create new template",
                            onClick: () => setShowCreateLabNotebookTemplateModal(true),
                            color: "soft-primary",
                            icon: "copy",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
          />
        ) : (
          <div
            className={styles.LabNotebookContentContainer}
            style={{
              backgroundColor: "var(--gray-100)",
              minWidth: "210mm",
              height: "100%",
              overflow: "hidden",
              width: "100%",
            }}
          >
            <div className={styles.labNotebookTemplatesMainContainer}>
              <div ref={ref} />
              <div
                className={styles.labNotebookTemplatesTextEditorContainer}
                style={{ height: "100%", paddingBottom: "20px" }}
              >
                {template ? (
                  <div
                    style={{
                      width: "100%",
                      height: "max-content",
                      maxWidth: "210mm",
                      backgroundColor: "var(--white)",
                      padding: "40px",
                      border: "1px solid var(--gray-300)",
                      boxShadow: "1px 1px 1px 1px solid var(--gray-200)",
                    }}
                  >
                    <TextEditor
                      remirrorContextRef={remirrorContextRef}
                      editable={false}
                      save={saveTemplate}
                      autosave
                      initialContent={template.content}
                      getSaveStatus={setSaveStatus}
                      generateIds={false}
                      saveCloseButtonRef={saveCloseButtonRef}
                      onClose={() => setELNTemplateRoute(template.id, "view")}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "max-content",
                      maxWidth: "210mm",
                    }}
                  >
                    <ELNNoContentContainer
                      noContentMessage="Select an existing template in the sidebar to start editing or"
                      createButton={{
                        label: "Create new template",
                        onClick: () => setShowCreateLabNotebookTemplateModal(true),
                        color: "soft-primary",
                        icon: "copy",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </ELNLayoutContainer>

      {/* Create */}
      {showCreateLabNotebookTemplateModal && (
        <QuickAddWrapper<LabNotebookTemplate>
          showModal={showCreateLabNotebookTemplateModal}
          setShowModal={setShowCreateLabNotebookTemplateModal}
          onCreate={(newNotebookTemplate) => {
            if (newNotebookTemplate && newNotebookTemplate.id > 0) {
              queryClient.invalidateQueries(["lab_notebook_templates"]);
              setELNTemplateRoute(newNotebookTemplate.id);
            }
          }}
        >
          {(props) => <QuickAddLabNotebookTemplateForm {...props} />}
        </QuickAddWrapper>
      )}

      {/* Edit */}
      {!!template && !!showEditLabNotebookTemplateModal && (
        <QuickEditWrapper<LabNotebookTemplate>
          showModal={!!showEditLabNotebookTemplateModal}
          setShowModal={setShowEditLabNotebookTemplateModal}
          onEdit={(editedLabNotebook) => {
            queryClient.invalidateQueries(["lab_notebook_templates"]);
          }}
        >
          {(props) => <QuickEditLabNotebookTemplateForm {...props} initialValues={template} />}
        </QuickEditWrapper>
      )}

      {template && (
        <ELNDeleteConfirmationModal
          isOpen={showDeleteLabNotebookTemplateModal}
          setIsOpen={setShowDeleteLabNotebookTemplateModal}
          id={labNotebookTemplateId}
          name={template.name}
          entityInfo={{ "Template name": template.name, "Description": template.description }}
          resource="lab_notebook_templates"
          onDelete={() => {
            queryClient.invalidateQueries({ queryKey: ["lab_notebook_templates"] });
            setELNTemplateRoute(undefined, undefined, true);
          }}
          description="Proceeding will move the selected template into trash."
          deletePermanently={false}
        />
      )}
      {/* History */}
      {template && (
        <Modal
          isOpen={showLabNotebookTemplateDetailsHistory}
          onClose={() => setShowLabNotebookTemplateDetailsHistory(false)}
        >
          <div style={{ width: "90vw", height: "90vh" }}>
            <EntityHistory<LabNotebookTemplate>
              resource="lab_notebook_templates"
              id={template.id}
              onRestore={() => {
                setShowLabNotebookTemplateDetailsHistory(false);
                setELNTemplateRoute(labNotebookTemplateId, "view");
                window.location.reload();
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
