// import { resetCache } from "./../SpectrumViewer/GraphApi";
// import { ParsersApi } from "./ParsersApi.ts_deactivated";
import { ApiAuthentication, Session, Shape } from "./ApiTypes";
import { BaseEntityApi } from "./BaseEntityApi";
import { DatasetFilters } from "./Datasets";
import { DatasetsApi } from "./DatasetsApi";
import { Experiment } from "./Experiments";
import { InstrumentFacility } from "./Facilities";

import { IEntityMinimalModel, IPagination } from "./GenericTypes";
import { Parser } from "./Parsers";
import { ParserStatistics, ParsingOutputModel } from "./Parsing";
import { ProjectsApi } from "./Projects";
import { Sample } from "./Samples";
import {
  DatasetStatistics,
  DatasetStatisticsByType,
  DatasetStatisticsByMethod,
  DatasetStatisticsByInstrument,
} from "./Statistics";
import { Viewer as ViewerApi } from "./ViewerApi";
import { ServerError, throwServerError } from "../common/helperfunctions/ApiError";
import { ApiKey } from "./ApiKey";
import { User } from "oidc-client-ts";
import { SessionFullModel } from "./Login";

export class API {
  public url: string;
  private auth: ApiAuthentication;
  public group: string;

  // TODO: figure out if there is a better way to split the API into groups
  public KeyManagement = {
    listApiKeys: this.listApiKeys.bind(this),
    generateApiKey: this.generateApiKey.bind(this),
    deleteApiKey: this.deleteApiKey.bind(this),
  };

  public Samples = new BaseEntityApi<Sample>(this, "samples");

  public Parsing = {
    getParserStatistics: this.getParsingStatistics.bind(this),
    getParserErrors: this.getParserErrors.bind(this),
    // getParsingState: this.getParsingState.bind(this),
    getParsingInProgress: this.getParsingInProgress.bind(this),
    enqueueParsing: this.enqueueParsing.bind(this),
    dequeueParsing: this.dequeueParsing.bind(this),
  };

  // public Formats = new ParsersApi(this);

  public Viewer = new ViewerApi(this);

  public Datasets = new DatasetsApi(this);

  public Projects = new ProjectsApi(this, "projects");

  // public Experiments = new ExperimentsApi(this, "experiments");

  // public CustomFieldsSchemas = new BaseDictEntityApi<CustomFieldsSchema>(this, "custom_fields_schemas");

  private personsGroup = "persons";
  private organizationsGroup = "organizations";
  private announcementsGroup = "announcements";
  private api_keysGroup = "api_keys";

  private personUrlResource = "person";
  private organizationUrlResource = "organization";
  private announcementUrlResource = "announcement";

  public triggerDownloadLink(url: string, filename: string, newTab: boolean = false) {
    const anchor = document.createElement("a");
    anchor.href = url;
    if (newTab) anchor.target = "_blank";
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  public getParamString(options: Record<string, any>, removePlural: boolean = false): string {
    // return new URLSearchParams(
    //   Object.fromEntries(Object.entries(options).filter(([key, value]) => value !== null))
    // ).toString();
    return Object.entries(options)
      .filter(([k, v]) => v !== null)
      .map(([k, v]) => {
        const type = typeof v;
        if (type === "object") {
          if (Array.isArray(v)) {
            if (removePlural) {
              const id = k[k.length - 1] === "s" ? k.substring(0, k.length - 1) : k;
              return v.map((d) => `${id}=${d}`).join("&");
            }
            return v.map((d) => `${k}=${d}`).join("&");
          } else {
            return Object.entries(v)
              .map(([id, value]) => `${k}#${id}=${value}`)
              .join("&");
          }
        } else {
          return `${k}=${v}`;
        }
      })
      .join("&");
  }

  public getAuthentication() {
    return this.auth;
  }

  public getGroup() {
    return this.group;
  }

  constructor(group: string, auth: ApiAuthentication) {
    this.url = `/${group}/api/0.1/`;
    this.auth = auth;
    this.group = group;
  }

  private getMainPropertyNameByGroupName(groupName: string, body: any) {
    if (groupName === this.personsGroup || groupName === this.personUrlResource) return body.lastName;
    else if (groupName === this.organizationsGroup || groupName === this.organizationUrlResource) return body.name;
    else if (groupName === this.announcementsGroup || groupName === this.announcementUrlResource) return body.message;
    else return "";
  }

  public getUrlComponents() {
    return {
      hostname: window.location.hostname,
      // The CRA proxy does not proxy this request as it accepts html as content type
      // so we have to switch to the .NET server on port 5000 here in development mode
      port: process.env.NODE_ENV === "development" ? 5000 : window.location.port,
      // The .NET backend doesn't handle SSL by itself, so we have to make plain http
      // requests when talking directly to it on port 5000
      protocol: process.env.NODE_ENV === "development" ? "http:" : window.location.protocol,
    };
  }

  public getResourceUrl(resource: string, params?: Record<string, string>) {
    const url = new URL(this.url + resource, window.location.origin);
    let urlParams: URLSearchParams = new URLSearchParams();
    if (params !== undefined) {
      for (const key in params) {
        if (params[key] !== undefined) {
          urlParams.set(key, params[key]);
        }
      }
      url.search = urlParams.toString();
    }
    return url;
  }

  private async fetch(resource: string, requestOverrides: RequestInit, params?: Record<string, string>) {
    const request: RequestInit = {};
    request.method = requestOverrides.method ?? "GET";
    if (this.auth.type === "session") {
      request.headers = {
        "X-Session": this.auth.value,
      };
    } else if (this.auth.type === "jwt") {
      console.log("Auth value", this.auth.value);

      const jwt = JSON.parse(localStorage.getItem(this.auth.value) || "") as User;
      request.headers = {
        Authorization: `Bearer ${jwt.id_token}`,
      };
    } else if (this.auth.type === "apikey") {
      request.headers = {
        "X-Api-Key": this.auth.value,
        "X-LOGS-internal": "true",
      };
    } else if (this.auth.type === "shared") {
      request.headers = {
        "X-Shared": this.auth.value,
        "X-LOGS-internal": "true",
      };

      if (this.auth.password) request.headers["X-Shared-Password"] = this.auth.password;
    } else throw new Error("No valid authentication method provided");

    const url = this.getResourceUrl(resource, params);
    let urlParams: URLSearchParams = new URLSearchParams();
    if (params !== undefined) {
      for (const key in params) {
        if (params[key] !== undefined) {
          urlParams.set(key, params[key]);
        }
      }
      url.search = urlParams.toString();
    }

    // console.log("URL:", url.toString());
    // console.log("request:", request);

    if (requestOverrides.headers) {
      request.headers = Object.assign(request.headers, requestOverrides.headers);
    }
    request.body = requestOverrides.body;
    request.signal = requestOverrides.signal;
    // console.log("Fetch", this.url + resource, request);

    const response = await fetch(url.toString(), request);
    if (!response.ok) await throwServerError(response);
    return response;
  }

  async rawFetch(fullUrl: string, requestOverrides: RequestInit) {
    const request: RequestInit = {};
    request.method = requestOverrides.method ?? "GET";
    request.signal = requestOverrides.signal;
    if (this.auth.type === "session") {
      request.headers = {
        "X-Session": this.auth.value,
      };
    } else if (this.auth.type === "jwt") {
      console.log("Auth value", this.auth.value);
      const jwt = JSON.parse(localStorage.getItem(this.auth.value) || "") as User;
      request.headers = {
        Authorization: `Bearer ${jwt.id_token}`,
      };
    } else if (this.auth.type === "apikey") {
      request.headers = {
        "X-Api-Key": this.auth.value,
        "X-LOGS-internal": "true",
      };
    } else if (this.auth.type === "shared") {
      request.headers = {
        "X-Shared": this.auth.value,
        "X-LOGS-internal": "true",
      };
      if (this.auth.password) request.headers["X-Shared-Password"] = this.auth.password;
    } else throw new Error("No valid authentication method provided");

    if (requestOverrides.headers) {
      request.headers = Object.assign(request.headers, requestOverrides.headers);
    }
    request.body = requestOverrides.body;
    const url = new URL(fullUrl);
    url.host = window.location.host;
    url.protocol = window.location.protocol;
    const response = await fetch(url.toString(), request);
    if (response.ok) {
      return response;
    } else {
      await throwServerError(response); // This will throw so return is ineffective
      return response;
      // const serverErrorMessage = await response.text();
      // console.error("XXXX", serverErrorMessage);
      // const errorMsg = `API request for ${fullUrl} returned error code ${response.status} ${response.statusText}: ${serverErrorMessage}`;
      // throw new Error(errorMsg);
    }
  }

  async rawGet(fullUrl: string, signal?: AbortSignal) {
    const response = await this.rawFetch(fullUrl, {
      headers: {
        Accept: "application/json",
      },
      signal: signal,
    });
    return response.json();
  }

  async get(resource: string, params?: any, signal?: AbortSignal) {
    // console.log("Api get", resource);
    const response = await this.fetch(
      resource,
      {
        headers: {
          Accept: "application/json",
        },
        signal: signal,
      },
      params
    );
    const type = response.headers.get("content-type")?.split(/[ ;]+/)[0];
    // console.log("Api get type", response.headers.get("content-type"));
    let result;
    switch (type) {
      case "application/octet-stream":
        result = response.arrayBuffer();
        break;
      case "application/zip":
        result = response.arrayBuffer();
        break;
      case "application/json":
        result = response.json();
        break;
      default:
        result = response.text();
        break;
    }
    return result;
  }

  async postForm(resource: string, body: FormData, signal?: AbortSignal) {
    const response = await this.fetch(resource, {
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      //   // Accept: "application/json",
      // },
      body: body,
      signal: signal,
    });
    return await this.convertResponse(response, resource);
  }

  async convertResponse(response: Response, resource: string = "<unknown resource>") {
    // Don't try parsing No Content responses
    if (response.status === 204) return undefined;
    const type = response.headers.get("content-type")?.split(/[ ;]+/)[0];
    // console.log("Api post type", type);
    let result;
    switch (type) {
      case "text/csv":
      case "text/plain":
        result = response.text();
        // console.log(">>", response.text());
        // result = response.arrayBuffer();
        break;
      case "application/octet-stream":
        result = await response.arrayBuffer();
        break;
      case "application/zip":
        result = await response.arrayBuffer();
        break;
      case "application/json":
        try {
          result = await response.json();
        } catch {
          console.error("Error parsing POST response ", resource, type);
          // We can't just output the text version here as the stream is already consumed
          //console.error(response.text());
        }
        break;
      default:
        console.error("Cannot handle response type ", type, " for endpoint ", resource);
    }
    return result;
  }

  async post(resource: string, body: any, params?: any, signal?: AbortSignal) {
    const response = await this.fetch(
      resource,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Accept: "application/json",
        },
        body: JSON.stringify(body),
        signal: signal,
      },
      params
    );

    return await this.convertResponse(response, resource);
  }

  async postMultipart(resource: string, body: FormData, onProgress?: (progress: number) => void) {
    return await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.upload.addEventListener("progress", (e) => onProgress?.(e.loaded / e.total));
      // xhr.addEventListener("load", () => {
      //   // console.log("LOAD", xhr);
      //   // resolve({ status: xhr.status, body: xhr.responseText });
      // });
      xhr.addEventListener("error", () => reject(new Error("File upload failed")));
      xhr.addEventListener("abort", () => reject(new Error("File upload aborted")));
      xhr.addEventListener("loadend", async () => {
        let responseType = xhr.getResponseHeader("content-type");
        if (responseType) {
          if (responseType.includes("arraybuffer")) responseType = "arraybuffer";
          if (responseType.includes("blob")) responseType = "blob";
          if (responseType.includes("json")) responseType = "json";
          if (responseType.includes("text")) responseType = "text";
          if (responseType.includes("document")) responseType = "document";
        }
        // console.log("loadend", xhr);
        // console.log("RESPONSE TYPE", responseType);
        if (xhr.readyState === 4 && xhr.status === 200) {
          switch (responseType) {
            case "arraybuffer":
              resolve(new ArrayBuffer(xhr.response));
              break;
            case "blob":
              resolve(new Blob(xhr.response));
              break;
            case "json":
              resolve(await JSON.parse(xhr.responseText));
              break;
            case "text":
              resolve(xhr.responseText);
              break;
            case "document":
              resolve(xhr.responseText);
              break;
            default:
              resolve(xhr.responseText);
              break;
          }
        } else {
          switch (responseType) {
            case "arraybuffer":
              reject(new ServerError({ title: xhr.responseText }));
              break;
            case "blob":
              reject(new ServerError({ title: xhr.responseText }));
              break;
            case "json":
              reject(new ServerError({ title: xhr.responseText }));
              break;
            case "text":
              reject(new ServerError({ title: xhr.responseText }));
              break;
            case "document":
              reject(new ServerError({ title: xhr.responseText }));
              break;
            default:
              reject(new ServerError({ title: xhr.responseText }));
              break;
          }
        }
      });
      xhr.open("POST", this.getResourceUrl(resource, undefined).toString(), true);
      if (this.auth.type === "session") {
        xhr.setRequestHeader("X-Session", this.auth.value);
      } else if (this.auth.type === "apikey") {
        xhr.setRequestHeader("X-Api-Key", this.auth.value);
        xhr.setRequestHeader("X-LOGS-internal", "true");
      } else if (this.auth.type === "shared") {
        xhr.setRequestHeader("X-Shared", this.auth.value);
        xhr.setRequestHeader("X-LOGS-internal", "true");
      }
      // xhr.setRequestHeader("Content-Type", "application/octet-stream"); // Important not to set a header here!
      xhr.send(body);
    });
  }

  // async postMultipart(resource: string, body: FormData) {
  //   const response = await this.fetch(resource, {
  //     mode: "no-cors",
  //     method: "POST",
  //     // The browser will set the contentType & boundary!
  //     // headers: {
  //     //   "Content-Type": `multipart/form-data: boundary=add-random-characters`,
  //     // },
  //     body: body,
  //   });

  //   // Don't try parsing No Content responses
  //   if (response.status === 204) return;

  //   const type = response.headers.get("content-type")?.split(/[ ;]+/)[0];
  //   // console.log("Api post type", type);
  //   let result;
  //   switch (type) {
  //     case "text/csv":
  //       result = response.text();
  //       // console.log(">>", response.text());
  //       // result = response.arrayBuffer();
  //       break;
  //     case "application/octet-stream":
  //       result = await response.arrayBuffer();
  //       break;
  //     case "application/zip":
  //       result = await response.arrayBuffer();
  //       break;
  //     case "application/json":
  //       try {
  //         result = await response.json();
  //       } catch {
  //         console.error("Error parsing POST response ", resource, type);
  //         // We can't just output the text version here as the stream is already consumed
  //         //console.error(response.text());
  //       }
  //       break;
  //     default:
  //       console.error("Cannot handle response type ", type, " for endpoint ", resource);
  //   }
  //   return result;
  // }

  async delete(resource: string, params?: Record<string, string>, signal?: AbortSignal) {
    const response = await this.fetch(
      resource,
      {
        method: "DELETE",
        signal: signal,
      },
      params
    );
    return response;
  }

  // this assumes no body in response for now
  async put(resource: string, body: any, params?: any, signal?: AbortSignal) {
    const response = await this.fetch(
      resource,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Accept: "application/json",
        },
        body: JSON.stringify(body),
        signal: signal,
      },
      params
    );

    if (response.status === 204) return response;
    return await response.json();

    // let result;
    // try {
    //   console.log("typeof response: ", typeof response);
    //   console.log("response: ", response);
    //   result = await response.json();
    //   // result = await JSON.stringify(response);
    //   return await result;
    // } catch {
    //   if (!result || !response.ok) console.error("Error parsing PUT response ", resource);
    // }
  }

  // this assumes no body in response for now
  async bulkDelete(resource: string, body: any) {
    const response = await this.fetch(resource, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return response;
  }

  public getSession = async (): Promise<Session> => {
    const response: SessionFullModel = await this.get("session");
    const permissions = Object.fromEntries(response.permissions.map((p: string) => [p, true]));
    return {
      userId: response.userId,
      person: response.person,
      permissions: permissions,
      sessionId: response.sessionId,
      features: response.features,
      publicSessionId: response.publicSessionId,
    };
  };

  private async listApiKeys(): Promise<ApiKey[]> {
    var rv = await this.get(this.api_keysGroup);
    rv.forEach((x: any) => {
      x.createdOn = new Date(x.createdOn);
    });
    return rv as ApiKey[];
  }

  private async generateApiKey(name: string, readOnly?: boolean): Promise<ApiKey> {
    var rv = await this.post(this.api_keysGroup, { name: name, readOnly: readOnly });
    rv.createdOn = new Date(rv.createdOn);
    return rv;
  }

  private async deleteApiKey(id: number): Promise<void> {
    await this.delete(`${this.api_keysGroup}/${id}`);
  }

  private async getParsingStatistics(): Promise<Record<string, ParserStatistics>> {
    return await this.get("parsing/statistics");
  }

  // private async getParsingState(): Promise<ParsingState> {
  //   return await this.get("parsing/state");
  // }

  private async getParsingInProgress(): Promise<number> {
    return await this.get("parsing/in_progress");
  }

  private async getParserErrors(page: number, parserType?: string): Promise<IPagination<ParsingOutputModel>> {
    return await this.get("parsing/errors", { page: page, parserType: parserType });
  }

  private async enqueueParsing(body: {
    ids?: number[];
    parserTypes?: string[];
  }): Promise<{ [parserId: string]: number }> {
    return await this.post("parsing/enqueue", body);
  }

  private async dequeueParsing(body: {
    ids?: number[];
    parserTypes?: string[];
  }): Promise<{ [parserId: string]: number }> {
    return await this.post("parsing/dequeue", body);
  }

  public async getDatasetStatistics(): Promise<DatasetStatistics> {
    try {
      return await this.get("dataset_statistics");
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async getDatasetStatisticsByType(): Promise<DatasetStatisticsByType> {
    try {
      return await this.get("dataset_statistics/by_type");
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async getDatasetStatisticsByMethod(): Promise<DatasetStatisticsByMethod> {
    try {
      return await this.get("dataset_statistics/by_method");
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async getDatasetStatisticsByInstrument(): Promise<DatasetStatisticsByInstrument> {
    try {
      return await this.get("dataset_statistics/by_instrument");
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async getFacilitiesInstruments(): Promise<InstrumentFacility> {
    try {
      return await this.get("instruments");
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async getMethods(): Promise<IPagination<IEntityMinimalModel>> {
    try {
      return await this.get("methods");
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async getMethodById(measurementMethodId: number): Promise<IEntityMinimalModel> {
    try {
      return await this.get(`methods/${measurementMethodId}`);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }
  public async getParser(parserId: string): Promise<Parser> {
    return await this.get(`parsers/${parserId}`);
  }
  // public async getParsers(): Promise<IPagination<Parser>> {
  //   try {
  //     return await this.get("parsers");
  //   } catch (error: any) {
  //     return Promise.resolve(error);
  //   }
  // }
  private NumberToHashCode = (n?: number): string => {
    if (!n) return "00";
    return n.toString(16);
  };

  private DateToHashCode = (date?: string | Date | undefined | null): string => {
    if (!date) return "00";
    return this.NumberToHashCode(new Date(date).getTime());
  };

  private StringToHashCode = (text?: string): string => {
    if (!text) return "00";
    var hash = 0,
      i,
      chr;
    if (text.length === 0) return hash.toString(16);
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return this.NumberToHashCode(hash);
  };

  public async getSample(id: number): Promise<Sample> {
    try {
      return await this.get(`samples/${id}`);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  private async createSample(newSample: Sample) {
    await this.post("samples", newSample);
  }

  private async getProjectSuggestions(): Promise<IEntityMinimalModel[]> {
    return await this.get("projects/suggestions");
  }

  public async getExperiments(): Promise<IPagination<Experiment>> {
    try {
      return await this.get(`experiments`);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async getExperimentId(id: number): Promise<Experiment> {
    try {
      return await this.get(`experiments/${id}`);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async createExperiment(name: string, measurementMethodId: number): Promise<Experiment> {
    try {
      return await this.post(`experiments`, { name: name, measurementMethodId: measurementMethodId });
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async editExperiment(id: number, name: string, measurementMethodId: number) {
    try {
      return await this.put(`experiments/${id}`, { name: name, measurementMethodId: measurementMethodId });
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  public async deleteExperiment(id: number) {
    try {
      return await this.delete(`experiments/${id}`);
    } catch (error: any) {
      return Promise.resolve(error);
    }
  }

  static typeSize: Record<string, any> = {
    int: Int32Array,
    float: Float32Array,
    complex: Float32Array,
    double: Float64Array,
    uint8: Uint8Array,
    jpeg: "raw",
  };

  public static arrayFromBuffer(buffer: { buffer: any; offset: number }, pointNumber: number, typeName: string) {
    if (!typeName || !(typeName in API.typeSize)) return [];
    const typeClass = API.typeSize[typeName];
    const a = new typeClass(buffer.buffer, buffer.offset, pointNumber);
    buffer.offset += a.byteLength;
    // console.log("buffer.offset", buffer.offset);
    return a;
  }

  public static getPDFTrackFromData(data: any, shape: Shape): { pdf: Uint8Array; width: number; height: number } {
    return {
      pdf: data,
      width: shape.points[0],
      height: shape.points[1],
    };
  }

  public static getXYZTrackFromData(data: any, shape: Shape): { x: any; y: any; len: number; type: string } {
    // let typeName = shape?.axisType || [];
    // let typeClass = typeName.map((t: string) => API.typeSize[t]);
    // let offset = 0;
    // let dim = 0;
    let x;
    const points = shape?.points?.[0] || 0;

    // console.log("typeClass", typeClass[dim]);

    const buffer = { buffer: data.buffer, offset: 0 };

    let typeName = shape?.axisType?.[0] || "float";

    x = API.arrayFromBuffer(buffer, points, typeName);
    // if (typeClass[dim]) x = new typeClass[dim](data.buffer, offset, shape.points[dim]);
    // else x = [];
    // typeName = shape?.dataType || [];
    typeName = shape?.dataType?.[0] || "float";
    // typeClass = typeName.map((t: string) => API.typeSize[t]);
    // offset += x.byteLength;
    let y: any;
    // if (typeName[dim] === "complex") {
    if (typeName === "complex") {
      // console.log("Y", typeClass[dim]);

      type complex = { re?: any; im?: any };
      // const y: complex = {};
      y = {} as complex;
      // y.re = new typeClass[dim](data.buffer, offset, shape.points[dim]);
      y.re = API.arrayFromBuffer(buffer, points, typeName);
      // offset += y.re.byteLength;
      y.im = API.arrayFromBuffer(buffer, points, typeName);
      // y.im = new typeClass[dim](data.buffer, offset, shape.points[dim]);
      // console.log("Y", y);
    } else {
      y = API.arrayFromBuffer(buffer, points, typeName);
      // y = new typeClass[dim](data.buffer, offset, shape.points[dim]);
    }
    // console.log("X:", x);
    // console.log("Y:", y);
    return { x: x, y: y, len: x.length, type: "1D" };
  }

  public static getSequenceTrackFromData(data: any, shape: Shape): any {
    const char_re = /char(\d+)/;
    const buffer = { buffer: data.buffer, offset: 0 };
    const points = shape?.points?.[0] || 0;

    let x;
    if (shape.axisType && shape.axisType.length > 0) {
      x = API.arrayFromBuffer(buffer, shape.points[0], shape.axisType[0]);
    } else {
      x = new Int32Array(points);
      for (let i = 0; i < points; i++) x[i] = i + 1;
    }
    // console.log("x", points, x);
    let typeName = shape?.dataType?.[0] || "char";
    let len = 1;
    const match = typeName.match(char_re);
    if (match) {
      typeName = "char";
      len = parseInt(match[1]);
    }
    const uint8array = API.arrayFromBuffer(buffer, points * len, "uint8");
    const str = new TextDecoder().decode(uint8array);
    const y: string[] = Array(points);

    for (let i = 0; i < points; i++) {
      y[i] = str.substr(i * len, len).split("\0")[0];
    }
    return { x: x, y: y, len: x.length, type: "sequence" };
    // console.log("shape type", typeName, len, str.substr(0, 20), y);
  }

  public static getTrackFromData(data: any, shape: Shape): any {
    if (!data) return;

    const copy = (a: ArrayBuffer) => {
      if (ArrayBuffer.prototype.slice === undefined) {
        const that = new Uint8Array(a);
        const end = that.length;
        var result = new ArrayBuffer(end);
        var resultArray = new Uint8Array(result);
        for (var i = 0; i < resultArray.length; i++) resultArray[i] = that[i];
        return result;
      } else {
        return new Uint8Array(a.slice(0));
      }
    };

    data = copy(data);

    let result = undefined;
    if (shape.dimension === 1) {
      // console.log("shape type", shape.type);
      switch (shape.type) {
        case "view":
          break;
        case "nucleotide_sequence":
        case "protein_sequence":
          result = API.getSequenceTrackFromData(data, shape);
          break;
        default:
          result = API.getXYZTrackFromData(data, shape);
          break;
      }
    } else if (shape.dimension === 2) {
      const typeName = shape?.dataType?.[0] || "float";
      const dataType = shape?.dataType?.[0] || "float";

      if (shape.type === "image" && dataType === "jpeg") {
        result = data;
      } else if (shape.type === "pdf") {
        result = API.getPDFTrackFromData(data, shape);
      } else if (shape.type === "matrix") {
        // data = new API.typeSize[dataType](data.buffer, 0, shape.points[0] * shape.points[1]);
        data = API.arrayFromBuffer({ buffer: data.buffer, offset: 0 }, shape.points[0] * shape.points[1], typeName);
        result = {
          matrix: data,
          width: shape.points[0],
          height: shape.points[1],
          type: "matrix",
        };
      }
    }

    return result;
  }

  public async getDatasetTrack(datasetId: number, shape: any, uid: string, options: DatasetFilters = {}): Promise<any> {
    const searchParams = new URLSearchParams(options as any);
    try {
      const rv = await this.get(
        `datasets/${datasetId}/tracks/${shape.id}/${searchParams.toString()}`
        // `dataset`
      );
      return { data: API.getTrackFromData(rv, shape), id: uid };
    } catch {
      return Promise.resolve({ id: uid, data: undefined });
    }

    // return [];
  }
}
