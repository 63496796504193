import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Tile } from "../../panels/Tile/Tile";
import {
  CustomFieldValuesSearchParametersBase,
  EntityConstants,
  GenericEntity,
  GenericIdEntity,
  ICustomFieldValuesSearchParameters,
  IdTypes,
  IGenericRequestParameters,
  INamedEntity,
  ISoftDeletable,
  StringIndexedDict,
} from "../../../api/GenericTypes";
import { useEntityCount, useInfiniteListEntity } from "../../../api/BaseEntityApi";
import {
  InventoryItem,
  InventoryItemFilters,
  InventoryItemFiltersTranslator,
  inventoryItemsConstants,
  InventoryItemSortingOptionsConsts,
} from "../../../api/Inventories";
import {
  Sample,
  SampleFilters,
  SampleFiltersTranslator,
  samplesConstants,
  SampleSortingOptionsConsts,
} from "../../../api/Samples";
import {
  Dataset,
  DatasetFilters,
  DatasetFiltersTranslator,
  datasetsConstants,
  DatasetSortingOptionsConsts,
} from "../../../api/Datasets";
import {
  Project,
  ProjectFilters,
  ProjectFiltersTranslator,
  projectsConstants,
  ProjectSortingOptionsConsts,
} from "../../../api/Projects";
import {
  Person,
  PersonFilters,
  PersonFiltersTranslator,
  personsConstants,
  PersonSortingOptionsConsts,
} from "../../../api/Person";
import {
  FacilityFiltersTranslator,
  FacilitySortingOptionsConsts,
  InstrumentFacility,
  InstrumentFacilityFilters,
  instrumentsConstants,
} from "../../../api/Facilities";
import { LucideIcon } from "../../icon/LucideIcon";
import { toUppercase } from "../../helperfunctions/stringFunctions";
import styles from "./styles.module.css";
import { useDebouncedValue } from "../../helperfunctions/useDebouncedValue";
// import { EntityFilterIndicator, IsFilteredDefinition } from "../../tables/EntityFilterIndicator/EntityFilterIndicator";
import { SearchInput } from "../../forms/SearchInput/SearchInput";
import { OrderBySelectDropdown } from "../../buttons/OrderBySelectDropdown/OrderBySelectDropdown";
import { PropertyTranslator } from "../../../api/GenericTranslator";
import { LoadingWrapper } from "../../LoadingWrapper";
import { Link } from "react-router-dom";
import { SessionContext } from "../../contexts/SessionContext";
import { getDetailLink } from "../../../main/Routing";
import ContentRow from "../../tables/ContentRow/ContentRow";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList } from "react-window";
import { Skeleton } from "../../loaders/Skeleton/Skeleton";
import { Alert } from "../../overlays/Alert/Alert";
import { DateTimeRenderer } from "../../datetime/DateTimeFormatter";
import { NotAvailable, NotSet } from "../../misc/UIconstants";
import { GetPersons, GetProjects } from "../../misc/EntityRenders/EntityRenderer";
import { EntitySelectOptions } from "../../../Customization/CustomFields/CustomFieldUtils";
import {
  labNotebookEntriesConstants,
  LabNotebookEntry,
  LabNotebookEntryFilters,
  LabNotebookEntryFiltersTranslator,
  LabNotebookEntrySortingOptionsConsts,
} from "../../../ELN/types/LabNotebookEntry";
import {
  LabNotebook,
  LabNotebookFilters,
  LabNotebookFiltersTranslator,
  labNoteBooksConstants,
  LabNotebookSortingOptionsConsts,
} from "../../../ELN/types/LabNotebook";
import { NotebookEntryLabel } from "../../../ELN/LabNotebookEntry/LabNotebookEntry";
import { RenderTabStoreHistoryButton } from "../../tables/Tabs/history/TabStoreHistory";
import { filtersToSamplesForm, SampleFilterForm } from "../../../samples/Table/SampleFilterBar";
import { InventoryFilterForm, inventoryItemsFiltersToForm } from "../../../Inventories/Table/InventoryItemsFilterBar";
import { DatasetsFilterForm, datasetsFiltersToForm } from "../../../Dataset/Table/DatasetsFilterBar";
import { filtersToProjectsForm, ProjectFilterForm } from "../../../Projects/Table/ProjectFilterBar";
import { filtersToPersonsForm, PersonFilterForm } from "../../../Persons/Table/PersonFilterBar";
import { filtersToInstrumentsForm, InstrumentFilterForm } from "../../../Instruments/Table/InstrumentFilterBar";
import {
  filtersToLabNotebookEntriesForm,
  LabNotebookEntriesFilterForm,
} from "../../../ELN/LabNotebookEntries/Table/LabNotebookEntriesFilterBar";
import {
  LabNotebookFilterForm,
  labNotebooksFiltersToForm,
} from "../../../ELN/LabNotebooks/Table/LabNotebooksFilterBar";

type ExtendedEntitySelectOptions = EntitySelectOptions | "ViewableEntity";

const generateCustomFieldValuesFilter = <T extends CustomFieldValuesSearchParametersBase>(
  id: IdTypes,
  entityType: ExtendedEntitySelectOptions
): ICustomFieldValuesSearchParameters<T> => {
  switch (entityType) {
    case "Sample":
      return {
        customFieldValues: [
          { dataType: "Sample", values: [{ id: id }] },
          { dataType: "SampleArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "Inventory":
      return {
        customFieldValues: [
          { dataType: "Inventory", values: [{ id: id }] },
          { dataType: "InventoryArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "Dataset":
      return {
        customFieldValues: [
          { dataType: "Dataset", values: [{ id: id }] },
          { dataType: "DatasetArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "Project":
      return {
        customFieldValues: [
          { dataType: "Project", values: [{ id: id }] },
          { dataType: "ProjectArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "Person":
      return {
        customFieldValues: [
          { dataType: "Person", values: [{ id: id }] },
          { dataType: "PersonArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "Instrument":
      return {
        customFieldValues: [
          { dataType: "Instrument", values: [{ id: id }] },
          { dataType: "InstrumentArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "LabNotebook":
      return {
        customFieldValues: [
          { dataType: "LabNotebook", values: [{ id: id }] },
          { dataType: "LabNotebookArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "LabNotebookEntry":
      return {
        customFieldValues: [
          { dataType: "LabNotebookEntry", values: [{ id: id }] },
          { dataType: "LabNotebookEntryArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "LabNotebookExperiment":
      return {
        customFieldValues: [
          { dataType: "LabNotebookExperiment", values: [{ id: id }] },
          { dataType: "LabNotebookExperimentArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "Method":
      return {
        customFieldValues: [
          { dataType: "Method", values: [{ id: id }] },
          { dataType: "MethodArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "Organization":
      return {
        customFieldValues: [
          { dataType: "Organization", values: [{ id: id }] },
          { dataType: "OrganizationArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "SharedContent":
      return {
        customFieldValues: [
          { dataType: "SharedContent", values: [{ id: id }] },
          { dataType: "SharedContentArray", values: [{ id: id }], predicate: "OR" },
        ],
      } as ICustomFieldValuesSearchParameters<T>;
    case "ViewableEntity":
      return {
        customFieldValues: [{ dataType: "ViewableEntity", values: [{ id: id }] }],
      } as ICustomFieldValuesSearchParameters<T>;
  }
};

interface RenderCustomFieldReferencesProps<
  Entity extends GenericEntity & Partial<ISoftDeletable> & INamedEntity,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>,
  FilterForm extends StringIndexedDict
> {
  defaultFilters: Filters;
  entityConstants: EntityConstants;
  filterToForm: (filters: Filters) => FilterForm;
  translatorConsts: PropertyTranslator<Filters>;
  orderByOptions: readonly Filters["orderBy"][];
  count: number | undefined;
  rowLabel?: (entity: Entity) => React.ReactNode;
  rowDate?: (entity: Entity) => React.ReactNode;
  rowInfo?: (entity: Entity) => React.ReactNode;
  rowTitle?: (entity: Entity) => string;
  rowControls?: (entity: Entity) => React.ReactNode;
  rowId?: (entity: Entity) => React.ReactNode;
  onRowClick?: (entity: Entity) => void;
}
const RenderCustomFieldReferences = <
  Entity extends GenericEntity & Partial<ISoftDeletable> & INamedEntity,
  Filters extends StringIndexedDict & IGenericRequestParameters<Entity, Filters["orderBy"]>,
  FilterForm extends StringIndexedDict
>({
  defaultFilters,
  entityConstants,
  filterToForm,
  translatorConsts,
  orderByOptions,
  count,
  rowLabel,
  rowDate,
  rowInfo,
  rowTitle,
  rowControls,
  rowId,
  onRowClick,
}: RenderCustomFieldReferencesProps<Entity, Filters, FilterForm>) => {
  const { route } = useContext(SessionContext);
  const [show, setShow] = useState(!!count);
  const [filters, setFilters] = useState<Filters>({} as Filters);
  const activeFilters: Filters = useMemo(() => ({ ...filters, ...defaultFilters }), [defaultFilters, filters]);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 500);
  const [items, setItems] = useState<Entity[]>([]);
  const pageSize = 10;

  const { data, error, fetchNextPage, hasNextPage, isFetching, status, fetchStatus } = useInfiniteListEntity<Entity>(
    entityConstants.resource,
    {
      page: 1,
      pageSize: pageSize,
      includeCount: false,
      ...{ searchTerm: debouncedSearchValue },
      ...activeFilters,
    },
    { enabled: true },
    "post"
  );

  useEffect(() => {
    if (data) {
      setItems(data.pages.map((d) => d.results).flat());
    }
  }, [data]);

  const loadMoreCallback = useCallback(() => {}, []);
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isFetching ? loadMoreCallback : hasNextPage ? fetchNextPage : loadMoreCallback;

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length - pageSize,
    [hasNextPage, items.length, pageSize]
  );

  const Item = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      if (!isItemLoaded(index))
        return (
          <div style={{ ...style, paddingBottom: "5px" }} key={index}>
            <span className="skeleton-block btn-lg" style={{ height: 51 }} />
          </div>
        );

      const entity = items[index];
      return (
        <div style={{ ...style, paddingBottom: "5px" }} key={index} title={rowTitle?.(entity)}>
          <Link
            key={index}
            to={route(getDetailLink(entityConstants.frontendIndexRoute, entity.id))}
            style={{ textDecoration: "none" }}
            target={"_blank"}
          >
            <ContentRow
              key={index}
              icon={
                <div className={styles.icon}>
                  <LucideIcon name={entityConstants.icon} />
                </div>
              }
              label={rowLabel?.(entity)}
              currentIndicator={
                <>
                  {entity.isDeleted ? (
                    <div>
                      <label className={"label label-soft-warning"} title="trashed">
                        <LucideIcon name="trash" />
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              }
              date={rowDate?.(entity)}
              id={
                rowId ? (
                  rowId?.(entity)
                ) : (
                  <>
                    {toUppercase(entityConstants.entitySingular)}-ID: {entity.id}
                  </>
                )
              }
              info={rowInfo?.(entity)}
              controls={rowControls?.(entity)}
              onClick={onRowClick ? () => onRowClick(entity) : undefined}
            />
          </Link>
        </div>
      );
    },
    [
      entityConstants.entitySingular,
      entityConstants.frontendIndexRoute,
      entityConstants.icon,
      isItemLoaded,
      items,
      onRowClick,
      route,
      rowControls,
      rowDate,
      rowId,
      rowInfo,
      rowLabel,
      rowTitle,
    ]
  );

  return (
    <div className={styles.customFieldReferencesContainerMain}>
      <div
        className={`${styles.customFieldReferencesContainer} ${
          show ? styles.customFieldReferencesContainerActive : ""
        } ${!count ? styles.customFieldReferencesContainerDisabled : ""}
          
        `}
        onClick={() => !!count && setShow((prev) => !prev)}
        style={{ cursor: !!count ? "pointer" : "default" }}
      >
        <>{!!count && <>{show ? <LucideIcon name="chevron-down" /> : <LucideIcon name="chevron-right" />}</>}</>
        <LucideIcon name={entityConstants.icon} style={{ color: "var(--primary)" }} />{" "}
        {toUppercase(entityConstants.entityPlural)}
        <span className="badge">{count ?? 0}</span>
        <div style={{ marginLeft: "auto" }}>
          <RenderTabStoreHistoryButton
            entityConstants={entityConstants}
            filters={activeFilters}
            filtersToForm={filterToForm}
          />
        </div>
      </div>
      {show && (
        <div className={styles.customFieldReferencesContainerContent}>
          <div className="flex col-nowrap align-center gap-5" style={{ width: "100%", height: "100%" }}>
            <div className="flex row-nowrap align-center gap-5" style={{ width: "100%" }}>
              {/* <EntityFilterIndicator<Entity, Filters>
                filters={filters}
                translatorConsts={translatorConsts}
                excludeFilters={
                  {
                    excludeIds: () => false,
                    customFieldValues: () => false,
                    orderBy: () => false,
                  } as Partial<IsFilteredDefinition<Filters>>
                }
                overlayPlaycement="right"
              /> */}

              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
              <div style={{ marginLeft: "auto" }}>
                <OrderBySelectDropdown
                  btnCls="btn btn-xs btn-ghost-secondary"
                  orderByOptions={orderByOptions}
                  currentFilters={filters}
                  setCurrentFilters={setFilters}
                />
              </div>
            </div>
            <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error} type="entity-tile">
              <div className={isFetching ? `${styles.container} ${styles.container_loading}` : styles.container}>
                {items && items.length > 0 ? (
                  <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={loadMoreItems as any}
                  >
                    {({ onItemsRendered, ref }) => (
                      <FixedSizeList
                        itemCount={itemCount}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        width="100%"
                        height={items.length > pageSize ? pageSize * 59 : items.length * 59}
                        itemSize={59}
                      >
                        {Item}
                      </FixedSizeList>
                    )}
                  </InfiniteLoader>
                ) : (
                  <>
                    {isFetching ? (
                      <Skeleton type="rows" />
                    ) : (
                      <>
                        {debouncedSearchValue ? (
                          <Alert type="light" message={`No results for: "${debouncedSearchValue}"`} fit centered />
                        ) : (
                          <Alert type="light" message={`No related ${entityConstants.entityPlural}`} fit centered />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </LoadingWrapper>
          </div>
        </div>
      )}
    </div>
  );
};

interface CustomFieldReferencesTileProps<Entity extends GenericIdEntity> {
  entity: Entity;
  entityType: ExtendedEntitySelectOptions;
  disabled?: boolean;
  labNotebookFilters?: LabNotebookFilters;
  labNotebookEntryFilters?: LabNotebookEntryFilters;
}
export const CustomFieldReferencesTile = <Entity extends GenericIdEntity>({
  entity,
  entityType,
  disabled = false,
  labNotebookFilters = {},
  labNotebookEntryFilters = {},
}: CustomFieldReferencesTileProps<Entity>) => {
  const { session } = useContext(SessionContext);
  const customFieldValuesFilter = generateCustomFieldValuesFilter(entity.id, entityType);

  const { data: samplesCount } = useEntityCount<SampleFilters>(samplesConstants.resource, customFieldValuesFilter, {
    enabled: !disabled,
  });
  const { data: inventoriesCount } = useEntityCount<InventoryItemFilters>(
    inventoryItemsConstants.resource,
    customFieldValuesFilter,
    { enabled: !disabled }
  );

  const { data: datasetsCount } = useEntityCount<DatasetFilters>(datasetsConstants.resource, customFieldValuesFilter, {
    enabled: !disabled,
  });
  const { data: projectsCount } = useEntityCount<ProjectFilters>(projectsConstants.resource, customFieldValuesFilter, {
    enabled: !disabled,
  });
  const { data: personsCount } = useEntityCount<PersonFilters>(personsConstants.resource, customFieldValuesFilter, {
    enabled: !disabled,
  });
  const { data: instrumentsCount } = useEntityCount<InstrumentFacilityFilters>(
    instrumentsConstants.resource,
    customFieldValuesFilter,
    { enabled: !disabled }
  );
  const { data: labNotebookEntriesCount } = useEntityCount<LabNotebookEntryFilters>(
    labNotebookEntriesConstants.resource,
    labNotebookEntryFilters,
    { enabled: !disabled && !!Object.keys(labNotebookEntryFilters).length }
  );

  const { data: labNoteBooksCount } = useEntityCount<LabNotebookFilters>(
    labNoteBooksConstants.resource,
    labNotebookFilters,
    { enabled: !disabled && !!Object.keys(labNotebookFilters).length }
  );

  const resultsCount =
    (samplesCount?.count ?? 0) +
    (inventoriesCount?.count ?? 0) +
    (datasetsCount?.count ?? 0) +
    (projectsCount?.count ?? 0) +
    (personsCount?.count ?? 0) +
    (instrumentsCount?.count ?? 0) +
    (labNotebookEntriesCount?.count ?? 0) +
    (labNoteBooksCount?.count ?? 0);

  return (
    <Tile
      isFolded={!resultsCount ? true : false}
      foldable={!resultsCount ? false : true}
      disabled={!resultsCount ? true : false}
    >
      <Tile.Head
        title={
          <div className="flex row-nowrap align-center gap-5">
            <LucideIcon name={"link"} /> Referenced by
            <span className={`badge ${typeof resultsCount !== "number" && !disabled ? "loading" : ""}`}>
              {disabled ? 0 : typeof resultsCount === "number" ? resultsCount : "•"}
            </span>
          </div>
        }
      />

      <Tile.Body>
        <div className="flex col-nowrap gap-5" style={{ width: "100%", alignItems: "flex-start" }}>
          {!!samplesCount?.count && (
            <RenderCustomFieldReferences<Sample, SampleFilters, SampleFilterForm>
              entityConstants={samplesConstants}
              filterToForm={filtersToSamplesForm}
              orderByOptions={SampleSortingOptionsConsts}
              translatorConsts={SampleFiltersTranslator}
              count={samplesCount?.count}
              defaultFilters={customFieldValuesFilter}
              rowLabel={(e) => <>{e.name}</>}
              rowDate={(e) => (
                <>
                  {e.preparedAt ? (
                    <DateTimeRenderer date={e.preparedAt} includeElapsed={false} includeTime={false} />
                  ) : (
                    NotSet
                  )}
                </>
              )}
              rowInfo={(e) => <>{e.createdBy ? <GetPersons persons={e.createdBy} createLinks={false} /> : NotSet}</>}
              rowId={(e) => (
                <label className="label label-soft-info">
                  {e.customType ? e.customType.name : `Basic ${samplesConstants.entitySingular}`}
                </label>
              )}
            />
          )}
          {!!inventoriesCount?.count && (
            <RenderCustomFieldReferences<InventoryItem, InventoryItemFilters, InventoryFilterForm>
              entityConstants={inventoryItemsConstants}
              filterToForm={inventoryItemsFiltersToForm}
              orderByOptions={InventoryItemSortingOptionsConsts}
              translatorConsts={InventoryItemFiltersTranslator}
              count={inventoriesCount?.count}
              defaultFilters={customFieldValuesFilter}
              rowLabel={(e) => <>{e.name}</>}
              rowDate={(e) => (
                <>
                  {e.createdOn ? (
                    <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} />
                  ) : (
                    NotSet
                  )}
                </>
              )}
              rowInfo={(e) => <>{e.createdBy ? <GetPersons persons={e.createdBy} createLinks={false} /> : NotSet}</>}
              rowId={(e) => (
                <label className="label label-soft-info" style={{ margin: 0 }}>
                  {e.rootCustomType?.name || NotAvailable}
                </label>
              )}
            />
          )}
          {!!datasetsCount?.count && (
            <RenderCustomFieldReferences<Dataset, DatasetFilters, DatasetsFilterForm>
              entityConstants={datasetsConstants}
              filterToForm={datasetsFiltersToForm}
              orderByOptions={DatasetSortingOptionsConsts}
              translatorConsts={DatasetFiltersTranslator}
              count={datasetsCount?.count}
              defaultFilters={customFieldValuesFilter}
              rowLabel={(e) => <>{e.name || NotAvailable}</>}
              rowDate={(e) => (
                <>
                  {e.acquisitionDate ? (
                    <DateTimeRenderer date={e.acquisitionDate} includeElapsed={false} includeTime={false} />
                  ) : (
                    NotSet
                  )}
                </>
              )}
              rowInfo={(e) => (
                <>
                  <label className="label label-soft-secondary" style={{ margin: 0 }}>
                    {e.method?.name}
                  </label>
                  {e.experiment ? <span style={{ color: "var(--primary)" }}> {e.experiment.name}</span> : ""}
                </>
              )}
              rowId={(e) => (
                <label className="label label-soft-info">
                  {e.customType ? e.customType.name : `Basic ${datasetsConstants.entitySingular}`}
                </label>
              )}
            />
          )}
          {!!projectsCount?.count && session?.features.enable_project_custom_types && (
            <RenderCustomFieldReferences<Project, ProjectFilters, ProjectFilterForm>
              entityConstants={projectsConstants}
              filterToForm={filtersToProjectsForm}
              orderByOptions={ProjectSortingOptionsConsts}
              translatorConsts={ProjectFiltersTranslator}
              count={projectsCount?.count}
              defaultFilters={customFieldValuesFilter}
              rowLabel={(e) => <>{e.name}</>}
              rowDate={(e) => (
                <>
                  {e.createdOn ? (
                    <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} />
                  ) : (
                    NotSet
                  )}
                </>
              )}
              rowInfo={(e) => <>{e.owner ? <GetPersons persons={e.owner} createLinks={false} /> : NotSet}</>}
              rowId={(e) => (
                <label className="label label-soft-info">
                  {e.customType ? e.customType.name : `Basic ${projectsConstants.entitySingular}`}
                </label>
              )}
            />
          )}
          {!!personsCount?.count && session?.features.enable_person_custom_types && (
            <RenderCustomFieldReferences<Person, PersonFilters, PersonFilterForm>
              entityConstants={personsConstants}
              filterToForm={filtersToPersonsForm}
              orderByOptions={PersonSortingOptionsConsts}
              translatorConsts={PersonFiltersTranslator}
              count={personsCount?.count}
              defaultFilters={customFieldValuesFilter}
              rowLabel={(e) => <>{e.name}</>}
              rowDate={(e) => (
                <>
                  <>{e.organization?.name || NotSet}</>
                </>
              )}
              rowInfo={(e) => <>{e.email || NotSet}</>}
              rowId={(e) => (
                <label className="label label-soft-info">
                  {e.customType ? e.customType.name : `Basic ${personsConstants.entitySingular}`}
                </label>
              )}
            />
          )}
          {!!instrumentsCount?.count && session?.features.enable_facility_custom_types && (
            <RenderCustomFieldReferences<InstrumentFacility, InstrumentFacilityFilters, InstrumentFilterForm>
              entityConstants={instrumentsConstants}
              filterToForm={filtersToInstrumentsForm}
              orderByOptions={FacilitySortingOptionsConsts}
              translatorConsts={FacilityFiltersTranslator}
              count={instrumentsCount?.count}
              defaultFilters={customFieldValuesFilter}
              rowLabel={(e) => <>{e.name}</>}
              rowDate={(e) => (
                <>
                  {e.createdOn ? (
                    <DateTimeRenderer date={e.createdOn} includeElapsed={false} includeTime={false} />
                  ) : (
                    NotSet
                  )}
                </>
              )}
              rowInfo={(e) => <>{e.owner ? <GetPersons persons={e.owner} createLinks={false} /> : NotSet}</>}
              rowId={(e) => (
                <label className="label label-soft-info">
                  {e.customType ? e.customType.name : `Basic ${instrumentsConstants.entitySingular}`}
                </label>
              )}
            />
          )}
          {!!labNotebookEntriesCount?.count && labNotebookEntryFilters && (
            <RenderCustomFieldReferences<LabNotebookEntry, LabNotebookEntryFilters, LabNotebookEntriesFilterForm>
              entityConstants={labNotebookEntriesConstants}
              filterToForm={filtersToLabNotebookEntriesForm}
              orderByOptions={LabNotebookEntrySortingOptionsConsts}
              translatorConsts={LabNotebookEntryFiltersTranslator}
              count={labNotebookEntriesCount?.count}
              defaultFilters={labNotebookEntryFilters}
              rowLabel={(e) => <NotebookEntryLabel entry={e} />}
              rowDate={(e) => (
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
                  <DateTimeRenderer date={e.modifiedOn} includeElapsed={true} includeTime={false} />
                </div>
              )}
              rowInfo={(e) => <>{`${e.labNotebook.name} > ${e.labNotebookExperiment.name}`}</>}
            />
          )}
          {!!labNoteBooksCount?.count && labNotebookFilters && (
            <RenderCustomFieldReferences<LabNotebook, LabNotebookFilters, LabNotebookFilterForm>
              entityConstants={labNoteBooksConstants}
              filterToForm={labNotebooksFiltersToForm}
              orderByOptions={LabNotebookSortingOptionsConsts}
              translatorConsts={LabNotebookFiltersTranslator}
              count={labNoteBooksCount?.count}
              defaultFilters={labNotebookFilters}
              rowLabel={(e) => <>{e.name}</>}
              rowDate={(e) => (
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <LucideIcon name="save" style={{ marginBottom: "2px", width: "12px" }} />
                  <DateTimeRenderer date={e.modifiedOn} includeElapsed={true} includeTime={false} />
                </div>
              )}
              rowInfo={(e) => <GetProjects projects={e.projects} />}
            />
          )}
        </div>
      </Tile.Body>
    </Tile>
  );
};
