import { CSSProperties, forwardRef, RefObject, useCallback, useContext, useEffect, useState } from "react";
import { CustomType, customTypeConstants, CustomTypeDataTypeUtils, CustomTypeEntityType } from "../../api/CustomTypes";
import { datasetsConstants } from "../../api/Datasets";
import { instrumentsConstants } from "../../api/Facilities";
import { inventoriesConstants } from "../../api/Inventories";
import { personsConstants } from "../../api/Person";
import { projectsConstants } from "../../api/Projects";
import { samplesConstants } from "../../api/Samples";
import { IconProps, LucideIcon } from "../../common/icon/LucideIcon";
import styles from "./CustomTypeBuilder.module.css";
import { FieldErrors } from "react-hook-form";
import { IdTypes, StringIndexedDict } from "../../api/GenericTypes";
import React from "react";
import { ErrorsRenderer } from "../../common/forms/MultiEditForms/common/MultiEditRenderUtils";
import useIntersectionObserver from "../../common/helperfunctions/useIntersectionObserver";
import { useResizeDetector } from "react-resize-detector";
import { useGet } from "../../api/BaseEntityApi";
import { EntityHierarchy, TreeViewer } from "../../common/hierarchy/TreeViewer/TreeViewer";
import { EntityBreadcrumbs } from "../../common/hierarchy/breadcrumbs/EntityBreadcrumbs";
import { SessionContext } from "../../common/contexts/SessionContext";
import { getAddRoute, getDetailLink, getIndexRoute } from "../../main/Routing";
import { Link, useHistory } from "react-router-dom";
import { toUppercase } from "../../common/helperfunctions/stringFunctions";
import { NotAvailable } from "../../common/misc/UIconstants";
import { AbstractedCustomFieldDataTypes } from "../CustomFields/CustomFieldUtils";
import { genericEntityConstants } from "../../api/GenericConstants";
import { abstractedDataTypeToIconName } from "../CustomFields/CustomFieldRenderUtils";
import { OverlayInfo } from "../../common/misc/OverlayInfo/OverlayInfo";
import { useEntityPermissions } from "../../common/permissions/useEntityPermissions";

function findAncestors<IdType extends IdTypes>(
  root: EntityHierarchy<IdType>,
  targetId: number
): EntityHierarchy<IdType>[] {
  // Helper function to recursively search for the target node and collect ancestors
  function search(
    node: EntityHierarchy<IdType>,
    ancestors: EntityHierarchy<IdType>[]
  ): EntityHierarchy<IdType>[] | null {
    if (node.id === targetId) {
      // Return the ancestors when the target node is found
      return ancestors;
    }
    if (node.children) {
      for (const child of node.children) {
        // Add the current node to the ancestors list for the next level of recursion
        const result = search(child, [...ancestors, node]);
        if (result) return result; // Target found in this subtree
      }
    }
    return null; // Target not found in this subtree
  }
  return search(root, []) ?? [];
}

interface CustomTypeHierarchyProps {
  customType: CustomType;
}

export const CustomTypeHierarchyViewer = ({ customType }: CustomTypeHierarchyProps) => {
  const enableQuery =
    customType.id !== undefined &&
    CustomTypeDataTypeUtils.CanDefineHierarchy(customType.entityType) &&
    (customType.isHierarchyRoot || (Array.isArray(customType.parentTypes) && !!customType.parentTypes.length));

  const { data: treeData } = useGet<EntityHierarchy<CustomType["id"]>>(
    `${customTypeConstants.resource}/hierarchy/${customType.id}`,
    undefined,
    {
      enabled: enableQuery,
    }
  );
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const permissions = useEntityPermissions({ entityTypeId: "customTypes" });

  const onLabelClick = useCallback(
    (d: d3.HierarchyPointNode<EntityHierarchy<CustomType["id"]>>) => {
      if (!d.data.id) {
        history.push(
          `${route(getAddRoute(customTypeConstants.frontendIndexRoute))}?parentTypes=${JSON.stringify([
            {
              id: customType.id,
              name: customType.name,
            },
          ])}&rootHierarchy=${JSON.stringify(
            customType.rootHierarchy
              ? {
                  id: customType.rootHierarchy.id,
                  name: customType.rootHierarchy.name,
                }
              : {
                  id: customType.id,
                  name: customType.name,
                }
          )}&entityType=Inventory`
        );
      } else {
        history.push(route(getDetailLink(customTypeConstants.frontendIndexRoute, d.data.id)));
      }
    },
    [customType.id, customType.name, customType.rootHierarchy, history, route]
  );

  if (treeData) {
    return (
      <div className="flex" style={{ width: "100%", height: "100%", padding: "10px 0px" }}>
        <TreeViewer
          nodeId={customType.id}
          treeData={treeData}
          onLabelClick={onLabelClick}
          showAddNode={permissions.canCreate}
        />
      </div>
    );
  } else {
    return null;
  }
};

export const RenderCustomTypeHierarchy = ({ customType }: { customType: CustomType }) => {
  const { route } = useContext(SessionContext);
  const [breadCrumbs, setBreadCrumbs] = useState<EntityHierarchy<CustomType["id"]>[]>([]);
  const enableQuery =
    customType.id !== undefined &&
    CustomTypeDataTypeUtils.CanDefineHierarchy(customType.entityType) &&
    (customType.isHierarchyRoot || (Array.isArray(customType.parentTypes) && !!customType.parentTypes.length));

  const { data: treeData } = useGet<EntityHierarchy<CustomType["id"]>>(
    `${customTypeConstants.resource}/hierarchy/${customType.id}`,
    undefined,
    { enabled: enableQuery }
  );

  useEffect(() => {
    if (treeData) {
      setBreadCrumbs(findAncestors(treeData, customType.id));
    }
  }, [customType.id, treeData]);

  if (Array.isArray(breadCrumbs) && breadCrumbs.length)
    return (
      <EntityBreadcrumbs
        entity={customType}
        breadcrumbs={breadCrumbs}
        entityConstants={customTypeConstants}
        indexEntityLabelOverride={`${customType.entityType} types`}
      />
    );
  return (
    <div className="flex row-nowrap align-center gap-5" style={{ overflow: "hidden" }}>
      <Link
        to={route(getIndexRoute(customTypeConstants.frontendIndexRoute))}
        style={{ textDecoration: "none" }}
        title={`Show ${customTypeConstants.entityPlural} table`}
      >
        <div
          className="flex row-nowrap align-center gap-5"
          style={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "24px",
            whiteSpace: "nowrap",
          }}
        >
          <LucideIcon name={customTypeConstants.icon} color={"var(--primary)"} style={{ width: 20, height: 20 }} />
          {toUppercase(`${customType.entityType} types`)}
        </div>
      </Link>
      <div>
        <LucideIcon name="arrow-big-right" color="var(--gray-400)" />
      </div>
      <span
        style={{
          fontSize: "24px",
          lineHeight: "26px",
          margin: 0,
          fontWeight: 400,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {customType?.name || NotAvailable}
      </span>
      {/* {customType.entityType && (
        <h2 style={{ margin: 0 }}>
          <label className="label label-sm label-soft-info" style={{ margin: 0 }} title={"Trashed"}>
            {`${customType.entityType} type`}
          </label>
        </h2>
      )} */}
    </div>
  );
};

interface CustomTypeEntityTypeOptionToIconProps extends Omit<IconProps, "name"> {
  entityType: CustomTypeEntityType;
}
export const CustomTypeEntityTypeOptionToIcon = ({ entityType, ...props }: CustomTypeEntityTypeOptionToIconProps) => {
  return <LucideIcon {...props} name={customTypeEntityTypeOptionToIconName(entityType)} />;
};

export const customTypeEntityTypeOptionToIconName = (entityType: CustomTypeEntityType) => {
  switch (entityType) {
    case "Dataset":
      return datasetsConstants.icon;
    case "Sample":
      return samplesConstants.icon;
    case "Person":
      return personsConstants.icon;
    case "Instrument":
      return instrumentsConstants.icon;
    case "Inventory":
      return inventoriesConstants.icon;
    case "Project":
      return projectsConstants.icon;
  }
};

interface GridLayoutElementWrapperProps {
  head: React.ReactNode;
  isFolded?: boolean;
  isDirty?: boolean;
  isValid?: boolean;
  children: React.ReactNode;
}
export const GridLayoutElementWrapper = forwardRef<HTMLDivElement, GridLayoutElementWrapperProps>(
  ({ head, isFolded, isDirty, isValid, children }, ref) => {
    const entry = useIntersectionObserver(ref as RefObject<HTMLDivElement>, { freezeOnceVisible: true });
    const { height } = useResizeDetector({ targetRef: ref as RefObject<HTMLDivElement> });
    const [_isFolded, setIsFolded] = useState(isFolded);
    const [isGrabbing, setIsGrabbing] = useState(false);

    useEffect(() => {
      setIsFolded(isFolded);
    }, [isFolded]);

    return (
      <div
        className={styles.wrapperContainer}
        ref={ref}
        style={{
          ...(entry && entry?.isIntersecting && isDirty !== undefined && isDirty
            ? { boxShadow: "0px 0px 4px var(--warning)" }
            : {}),
          ...(entry && entry?.isIntersecting && isValid !== undefined && !isValid
            ? { boxShadow: "0px 0px 4px var(--danger)" }
            : {}),
        }}
      >
        {entry && entry?.isIntersecting ? (
          <>
            <div className="flex col-nowrap align-start gap-5">
              <div
                className={styles.wrapperContainerLabel}
                onMouseDown={() => setIsGrabbing(true)}
                onMouseUp={() => setIsGrabbing(false)}
                style={{ cursor: isGrabbing ? "grabbing" : "grab" }}
              >
                <button
                  type="button"
                  className="btn btn-round btn-ghost-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsFolded((prev) => !prev);
                  }}
                  onMouseDown={(e) => {
                    // We prevent dragging inside the actual widget
                    e.stopPropagation();
                  }}
                  // style={{ marginRight: "8px", padding: "0", border: "none", background: "none" }}
                  title="Fold/Unfold"
                >
                  {_isFolded ? <LucideIcon name="chevron-right" /> : <LucideIcon name="chevron-down" />}
                </button>
                {head}
              </div>
            </div>

            <div
              className={styles.wrapperContainerContent}
              onMouseDown={(e) => {
                // We prevent dragging inside the actual widget
                e.stopPropagation();
              }}
              style={{
                ...(_isFolded && {
                  height: 0,
                  padding: 0,
                  border: 0,
                }),
              }}
            >
              {children}
            </div>
          </>
        ) : (
          <span className="skeleton skeleton-block" style={{ width: "100%", height: height || 57 }} />
        )}
      </div>
    );
  }
);

// A custom draggable container for widgets
interface DragContainerProps<T extends StringIndexedDict> {
  dataGrid: T;
  style?: CSSProperties;
  children: React.ReactNode;
}
export const DragContainer = <T extends StringIndexedDict>({ dataGrid, style, children }: DragContainerProps<T>) => {
  // const [isDragging, setIsDragging] = useState(false);
  return (
    <div
      className={styles.drag_container}
      draggable={true}
      unselectable="on"
      onDragStart={(e) => {
        e.dataTransfer.setData("dragData", JSON.stringify(dataGrid));
      }}
      onDragEnd={(e) => {}}
      style={style}
    >
      {children}
    </div>
  );
};

interface NestedErrorContainerProps<T extends StringIndexedDict> {
  errors: FieldErrors<T>;
}
export const NestedErrorRenderer = <T extends StringIndexedDict>({ errors }: NestedErrorContainerProps<T>) => {
  if (!errors) return <></>;
  const nErrors = Object.keys(errors).length;
  if (!errors || !nErrors) return <></>;
  return (
    <div style={{ paddingLeft: 15 }}>
      {Object.entries(errors).map(([id, error], index) => (
        <React.Fragment key={index}>
          <label className="label label-soft-danger">{id}</label>
          {Array.isArray(error) ? (
            <div className="flex col-nowrap" style={{ paddingLeft: 15 }}>
              {error.map((e, i) => (
                <React.Fragment key={i}>
                  [{i + 1}] : <NestedErrorRenderer errors={e as any} />
                </React.Fragment>
              ))}
            </div>
          ) : (
            <div style={{ paddingLeft: 10 }}>
              <ErrorsRenderer error={error} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export const NestedErrorContainer = <T extends StringIndexedDict>({ errors }: NestedErrorContainerProps<T>) => {
  if (!errors) return <></>;
  const nErrors = Object.keys(errors).length;
  if (!errors || !nErrors) return <></>;
  return (
    <div className={styles.errorListContainer}>
      <h3>Validation errors</h3>
      <div className={styles.errorList}>
        <NestedErrorRenderer errors={errors} />
      </div>
    </div>
  );
};

interface RenderCustomTypeDefaultSectionsProps {
  entityType: CustomTypeEntityType;
}
export const RenderCustomTypeDefaultSections = ({ entityType }: RenderCustomTypeDefaultSectionsProps) => {
  const sections = defaultSectionProperties(entityType);
  return (
    <div className="flex col-nowrap gap-5">
      {Object.entries(sections).map(([sectionName, properties], i) => (
        <CustomTypeDefaultSection sectionName={sectionName} section={properties} key={i} />
      ))}
    </div>
  );
};

interface CustomTypeDefaultSectionProps {
  sectionName: string;
  section: CustomTypeDefaultSectionPropertyDescription[];
}
const CustomTypeDefaultSection = ({ sectionName, section }: CustomTypeDefaultSectionProps) => {
  const [folded, setFolded] = useState(true);
  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.wrapperContainerLabel}>
        <button
          type="button"
          className="btn btn-round btn-ghost-secondary"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setFolded((prev) => !prev);
          }}
          onMouseDown={(e) => {
            // We prevent dragging inside the actual widget
            e.stopPropagation();
          }}
          // style={{ marginRight: "8px", padding: "0", border: "none", background: "none" }}
          title="Fold/Unfold"
        >
          {folded ? <LucideIcon name="chevron-right" /> : <LucideIcon name="chevron-down" />}
        </button>
        <LucideIcon name={"panel-top-dashed"} style={{ width: "24px", height: "24px" }} />
        <h3 style={{ margin: 0 }}>{sectionName}</h3>
        <OverlayInfo icon="info">This is a default section and cannot be customized.</OverlayInfo>
      </div>
      <div className={styles.wrapperContainerContent} style={{ ...(folded && { height: 0, padding: 0, border: 0 }) }}>
        <div className="flex col-nowrap gap-5">
          {section.map((p, i) => (
            <div
              className={`flex row-nowrap align-center gap-5 form-group ${p.required ? "required" : ""}`}
              key={i}
              style={{ margin: 0 }}
            >
              <LucideIcon name={abstractedDataTypeToIconName(p.dataType)} />
              <span className="control-label" style={{ padding: 0, fontWeight: 500 }}>
                {p.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface CustomTypeDefaultSectionPropertyDescription {
  name: string;
  dataType: AbstractedCustomFieldDataTypes;
  required: boolean;
}
interface CustomTypeDefaultSectionPropertyDescriptionCollection {
  [sectionName: string]: CustomTypeDefaultSectionPropertyDescription[];
}

const defaultSectionProperties = (
  entityType: CustomTypeEntityType
): CustomTypeDefaultSectionPropertyDescriptionCollection => {
  switch (entityType) {
    case "Dataset":
      return {
        "Basic details": [
          { name: genericEntityConstants.datasets.fieldLabels.name, dataType: "Text", required: true },
          {
            name: genericEntityConstants.datasets.fieldLabels.customType,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.datasets.fieldLabels.method, dataType: "Entity Reference", required: false },
          {
            name: genericEntityConstants.datasets.fieldLabels.experiment,
            dataType: "Entity Reference",
            required: false,
          },
          {
            name: genericEntityConstants.datasets.fieldLabels.instrument,
            dataType: "Entity Reference",
            required: false,
          },
          {
            name: genericEntityConstants.datasets.fieldLabels.equipments,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.datasets.fieldLabels.acquisitionDate, dataType: "Date", required: false },
          { name: genericEntityConstants.datasets.fieldLabels.projects, dataType: "Entity Reference", required: false },
          {
            name: genericEntityConstants.datasets.fieldLabels.organizations,
            dataType: "Entity Reference",
            required: false,
          },
          {
            name: genericEntityConstants.datasets.fieldLabels.operators,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.datasets.fieldLabels.notes, dataType: "Text", required: false },
        ],
      };
    case "Sample":
      return {
        "Basic details": [
          { name: genericEntityConstants.samples.fieldLabels.name, dataType: "Text", required: true },
          { name: genericEntityConstants.samples.fieldLabels.sequenceNumber, dataType: "Text", required: false },
          {
            name: genericEntityConstants.samples.fieldLabels.customType,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.samples.fieldLabels.projects, dataType: "Entity Reference", required: true },
          { name: genericEntityConstants.samples.fieldLabels.preparedBy, dataType: "Entity Reference", required: true },
          { name: genericEntityConstants.samples.fieldLabels.preparedAt, dataType: "Date", required: true },
          {
            name: genericEntityConstants.samples.fieldLabels.discardedBy,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.samples.fieldLabels.discardedAt, dataType: "Date", required: false },
          {
            name: genericEntityConstants.samples.fieldLabels.organizations,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.samples.fieldLabels.notes, dataType: "Text", required: false },
        ],
      };
    case "Person":
      return {
        "Basic details": [
          { name: genericEntityConstants.persons.fieldLabels.salutation, dataType: "Text", required: false },
          { name: genericEntityConstants.persons.fieldLabels.name, dataType: "Text", required: true },
          { name: genericEntityConstants.persons.fieldLabels.email, dataType: "Text", required: false },
          {
            name: genericEntityConstants.persons.fieldLabels.organization,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.persons.fieldLabels.privateAddress, dataType: "Text", required: false },
          { name: genericEntityConstants.persons.fieldLabels.officePhone, dataType: "Text", required: false },
          { name: genericEntityConstants.persons.fieldLabels.phone, dataType: "Text", required: false },
          { name: genericEntityConstants.persons.fieldLabels.web, dataType: "URL", required: false },
          {
            name: genericEntityConstants.persons.fieldLabels.personTags,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.persons.fieldLabels.notes, dataType: "Text", required: false },

          { name: "Name", dataType: "Text", required: true },
        ],
      };
    case "Instrument":
      return {
        "Basic details": [
          { name: genericEntityConstants.instruments.fieldLabels.name, dataType: "Text", required: true },
          {
            name: genericEntityConstants.instruments.fieldLabels.customType,
            dataType: "Entity Reference",
            required: false,
          },
          {
            name: genericEntityConstants.instruments.fieldLabels.method,
            dataType: "Entity Reference",
            required: true,
          },
          { name: genericEntityConstants.instruments.fieldLabels.isObsolete, dataType: "Toggle", required: false },
          { name: genericEntityConstants.instruments.fieldLabels.serialnumber, dataType: "Text", required: false },
          { name: genericEntityConstants.instruments.fieldLabels.model, dataType: "URL", required: false },
          { name: genericEntityConstants.instruments.fieldLabels.room, dataType: "Entity Reference", required: false },
        ],
        "Contact details": [
          { name: genericEntityConstants.instruments.fieldLabels.localPhone, dataType: "Text", required: false },
          {
            name: genericEntityConstants.instruments.fieldLabels.homeLab,
            dataType: "Entity Reference",
            required: false,
          },
          {
            name: genericEntityConstants.instruments.fieldLabels.homeLabContacts,
            dataType: "Entity Reference",
            required: false,
          },
          {
            name: genericEntityConstants.instruments.fieldLabels.companyContacts,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.instruments.fieldLabels.notes, dataType: "Text", required: false },
        ],
      };
    case "Inventory":
      return {
        "Basic details": [
          { name: genericEntityConstants.inventoryItems.fieldLabels.name, dataType: "Text", required: true },
          {
            name: genericEntityConstants.inventoryItems.fieldLabels.projects,
            dataType: "Entity Reference",
            required: false,
          },

          {
            name: genericEntityConstants.inventoryItems.fieldLabels.customType,
            dataType: "Entity Reference",
            required: true,
          },
        ],
      };
    case "Project":
      return {
        "Basic details": [
          { name: genericEntityConstants.projects.fieldLabels.name, dataType: "Text", required: true },
          {
            name: genericEntityConstants.projects.fieldLabels.customType,
            dataType: "Entity Reference",
            required: false,
          },
          { name: genericEntityConstants.projects.fieldLabels.notes, dataType: "Text", required: false },
          {
            name: genericEntityConstants.projects.fieldLabels.projectTags,
            dataType: "Entity Reference",
            required: false,
          },
        ],
      };
    default:
      return {};
  }
};
