import { useContext, useMemo, useState } from "react";
import SinglePage from "../../common/panels/SinglePage/SinglePage";
import { SharedContent, sharedContentConstants, SharedContentFilters } from "../../api/SharedContent";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../common/contexts/SessionContext";
import Status from "../../common/badges/Status/Status";
import styles from "../../common/tables/GenericVirtualizedTable/commons.module.css";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { NotSet } from "../../common/misc/UIconstants";
import { TableViewLabel } from "../../common/panels/TableView/TableView";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { EntityTableTabs } from "../../common/tables/Tabs/EntityTableTabs";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableDeleteButton } from "../../common/entity/entityComponents/EntityTableDeleteButton";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";

export const switchSharedContentDefaultSortState = (
  sortState: SharedContentFilters["orderBy"]
): SortState<SharedContentFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "CREATION_DATE_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdAt",
        orderBy: sortState,
      };
    case "CREATION_DATE_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdAt",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "DESC",
        headerId: "default-createdAt",
        orderBy: sortState,
      };
  }
};

const defaultFilter: SharedContentFilters = { orderBy: "CREATION_DATE_ASC" };

export const useSharedContentsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"sharedContents">) => {
  const { session } = useContext(SessionContext);

  const defaults: ColumnsSettings<SharedContent> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-owner": { pos: 2, active: true, header: fieldLabels.owner, property: "owner" },
      "default-createdAt": { pos: 3, active: true, header: fieldLabels.createdAt, property: "createdAt" },
      "default-pubnotes": { pos: 4, active: true, header: fieldLabels.publicNotes, property: "publicNotes" },
      "default-privnotes": { pos: 5, active: true, header: fieldLabels.privateNotes, property: "privateNotes" },
      "default-link": { pos: 6, active: true, header: fieldLabels.link, property: "shareId" },
      "default-password": { pos: 7, active: true, header: fieldLabels.password, property: "password" },
      "default-contentDownload": {
        pos: 8,
        active: true,
        header: fieldLabels.allowContentDownload,
        property: "allowContentDownload",
      },
      "default-datasets": { pos: 9, active: true, header: fieldLabels.datasets, property: "datasets" },
    }),
    [fieldLabels]
  );

  const tabStoreDefaults: TableTabsDict<SharedContent, SharedContentFilters, {}> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
      mydata: {
        tabId: "mydata",
        type: "fixed",
        label: `My ${sharedContentConstants.entityPlural}`,
        title: `My ${sharedContentConstants.entityPlural}`,
        icon: "user",
        align: "left",
        xPos: 1,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: { ownerIds: session?.person.id ? [session.person.id] : null },
          sidebarFilters: {},
        },
      },
    }),
    [session?.person.id]
  );
  return { defaults, tabStoreDefaults };
};

export const useSharedContentsTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"sharedContents">) => {
  const columns = useMemo(
    () =>
      [
        {
          id: "default-id",
          Header: fieldLabels.id,
          accessor: (row) => (
            <span style={{ color: "var(--gray-400)" }}>
              <samp>{row.id}</samp>
            </span>
          ),
          width: 155,
          minWidth: 155,
          align: "right",
          sortingFn: (id) => {
            if (sort.headerId === id) {
              if (sort.sortDirection === "ASC") {
                setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
              } else {
                setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
              }
            } else {
              setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
            }
          },
          sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
        },
        {
          id: "default-name",
          Header: fieldLabels.name,
          accessor: (row) => (
            <div className={`${styles.container_ellipsis} gap-5`}>
              <div className={`${styles.container_ellipsis}`}>
                <span className={styles.label_highlight}>
                  <LucideIcon name={entityConstants.icon} color={"var(--primary)"} /> {row.name}
                </span>
              </div>
            </div>
          ),
          minWidth: 150,
          width: 250,
          align: "left",
          sortingFn: (id) => {
            if (sort.headerId === id) {
              if (sort.sortDirection === "ASC") {
                setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
              } else {
                setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
              }
            } else {
              setSort({ headerId: id, sortDirection: "ASC", orderBy: "NAME_ASC" });
            }
          },
          sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
        },
        {
          id: "default-owner",
          Header: fieldLabels.owner,
          accessor: (row) => <GetPersons persons={row.owner} createLinks={false} />,
          minWidth: 150,
          width: 250,
          align: "left",
          sortingFn: (id) => {
            if (sort.headerId === id) {
              if (sort.sortDirection === "ASC") {
                setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "OWNER_DESC" }));
              } else {
                setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "OWNER_ASC" }));
              }
            } else {
              setSort({ headerId: id, sortDirection: "ASC", orderBy: "OWNER_ASC" });
            }
          },
          sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
        },
        {
          id: "default-createdAt",
          Header: fieldLabels.createdAt,
          accessor: (row) => <DateTimeRenderer date={row.createdAt} includeElapsed={false} />,
          minWidth: 170,
          width: 170,
          align: "left",
          sortingFn: (id) => {
            if (sort.headerId === id) {
              if (sort.sortDirection === "ASC") {
                setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATION_DATE_DESC" }));
              } else {
                setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATION_DATE_ASC" }));
              }
            } else {
              setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATION_DATE_ASC" });
            }
          },
          sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
        },
        {
          id: "default-pubnotes",
          Header: fieldLabels.publicNotes,
          accessor: (row) => (
            <div className={`${styles.container_ellipsis} gap-5`}>
              <div className={`${styles.container_ellipsis}`}>
                <span>{row.publicNotes ? row.publicNotes : NotSet}</span>
              </div>
            </div>
          ),
          minWidth: 150,
          width: 250,
          align: "left",
        },
        {
          id: "default-privnotes",
          Header: fieldLabels.privateNotes,
          accessor: (row) => (
            <div className={`${styles.container_ellipsis} gap-5`}>
              <div className={`${styles.container_ellipsis}`}>
                <span>{row.privateNotes ? row.privateNotes : NotSet}</span>
              </div>
            </div>
          ),
          minWidth: 150,
          width: 250,
          align: "left",
        },
        {
          id: "default-password",
          Header: fieldLabels.password,
          accessor: (row) => (
            <>{row.password ? <Status type="success">Enabled</Status> : <Status type="warning">Disabled</Status>}</>
          ),
          minWidth: 183,
          width: 183,
          align: "left",
        },
        {
          id: "default-contentDownload",
          Header: fieldLabels.allowContentDownload,
          accessor: (row) => (
            <>
              {row.allowContentDownload ? (
                <Status type="success">Enabled</Status>
              ) : (
                <Status type="warning">Disabled</Status>
              )}
            </>
          ),
          minWidth: 183,
          width: 183,
          align: "left",
        },
        {
          id: "default-datasets",
          Header: fieldLabels.datasets,
          accessor: (row) => <span className="badge">{row.datasets.length}</span>,
          minWidth: 100,
          width: 100,
          align: "left",
        },
      ] as GenericVirtualizedTableCells<SharedContent>,
    [
      entityConstants.icon,
      fieldLabels.allowContentDownload,
      fieldLabels.createdAt,
      fieldLabels.datasets,
      fieldLabels.id,
      fieldLabels.name,
      fieldLabels.owner,
      fieldLabels.password,
      fieldLabels.privateNotes,
      fieldLabels.publicNotes,
      setSort,
      sort.headerId,
      sort.sortDirection,
    ]
  );
  return { columns };
};
export const SharedContentsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"sharedContents">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync, isLoadingBulkDeleteMutation } = entityApi;

  const { defaults, tabStoreDefaults } = useSharedContentsTableDefaults({ fieldLabels });

  const { selection, resultsCount, onCountChange, onSelectionChange, selectionPermissions } =
    useGenericVirtualizedTable<SharedContent>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    customTabs,
    fixedTabs,
    temporaryTabs,
    dispatchTabStore,
    currentTab,
    tabsLoading,
    tabsModified,
  } = useTabStore<SharedContent, SharedContentFilters, {}>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue, onTabChange } = useGenericVirtualizedTableTabs({
    currentTab,
    tabsLoading,
    filters,
    switchSortState: switchSharedContentDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useSharedContentsTableColumns({ fieldLabels, entityConstants, sort, setSort });

  const memoizedFilters = useMemo(
    () => ({ ...filters, ...forcedFilters, includeRelations: true }),
    [filters, forcedFilters]
  );

  return (
    <SinglePage>
      <SinglePage.Head>
        <SinglePage.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </SinglePage.Head.Label>
        <SinglePage.Head.Controls>
          <button
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            disabled={!permissions.canCreate}
            onClick={() => history.push(routes.getAddRoute)}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </button>
        </SinglePage.Head.Controls>
      </SinglePage.Head>
      <SinglePage.Content style={{ background: "transparent", borderWidth: 0 }}>
        <EntityTableTabs
          currentTab={currentTab}
          onTabChange={onTabChange}
          temporaryTabs={temporaryTabs}
          fixedTabs={fixedTabs}
          filters={filters}
          sidebarFilters={sidebarFilters}
          columnSetting={columnSetting}
          columnWidths={columnWidths}
          customTabs={customTabs}
          dispatchTabStore={dispatchTabStore}
          tabsLoading={tabsLoading}
          tabsModified={tabsModified}
          showAddTab={false}
        />
        <EntityTable>
          <EntityTable.Controls
            style={{
              borderTop: "0px",
              borderRadius: "0px",
            }}
          >
            <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
            <EntityTableEditButton
              entityConstants={entityConstants}
              routes={routes}
              permissions={permissions}
              selection={selection}
              selectionPermissions={selectionPermissions}
            />
            <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
              <li>
                <EntityTableCloneButton
                  entityConstants={entityConstants}
                  routes={routes}
                  permissions={permissions}
                  selection={selection}
                />
              </li>
              <li>
                <EntityTableDeleteButton
                  entityConstants={entityConstants}
                  permissions={permissions}
                  selection={selection}
                  selectionPermissions={selectionPermissions}
                  onClick={() => setShowModal(true)}
                />
              </li>
            </MoreDropdown>
            <AlertModal
              type="danger"
              showModal={showModal}
              setShowModal={setShowModal}
              title={`${
                selection.size === 0
                  ? `Select ${entityConstants.entitySingular} to delete`
                  : selection.size === 1
                  ? `Delete the selected ${entityConstants.entitySingular}`
                  : `Delete the selected ${entityConstants.entityPlural}`
              }`}
              description="Proceeding will permanently delete the selected project tags."
              proceedLabel="Delete"
              onProceed={async () => {
                await bulkDeleteMutationAsync({
                  ids: Array.from(selection),
                  goBackOnSuccess: false,
                  showToast: false,
                  entityName: entityConstants.entityPlural,
                  params: { deletePermanently: true },
                }).catch((e) => {});
                functionRef.current?.resetSelection();
                setShowModal(false);
              }}
              loading={isLoadingBulkDeleteMutation}
            />
          </EntityTable.Controls>
          <EntityTable.Body<SharedContent, SharedContentFilters>
            functionRef={functionRef}
            entityConstants={entityConstants}
            filters={memoizedFilters}
            columns={columns}
            columnSelect
            columnSetting={columnSetting}
            columnWidths={columnWidths}
            defaultColumnSettings={defaults}
            dispatchTabStore={dispatchTabStore}
            setResultsCount={onCountChange}
            onSelectionChange={onSelectionChange}
            loading={tabsLoading}
            showPermissionColumn
          />
        </EntityTable>
      </SinglePage.Content>
    </SinglePage>
  );
};
