import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { DefinedPropertyObject, GenericEntity, StringIndexedDict } from "../../../api/GenericTypes";
import { UseTabActions } from "../../tables/Tabs/TableTabsTypes";
import { DefaultValues, useForm } from "react-hook-form";

// TabStore FilterSideBar custom hooks
// Common interface to be used for Entity Tables Sidebar Filter component

export interface TabStoreFilterSidebarProps<
  Entity extends GenericEntity,
  FormInterface extends StringIndexedDict,
  Filters extends StringIndexedDict
> {
  initialValues?: FormInterface;
  onFormChange?: (formValues: FormInterface) => void; // Optional callback to retrieve form values
  dispatchTabStore?: (action: UseTabActions<Entity, Filters, FormInterface>) => Promise<void>; // Dispatcher for TabsStore
  tabsLoading?: boolean; // TabsStore loading state
  currentTab?: string; // TabsStore currentTab
  hideReset?: boolean; // Hide reset button
  setFilters?: React.Dispatch<React.SetStateAction<Filters>>; // Optional stateSetter
}

interface UseTabStoreFilterSidebarProps<
  Entity extends GenericEntity,
  FormInterface extends StringIndexedDict,
  EntityFilters extends StringIndexedDict
> {
  initialValues?: FormInterface;
  defaultValues: DefinedPropertyObject<FormInterface>;
  defaultFilters?: EntityFilters;
  formToFilters: (values: FormInterface) => EntityFilters;
  onFormChange?: TabStoreFilterSidebarProps<Entity, FormInterface, EntityFilters>["onFormChange"];
  dispatchTabStore?: TabStoreFilterSidebarProps<Entity, FormInterface, EntityFilters>["dispatchTabStore"];
  setFilters?: TabStoreFilterSidebarProps<Entity, FormInterface, EntityFilters>["setFilters"];
  tabsLoading?: boolean;
}
/**
 * Custom hook to manage the state and behavior of a filter sidebar with tabs.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @template FormInterface - The type of the form interface.
 * @template EntityFilters - The type of the entity filters.
 *
 * @param {Object} props - The properties for the hook.
 * @param {FormInterface} props.initialValues - The initial values for the form.
 * @param {FormInterface} props.defaultValues - The default values for the form.
 * @param {EntityFilters} props.defaultFilters - The default filters for the entity.
 * @param {(form: FormInterface) => EntityFilters} props.formToFilters - Function to convert form values to filters.
 * @param {Function} props.dispatchTabStore - Function to dispatch actions to the tab store.
 * @param {(value: any) => void} [props.onFormChange] - Optional callback function to handle form changes.
 * @param {boolean} props.tabsLoading - Flag indicating if the tabs are loading.
 *
 * @returns {Object} - The hook returns an object containing:
 *   - `register`: Function to register form inputs.
 *   - `control`: Control object for the form.
 *   - `reset`: Function to reset the form.
 *   - `watch`: Function to watch form values.
 *   - `onReset`: Function to handle form reset.
 */
export const useTabStoreFilterSidebar = <
  Entity extends GenericEntity,
  FormInterface extends StringIndexedDict,
  EntityFilters extends StringIndexedDict
>({
  initialValues,
  defaultValues,
  defaultFilters,
  formToFilters,
  dispatchTabStore,
  onFormChange,
  setFilters,
  tabsLoading,
}: UseTabStoreFilterSidebarProps<Entity, FormInterface, EntityFilters>) => {
  const initialForm = useMemo(() => initialValues, [initialValues]);
  const values = useSidebarValues({ defaultValues, initialValues: initialForm });

  const { register, control, reset, watch } = useForm<FormInterface>({
    defaultValues: defaultValues as DefaultValues<FormInterface>,
    values: values,
  });

  // TODO this needs to run before restoring the tab state from history
  // useEffect(() => {
  //   if (!tabsLoading) {
  //     (async () =>
  //       await dispatchTabStore?.({
  //         type: "setTab",
  //         options: { keepPrevious: true },
  //         payload: {
  //           settings: {
  //             sidebarFilters: {
  //               ...(defaultValues as FormInterface),
  //             },
  //             filters: formToFilters(defaultValues),
  //           },
  //         },
  //       }))();
  //   }
  // }, [defaultValues, dispatchTabStore, formToFilters, tabsLoading]);

  useEffect(() => {
    const subscription = watch(async (value: any) => {
      onFormChange?.(value);
      setFilters?.((prev) => ({ ...prev, ...formToFilters(value as FormInterface) }));
      if (!tabsLoading) {
        await dispatchTabStore?.({
          type: "setTab",
          options: { keepPrevious: true },
          payload: {
            settings: {
              sidebarFilters: {
                ...(value as FormInterface),
              },
              filters: formToFilters(value),
            },
          },
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [dispatchTabStore, watch, formToFilters, onFormChange, tabsLoading, setFilters]);

  const onReset = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      reset(defaultValues);
      await dispatchTabStore?.({
        type: "setTab",
        payload: {
          settings: {
            sidebarFilters: defaultValues,
            filters: defaultFilters
              ? { ...formToFilters(defaultValues), ...defaultFilters }
              : formToFilters(defaultValues),
          },
        },
      });
    },
    [defaultFilters, defaultValues, dispatchTabStore, formToFilters, reset]
  );

  return { register, control, reset, watch, onReset };
};

// Common

// Hook for sidebar value updates
interface UseSidebarValuesProps<FormInterface extends StringIndexedDict> {
  defaultValues: DefinedPropertyObject<FormInterface>;
  initialValues?: FormInterface;
}
/**
 * Custom hook to merge default values with initial values for a sidebar form.
 * @author @CorradoSurmanowicz
 * @template FormInterface - The interface representing the form values.
 *
 * @param {UseSidebarValuesProps<FormInterface>} props - The properties for the hook.
 * @param {StringIndexedDict} props.defaultValues - The default values for the form.
 * @param {StringIndexedDict} [props.initialValues] - The initial values for the form, which will override the default values if provided.
 *
 * @returns {FormInterface} - The merged form values.
 */
export const useSidebarValues = <FormInterface extends StringIndexedDict>({
  defaultValues,
  initialValues,
}: UseSidebarValuesProps<FormInterface>) => {
  const values = useMemo(() => {
    return Object.fromEntries(
      Object.entries(defaultValues).map(([key, value]) => [key, initialValues?.[key] ?? value])
    ) as FormInterface;
  }, [defaultValues, initialValues]);

  return values;
};
