import { StringIndexedDict } from "../../../../api/GenericTypes";
import {
  GenericEntityVirtualizedEntitySelectFormFieldProps,
  GenericVirtualizedEntitySelectFormFieldControllerWrapper,
} from "../../../formfields/GenericVirtualizedSelectFormField/GenericVirtualizedEntitySelectFormFieldControllerWrapper";
// import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";

import { useContext } from "react";
import { SessionContext } from "../../../contexts/SessionContext";
import {
  InventoryItemSuggestions,
  InventoryItemFilters,
  InventoryItemSortingOptionsConsts,
  inventoryItemsConstants,
  inventoriesConstants,
} from "../../../../api/Inventories";
import { QuickAddWrapper } from "../../QuickAdd/common/QuickAddWrapper";
import { QuickAddInventoryItemForm } from "../../QuickAdd/forms/QuickAddInventoryItemForm";
import { CustomField } from "../../../../api/CustomFields";
import { CustomTypeConstraintWrapper } from "../../../hierarchy/common/CustomTypeConstraintWrapper";
import { LoadingWrapper } from "../../../LoadingWrapper";
import { InventoryItemSelectModal } from "../../../../Inventories/InventoryItemSelectModal";

interface InventoriesVirtualizedSelectFormProps<Entity extends StringIndexedDict>
  extends GenericEntityVirtualizedEntitySelectFormFieldProps<Entity, InventoryItemSuggestions, InventoryItemFilters> {
  customTypeConstraint?: CustomField["customTypeConstraint"];
  showBrowseButton?: boolean;
}

export const InventoriesVirtualizedSelectForm = <Entity extends StringIndexedDict>(
  props: InventoriesVirtualizedSelectFormProps<Entity>
) => {
  const { session } = useContext(SessionContext);
  // const disabled = props.disabled === undefined ? true : props.disabled;
  const prependChildren = props.prependChildren === undefined ? true : props.prependChildren;
  const showBrowseButton = props.showBrowseButton === undefined ? true : props.showBrowseButton;
  return (
    <GenericVirtualizedEntitySelectFormFieldControllerWrapper<Entity, InventoryItemSuggestions, InventoryItemFilters>
      {...props}
      filters={{ orderBy: "CREATED_ON_DESC", ...props.filters }}
      createModal={({ showModal, setShowModal, onCreate }) => (
        <QuickAddWrapper showModal={showModal} setShowModal={setShowModal} onCreate={onCreate}>
          {(quickAddProps) => (
            <QuickAddInventoryItemForm
              {...quickAddProps}
              initialValues={{
                ...quickAddProps.initialValues,
                ...(props.filters?.customTypeIds && {
                  customType: { name: "", id: props.filters?.customTypeIds?.[0] },
                }),
              }}
            />
          )}
        </QuickAddWrapper>
      )}
      entityConstants={inventoryItemsConstants}
      orderByOptions={InventoryItemSortingOptionsConsts}
      allowCreateEntity={session?.permissions.administer_custom_field_schemas && props.allowCreateEntity}
      disabled={props.disabled} // Disable the form to prevent the user from changing the value; User must use button
      prependChildren={prependChildren}
    >
      {({ value, onChange }) => (
        <>
          {showBrowseButton && (
            <CustomTypeConstraintWrapper customTypeConstraints={props.customTypeConstraint}>
              {({ inventoryIds, constraintIds, status, fetchStatus, error }) => (
                <LoadingWrapper status={status} fetchStatus={fetchStatus} error={error}>
                  <InventoryItemSelectModal
                    hierarchyRootTypeIds={inventoryIds}
                    triggerComponent={({ setShowModal }) => (
                      <div className="btn btn-primary" onClick={() => setShowModal(true)}>
                        Browse {inventoriesConstants.entityPlural}
                      </div>
                    )}
                    onSelectCallback={onChange}
                    selectableCustomTypeIds={constraintIds}
                    isMulti={props.isMulti}
                    initialValues={value}
                  />
                </LoadingWrapper>
              )}
            </CustomTypeConstraintWrapper>
          )}
          {props.children}
        </>
      )}
    </GenericVirtualizedEntitySelectFormFieldControllerWrapper>
  );
};
