import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import {
  TableTabsStore,
  Tab,
  TableTabsDict,
  UseTabActions,
  TabCore,
  TabType,
  UserPreferencesTabStore,
} from "./TableTabsTypes";
import { useDeleteMutation, useGetMutation, usePostMutation } from "../../../api/BaseEntityApi";
import {
  UserPreferencesLocal,
  UserPreferencesLocalFilters,
  UserPreferencesGlobal,
  UserPreferencesGlobalFilters,
  UserPreferencesGlobalPayload,
  UserPreferencesLocalPayload,
} from "../../../api/UserPreferences";
import { ResourceName } from "../../../main/Routing";
import { GenericEntity, StringIndexedDict } from "../../../api/GenericTypes";
import { ColumnsSettings } from "../ColumnsSelector/ColumnsSelector";
import { useLocation } from "react-router-dom";
import { TabStoreHistoryState, generateTabStoreHistoryState } from "./history/TabStoreHistory";

// TabStore

const debug = false;
const diff_debug = false;
const consolidateTab = <Entity extends GenericEntity, Filters, SidebarForm>(
  tab: Tab<Entity, Filters, SidebarForm> | Partial<Tab<Entity, Filters, SidebarForm>>
) => {
  let _tab = { ...tab };
  _tab.settings = _tab.settings ? { ..._tab.settings } : {};
  _tab.settings.columnSettings = _tab.settings.columnSettings ? _tab.settings.columnSettings : {};
  _tab.settings.columnWidths = _tab.settings.columnWidths ? _tab.settings.columnWidths : {};
  _tab.settings.filters = _tab.settings.filters ? _tab.settings.filters : ({} as Filters);
  _tab.settings.sidebarFilters = _tab.settings.sidebarFilters ? _tab.settings.sidebarFilters : ({} as SidebarForm);

  if (_tab.forcedSettings && !!Object.keys(_tab.forcedSettings).length) {
    if (_tab.forcedSettings.columnSettings) {
      _tab.settings.columnSettings = { ..._tab.settings.columnSettings, ..._tab.forcedSettings.columnSettings };
    }
    if (_tab.forcedSettings.columnWidths) {
      _tab.settings.columnWidths = { ..._tab.settings.columnWidths, ..._tab.forcedSettings.columnWidths };
    }
    // if (_tab.forcedSettings.filters) {
    //   _tab.settings.filters = { ..._tab.settings.filters, ..._tab.forcedSettings.filters };
    // }
    if (_tab.forcedSettings.sidebarFilters) {
      _tab.settings.sidebarFilters = { ..._tab.settings.sidebarFilters, ..._tab.forcedSettings.sidebarFilters };
    }
  } else {
    _tab = tab;
  }

  // if(debug) console.log("Consolidate", _tab.settings);
  return _tab as Tab<Entity, Filters, SidebarForm>;
};

const applyPayload = <
  Entity extends GenericEntity,
  Filters extends StringIndexedDict<any>,
  SidebarForm extends StringIndexedDict<any>
>(
  prevTab: Tab<Entity, Filters, SidebarForm>,
  payload: Partial<Tab<Entity, Filters, SidebarForm>>,
  keepPrevious: boolean = false
) => {
  let changes: boolean[] = [];
  const _tab = { ...prevTab };
  _tab.settings = _tab.settings ? { ..._tab.settings } : {};

  if (payload.label && _tab.label !== payload.label) {
    changes.push(true);
    _tab.label = payload.label;
  }
  if (payload.title && _tab.title !== payload.title) {
    changes.push(true);
    _tab.title = payload.title;
  }
  if (payload.align && _tab.align !== payload.align) {
    changes.push(true);
    _tab.align = payload.align;
  }
  if (payload.icon) {
    changes.push(true);
    _tab.icon = payload.icon;
  }

  if (payload.settings?.columnSettings) {
    if (needsUpdate(payload.settings.columnSettings, _tab.settings.columnSettings)) {
      changes.push(true);
      if (keepPrevious) {
        _tab.settings.columnSettings = {
          ...(prevTab.settings?.columnSettings ?? {}),
          ...payload.settings?.columnSettings,
        };
      } else {
        _tab.settings.columnSettings = { ...payload.settings?.columnSettings };
      }
    } else {
      if (debug) console.log("No payload update needed for column settings");
    }
  }
  if (payload.settings?.columnWidths) {
    if (needsUpdate(payload.settings.columnWidths, _tab.settings.columnWidths)) {
      changes.push(true);
      if (keepPrevious) {
        _tab.settings.columnWidths = { ...(prevTab.settings?.columnWidths ?? {}), ...payload.settings?.columnWidths };
      } else {
        _tab.settings.columnWidths = { ...payload.settings.columnWidths };
      }
    } else {
      if (debug) console.log("No payload update needed for columnWidths");
    }
  }
  if (payload.settings?.filters) {
    if (needsUpdate(payload.settings.filters, _tab.settings.filters)) {
      changes.push(true);
      if (keepPrevious) {
        _tab.settings.filters = { ...(prevTab.settings?.filters ?? {}), ...payload.settings?.filters };
      } else {
        _tab.settings.filters = { ...payload.settings?.filters };
      }
    } else {
      if (debug) console.log("No payload update needed for filters");
    }
  }
  if (payload.settings?.sidebarFilters) {
    if (needsUpdate(payload.settings.sidebarFilters, _tab.settings.sidebarFilters)) {
      changes.push(true);
      if (keepPrevious) {
        _tab.settings.sidebarFilters = {
          ...(prevTab.settings?.sidebarFilters ?? {}),
          ...payload.settings?.sidebarFilters,
        };
      } else {
        _tab.settings.sidebarFilters = { ...payload.settings?.sidebarFilters };
      }
    } else {
      if (debug) console.log("No payload update needed for sidebar filters");
    }
  }

  if (changes.some((d) => d === true)) {
    return _tab;
  } else {
    return prevTab;
  }
};
const eqSet = (xs: Set<string>, ys: Set<string>) => xs.size === ys.size && Array.from(xs).every((x) => ys.has(x));
// const isObj = (x: any) => typeof x === "object" && !Array.isArray(x) && x !== null;
const isObjOrArray = (x: any) => x !== null && (typeof x === "object" || Array.isArray(x));
const setDifference = (a: Set<string>, b: Set<string>) => {
  return new Set(Array.from(a).filter((item) => !b.has(item)));
};

export const needsUpdate = (A?: any, B?: any): boolean => {
  if (A === B) return false;
  if (A === undefined && B === undefined) return false;
  if (A === null && B === null) return false;
  if (typeof A !== typeof B) {
    if (diff_debug) console.log("Type difference", A, B);
    return true;
  }
  if (A === null && isObjOrArray(B)) {
    if (diff_debug) console.log("Null vs Object", A, B);
    return true;
  }
  if (B === null && isObjOrArray(A)) {
    if (diff_debug) console.log("Null vs Object", A, B);
    return true;
  }
  if (typeof A === "string" && typeof B === "string" && A !== B) {
    if (diff_debug) console.log("String difference", A, B);
    return true;
  }
  if (typeof A === "number" && typeof B === "number" && A !== B) {
    if (diff_debug) console.log("Number difference", A, B);
    return true;
  }
  if (typeof A === "boolean" && typeof B === "boolean" && A !== B) {
    if (diff_debug) console.log("Bool difference", A, B);
    return true;
  }

  if (Array.isArray(A) && Array.isArray(B)) {
    if (A.length !== B.length) {
      if (diff_debug) console.log("Array length difference", A, B);
      return true;
    } else {
      if (A.map((a, i) => needsUpdate(a, B[i])).some((t) => t === true)) return true;
      // for (let index = 0; index < A.length; index++) {
      //   return needsUpdate(A[index], B[index]);
      // }
    }
  }
  if (typeof A === "object" && typeof B === "object") {
    if (A instanceof Date && B instanceof Date && A.toISOString() !== B.toISOString()) {
      console.log("Date difference", A, B);
      return true;
    }

    const keysA = new Set(Object.getOwnPropertyNames(A));
    const keysB = new Set(Object.getOwnPropertyNames(B));
    if (!eqSet(keysA, keysB)) {
      if (diff_debug)
        console.log("Object key difference", setDifference(keysA, keysB), setDifference(keysB, keysA), A, B);
      return true;
    }
    if (
      Array.from(keysA)
        .map((key) => needsUpdate(A[key], B[key]))
        .some((t) => t === true)
    )
      return true;

    // for (const key in keysA) {

    //   return needsUpdate(A[key], B[key]);
    // }
  }
  // if (diff_debug) console.log("Returning default", A, B);
  return false;
};

// A custom hook to save handle GenericVirtualized Table settings states. Optional: synch to local storage in an ordered manner by resource -> tab
interface UseTabStoreProps<
  Entity extends GenericEntity,
  Filters extends StringIndexedDict,
  SidebarForm extends StringIndexedDict
> {
  resource: string;
  defaults?: TableTabsDict<Entity, Filters, SidebarForm>;
  enable?: boolean;
  keyOverride?: string;
  initialState?: TableTabsStore<Entity, Filters, SidebarForm>;
  restoreFromHistory?: boolean;
}
/**
 * Custom hook to manage tab states and user preferences for a table component.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @template Filters - The type of the filters.
 * @template SidebarForm - The type of the sidebar form.
 *
 * @param {Object} props - The properties for the hook.
 * @param {string} props.resource - The resource name.
 * @param {TableTabsStore<Entity, Filters, SidebarForm>} props.defaults - The default tab settings.
 * @param {boolean} [props.enable=true] - Flag to enable or disable the tab store.
 * @param {string} [props.keyOverride] - Optional key to override the default key.
 * @param {TableTabsStore<Entity, Filters, SidebarForm>} [props.initialState] - The initial state of the tab store.
 * @param {boolean} [props.restoreFromHistory=true] - Flag to restore tab state from history.
 *
 * @returns {Object} The tab store and related functions.
 * @returns {ColumnsSettings<Entity>} columnSetting - The column settings for the current tab.
 * @returns {Object} columnWidths - The column widths for the current tab.
 * @returns {Filters} filters - The filters for the current tab.
 * @returns {Filters} forcedFilters - The forced filters for the current tab.
 * @returns {SidebarForm} sidebarFilters - The sidebar filters for the current tab.
 * @returns {TabCore[]} customTabs - The custom tabs.
 * @returns {TabCore[]} fixedTabs - The fixed tabs.
 * @returns {React.MutableRefObject<TableTabsStore<Entity, Filters, SidebarForm>>} tabsStore - The reference to the tab store.
 * @returns {Function} dispatchTabStore - The function to dispatch actions to the tab store.
 * @returns {string} currentTab - The current tab ID.
 * @returns {boolean} tabsLoading - The loading state of the tabs.
 * @returns {boolean} tabsModified - The modified state of the tabs.
 */
export const useTabStore = <
  Entity extends GenericEntity,
  Filters extends StringIndexedDict,
  SidebarForm extends StringIndexedDict
>({
  resource,
  defaults,
  enable = true,
  keyOverride,
  initialState,
  restoreFromHistory = true,
}: UseTabStoreProps<Entity, Filters, SidebarForm>) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const tabsLoading = enable ? isLoading : false;
  const key = useMemo(() => keyOverride || `tableTabs-${resource}`, [keyOverride, resource]);
  const _initialState: TableTabsStore<Entity, Filters, SidebarForm> = useMemo(
    () => ({
      currentTabId: "default",
      tabs: {
        default: {
          tabId: "default",
          label: "All",
          align: "left",
          icon: "house",
          title: "All",
          xPos: 0,
          type: "fixed",
          settings: {},
          forcedSettings: {},
        },
      },
    }),
    []
  );
  const hasInit = useRef(false);
  const hasInitSuccessfully = useRef(false);
  const hasInitHistoryState = useRef(false);
  const [, forceUpdate] = useState(false);

  const tabsStore = useRef<TableTabsStore<Entity, Filters, SidebarForm>>(
    initialState ? initialState : { ..._initialState }
  );
  const userPreferencesTabsStoreRef = useRef<UserPreferencesTabStore<Entity, Filters, SidebarForm>>();
  const globalUserPreferencesTabsRef = useRef<StringIndexedDict<Tab<Entity, Filters, SidebarForm>>>();

  // We overwrite the actual type since we use a specialized endpoint
  const { mutateAsync: userPreferencesGetMutationAsync } = useGetMutation<
    UserPreferencesLocal<Entity, Filters, SidebarForm>,
    UserPreferencesLocalFilters
  >(`users_preferences/key` as ResourceName);
  const { mutateAsync: userPreferencesGlobalGetMutationAsync } = useGetMutation<
    UserPreferencesGlobal<Entity, Filters, SidebarForm>,
    UserPreferencesGlobalFilters
  >(`users_preferences_global/key` as ResourceName);

  const { mutateAsync: userPreferencesDeleteMutation } = useDeleteMutation("users_preferences");
  const { mutateAsync: globalUserPreferencesDeleteMutation } = useDeleteMutation("users_preferences_global");
  const { mutateAsync: userPreferencesCreateOrEditMutation } = usePostMutation<
    UserPreferencesLocal<Entity, Filters, SidebarForm>,
    UserPreferencesLocalPayload<Entity, Filters, SidebarForm>
  >({
    resource: `users_preferences/write/${key}` as ResourceName,
  });
  const { mutateAsync: userPreferencesGlobalCreateOrEditMutation } = usePostMutation<
    UserPreferencesGlobal<Entity, Filters, SidebarForm>,
    UserPreferencesGlobalPayload<Entity, Filters, SidebarForm>
  >({
    resource: `users_preferences_global/write/${key}` as ResourceName,
  });

  // Handler
  const restoreHistory = useCallback(() => {
    // check for history state
    const historyState: TabStoreHistoryState<Filters, SidebarForm> = window.history.state;
    // console.log("History state", historyState);
    if (
      historyState &&
      Object.hasOwn(historyState, "tabStore") &&
      historyState.tabStore &&
      Object.hasOwn(historyState.tabStore, key)
    ) {
      tabsStore.current = {
        ...tabsStore.current,
        tabs: {
          ...tabsStore.current.tabs,
          [tabsStore.current.currentTabId]: {
            ...tabsStore.current.tabs[tabsStore.current.currentTabId],
            settings: {
              ...(tabsStore.current.tabs[tabsStore.current.currentTabId]?.settings ?? {}),
              ...historyState.tabStore[key],
            },
          },
        },
      };
      // console.log("Restored history state", historyState);
    }
    hasInitHistoryState.current = true;
    forceUpdate((prev) => !prev);
  }, [key]);

  const init = useCallback(async () => {
    if (debug) console.group(`Initializing Tabs Key: ${key}`);
    setIsLoading(true);

    // Local user preferences
    if (debug) console.group("--> Fetching User Preferences...");
    let _store: TableTabsStore<Entity, Filters, SidebarForm> = initialState ?? _initialState;
    // Write defaults
    _store = {
      ..._store,
      tabs: {
        ..._store.tabs,
        ...(defaults ? defaults : {}),
      },
    };
    if (enable) {
      await userPreferencesGetMutationAsync(
        { id: key as unknown as number },
        {
          onSuccess: (response) => {
            if (response) {
              if (debug) console.log("--> Fetched Tabs from API");
              const payload = response.payload;
              userPreferencesTabsStoreRef.current = payload;
              _store = {
                currentTabId: payload.currentTabId,
                tabs: {
                  ...payload.tabs,
                  ..._store.tabs,
                },
              };
            } else {
              if (debug) console.log("--> No Tabs from API");
            }
          },
        }
      ).catch((e) => {
        console.error(e);
      });

      // Global user preferences
      if (debug) console.groupEnd();
      if (debug) console.group("--> Fetching Global User Preferences...");
      await userPreferencesGlobalGetMutationAsync(
        { id: key as unknown as number },
        {
          onSuccess: (response) => {
            if (response) {
              if (debug) console.log("--> Fetched Global Tabs from API");
              const payload = response.payload;
              globalUserPreferencesTabsRef.current = payload;
              _store = {
                ..._store,
                tabs: {
                  ..._store.tabs,
                  ...payload,
                },
              };
            } else {
              if (debug) console.log("--> No Global Tabs from API");
            }
          },
        }
      ).catch((e) => {
        console.error(e);
      });
    }

    // Dispatch
    if (debug) console.groupEnd();

    tabsStore.current = _store;
    userPreferencesTabsStoreRef.current = {
      currentTabId: _store.currentTabId,
      tabs: Object.fromEntries(Object.entries(_store.tabs).filter(([, tab]) => tab.type === "custom")),
    };
    globalUserPreferencesTabsRef.current = Object.fromEntries(
      Object.entries(_store.tabs).filter(([, tab]) => tab.type === "global")
    );
    restoreHistory();
    hasInitSuccessfully.current = true;
    setIsLoading(false);
    forceUpdate((prev) => !prev);
    return Promise.resolve();
  }, [
    _initialState,
    defaults,
    enable,
    initialState,
    key,
    restoreHistory,
    userPreferencesGetMutationAsync,
    userPreferencesGlobalGetMutationAsync,
  ]);

  const reset = useCallback(async () => {
    setIsLoading(true);
    tabsStore.current = initialState ?? _initialState;
    userPreferencesTabsStoreRef.current = undefined;
    globalUserPreferencesTabsRef.current = undefined;

    if (debug) console.groupEnd();
    if (debug) console.log("Resetting...");
    await userPreferencesGetMutationAsync(
      { id: key as unknown as number },
      {
        onSuccess: async (res) => {
          if (res) await userPreferencesDeleteMutation({ id: res.id }).catch((e) => console.error(e));
        },
      }
    ).catch((e) => console.error(e));

    await userPreferencesGlobalGetMutationAsync(
      { id: key as unknown as number },
      {
        onSuccess: async (res) => {
          if (res) await globalUserPreferencesDeleteMutation({ id: res.id }).catch((e) => console.error(e));
        },
      }
    ).catch((e) => console.error(e));

    await init();
    return Promise.resolve();
  }, [
    _initialState,
    globalUserPreferencesDeleteMutation,
    init,
    initialState,
    key,
    userPreferencesDeleteMutation,
    userPreferencesGetMutationAsync,
    userPreferencesGlobalGetMutationAsync,
  ]);

  const synchCurrentTabId = useCallback(
    async (currentTabId: string) => {
      if (debug) console.log("[Start] Synching current tab Id", currentTabId);
      return await userPreferencesCreateOrEditMutation(
        {
          body: {
            stringId: key,
            payload: {
              ...(userPreferencesTabsStoreRef.current ?? {
                tabs: {},
              }),
              currentTabId: currentTabId,
            },
          },
        },
        {
          onSuccess: (response) => {
            const payload = response.payload;
            userPreferencesTabsStoreRef.current = payload;
            if (debug) console.log("[Finished] Synching current tab Id", currentTabId);
          },
        }
      );
    },
    [key, userPreferencesCreateOrEditMutation]
  );
  const createUserPreferencesTab = useCallback(
    async (tab: Tab<Entity, Filters, SidebarForm>) => {
      if (tab.type === "custom") {
        if (debug) console.log("[Start] Creating userPreferences tab...", tab);
        if (userPreferencesTabsStoreRef.current) {
          let update = {
            ...userPreferencesTabsStoreRef.current,
            currentTabId: tab.tabId,
            tabs: {
              ...userPreferencesTabsStoreRef.current.tabs,
              [tab.tabId]: {
                ...tab,
              },
            },
          };
          return await userPreferencesCreateOrEditMutation(
            { body: { stringId: key, payload: update } },
            {
              onSuccess: (response) => {
                userPreferencesTabsStoreRef.current = response.payload;
                if (debug) console.log("[Finished] Creating userPreferences tab...", response.payload);
              },
            }
          );
        }
      } else {
        console.log(`Skipping creation of tab of type: ${tab.type} with ID: ${tab.tabId}`);
        return Promise.resolve(null);
      }
    },
    [key, userPreferencesCreateOrEditMutation]
  );

  const synchUserPreferencesTab = useCallback(
    async (tab: Tab<Entity, Filters, SidebarForm>) => {
      if (tab.type === "custom") {
        if (debug) console.log("[Start] Synching userPreferences tab...", tab);
        if (userPreferencesTabsStoreRef.current) {
          let update = {
            ...userPreferencesTabsStoreRef.current,
            tabs: {
              ...userPreferencesTabsStoreRef.current.tabs,
              [tab.tabId]: {
                ...tab,
              },
            },
          };
          return await userPreferencesCreateOrEditMutation(
            { body: { stringId: key, payload: update } },
            {
              onSuccess: (response) => {
                userPreferencesTabsStoreRef.current = response.payload;
                if (debug) console.log("[Finished] Synching userPreferences tab...", response.payload);
              },
            }
          );
        }
      } else {
        console.log(`Skipping synchronizing of tab of type: ${tab.type} with ID: ${tab.tabId}`);
        return Promise.resolve(null);
      }
    },
    [key, userPreferencesCreateOrEditMutation]
  );

  const synchDeleteUserPreferencesTab = useCallback(
    async (tabId: string, currentTabId: string) => {
      if (debug) console.log("[Start] Synching deletion of userPreferences tab Id", tabId);
      if (userPreferencesTabsStoreRef.current) {
        const update = {
          ...userPreferencesTabsStoreRef.current,
          currentTabId: currentTabId,
          tabs: Object.fromEntries(
            Object.entries(userPreferencesTabsStoreRef.current.tabs).filter(([id]) => id !== tabId)
          ),
        };
        return await userPreferencesCreateOrEditMutation(
          { body: { stringId: key, payload: update } },
          {
            onSuccess: (response) => {
              const payload = response.payload;
              userPreferencesTabsStoreRef.current = payload;
              if (debug) console.log("[Finished] Synching deletion of userPreferences tab Id", tabId);
            },
          }
        );
      }
    },
    [key, userPreferencesCreateOrEditMutation]
  );

  const synchUserPreferencesTabPositions = useCallback(
    async (xPositions: number[], tabIds: string[]) => {
      if (debug) console.log("[Start] Synching positions of userPreferences tabs...");
      if (userPreferencesTabsStoreRef.current) {
        const update = {
          ...userPreferencesTabsStoreRef.current,
          tabs: {
            ...userPreferencesTabsStoreRef.current.tabs,
            ...(Object.fromEntries(
              tabIds.map((tabId, index) => [
                [tabId],
                {
                  ...(userPreferencesTabsStoreRef.current?.tabs[tabId] ?? {}),
                  xPos: xPositions[index],
                },
              ])
            ) as TableTabsDict<Entity, Filters, SidebarForm>),
          },
        };
        return await userPreferencesCreateOrEditMutation(
          { body: { stringId: key, payload: update } },
          {
            onSuccess: (response) => {
              userPreferencesTabsStoreRef.current = response.payload;
              if (debug) console.log("[Finished] Synching positions of userPreferences tabs...");
            },
          }
        );
      }
    },
    [key, userPreferencesCreateOrEditMutation]
  );

  // const debouncedsynchUserPreferencesTabPositions = useDebounceAsyncVoidFunction(synchUserPreferencesTabPositions, 100);

  const synchUserPreferencesGlobalTab = useCallback(
    async (tab: Tab<Entity, Filters, SidebarForm>) => {
      if (debug) console.log("[Start] Synching global userPreferences tab...");
      if (tab.type === "global") {
        const update = {
          ...(globalUserPreferencesTabsRef.current ?? {}),
          [tab.tabId]: tab,
        };
        return await userPreferencesGlobalCreateOrEditMutation(
          { body: { stringId: key, payload: update } },
          {
            onSuccess: (response) => {
              const payload = response.payload as TableTabsDict<Entity, Filters, SidebarForm>;
              globalUserPreferencesTabsRef.current = payload;
              if (debug) console.log("[Finished] Synching global userPreferences tab...");
            },
          }
        );
      } else {
        if (debug) console.log(`Ignoring synchronization of global tab of type: ${tab.type} with ID: ${tab.tabId}`);
        if (debug) console.log("[Finished] Synching global userPreferences tab...");
        return Promise.resolve();
        // Promise.reject(`Synchronizing of global tab of type: ${tab.type} with ID: ${tab.tabId} is not allowed `);
      }
    },
    [key, userPreferencesGlobalCreateOrEditMutation]
  );

  const synchPinTab = useCallback(
    async (tab: Tab<Entity, Filters, SidebarForm>) => {
      let finished = false;
      if (tab.type === "custom") {
        if (debug) console.log("[Start] Pinning userPreferences tab...");
        // PIN
        const update = {
          ...(globalUserPreferencesTabsRef.current ?? {}),
          [tab.tabId]: {
            ...tab,
            icon: undefined,
            // xPos: undefined,
          },
        };
        // We move the tab to global user preferences
        await userPreferencesGlobalCreateOrEditMutation(
          { body: { stringId: key, payload: update } },
          {
            onSuccess: (response) => {
              const payload = response.payload;
              globalUserPreferencesTabsRef.current = payload;
              if (debug) console.log("[Finished 1/2] Pinning userPreferences tab...");
            },
          }
        );
        // We remove the tab from local user preferences
        if (userPreferencesTabsStoreRef.current) {
          await userPreferencesCreateOrEditMutation(
            {
              body: {
                stringId: key,
                payload: {
                  ...userPreferencesTabsStoreRef.current,
                  currentTabId: tab.tabId,
                  tabs: Object.fromEntries(
                    Object.entries(userPreferencesTabsStoreRef.current.tabs).filter(([tabId]) => tabId !== tab.tabId)
                  ),
                },
              },
            },
            {
              onSuccess: (response) => {
                const payload = response.payload;
                userPreferencesTabsStoreRef.current = payload;
                if (debug) console.log("[Finished 2/2] Pinning userPreferences tab...");
              },
            }
          );
          finished = true;
        } else {
          if (debug) console.log("Unpin error - user preferences undefined");
          finished = true;
        }
      } else if (tab.type === "global") {
        if (debug) console.log("[Start] Unpinning global userPreferences tab...");
        // UNPIN
        if (userPreferencesTabsStoreRef.current) {
          // We move the tab to local user preferences
          const update = {
            currentTabId: userPreferencesTabsStoreRef.current.currentTabId,
            tabs: {
              ...userPreferencesTabsStoreRef.current.tabs,
              [tab.tabId]: { ...tab, type: "custom" as TabType, xPos: 9999 },
            },
          };
          await userPreferencesCreateOrEditMutation(
            {
              body: {
                stringId: key,
                payload: update,
              },
            },
            {
              onSuccess: (response) => {
                const payload = response.payload;
                userPreferencesTabsStoreRef.current = payload;
                if (debug) console.log("[Finished 1/2] Unpinning global userPreferences tab...");
              },
            }
          );
          // We remove the tab from global user preferences
          if (globalUserPreferencesTabsRef.current) {
            await userPreferencesGlobalCreateOrEditMutation(
              {
                body: {
                  stringId: key,
                  payload: Object.fromEntries(
                    Object.entries(globalUserPreferencesTabsRef.current).filter(([tabId]) => tabId !== tab.tabId)
                  ),
                },
              },
              {
                onSuccess: (response) => {
                  const payload = response.payload;
                  globalUserPreferencesTabsRef.current = payload;
                  if (debug) console.log("[Finished 2/2] Unpinning global userPreferences tab...");
                },
              }
            );
            finished = true;
          } else {
            if (debug) console.log("Unpin error - global user preferences undefined");
            finished = true;
          }
        } else {
          if (debug) console.log("Unpin error - user preferences undefined");
          finished = true;
        }
      } else {
        console.error(`Pinning of tab of type: ${tab.type} with ID: ${tab.tabId} is not allowed `);
        finished = true;
      }
      return Promise.resolve(finished);
    },
    [key, userPreferencesCreateOrEditMutation, userPreferencesGlobalCreateOrEditMutation]
  );

  const dispatchTabStore = useCallback(
    async (action: UseTabActions<Entity, Filters, SidebarForm>): Promise<void> => {
      if (!hasInitSuccessfully.current || !hasInitHistoryState.current) {
        console.error("[!] Aborting dispatch, init unfinished!", action);
        return Promise.resolve();
      }
      if (debug) console.groupEnd();
      if (debug) console.group("Dispatch", action);
      switch (action.type) {
        case "reset": {
          await reset();
          forceUpdate((prev) => !prev);
          return Promise.resolve();
          // return {} as TableTabsStore<Filters, SidebarForm>;
        }
        case "saveChanges":
          if (enable) {
            const currentTab = { ...tabsStore.current.tabs[tabsStore.current.currentTabId] };
            if (currentTab.type === "custom") {
              if (debug) console.log("Synchronizing custom tab");
              await synchUserPreferencesTab(currentTab);
            } else if (currentTab.type === "global") {
              if (debug) console.log("Synchronizing global tab");
              await synchUserPreferencesGlobalTab(currentTab);
            } else {
              console.error(
                `Synchronizing of tab of type: ${currentTab.type} with ID: ${currentTab.tabId} is not allowed `
              );
            }
          }
          forceUpdate((prev) => !prev);
          return Promise.resolve();
        case "createTab":
          const currentTab = consolidateTab(action.payload);
          if (enable && currentTab.type === "custom") {
            const tab = await createUserPreferencesTab(currentTab);
            tabsStore.current = {
              ...tabsStore.current,
              currentTabId: action.payload.tabId,
              tabs: {
                ...tabsStore.current.tabs,
                ...tab?.payload.tabs,
              },
            };
          } else {
            tabsStore.current = {
              ...tabsStore.current,
              currentTabId: action.payload.tabId,
              tabs: {
                ...tabsStore.current.tabs,
                [action.payload.tabId]: {
                  ...currentTab,
                },
              },
            };
          }
          forceUpdate((prev) => !prev);
          // if (enable) await dispatchTabStore({ type: "setCurrentTab", tabId: action.payload.tabId });
          return Promise.resolve();
        case "setTab":
          if (tabsStore.current.tabs?.[tabsStore.current.currentTabId]) {
            const prevTab = tabsStore.current.tabs[tabsStore.current.currentTabId];
            const newTab = consolidateTab(applyPayload(prevTab, action.payload, !!action.options?.keepPrevious));
            if (needsUpdate(prevTab, newTab)) {
              // const temporary = ["global", "fixed"].includes(prevTab.type);
              if (debug) console.log("--> State update [Tab]: ", prevTab.tabId);
              tabsStore.current = {
                ...tabsStore.current,
                tabs: {
                  ...tabsStore.current.tabs,
                  [prevTab.tabId]: {
                    ...prevTab,
                    ...newTab,
                    tabId: prevTab.tabId,
                  },
                },
              };
              if (debug) console.log("--> State updated [Tab]: ", tabsStore.current.tabs[prevTab.tabId]);

              forceUpdate((prev) => !prev);
            } else {
              if (debug) console.log("--> No state update");
            }
          } else {
            if (debug) console.log(`Cannot set unknown TabId: ${tabsStore.current.currentTabId}. Returning default`);
          }
          return Promise.resolve();
        case "pinTab":
          let finished = false;
          if (tabsStore.current.tabs.hasOwnProperty(action.tabId)) {
            if (enable) {
              finished = await synchPinTab(tabsStore.current.tabs[action.tabId]);
            } else {
              finished = true;
            }
            if (finished)
              tabsStore.current = {
                ...tabsStore.current,
                currentTabId: action.tabId,
                tabs: {
                  ...tabsStore.current.tabs,
                  [action.tabId]: {
                    ...tabsStore.current.tabs[action.tabId],
                    icon: null,
                    type: (tabsStore.current.tabs[action.tabId].type === "custom" ? "global" : "custom") as TabType,
                    xPos:
                      tabsStore.current.tabs[action.tabId].type === "custom"
                        ? Object.values(tabsStore.current.tabs).filter((t) => ["fixed", "global"].includes(t.type))
                            .length + 1
                        : 9999,
                  },
                },
              };
            if (enable) await synchUserPreferencesGlobalTab(tabsStore.current.tabs[action.tabId]);
          }
          forceUpdate((prev) => !prev);
          return Promise.resolve();
        case "setCurrentTab":
          if (tabsStore.current.tabs.hasOwnProperty(action.tabId)) {
            if (enable && ["fixed", "global", "custom"].includes(tabsStore.current.tabs[action.tabId].type))
              await synchCurrentTabId(action.tabId);
            tabsStore.current = {
              ...tabsStore.current,
              currentTabId: action.tabId,
            };
            forceUpdate((prev) => !prev);
          } else {
            console.error(`Cannot switch to unknown TabId ${action.tabId}`);
          }
          return Promise.resolve();
        case "updateTabPositions":
          const prevTabsXpos = action.tabIds.map((id) => tabsStore.current.tabs?.[id]?.xPos ?? 0);
          if (!action.xPos.every((e, i) => prevTabsXpos[i] === e)) {
            if (enable) await synchUserPreferencesTabPositions(action.xPos, action.tabIds);
            tabsStore.current = {
              ...tabsStore.current,
              currentTabId: tabsStore.current.currentTabId,
              tabs: {
                ...tabsStore.current.tabs,
                ...Object.fromEntries(
                  action.tabIds.map((tabId, index) => [
                    [tabId],
                    {
                      ...(tabsStore.current.tabs[tabId] ?? {}),
                      xPos: action.xPos[index],
                    },
                  ])
                ),
              },
            };

            forceUpdate((prev) => !prev);
          } else {
            if (debug) console.log("No position update needed");
          }
          return Promise.resolve();
        case "deleteTab":
          let currentTabId = "default";
          // We jump to the previous tab as a new current tab if available
          const tabIds = Object.entries(tabsStore.current.tabs)
            .filter(([, t]) => t.type === "custom")
            .sort(([, a], [, b]) => (a.xPos !== undefined && b.xPos !== undefined ? (a.xPos > b.xPos ? 1 : -1) : 0))
            .map(([id]) => id);
          const deletedTabIndex = tabIds.findIndex((id) => id === action.tabId);
          if (deletedTabIndex !== -1 && deletedTabIndex > 1) {
            currentTabId = tabIds[deletedTabIndex - 1];
          }
          if (enable) await synchDeleteUserPreferencesTab(action.tabId, currentTabId);
          tabsStore.current = {
            ...tabsStore.current,
            currentTabId: currentTabId,
            tabs: Object.fromEntries(
              Object.entries(tabsStore.current.tabs).filter(([tabId]) => tabId !== action.tabId)
            ) as TableTabsDict<Entity, Filters, SidebarForm>,
          };
          forceUpdate((prev) => !prev);
          return Promise.resolve();
        default:
          forceUpdate((prev) => !prev);
          return Promise.resolve();
      }
    },
    [
      enable,
      synchDeleteUserPreferencesTab,
      reset,
      synchUserPreferencesTab,
      synchUserPreferencesGlobalTab,
      createUserPreferencesTab,
      synchCurrentTabId,
      synchPinTab,
      synchUserPreferencesTabPositions,
    ]
  );

  useLayoutEffect(() => {
    if (!hasInit.current) {
      hasInit.current = true;
      (async () => await init())();
    }
    return () => {
      if (debug) console.log("[TABS] Unmount --> Cleanup");
      if (debug) console.groupEnd();
      tabsStore.current = initialState ?? _initialState;
      userPreferencesTabsStoreRef.current = undefined;
      globalUserPreferencesTabsRef.current = undefined;
      hasInit.current = false;
      hasInitSuccessfully.current = false;
      hasInitHistoryState.current = false;
      // dispatchTabStore({ type: "reset" });
    };
  }, [_initialState, init, initialState]);

  const currentTab = useMemo(
    () =>
      Object.hasOwn(tabsStore.current.tabs, tabsStore.current.currentTabId)
        ? tabsStore.current.currentTabId
        : "default",

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabsStore.current]
  );

  const tabsModified = useMemo(() => {
    if (!hasInitSuccessfully.current) return false;
    if (!hasInit.current) return false;
    const tabA = tabsStore.current.tabs?.[currentTab] ?? {};
    const store = {
      ...userPreferencesTabsStoreRef.current,
      tabs: {
        ...(userPreferencesTabsStoreRef.current?.tabs || {}),
        ...(globalUserPreferencesTabsRef.current || {}),
      },
    };
    const tabB = store.tabs?.[currentTab] ?? {};
    const isModified = needsUpdate(tabA, tabB);
    if (debug) console.log("Checking diff... Is tab modified? = ", isModified);
    return isModified;
    // return tabIsModified(JSON.parse(JSON.stringify(tabA)), JSON.parse(JSON.stringify(tabB)));
    // return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, tabsStore.current, userPreferencesTabsStoreRef.current, globalUserPreferencesTabsRef.current]);

  const columnSetting = useMemo(
    () => (tabsStore.current.tabs?.[currentTab]?.settings?.columnSettings ?? {}) as ColumnsSettings<Entity>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab, tabsStore.current.tabs?.[currentTab]?.settings?.columnSettings]
  );

  const columnWidths = useMemo(
    () => tabsStore.current.tabs?.[currentTab]?.settings?.columnWidths ?? {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab, tabsStore.current.tabs?.[currentTab]?.settings?.columnWidths]
  );

  const filters = useMemo(
    () => tabsStore.current.tabs?.[currentTab]?.settings?.filters ?? ({} as Filters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab, tabsStore.current.tabs?.[currentTab]?.settings?.filters]
  );

  const forcedFilters = useMemo(
    () => tabsStore.current.tabs?.[currentTab]?.forcedSettings?.filters ?? ({} as Filters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab, tabsStore.current.tabs?.[currentTab]?.forcedSettings?.filters]
  );

  const sidebarFilters = useMemo(
    () => tabsStore.current.tabs?.[currentTab]?.settings?.sidebarFilters ?? ({} as SidebarForm),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab, tabsStore.current.tabs?.[currentTab]?.settings?.sidebarFilters]
  );

  const fixedTabs: TabCore[] = useMemo(() => {
    if (tabsStore.current?.tabs) {
      return Object.entries(tabsStore.current?.tabs)
        .filter(([, tab]) => ["fixed", "global"].includes(tab.type))
        .map(([tabId, tab]) => ({
          tabId: tabId,
          label: tab.label,
          title: tab.title,
          icon: tab.icon,
          align: tab.align,
          xPos: tab.xPos,
          type: tab.type,
        }))
        .sort((a, b) =>
          a.xPos === b.xPos ? 0 : a.xPos !== undefined && b.xPos !== undefined ? (a.xPos > b.xPos ? 1 : -1) : 0
        );
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsStore.current]);

  const customTabs: TabCore[] = useMemo(() => {
    if (tabsStore.current?.tabs) {
      return Object.entries(tabsStore.current.tabs)
        .filter(([, tab]) => tab.type === "custom")
        .map(([tabId, tab]) => ({
          tabId: tabId,
          label: tab.label,
          title: tab.title,
          icon: tab.icon,
          align: tab.align,
          xPos: tab.xPos,
          type: tab.type,
        }))
        .sort((a, b) =>
          a.xPos === b.xPos ? 0 : a.xPos !== undefined && b.xPos !== undefined ? (a.xPos > b.xPos ? 1 : -1) : 0
        );
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsStore.current]);

  const temporaryTabs: TabCore[] = useMemo(() => {
    if (tabsStore.current?.tabs) {
      return Object.entries(tabsStore.current.tabs)
        .filter(([, tab]) => tab.type === "temporary")
        .map(([tabId, tab]) => ({
          tabId: tabId,
          label: tab.label,
          title: tab.title,
          icon: tab.icon,
          align: tab.align,
          xPos: tab.xPos,
          type: tab.type,
        }))
        .sort((a, b) =>
          a.xPos === b.xPos ? 0 : a.xPos !== undefined && b.xPos !== undefined ? (a.xPos > b.xPos ? 1 : -1) : 0
        );
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsStore.current]);

  // Hook to handle location state
  useEffect(() => {
    if (restoreFromHistory) {
      if (!tabsLoading) {
        const historyState = location.state as TabStoreHistoryState<Filters, SidebarForm>;
        // console.log("location state", historyState);
        if (historyState && Object.hasOwn(historyState, "tabStore")) {
          const history_filters: Filters | undefined = historyState.tabStore?.[key]?.filters;
          const history_sidebarFilters: SidebarForm | undefined = historyState.tabStore?.[key]?.sidebarFilters;
          if (debug) console.log("History", history_filters, history_sidebarFilters);
          dispatchTabStore({
            type: "createTab",
            payload: {
              tabId: "temp",
              align: "left",
              label: "",
              title: "Filtered selection",
              icon: "filter",
              type: "temporary",
              settings: {
                filters: history_filters,
                sidebarFilters: history_sidebarFilters,
              },
            },
          });
        }
      }
    }
  }, [dispatchTabStore, key, location.state, restoreFromHistory, tabsLoading]);

  useEffect(() => {
    if (!tabsLoading) {
      window.history.replaceState(
        {
          ...window.history.state,
          tabStore: {
            ...window.history.state?.tabStore,
            ...generateTabStoreHistoryState(key, filters, sidebarFilters),
          },
        } as TabStoreHistoryState<Filters, SidebarForm>,
        ""
      );
    }
  }, [filters, key, sidebarFilters, tabsLoading]);

  return {
    columnSetting,
    columnWidths,
    filters,
    forcedFilters,
    sidebarFilters,
    customTabs,
    fixedTabs,
    temporaryTabs,
    tabsStore,
    dispatchTabStore,
    currentTab: currentTab,
    tabsLoading,
    tabsModified,
  };
};
