import { EquipmentTag, EquipmentTagFilters } from "../../api/EquipmentTag";
import { DefinedPropertyObject, IEntityMinimalModel } from "../../api/GenericTypes";
import {
  TabStoreFilterSidebarProps,
  useTabStoreFilterSidebar,
} from "../../common/sidebar/filtersidebar/useTabStoreFilterSidebar";
import { EquipmentsVirtualizedSelectForm } from "../../common/forms/EntityForms/formsVirtualized/EquipmentsVirtualizedSelectForm";
import { EntityFilterSideBar } from "../../common/sidebar/filtersidebar/EntityFilterBar";
import { SplitDatePickerFormField } from "../../common/formfields/DatePickerFormField/SplitDatePickerFormField";

export interface EquipmentTagFilterForm {
  createdByIds?: IEntityMinimalModel[] | undefined | null;
  createdFrom?: Date | undefined | null;
  createdTo?: Date | undefined | null;
  modifiedByIds?: IEntityMinimalModel[] | undefined | null;
  modifiedFrom?: Date | undefined | null;
  modifiedTo?: Date | undefined | null;
}
const defaultValues: DefinedPropertyObject<EquipmentTagFilterForm> = {
  createdByIds: null,
  createdFrom: null,
  createdTo: null,
  modifiedByIds: null,
  modifiedFrom: null,
  modifiedTo: null,
};

export const equipmentTagsFormToFilters = (form: EquipmentTagFilterForm): EquipmentTagFilters => {
  let filters: EquipmentTagFilters = {};
  filters.createdByIds =
    Array.isArray(form.createdByIds) && form.createdByIds.length > 0 ? form.createdByIds?.map((d) => d.id) : null;
  filters.createdFrom = form.createdFrom ? form.createdFrom.toISOString() : null;
  filters.createdTo = form.createdTo ? form.createdTo.toISOString() : null;
  filters.modifiedByIds =
    Array.isArray(form.modifiedByIds) && form.modifiedByIds.length > 0 ? form.modifiedByIds?.map((d) => d.id) : null;
  filters.modifiedFrom = form.modifiedFrom ? form.modifiedFrom.toISOString() : null;
  filters.modifiedTo = form.modifiedTo ? form.modifiedTo.toISOString() : null;

  return filters;
};

/**
 * Converts EquipmentTagFilters to EquipmentTagFilterForm
 * @param filters EquipmentTagFilters
 * @returns EquipmentTagFilterForm
 * @author @CorradoSurmanowicz
 */
export const filtersToEquipmentTagsForm = (filters: EquipmentTagFilters): EquipmentTagFilterForm => {
  return {
    createdByIds: filters.createdByIds ? filters.createdByIds.map((id) => ({ id, name: "" })) : null,
    createdFrom: filters.createdFrom ? new Date(filters.createdFrom) : null,
    createdTo: filters.createdTo ? new Date(filters.createdTo) : null,
    modifiedByIds: filters.modifiedByIds ? filters.modifiedByIds.map((id) => ({ id, name: "" })) : null,
    modifiedFrom: filters.modifiedFrom ? new Date(filters.modifiedFrom) : null,
    modifiedTo: filters.modifiedTo ? new Date(filters.modifiedTo) : null,
  };
};

const defaultFilters: EquipmentTagFilters = { orderBy: "NAME_ASC" };

export const EquipmentTagFilterBar = ({
  initialValues,
  dispatchTabStore,
  onFormChange,
  tabsLoading,
  currentTab,
}: TabStoreFilterSidebarProps<EquipmentTag, EquipmentTagFilterForm, EquipmentTagFilters>) => {
  const { control, onReset } = useTabStoreFilterSidebar({
    tabsLoading,
    initialValues,
    defaultValues,
    defaultFilters,
    formToFilters: equipmentTagsFormToFilters,
    onFormChange,
    dispatchTabStore,
  });

  return (
    <EntityFilterSideBar onReset={onReset}>
      {currentTab !== "mydata" && (
        <EquipmentsVirtualizedSelectForm id="createdByIds" label="Created by" control={control} isMulti />
      )}
      <SplitDatePickerFormField
        control={control}
        idStart="createdFrom"
        idEnd="createdTo"
        label="Creation date (from - to)"
        horizontal={false}
        showTimeSelect
      />
      <EquipmentsVirtualizedSelectForm id="modifiedByIds" label="Modified by" control={control} isMulti />

      <SplitDatePickerFormField
        control={control}
        idStart="modifiedFrom"
        idEnd="modifiedTo"
        label="Modification date (from - to)"
        horizontal={false}
        showTimeSelect
      />
    </EntityFilterSideBar>
  );
};
