import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./EntityTableTabs.module.css";
import { v4 as uuidv4 } from "uuid";
import { TabNewProps, TabProps } from "./EntityTableTabsTypes";
import { ColumnWidthSettings, TabCore, Tabs, UseTabActions } from "./TableTabsTypes";
import { LucideIcon } from "../../icon/LucideIcon";
import { AlertModal } from "../../modals/AlertModal/AlertModal";
import { ColumnsSettings } from "../ColumnsSelector/ColumnsSelector";
import GridLayout from "react-grid-layout";
import { useResizeDetector } from "react-resize-detector";
import { useHorizontalScroll } from "../../helperfunctions/useHorizontalScroll";
import { useDebounceVoidFunction } from "../../helperfunctions/useDebounceVoidFunction";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import { SessionContext } from "../../contexts/SessionContext";
import { useDebouncedValue } from "../../helperfunctions/useDebouncedValue";
import { GenericEntity, StringIndexedDict } from "../../../api/GenericTypes";

/* eslint-disable jsx-a11y/anchor-is-valid */

// Constants
const regExp = new RegExp("^[a-zA-Z0-9/\\s\\-_\\\\]*$", "u");
const validationMsg = "Only alphanumeric characters, spaces, underscores and hyphens allowed.";

// Helper Components

const LoadingTabs = () => (
  <div className="flex row-nowrap align-center" style={{ width: "100%" }}>
    <ul className={`${styles.navTabs} nav nav-tabs`} style={{ width: "fit-content" }}>
      <li className={`${styles.tab} ${styles.newTab} skeleton-block`} style={{ width: "100px", marginRight: "2px" }} />
      <li
        className={`${styles.tab} ${styles.newTab} skeleton-block second`}
        style={{ width: "100px", marginRight: "2px" }}
      />
      <li
        className={`${styles.tab} ${styles.newTab} skeleton-block third`}
        style={{ width: "100px", marginRight: "2px" }}
      />
    </ul>
  </div>
);

// Custom hook to callback RGL parent with calculated height in grid units
const useTabWidth = (tabId: string, nodeWidthCallback?: (tabId: string, width: number) => void) => {
  const { ref } = useResizeDetector();
  const width = ref.current?.getBoundingClientRect().width;
  useEffect(() => {
    if (width && nodeWidthCallback) {
      const w = Math.ceil(width + 0 + 1); // width, padding, margin
      nodeWidthCallback(tabId, w);
    }
  }, [nodeWidthCallback, tabId, width]);
  return { ref };
};

// Main
export interface EntityTableTabsProps<
  Entity extends GenericEntity,
  Filters extends StringIndexedDict,
  SidebarForm extends StringIndexedDict
> {
  fixedTabs?: TabCore[];
  customTabs: TabCore[];
  temporaryTabs?: TabCore[];
  currentTab: string;
  onTabChange: (tabId: string) => Promise<void>;
  columnSetting?: ColumnsSettings<Entity>;
  columnWidths?: ColumnWidthSettings;
  filters?: Filters;
  sidebarFilters?: SidebarForm;
  dispatchTabStore: (action: UseTabActions<Entity, Filters, SidebarForm>) => Promise<void>;
  tabsLoading: boolean;
  tabsModified: boolean;
  showAddTab?: boolean;
  showReset?: boolean;
}
/**
 * EntityTableTabs component renders a tabbed interface for displaying entities with customizable tabs.
 * @author @CorradoSurmanowicz
 * @template Entity - The type of the entity.
 * @template Filters - The type of the filters.
 * @template SidebarForm - The type of the sidebar form.
 *
 * @param {Object} props - The properties object.
 * @param {Tabs} props.fixedTabs - The fixed tabs to be displayed.
 * @param {Tabs} props.customTabs - The custom tabs to be displayed.
 * @param {Tabs} props.temporaryTabs - The temporary tabs to be displayed.
 * @param {string} props.currentTab - The ID of the currently selected tab.
 * @param {Function} props.onTabChange - Callback function to handle tab change.
 * @param {Object} props.columnSetting - The column settings for the table.
 * @param {Object} props.columnWidths - The column widths for the table.
 * @param {Filters} props.filters - The filters applied to the table.
 * @param {SidebarForm} props.sidebarFilters - The sidebar filters applied to the table.
 * @param {Function} props.dispatchTabStore - Function to dispatch actions to the tab store.
 * @param {boolean} props.tabsLoading - Flag indicating if the tabs are loading.
 * @param {boolean} props.tabsModified - Flag indicating if the tabs have been modified.
 * @param {boolean} [props.showAddTab=true] - Flag indicating if the "Add Tab" button should be shown.
 * @param {boolean} [props.showReset=false] - Flag indicating if the "Reset Tab" button should be shown.
 *
 *
 * @returns {JSX.Element} The rendered EntityTableTabs component.
 */
export const EntityTableTabs = <
  Entity extends GenericEntity,
  Filters extends StringIndexedDict,
  SidebarForm extends StringIndexedDict
>({
  fixedTabs,
  customTabs,
  temporaryTabs,
  currentTab,
  onTabChange,
  columnSetting,
  columnWidths,
  filters,
  sidebarFilters,
  dispatchTabStore,
  tabsLoading,
  tabsModified,
  showAddTab = true,
  showReset = true,
}: EntityTableTabsProps<Entity, Filters, SidebarForm>) => {
  const minOverflowWidth = 200;
  const _fixedTabs: Tabs = useMemo(() => fixedTabs ?? [], [fixedTabs]);
  const _tempTabs: Tabs = useMemo(() => temporaryTabs ?? [], [temporaryTabs]);

  const { session } = useContext(SessionContext);
  const [tabWidths, setTabWidths] = useState<Map<string, number>>(new Map());
  const [layout, setLayout] = useState<ReactGridLayout.Layout[]>([]);
  const { ref: rglWrapperRef, width: rglWrapperWidth } = useResizeDetector();
  const { ref: scrollableTabsRef, width: scrollableTabsWidth } = useResizeDetector();
  const { ref: tabContainerRef, width: tabContainerWidth } = useResizeDetector();
  const { ref: fixedTabsRef, width: fixedTabsWidth } = useResizeDetector();
  const { ref: tempTabsRef, width: tempTabsWidth } = useResizeDetector();

  const { ref: navTabsRightRef, width: navTabsRightWidth } = useResizeDetector();

  const isScrolling = useRef<boolean>(false);
  const currentFrame = useRef<number>(0);
  const setMove = useCallback((state: boolean) => (isScrolling.current = state), []);
  const [mask, setMask] = useState<CSSProperties["mask"]>("none");
  const [canScroll, setCanScroll] = useState<{ left: boolean; right: boolean }>({ left: true, right: true });
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const onWheelCallback = useCallback(() => {
    cancelAnimationFrame(currentFrame.current);
  }, []);

  const { ref: scrollRef, node: scrollNode } = useHorizontalScroll<HTMLDivElement>(onWheelCallback);

  const rglTabWidth = useMemo(
    () =>
      layout
        ? Array.from(layout)
            .map((l) => l.w)
            .reduce((pv, cv) => pv + cv + 2, 0)
        : 0,
    [layout]
  );
  const isOverflowing = useMemo(
    () => (rglWrapperWidth ? rglTabWidth > rglWrapperWidth : false),
    [rglTabWidth, rglWrapperWidth]
  );

  useMemo(() => {
    if (!tabsLoading && Array.isArray(customTabs) && !!customTabs.length) {
      const customTabLayout = customTabs.map((tab, index) => ({
        i: tab.tabId,
        x: index,
        y: 0,
        w: tabWidths.get(tab.tabId) ?? 1,
        h: 1,
        isDraggable: true,
        isResizable: false,
        // static: true,
      }));
      setLayout(customTabLayout);
    }
  }, [customTabs, tabWidths, tabsLoading]);

  useEffect(() => {
    return () => {
      setLayout([]);
      setTabWidths(new Map());
    };
  }, []);

  const scrollTo = useCallback(
    (tabId: string) => {
      if (scrollNode) {
        const xPos = layout?.find((e) => e.i === tabId)?.x;
        if (xPos !== undefined) {
          // console.log("Scrolling to..", tabId, xPos);
          scrollNode.scrollTo({ left: xPos, behavior: "auto" });
        }
      }
    },
    [layout, scrollNode]
  );

  const scrollToDebounced = useDebounceVoidFunction(scrollTo, 500);

  useEffect(() => {
    scrollToDebounced(currentTab);
  }, [currentTab, scrollToDebounced]);

  const onSelected = useCallback(
    async (tabId: string) => {
      // console.log("Selecting", tabId);
      // setCurrentTabId(tabId);
      await onTabChange?.(tabId);
      scrollTo(tabId);
    },
    [onTabChange, scrollTo]
  );

  const onReset = useCallback(async () => {
    await dispatchTabStore({ type: "reset" });
  }, [dispatchTabStore]);

  const onEdit = useCallback(
    async (tab: TabCore) => {
      // setTabs((prev) => new Map([...Array.from(prev), [tab.tabId, tab]]));
      await dispatchTabStore({
        type: "setTab",
        payload: {
          // tabId: tab.tabId,
          label: tab.label,
          title: tab.label,
          type: tab.type,
          align: "left",
          settings: {
            filters: filters,
            sidebarFilters: sidebarFilters,
            columnSettings: columnSetting,
            columnWidths: columnWidths,
          },
          forcedSettings: {},
        },
      });
      // setCurrentTabId(tab.tabId);
      onSelected(tab.tabId);
    },
    [columnSetting, columnWidths, dispatchTabStore, filters, onSelected, sidebarFilters]
  );

  const onCreateTab = useCallback(
    async (tab: TabCore) => {
      await dispatchTabStore({
        type: "createTab",
        payload: {
          tabId: tab.tabId,
          label: tab.label,
          title: tab.label,
          type: tab.type,
          align: "left",
          xPos: 99999,
          icon: null,
          settings: {
            filters: filters,
            sidebarFilters: sidebarFilters,
            columnSettings: columnSetting,
            columnWidths: columnWidths,
          },
          forcedSettings: {},
        },
      });
    },
    [columnSetting, columnWidths, dispatchTabStore, filters, sidebarFilters]
  );

  const onSave = useCallback(async () => {
    await dispatchTabStore({ type: "saveChanges" });
  }, [dispatchTabStore]);

  const onFavorite = useCallback(
    async (tabId: string) => {
      await dispatchTabStore({ type: "pinTab", tabId: tabId });
    },
    [dispatchTabStore]
  );

  const onDelete = useCallback(
    async (tabId: string) => {
      // console.log("DELETING", tabId);
      // setTabs((prev) => new Map([...Array.from(prev).filter(([_tabId, tab]) => _tabId !== tabId)]));
      await dispatchTabStore({ type: "deleteTab", tabId: tabId });
    },
    [dispatchTabStore]
  );

  const onPositionChange = useCallback(
    async (layout: ReactGridLayout.Layout[]) => {
      await dispatchTabStore({
        type: "updateTabPositions",
        tabIds: layout.map((l) => l.i),
        xPos: layout.map((l) => l.x),
      });
    },
    [dispatchTabStore]
  );

  const onPositionChangeDebounced = useDebounceVoidFunction(onPositionChange, 300);

  const onLayoutChange = useCallback(
    async (layout: ReactGridLayout.Layout[]) => {
      if (!tabsLoading) {
        setLayout(layout);
        onPositionChangeDebounced(layout);
      }
    },
    [onPositionChangeDebounced, tabsLoading]
  );

  // This callback will dynamically adjust the layout based on the width of the tabs
  const nodeWidthCallback = useCallback((tabId: string, width: number) => {
    // console.log("Component reported width: ", width, " with TabId: ", tabId);
    setLayout((prev) => {
      const _prev = [...prev];
      const idx = prev.findIndex((e) => e.i === tabId);
      if (idx > -1) {
        if (prev[idx].w !== width) {
          _prev[idx] = { ..._prev[idx], w: width };
          return _prev;
        } else {
          return prev;
        }
      } else {
        return prev;
      }
    });
    // setTabs((prev) => {
    //   const prevValue = prev.get(tabId);
    //   if (prevValue && prevValue.xPos !== width) prev.set(tabId, { ...prevValue, xPos: width });
    //   return prev;
    // });
    setTabWidths((prev) => {
      prev.set(tabId, width);
      return prev;
    });
  }, []);

  const scrollLeft = useCallback(() => {
    if (scrollNode && isScrolling.current) {
      scrollNode.scrollTo({ left: scrollNode.scrollLeft - 5 });
      // scrollNode.scrollLeft = scrollNode.scrollLeft - 5;
      currentFrame.current = requestAnimationFrame(scrollLeft);
    }
  }, [scrollNode]);

  const scrollRight = useCallback(() => {
    if (scrollNode && isScrolling.current) {
      scrollNode.scrollTo({ left: scrollNode.scrollLeft + 5 });
      // scrollNode.scrollLeft = scrollNode.scrollLeft + 5;
      currentFrame.current = requestAnimationFrame(scrollRight);
    }
  }, [scrollNode]);

  const getMask = useCallback(() => {
    if (isOverflowing) {
      if (scrollNode) {
        if (scrollNode.scrollLeft === 0) {
          setCanScroll({ left: false, right: true });
          setMask(
            "linear-gradient(90deg,rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 1) 5%,rgba(255, 255, 255, 1) 95%,rgba(255, 255, 255, 0.3) 100%)"
          );
        } else if (scrollNode.scrollWidth - scrollNode.scrollLeft === scrollNode.clientWidth) {
          setCanScroll({ left: true, right: false });
          setMask(
            "linear-gradient(90deg,rgba(255, 255, 255, 0.3) 0%,rgba(255, 255, 255, 1) 5%,rgba(255, 255, 255, 1) 95%,rgba(255, 255, 255, 1) 100%)"
          );
        } else {
          setCanScroll({ left: true, right: true });
          setMask(
            "linear-gradient(90deg,rgba(255, 255, 255, 0.3) 0%,rgba(255, 255, 255, 1) 5%,rgba(255, 255, 255, 1) 95%,rgba(255, 255, 255, 0.3) 100%)"
          );
        }
      } else {
        setCanScroll({ left: true, right: true });
        setMask("none");
      }
    } else {
      setCanScroll({ left: true, right: true });
      setMask("none");
    }
  }, [isOverflowing, scrollNode]);

  useEffect(() => {
    getMask();
  }, [getMask]);

  const isTabsCutoff = useMemo(() => {
    const diff =
      (tabContainerWidth ?? 0) -
      (fixedTabsWidth ?? 0) -
      (tempTabsWidth ?? 0) -
      (scrollableTabsWidth ?? 0) -
      (navTabsRightWidth ?? 0);
    // console.log("DIFF", diff);
    return diff < 0;
  }, [tabContainerWidth, fixedTabsWidth, tempTabsWidth, scrollableTabsWidth, navTabsRightWidth]);

  const debouncedIsTabsCutoff = useDebouncedValue(isTabsCutoff, 300);

  //  Not used
  //   <ul className={`${styles.navTabs} nav nav-tabs`} style={{ width: "fit-content" }}>
  //   <TabDropdown
  //     tabs={customTabs}
  //     currentTabId={currentTab}
  //     onSelected={onSelected}
  //     onSave={onSave}
  //     onEdit={onEdit}
  //     onDelete={onDelete}
  //     onFavorite={onFavorite}
  //     tabModified={tabsModified}
  //     session={session}
  //   />
  // </ul>

  const customTabsWidth = customTabs.map((t, i) => tabWidths.get(t.tabId) ?? i).reduce((pv, cv, i) => pv + cv, 0);
  return (
    <div
      className={styles.tabContainer}
      ref={tabContainerRef}
      style={{
        overflowX: debouncedIsTabsCutoff ? "scroll" : "unset",
        overflowY: debouncedIsTabsCutoff ? "hidden" : "unset",
        // overflow: debouncedIsTabsCutoff ? "scroll" : "hidden",
      }}
    >
      {tabsLoading ? (
        <LoadingTabs />
      ) : (
        <>
          {Array.isArray(_tempTabs) && !!_tempTabs.length && (
            <ul ref={tempTabsRef} className={`${styles.navTabs} nav nav-tabs`} style={{ width: "fit-content" }}>
              {_tempTabs.map((tab, index) => (
                <TabFixed
                  tab={tab}
                  currentTabId={currentTab}
                  onSelected={onSelected}
                  onFavorite={onFavorite}
                  onSave={onSave}
                  tabModified={tabsModified}
                  key={`temp-left-${index}`}
                  session={session}
                  type="temporary"
                />
              ))}
            </ul>
          )}

          <ul ref={fixedTabsRef} className={`${styles.navTabs} nav nav-tabs`} style={{ width: "fit-content" }}>
            {_fixedTabs
              .filter((t) => t.align !== "right")
              .map((tab, index) => (
                <TabFixed
                  tab={tab}
                  currentTabId={currentTab}
                  onSelected={onSelected}
                  onFavorite={onFavorite}
                  onSave={onSave}
                  tabModified={tabsModified}
                  key={`fixed-left-${index}`}
                  session={session}
                />
              ))}
          </ul>

          <div
            className="flex row-nowrap align-center"
            style={{ width: "100%", gap: "2px", marginRight: "2px" }}
            ref={scrollableTabsRef}
          >
            <>
              {isOverflowing && (
                <div className="flex" style={{ height: "100%", width: "min-content" }}>
                  <button
                    className="btn btn-xs btn-soft-secondary"
                    onMouseDown={() => {
                      setMove(true);
                      cancelAnimationFrame(currentFrame.current);
                      scrollLeft();
                    }}
                    onMouseUp={() => {
                      setMove(false);
                    }}
                    disabled={!canScroll.left}
                  >
                    <LucideIcon name="arrow-left" />
                  </button>
                </div>
              )}
              {!!customTabs.length && (
                <div
                  className={`${styles.rglWrapper}`}
                  ref={rglWrapperRef}
                  style={{
                    mask: mask,
                    overflow: isOverflowing ? "visible" : "hidden",
                    minWidth: customTabsWidth > minOverflowWidth ? `${minOverflowWidth}px` : customTabsWidth + 2,
                  }}
                >
                  <div className={styles.rglFlexContainer} ref={scrollRef} onScroll={getMask}>
                    {!!rglWrapperWidth && (
                      <GridLayout
                        layout={layout}
                        maxRows={1}
                        cols={!!rglTabWidth ? rglTabWidth : rglWrapperWidth}
                        rowHeight={42}
                        preventCollision={false}
                        width={!!rglTabWidth ? rglTabWidth : rglWrapperWidth}
                        autoSize
                        compactType="horizontal"
                        onLayoutChange={async (layout) => await onLayoutChange(layout)}
                        margin={[2, 0]}
                        containerPadding={[0, 0]}
                        isBounded={false}
                        onDrag={() => setIsDragging(true)}
                        onDragStop={() => setTimeout(() => setIsDragging(false), 100)}
                        draggableHandle=".HandleDrag"
                      >
                        {customTabs.map((tab, index) => (
                          <div key={tab.tabId}>
                            <TabEditable
                              // key={`editable-${tab.tabId}`}
                              isDragging={isDragging}
                              tab={tab}
                              currentTabId={currentTab}
                              onSelected={onSelected}
                              onSave={onSave}
                              onEdit={onEdit}
                              onDelete={onDelete}
                              onFavorite={onFavorite}
                              tabModified={tabsModified}
                              nodeWidthCallback={nodeWidthCallback}
                              session={session}
                            />
                          </div>
                        ))}
                      </GridLayout>
                    )}
                  </div>
                </div>
              )}
              {isOverflowing && (
                <div className="flex" style={{ height: "100%", width: "min-content" }}>
                  <button
                    className="btn btn-xs btn-soft-secondary"
                    onMouseDown={() => {
                      setMove(true);
                      cancelAnimationFrame(currentFrame.current);
                      scrollRight();
                    }}
                    onMouseUp={() => {
                      setMove(false);
                    }}
                    disabled={!canScroll.right}
                  >
                    <LucideIcon name="arrow-right" />
                  </button>
                </div>
              )}
            </>
          </div>
          <ul ref={navTabsRightRef} className={`${styles.navTabs} nav nav-tabs`} style={{ width: "fit-content" }}>
            {showAddTab && !tabsLoading && <TabNew onCreate={onCreateTab} />}
            {_fixedTabs
              .filter((t) => t.align && t.align === "right")
              .map((tab, index) => (
                <TabFixed tab={tab} currentTabId={currentTab} onSelected={onSelected} key={`fixed-right-${index}`} />
              ))}
          </ul>
          {/* <div className={styles.tableTabsControls} ref={tableTabsControlsRef}>
            <AlertModal
              type="danger"
              title={`Delete all custom tabs?`}
              description="This will delete all custom tabs and their settings. This action cannot be undone."
              showModal={showResetModal}
              setShowModal={setShowResetModal}
              onDelete={() => {
                dispatchTabStore({ type: "reset" });
                setShowResetModal(false);
              }}
              key={"pin"}
            />
            <button
              className="btn btn-sm btn-ghost-danger"
              title="Reset to defaults"
              onClick={() => {
                setShowResetModal(true);
              }}
            >
              <FeatherIcon name="refresh-cw" />
            </button>
          </div> */}
          {showReset && (
            <button
              className="btn btn-sm btn-ghost-danger"
              title="Reset to defaults"
              onClick={async () => await onReset()}
            >
              <LucideIcon name="refresh-cw" />
            </button>
          )}
        </>
      )}
    </div>
  );
};

// Individual Tabs
// interface TabDropDownProps extends Omit<TabProps, "tabId" | "tab"> {
//   tabs: Tabs;
// }
// const TabDropdown = ({
//   tabs,
//   currentTabId,
//   tabModified,
//   onSelected,
//   onEdit,
//   onDelete,
//   onSave,
//   onFavorite,
//   session,
// }: TabDropDownProps) => {
//   const currentTab = useMemo(() => tabs.filter((t) => t.tabId === currentTabId)?.[0], [currentTabId, tabs]);

//   const [open, setOpen] = useState(false);
//   const [node, setNode] = useState<HTMLDivElement>();
//   const [nodeform, setNodeForm] = useState<HTMLFormElement>();
//   const [showAlertModal, setShowAlertModal] = useState(false);
//   const [edit, setEdit] = useState(false);
//   const [value, setValue] = useState(currentTab ? currentTab.label : "");
//   const dropdownNode = useCallback((node: HTMLDivElement) => setNode(node), []);
//   const formNode = useCallback((node: HTMLFormElement) => setNodeForm(node), []);

//   const handleClickOutside = useCallback(
//     (event: MouseEvent) => {
//       if (node && !node.contains(event.target as Node)) {
//         setOpen(false);
//       }
//     },
//     [node]
//   );

//   const handleClickOutsideForm = useCallback(
//     (event: MouseEvent) => {
//       if (nodeform && !nodeform.contains(event.target as Node)) {
//         setEdit(false);
//         setValue(currentTab ? currentTab.label : "");
//       }
//     },
//     [currentTab, nodeform]
//   );

//   useEffect(() => {
//     document.addEventListener("click", handleClickOutside);
//     document.addEventListener("click", handleClickOutsideForm);

//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//       document.removeEventListener("click", handleClickOutsideForm);
//     };
//   }, [handleClickOutside, handleClickOutsideForm]);

//   useEffect(() => {
//     if (currentTab) {
//       setValue(currentTab.label);
//     }
//   }, [currentTab]);

//   const _onSelected = useCallback(
//     async (tabId: string) => {
//       await onSelected(tabId);
//       setOpen(false);
//     },
//     [onSelected]
//   );

//   return (
//     <div
//       className={`dropdown ${open ? "open" : ""} ${styles.tab} ${styles.tabFlex} ${
//         tabs.map((t) => t.tabId).includes(currentTabId) ? `${styles.tabFlexActive} active` : ""
//       }`}
//       ref={dropdownNode}
//       style={{
//         padding: edit ? "0px 8px 0px 8px" : 0,
//         //  overflow: "hidden"
//       }}
//     >
//       <div
//         className={`${styles.tabLabel}`}
//         style={{
//           overflow: "hidden",
//           height: "100%",
//           width: "fit-content",
//           // padding: "0px 8px",
//         }}
//         onClick={(e) => {
//           switch (e.detail) {
//             case 1:
//               if (!currentTab) setOpen(true);
//               break;
//             case 2:
//               if (currentTab) setEdit(true);
//               break;
//           }
//         }}
//       >
//         {edit && currentTab ? (
//           <>
//             <AlertModal
//               type="warning"
//               title={`Delete Tab "${currentTab.label}"?`}
//               description=""
//               showModal={showAlertModal}
//               setShowModal={setShowAlertModal}
//               onDelete={() => {
//                 onDelete?.(currentTab.tabId);
//                 setShowAlertModal(false);
//               }}
//             />
//             <form
//               ref={formNode}
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 setEdit(false);
//                 // onEdit?.({ tabId: tab.tabId, label: value, type: tab.type });
//                 onEdit?.({ tabId: currentTab.tabId, label: value, title: value, type: "custom", align: "left" });
//               }}
//               className={styles.tabFlexForm}
//               style={{ overflow: "visible" }}
//             >
//               <div className="flex row-nowrap align-center gap-5" style={{ width: "fit-content" }}>
//                 <input
//                   className="form-control input-sm required"
//                   type="text"
//                   value={value}
//                   placeholder={value}
//                   onChange={(e) => setValue(e.target.value)}
//                   onInvalid={(e) => {
//                     if (!value || !regExp.test(value)) e.currentTarget.setCustomValidity(validationMsg);
//                   }}
//                   onKeyPress={(e) => {
//                     if (e.key === "Enter") {
//                       if (!value || !regExp.test(value)) {
//                         e.currentTarget.setCustomValidity(validationMsg);
//                       } else {
//                         setEdit(false);
//                         // onEdit?.({ tabId: tab.tabId, label: value, type: tab.type });
//                         onEdit?.({
//                           tabId: currentTab.tabId,
//                           label: value,
//                           title: value,
//                           type: "custom",
//                           align: "left",
//                         });
//                       }
//                     }
//                   }}
//                   required
//                   pattern="[a-zA-Z0-9_ ]+"
//                   maxLength={200}
//                   style={{ minWidth: "120px" }}
//                 />
//               </div>
//             </form>
//           </>
//         ) : (
//           <div className={`${styles.tabFlexLabelContainer}`} style={{ padding: "0px 8px" }}>
//             {session?.permissions.administer_global_user_preferences && currentTab && (
//               <div
//                 className={styles.tabFavorite}
//                 title="Pin this tab globally"
//                 onClick={() => onFavorite?.(currentTab.tabId)}
//                 style={{ pointerEvents: currentTab.tabId === currentTabId ? "all" : "none" }}
//               >
//                 <FeatherIcon name="star" />
//               </div>
//             )}
//             <span className={styles.tabFlexLabel}>
//               {currentTab ? (
//                 <>
//                   <>{currentTab.icon ? <FeatherIcon name={currentTab.icon} /> : ""}</>
//                   <>{currentTab.label ?? ""}</>
//                 </>
//               ) : (
//                 "More"
//               )}
//             </span>
//           </div>
//         )}
//       </div>
//       {currentTab && currentTabId === currentTab.tabId && (
//         <div className={`${styles.tabControls}`}>
//           <div
//             className={`${styles.tabControlsSave}`}
//             title="Save current changes"
//             onClick={() => onSave?.()}
//             style={{ color: tabModified ? "var(--success)" : "var(--gray-200)" }}
//           >
//             <FeatherIcon name="save" />
//           </div>
//           <div
//             className={`${styles.tabControlsDelete}`}
//             title={`Delete this tab`}
//             onClick={() => {
//               if (edit) {
//                 setEdit(false);
//                 setValue("");
//               } else {
//                 setShowAlertModal(true);
//               }
//             }}
//           >
//             <FeatherIcon name="x" />
//           </div>
//         </div>
//       )}

//       {!edit && (
//         <>
//           <div
//             className={`${styles.tabDropDown} dropdown-toggle`}
//             data-toggle="dropdown"
//             role="button"
//             aria-haspopup="true"
//             aria-expanded={open}
//             onClick={(e) => {
//               e.preventDefault();
//               setOpen((prev) => !prev);
//             }}
//           >
//             <span className="caret"></span>
//           </div>
//           {open && (
//             <ul className={`${styles.tabDropDownMenu} dropdown-menu pull-right`}>
//               {tabs
//                 .filter((t) => t.tabId !== currentTabId)
//                 .map((tab, index) => (
//                   <li key={`dropdown-${index}`} style={{ overflow: "hidden" }}>
//                     <a
//                       className={styles.tabFlexLabel}
//                       href="#"
//                       role="button"
//                       onClick={(e) => {
//                         e.preventDefault();
//                         e.stopPropagation();
//                         _onSelected(tab.tabId);
//                       }}
//                       style={{ overflow: "hidden" }}
//                     >
//                       <span className={styles.tabFlexLabel}>
//                         {tab.icon ? <FeatherIcon name={tab.icon} /> : ""} {tab.label ?? ""}
//                       </span>
//                     </a>
//                   </li>
//                 ))}
//             </ul>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

const TabFixed = ({ tab, currentTabId, onSelected, onFavorite, onSave, tabModified, session, type }: TabProps) => {
  const [showPinAlertModal, setShowPinAlertModal] = useState(false);
  return (
    <li
      className={`${styles.tab} ${styles.tabFixed} ${type === "temporary" && styles.tabTemporary} ${
        tab.align === "right" ? styles.tabRight : ""
      } ${
        currentTabId === tab.tabId
          ? tabModified && tab.type === "global" && session?.permissions.administer_global_user_preferences
            ? `${styles.tabActive} active ${styles.tabActiveUnsaved} `
            : `${styles.tabActive} active`
          : styles.tabStale
      } `}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await onSelected(tab.tabId);
      }}
      title={tab.title}
    >
      <a>
        <div
          className={styles.tabLabel}
          style={{
            paddingRight:
              tab.type === "global" && currentTabId === tab.tabId
                ? session?.permissions.administer_global_user_preferences
                  ? "14px"
                  : 0
                : 0,
          }}
        >
          <span className={styles.tabFlexLabel}>
            {tab.type === "fixed" && tab.icon ? (
              <LucideIcon name={tab.icon} />
            ) : tab.type === "temporary" ? (
              <LucideIcon name={"filter"} />
            ) : (
              ""
            )}{" "}
            {tab.label || ""}
          </span>
          {!!session?.permissions.administer_global_user_preferences &&
            currentTabId === tab.tabId &&
            tab.type === "global" && (
              <>
                <AlertModal
                  type="warning"
                  title={`Unpin tab "${tab.label}" globally for all users?`}
                  description="Do you want to unpin this tab globally for all users in the current group?"
                  showModal={showPinAlertModal}
                  setShowModal={setShowPinAlertModal}
                  proceedLabel="Unpin Tab"
                  onProceed={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    await onFavorite?.(tab.tabId);
                    setShowPinAlertModal(false);
                  }}
                  key={"pin"}
                />
                <div className={`${styles.tabControls}`}>
                  {tabModified && (
                    <>
                      <div
                        className={styles.tabControlsSave}
                        title={`Save this tab`}
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          await onSave?.();
                        }}
                      >
                        <LucideIcon name="save" />
                      </div>
                    </>
                  )}
                  <div
                    className={styles.tabControlsDelete}
                    title="Unpin this tab globally"
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowPinAlertModal(true);
                    }}
                    style={{ pointerEvents: tab.tabId === currentTabId ? "all" : "none" }}
                  >
                    <LucideIcon name="pin-off" />
                  </div>
                </div>
              </>
            )}
          {!!session?.permissions.administer_global_user_preferences &&
            currentTabId === tab.tabId &&
            tabModified &&
            tab.type === "global" && <div className={styles.modifiedIndicator} />}
        </div>
      </a>
    </li>
  );
};

const TabNew = ({ onCreate }: TabNewProps) => {
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState("");
  const [node, setNode] = useState<HTMLFormElement>();
  const formNode = useCallback((node: HTMLFormElement) => setNode(node), []);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (node && !node.contains(event.target as Node)) {
        setEdit(false);
        setValue("");
      }
    },
    [node]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);
  return (
    <li
      className={`${styles.tab} ${styles.newTab} ${edit ? styles.newTabEditing : ""}`}
      title="Open new tab"
      onClick={() => setEdit(true)}
    >
      <a style={{ marginRight: 0 }}>
        <div className={`${styles.tabLabel}`}>
          {edit ? (
            <form
              ref={formNode}
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // setEdit(false);
                // await onCreate?.({ tabId: uuidv4(), label: value, title: value, type: "custom", align: "left" });
                // setValue("");
              }}
            >
              <div className="flex row-nowrap align-center gap-5">
                <input
                  ref={inputRef}
                  autoFocus
                  className="form-control input-sm required"
                  type="text"
                  value={value}
                  placeholder="A new tab"
                  onChange={(e) => setValue(e.target.value)}
                  onInvalid={(e) => {
                    if (!regExp.test(value)) e.currentTarget.setCustomValidity(validationMsg);
                  }}
                  onKeyPress={async (e) => {
                    if (e.key === "Enter") {
                      if (!value || !regExp.test(value)) {
                        e.currentTarget.setCustomValidity(validationMsg);
                      } else {
                        setEdit(false);
                        await onCreate?.({
                          tabId: uuidv4(),
                          label: value,
                          title: value,
                          type: "custom",
                          align: "left",
                        });
                        setValue("");
                      }
                    }
                  }}
                  required
                  // pattern={pattern}
                  maxLength={30}
                  style={{ width: "15ch", height: "26px" }}
                />
                <button
                  className="btn btn-xs btn-ghost-success"
                  title="Save"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!value || !regExp.test(value)) {
                      inputRef.current?.setCustomValidity(validationMsg);
                    } else {
                      setEdit(false);
                      await onCreate?.({ tabId: uuidv4(), label: value, title: value, type: "custom", align: "left" });
                      setValue("");
                    }
                  }}
                >
                  <LucideIcon name="save" />
                </button>
                <button
                  className="btn btn-xs btn-ghost-dark"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEdit(false);
                    setValue("");
                  }}
                  title="Cancel"
                >
                  <LucideIcon name="x" />
                </button>
              </div>
            </form>
          ) : (
            <div className="flex row-nowrap align-center gap-5">
              <LucideIcon name="plus" />
            </div>
          )}
        </div>
      </a>
    </li>
  );
};

const TabEditable = ({
  tab,
  currentTabId,
  isDragging,
  onSelected,
  onEdit,
  onDelete,
  onSave,
  onFavorite,
  tabModified,
  nodeWidthCallback,
  type = "tab",
  session,
}: TabProps) => {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showPinAlertModal, setShowPinAlertModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(tab.label);
  const [node, setNode] = useState<HTMLFormElement>();
  const formNode = useCallback((node: HTMLFormElement) => setNode(node), []);
  const { ref } = useTabWidth(tab.tabId, nodeWidthCallback);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (node && !node.contains(event.target as Node)) {
        setEdit(false);
        setValue(tab.label);
      }
    },
    [node, tab.label]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const cls =
    type === "dropdown"
      ? `${currentTabId === tab.tabId ? `${styles.tabActive} ${styles.tabBorderless} active` : styles.tabStale}`
      : `${styles.tab} ${styles.tabFlex} ${tab.align === "right" ? styles.tabRight : ""} ${
          currentTabId === tab.tabId ? styles.tabFlexActive : styles.tabStale
        } `;
  return (
    <div
      className={`${cls} ${styles.dragHandle}`}
      title={tab.title ?? tab.label}
      ref={ref}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isDragging) {
          switch (e.detail) {
            case 1:
              if (tab.tabId !== currentTabId) await onSelected(tab.tabId);
              break;
            case 2:
              setEdit(true);
              break;
          }
        }
      }}
      style={{
        paddingRight:
          currentTabId === tab.tabId ? (session?.permissions.administer_global_user_preferences ? "15px" : "7.5px") : 0,
      }}
    >
      {currentTabId === tab.tabId && (
        <div className={`${styles.tabDragOverlay} HandleDrag`}>
          <LucideIcon name="ellipsis-vertical" />
        </div>
      )}
      <div className={`${styles.tabLabel}`} style={{ overflow: edit ? "visible" : "hidden" }}>
        <AlertModal
          type="danger"
          title={`Permanently delete tab "${tab.label}"?`}
          description="Are you sure you want to delete this tab and all its settings? This action cannot be undone."
          showModal={showAlertModal}
          setShowModal={setShowAlertModal}
          onProceed={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await onDelete?.(tab.tabId);
            setShowAlertModal(false);
          }}
          key={"delete"}
        />
        <AlertModal
          type="warning"
          title={`Pin tab "${tab.label}" for all users?`}
          description="Do you want to pin this tab for all users in the current group? Only admins can modify pinned tabs. Saving changes will update the tab for all users."
          showModal={showPinAlertModal}
          setShowModal={setShowPinAlertModal}
          proceedLabel="Pin Tab"
          onProceed={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await onFavorite?.(tab.tabId);
            setShowPinAlertModal(false);
          }}
          key={"pin"}
        />
        {edit ? (
          <form
            ref={formNode}
            onSubmit={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              setEdit(false);
              // onEdit?.({ tabId: tab.tabId, label: value, type: tab.type });
              // await onEdit?.({ tabId: tab.tabId, label: value, title: value, type: "custom", align: "left" });
            }}
            className={styles.tabFlexForm}
          >
            <div className="flex row-nowrap align-center" style={{ width: "fit-content" }}>
              <input
                ref={inputRef}
                autoFocus
                className="form-control input-sm required"
                type="text"
                value={value}
                placeholder={value}
                onChange={(e) => setValue(e.target.value)}
                onInvalid={(e) => {
                  if (!value || !regExp.test(value)) e.currentTarget.setCustomValidity(validationMsg);
                }}
                onKeyPress={async (e) => {
                  if (e.key === "Enter") {
                    if (!value || !regExp.test(value)) {
                      e.currentTarget.setCustomValidity(validationMsg);
                    } else {
                      setEdit(false);
                      // onEdit?.({ tabId: tab.tabId, label: value, type: tab.type });
                      await onEdit?.({ tabId: tab.tabId, label: value, title: value, type: "custom", align: "left" });
                      await onSave?.();
                    }
                  }
                }}
                required
                // pattern={pattern}
                maxLength={200}
                style={{ minWidth: "120px", height: "26px" }}
              />
              <button
                className="btn btn-xs btn-ghost-success"
                title="Save"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!value || !regExp.test(value)) {
                    inputRef.current?.setCustomValidity(validationMsg);
                  } else {
                    setEdit(false);
                    await onEdit?.({ tabId: tab.tabId, label: value, title: value, type: "custom", align: "left" });
                    setValue("");
                  }
                }}
              >
                <LucideIcon name="save" />
              </button>
              <button
                className="btn btn-xs btn-ghost-dark"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEdit(false);
                  setValue("");
                }}
                title="Cancel"
              >
                <LucideIcon name="x" />
              </button>
            </div>
          </form>
        ) : (
          <div className={`${styles.tabFlexLabelContainer} ellipsisContainer`}>
            <span className={styles.tabFlexLabel}>
              {tab.icon ? <LucideIcon name={tab.icon} /> : ""} {tab.label ?? ""}
            </span>
          </div>
        )}
      </div>
      <div className={`${styles.tabControls}`}>
        {currentTabId === tab.tabId && (
          <>
            {edit && (
              <button
                className={`btn btn-xs btn-ghost-success ${styles.tabControlsSave}`}
                title="Save current changes"
                onInvalid={(e) => {
                  if (!value || !regExp.test(value)) e.currentTarget.setCustomValidity(validationMsg);
                }}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (edit) {
                    await onEdit?.({ tabId: tab.tabId, label: value, title: value, type: "custom", align: "left" });
                    setEdit(false);
                  }
                  await onSave?.();
                }}
                // style={{ color: tabModified ? "var(--success)" : "var(--gray-200)" }}
                disabled={!edit ? !tabModified : false}
                style={{ display: !tabModified ? "none" : "block" }}
              >
                <LucideIcon name="save" />
              </button>
            )}

            {!edit && (
              <>
                {session?.permissions.administer_global_user_preferences && currentTabId === tab.tabId && (
                  <div
                    // className={styles.tabControlsBase}
                    title="Pin this tab globally"
                    onClick={() => setShowPinAlertModal(true)}
                    style={{ pointerEvents: tab.tabId === currentTabId ? "all" : "none" }}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    <LucideIcon name="pin" />
                  </div>
                )}
                {tabModified && (
                  <div
                    className={styles.tabControlsSave}
                    title={`Save this tab`}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      await onSave?.();
                    }}
                  >
                    <LucideIcon name="save" />
                  </div>
                )}
                <div
                  className={` ${styles.tabControlsDelete}`}
                  title={`Delete this tab`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (edit) {
                      setEdit(false);
                      setValue("");
                    } else {
                      setShowAlertModal(true);
                    }
                  }}
                >
                  <LucideIcon name="x" />
                </div>
              </>
            )}
          </>
        )}
      </div>

      {currentTabId === tab.tabId && tabModified && (
        <>
          <div className={styles.modifiedIndicator} />
        </>
      )}
    </div>
  );
};
