import { useCallback, useContext, useEffect, useState } from "react";
import { EntityDetailProps, EntityDetailTableProps } from "../../common/entity/EntityInterfaces";
import Detail from "../../common/panels/Detail/Detail";
import { DetailsPageTopControlsView } from "../../common/panels/misc/DetailsPageTopControlsView/DetailsPageTopControlsView";
import { CustomTypeDataTypeUtils, CustomTypeSection } from "../../api/CustomTypes";
import { toUppercase } from "../../common/helperfunctions/stringFunctions";
import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { LucideIcon } from "../../common/icon/LucideIcon";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../common/contexts/SessionContext";
import { getEditLink } from "../../main/Routing";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
import { DatasetsTile } from "../../common/sidebar/tiles/DatasetsTile";
import { InstrumentsTile } from "../../common/sidebar/tiles/InstrumentsTile";
import { InventoriesTile } from "../../common/sidebar/tiles/InventoriesTile";
import { PersonsTile } from "../../common/sidebar/tiles/PersonsTile";
import { ProjectsTile } from "../../common/sidebar/tiles/ProjectsTile";
import { SamplesTile } from "../../common/sidebar/tiles/SamplesTile";
import { Table } from "../../common/panels/Detail/DetailTable";
import { NotSet } from "../../common/misc/UIconstants";
import {
  CustomTypeEntityTypeOptionToIcon,
  CustomTypeHierarchyViewer,
  GridLayoutElementWrapper,
  RenderCustomTypeDefaultSections,
  RenderCustomTypeHierarchy,
} from "./CustomTypeRenderUtils";
import { ToggleIndicator } from "../../common/badges/ToggleIndicator/ToggleIndicator";
import styles from "./CustomTypeBuilder.module.css";
import { useResizeDetector } from "react-resize-detector";
import GridLayout from "react-grid-layout";
import { v4 as uuidv4 } from "uuid";
import { useDynamicHeight } from "./hooks/useDynamicHeight";
import { CustomField, customFieldConstants, CustomFieldFieldLabels } from "../../api/CustomFields";
import { CustomFieldsDetailTable } from "../CustomFields/CustomFieldsDetailView";
import { AbstractedCustomFieldDataTypeIcon } from "../CustomFields/CustomFieldRenderUtils";
import { generateAbstractedFromDataType } from "../CustomFields/CustomFieldUtils";
import { LinkEntity } from "../../common/misc/LinkEntity/LinkEntity";
import { IdTypes, IEntityMinimalModel } from "../../api/GenericTypes";
import { hierarchyConstants } from "../../api/Inventories";
import { LinkButton } from "../../common/buttons/LinkButton/LinkButton";
import { Alert } from "../../common/overlays/Alert/Alert";
import { EntityRestoreButton } from "../../common/entity/entityComponents/EntityRestoreButton";

interface RenderCustomTypeSectionCustomFieldProps<Field extends Partial<CustomField>> {
  elementId: string;
  customField: Field;
  nodeHeightCallback: (uuid: string, height: number) => void;
  removeCustomField?: (elementId: string) => void;
}
export const RenderCustomTypeSectionCustomField = <Field extends Partial<CustomField>>({
  elementId,
  customField,
  nodeHeightCallback,
  removeCustomField,
}: RenderCustomTypeSectionCustomFieldProps<Field>) => {
  const history = useHistory();
  const { route } = useContext(SessionContext);
  const { ref } = useDynamicHeight({
    uuid: elementId,
    nodeHeightCallback: nodeHeightCallback,
    gridRowHeight: 1,
  });

  const abstracted = generateAbstractedFromDataType(customField.dataType);
  return (
    <GridLayoutElementWrapper
      isFolded
      head={
        <div className="flex row-nowrap align-center gap-5" style={{ width: "100%", height: "100%" }}>
          {abstracted?.abstractedDataType ? (
            <AbstractedCustomFieldDataTypeIcon
              dataType={abstracted.abstractedDataType}
              style={{ width: "24px", height: "24px" }}
            />
          ) : (
            <LucideIcon name={customFieldConstants.icon} style={{ width: "24px", height: "24px" }} />
          )}
          <h4 style={{ margin: 0 }}>{customField.name}</h4>
          <div className="flex row-nowrap align-center gap-5" style={{ height: "100%", marginLeft: "auto" }}>
            <div className={"container_label"} onMouseDown={(e) => e.stopPropagation()}>
              <div className={"container_label_id"}>
                <span style={{ whiteSpace: "nowrap" }}>
                  <LinkEntity
                    entityConstants={customFieldConstants}
                    property={customField as IEntityMinimalModel<IdTypes>}
                    labelOverride={`${toUppercase(customFieldConstants.entitySingular)}-ID: ${customField.id}`}
                    openInNewTab
                  />
                </span>
              </div>
            </div>
            {customField.id && (
              <button
                className="btn btn-sm btn-default"
                onClick={() =>
                  customField.id &&
                  history.push(route(getEditLink(customFieldConstants.frontendIndexRoute, customField.id)))
                }
                onMouseDown={(e) => e.stopPropagation()}
                title="Edit this custom field"
              >
                <LucideIcon name="square-pen" /> Edit
              </button>
            )}
            {removeCustomField && (
              <button
                className="btn btn-sm btn-soft-danger"
                onClick={() => removeCustomField(elementId)}
                onMouseDown={(e) => e.stopPropagation()}
                title="Remove this custom field"
              >
                <LucideIcon name="x" />
              </button>
            )}
          </div>
        </div>
      }
      ref={ref}
    >
      <div className="flex col-nowrap" style={{ width: "100%" }}>
        {/* <DetailsPageTopControlsView entity={customField} entityConstants={customFieldConstants} /> */}
        <CustomFieldsDetailTable
          entity={customField as CustomField}
          nonFoldable
          entityConstants={customFieldConstants}
          fieldLabels={CustomFieldFieldLabels}
        />
      </div>
    </GridLayoutElementWrapper>
  );
};

interface LayoutStateSectionCustomField {
  [elementId: string]: {
    field: CustomField;
    layout: GridLayout.Layout;
  };
}

interface RenderCustomTypeSectionProps {
  elementId: string;
  section: CustomTypeSection;
  nodeHeightCallback: (uuid: string, height: number) => void;
}
const RenderCustomTypeSection = ({ elementId, section, nodeHeightCallback }: RenderCustomTypeSectionProps) => {
  const { ref } = useDynamicHeight({
    uuid: elementId,
    nodeHeightCallback: nodeHeightCallback,
    gridRowHeight: 1,
  });
  const { ref: internalRef, width } = useResizeDetector();
  const [layout, setLayout] = useState<LayoutStateSectionCustomField>(
    Object.fromEntries(
      Object.entries(section.customFields).map(([k, v]) => {
        const elementId = uuidv4();
        return [elementId, { field: v, layout: { i: elementId, x: 0, y: 0, w: 1, h: 6 } }];
      })
    )
  );

  useEffect(() => {
    setLayout((prev) =>
      Object.fromEntries(
        Object.entries(section.customFields).map(([k, v]) => {
          const elementId = uuidv4();
          return [
            elementId,
            { field: v, layout: { i: elementId, x: 0, y: 0, w: 1, h: prev?.[elementId]?.layout?.h || 6 } },
          ];
        })
      )
    );
  }, [section.customFields]);

  // This callback will dynamically adjust the layout based on the height of the widgets
  const childNodeHeightCallback = useCallback((elementId: string, height: number) => {
    // console.log("Component reported height: ", height, " with elementId: ", elementId);
    setLayout((prev) => ({
      ...prev,
      [elementId]: { ...prev[elementId], layout: { ...prev[elementId].layout, h: height } },
    }));
  }, []);

  return (
    <GridLayoutElementWrapper
      head={
        <div className="flex row-nowrap align-center gap-5" style={{ width: "100%", height: "100%" }}>
          <LucideIcon name={"panel-top-dashed"} style={{ width: "24px", height: "24px" }} />
          <h4 style={{ margin: 0 }}>{section.name}</h4>
          <div style={{ marginLeft: "auto" }}>
            Default folded: <ToggleIndicator enabled={!!section.isFolded} />
          </div>
        </div>
      }
      ref={ref}
    >
      <div className={styles.rglMainContainerSection} ref={internalRef}>
        {!!width && (
          <div style={{ flex: "1 0 100%", position: "relative" }}>
            <GridLayout
              className={styles.rglContainerSection}
              layout={Object.values(layout).map((l) => l.layout)}
              cols={1}
              rowHeight={1}
              width={width}
              verticalCompact={true}
              // margin={[0, 0]}
              containerPadding={[5, 7]}
              compactType={"vertical"}
              preventCollision={false}
              isDroppable={false}
              isDraggable={false}
              isResizable={false}
              isBounded={true}
              droppingItem={{ i: "new", w: 1, h: 7 }}
            >
              {Object.entries(layout).map(([elementId, l]) => (
                <div key={elementId} className="customField" data-grid={l.layout}>
                  <RenderCustomTypeSectionCustomField
                    elementId={elementId}
                    customField={l.field}
                    nodeHeightCallback={childNodeHeightCallback}
                  />
                </div>
              ))}
            </GridLayout>
          </div>
        )}
      </div>
    </GridLayoutElementWrapper>
  );
};

interface LayoutStateSection {
  [elementId: string]: {
    field: CustomTypeSection;
    layout: GridLayout.Layout;
  };
}

export const CustomTypeDetailTable = ({
  permissions,
  routes,
  entity,
  entityConstants,
  fieldLabels,
}: EntityDetailTableProps<"customTypes">) => {
  const { ref, width } = useResizeDetector();

  const [layout, setLayout] = useState<LayoutStateSection>(
    Object.fromEntries(
      Object.entries(entity.sections).map(([k, v]) => {
        const elementId = uuidv4();
        return [elementId, { field: v, layout: { i: elementId, x: 0, y: 0, w: 1, h: 6 } }];
      })
    )
  );
  useEffect(() => {
    setLayout((prev) =>
      Object.fromEntries(
        Object.entries(entity.sections).map(([k, v]) => {
          const elementId = uuidv4();
          return [
            elementId,
            { field: v, layout: { i: elementId, x: 0, y: 0, w: 1, h: prev?.[elementId]?.layout?.h || 6 } },
          ];
        })
      )
    );
  }, [entity.sections]);

  // This callback will dynamically adjust the layout based on the height of the widgets
  const nodeHeightCallback = useCallback((elementId: string, height: number) => {
    // console.log("Component reported height: ", height, " with elementId: ", elementId);
    setLayout((prev) => ({
      ...prev,
      [elementId]: { ...prev[elementId], layout: { ...prev[elementId].layout, h: height } },
    }));
  }, []);

  return (
    <>
      <Table noPadding>
        {entity.inventoryName && (
          <>
            <Table.Body.RowContent
              title={fieldLabels.inventoryName}
              content={
                <div className={"container_label"}>
                  <div className={"container_label_name"} style={{ fontWeight: 600 }}>
                    {entity.inventoryName ?? NotSet}
                  </div>
                </div>
              }
            />
            <Table.Body.RowContent
              title={fieldLabels.inventoryDescription}
              content={
                <>
                  {entity.inventoryDescription ? (
                    <div className={"container_notes"}>
                      <textarea
                        rows={4}
                        className={"container_textarea"}
                        value={entity.inventoryDescription}
                        disabled
                      />
                    </div>
                  ) : (
                    NotSet
                  )}
                </>
              }
            />
            <Table.Body.RowContent
              title={fieldLabels.isHierarchyRoot}
              content={<ToggleIndicator enabled={!!entity.isHierarchyRoot} />}
            />
          </>
        )}

        <Table.Body.RowContent
          title={
            entity.entityType && CustomTypeDataTypeUtils.CanDefineHierarchy(entity.entityType)
              ? `${toUppercase(hierarchyConstants.childType)} name`
              : fieldLabels.name
          }
          content={
            <div className={"container_label"}>
              <div className={"container_label_name"} style={{ fontWeight: 600 }}>
                {entity.name ?? NotSet}
              </div>
              <div className={"container_label_id"}>
                <span style={{ whiteSpace: "nowrap" }}>
                  {toUppercase(entityConstants.entitySingular)}-ID: {entity.id}
                </span>
              </div>
            </div>
          }
        />
        <Table.Body.RowContent
          title={fieldLabels.entityType}
          content={
            <div className="flex row-nowrap align-center gap-5">
              <span>
                <CustomTypeEntityTypeOptionToIcon entityType={entity.entityType} color={"var(--primary)"} />{" "}
                {entity.entityType ?? NotSet}
              </span>
            </div>
          }
        />
        {CustomTypeDataTypeUtils.CanDefineHierarchy(entity.entityType) && entity.rootHierarchy && (
          <>
            <Table.Body.RowContent
              title={fieldLabels.rootHierarchy}
              content={
                <>
                  {entity.rootHierarchy ? (
                    <LinkEntity entityConstants={entityConstants} property={entity.rootHierarchy} />
                  ) : (
                    NotSet
                  )}
                </>
              }
            />
            {/* <Table.Body.RowContent
              title={fieldLabels.parentTypes}
              content={
                <>
                  {Array.isArray(customType.parentTypes) ? (
                    <RenderMinimalEntity
                      entities={customType.parentTypes}
                      entityConstants={entityConstants}
                      createLinks
                      separator=",&nbsp;"
                    />
                  ) : (
                    NotSet
                  )}
                </>
              }
            /> */}
          </>
        )}
        <Table.Body.RowContent
          title={fieldLabels.description}
          content={
            <>
              {entity.description ? (
                <div className={"container_notes"}>
                  <textarea rows={4} className={"container_textarea"} value={entity.description} disabled />
                </div>
              ) : (
                NotSet
              )}
            </>
          }
        />
        {entity.entityType && CustomTypeDataTypeUtils.CanDefineHierarchy(entity.entityType) && (
          <>
            <Table.Body.RowContent
              title={fieldLabels.hasRestrictedAddPermission}
              content={<ToggleIndicator enabled={!!entity.hasRestrictedAddPermission} />}
            />
            <Table.Body.RowContent
              title={fieldLabels.hasRestrictedEditPermission}
              content={<ToggleIndicator enabled={!!entity.hasRestrictedEditPermission} />}
            />
            <Table.Body.RowContent
              title={fieldLabels.hasRestrictedReadPermission}
              content={<ToggleIndicator enabled={!!entity.hasRestrictedReadPermission} />}
            />
          </>
        )}
        <Table.Body.RowContent title={fieldLabels.isEnabled} content={<ToggleIndicator enabled={entity.isEnabled} />} />
      </Table>
      <h2>Sections</h2>
      <h3>Default sections</h3>
      <RenderCustomTypeDefaultSections entityType={entity.entityType} />
      <h3>Custom sections</h3>
      {Array.isArray(entity.sections) && !!entity.sections.length ? (
        <div className="flex col-nowrap">
          <div className={`${styles.rglMainContainer}`} style={{ minHeight: "min-content" }} ref={ref}>
            <GridLayout
              className={`${styles.rglContainer} `}
              layout={Object.values(layout).map((l) => l.layout)}
              cols={1}
              rowHeight={1}
              width={width}
              verticalCompact={true}
              margin={[10, 10]}
              containerPadding={[10, 10]}
              compactType={"vertical"}
              preventCollision={false}
              isDroppable={false}
              isResizable={false}
              isDraggable={false}
            >
              {Object.entries(layout).map(([elementId, l]) => (
                <div key={elementId} className="section" data-grid={l.layout}>
                  <RenderCustomTypeSection
                    elementId={elementId}
                    section={l.field}
                    nodeHeightCallback={nodeHeightCallback}
                  />
                </div>
              ))}
            </GridLayout>
          </div>
        </div>
      ) : (
        <Alert type="info" message={"No custom sections defined"} fit centered />
      )}
      <CustomTypeHierarchyViewer customType={entity} />
    </>
  );
};

export const CustomTypeDetail = ({
  permissions,
  routes,
  entity,
  entityApi,
  entityConstants,
  fieldLabels,
}: EntityDetailProps<"customTypes">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  return (
    <Detail
      head={
        <DetailsPageTopControlsView
          entity={entity}
          entityConstants={entityConstants}
          fullTitleOverride={<RenderCustomTypeHierarchy customType={entity} />}
          controls={
            <>
              {(!!entity.rootHierarchy?.id || entity.isHierarchyRoot) && (
                <LinkButton
                  className="btn btn-primary"
                  linkTo={`${routes.getAddRoute}?parentTypes=${JSON.stringify([
                    {
                      id: entity.id,
                      name: entity.name,
                    },
                  ])}&rootHierarchy=${JSON.stringify(
                    entity.rootHierarchy
                      ? {
                          id: entity.rootHierarchy.id,
                          name: entity.rootHierarchy.name,
                        }
                      : {
                          id: entity.id,
                          name: entity.name,
                        }
                  )}&entityType=Inventory`}
                  disabled={!permissions.canCreate}
                >
                  <LucideIcon name={hierarchyConstants.childIcon} />
                  <span> Add child {hierarchyConstants.childType}</span>
                </LinkButton>
              )}
              <LinkButton
                className="btn btn-default"
                linkTo={routes.getEditLink(entity.id)}
                disabled={!permissions.canEdit(entity)}
              >
                <LucideIcon name="square-pen" />
                <span> Edit</span>
              </LinkButton>
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <LinkButton
                    className="btn btn-sm btn-ghost-secondary"
                    linkTo={routes.getCloneLink(entity.id)}
                    title={`Clone this ${entityConstants.entitySingular}`}
                    disabled={!permissions.canCreate}
                  >
                    <LucideIcon name="copy" /> Clone
                  </LinkButton>
                </li>
                <li>
                  <button
                    className={`btn btn-sm btn-ghost-danger`}
                    title={"Delete"}
                    onClick={() => setShowModal(true)}
                    disabled={!permissions.canDelete(entity)}
                  >
                    <LucideIcon name="trash-2" /> Delete
                  </button>
                </li>
              </MoreDropdown>
              <AlertModal
                type={"danger"}
                showModal={showModal}
                setShowModal={setShowModal}
                title={`Permanently delete ${entityConstants.entitySingular}?`}
                description={`This ${entityConstants.entitySingular} may be referenced by other entities. Deleting a ${entityConstants.entitySingular} is only possible if all references are removed.`}
                proceedLabel={"Delete"}
                onProceed={async () => {
                  await entityApi
                    .deleteMutationAsync(
                      {
                        id: entity.id,
                        goBackOnSuccess: false,
                        showToast: false,
                        entityName: entityConstants.entitySingular,
                        params: { deletePermanently: true },
                      },

                      {
                        onSuccess: () => {
                          showtoast(
                            "success",
                            `Deleted ${toUppercase(entityConstants.entitySingular)}-ID: ${entity.id}.`
                          );
                          history.goBack();
                        },
                      }
                    )
                    .catch((e) => {});
                }}
                loading={entityApi.isLoadingDeleteMutation}
              />
            </>
          }
        />
      }
      mainStyle={{ padding: "0px 10px" }}
      main={<CustomTypeDetailTable entity={entity} entityConstants={entityConstants} fieldLabels={fieldLabels} />}
      sidebar={
        <>
          {/* TODO */}
          {entity.entityType === "Dataset" && !!entity.relations?.datasets.count && (
            <DatasetsTile
              defaultFilters={{ customTypeIds: [entity.id] }}
              excludeFilters={{ customTypeIds: () => false }}
            />
          )}
          {entity.entityType === "Sample" && !!entity.relations?.samples.count && (
            <SamplesTile
              defaultFilters={{ customTypeIds: [entity.id] }}
              excludeFilters={{ customTypeIds: () => false }}
            />
          )}
          {entity.entityType === "Inventory" && !!entity.relations?.inventories.count && (
            <InventoriesTile
              defaultFilters={{ customTypeIds: [entity.id] }}
              excludeFilters={{ customTypeIds: () => false }}
            />
          )}
          {entity.entityType === "Project" && !!entity.relations?.projects.count && (
            <ProjectsTile
              defaultFilters={{ customTypeIds: [entity.id] }}
              excludeFilters={{ customTypeIds: () => false }}
            />
          )}
          {entity.entityType === "Person" && !!entity.relations?.persons.count && (
            <PersonsTile
              defaultFilters={{ customTypeIds: [entity.id] }}
              excludeFilters={{ customTypeIds: () => false }}
            />
          )}
          {entity.entityType === "Instrument" && !!entity.relations?.facilities.count && (
            <InstrumentsTile
              defaultFilters={{ customTypeIds: [entity.id] }}
              excludeFilters={{ customTypeIds: () => false }}
            />
          )}

          {entity.entityType === "Dataset" && !entity.relations?.datasets.count && <DatasetsTile disabled />}
          {entity.entityType === "Sample" && !entity.relations?.samples.count && <SamplesTile disabled />}
          {entity.entityType === "Inventory" && !entity.relations?.inventories.count && <InventoriesTile disabled />}
          {entity.entityType === "Project" && !entity.relations?.projects.count && <ProjectsTile disabled />}
          {entity.entityType === "Person" && !entity.relations?.persons.count && <PersonsTile disabled />}
          {entity.entityType === "Instrument" && !entity.relations?.facilities.count && <InstrumentsTile disabled />}
        </>
      }
    />
  );
};
