import { createContext } from "react";
import { API } from "../../api/Api";
import { Session } from "../../api/ApiTypes";
import { LicenseModelValidated } from "../../api/Login";
import { SignalRConnectionContextReturnValues } from "../signalr/useLiveConnection";

type ContextProps = {
  api: API;
  session?: Session;
  group: string;
  clearSession: () => void;
  route: (route: string) => string;
  license?: LicenseModelValidated;
  signalRConnection?: SignalRConnectionContextReturnValues;
};

// we trick Typescript here to avoid having to handle the case of api being undefined
// which never happens in practice
export const SessionContext = createContext<ContextProps>({} as ContextProps);
