import { useQuery } from "@tanstack/react-query";
import { HashRouter } from "react-router-dom";
import { API } from "../api/Api";
import { SessionContext } from "../common/contexts/SessionContext";
import { LoadingWrapper } from "../common/LoadingWrapper";
// import { ToasterContainerView } from "../common/overlays/Toasts/ToasterContainerView";
import { EmbeddedRouter } from "./MainRouter";
import { AccountRequiredFieldWrapper, MigrationModeWrapper } from "./Main";
import { useSignalRConnection } from "../common/signalr/useLiveConnection";

export const ConductanceIntegration = ({ api, group }: { api: API; group: string }) => {
  const session = useQuery({ queryKey: ["session"], queryFn: async ({ signal }) => await api.getSession() });

  const signalRConnection = useSignalRConnection({ api, route: "subscribe" });
  return (
    <LoadingWrapper status={session.status} fetchStatus={session.fetchStatus}>
      <SessionContext.Provider
        value={{
          api,
          session: session.data!,
          group: group,
          clearSession: () => {},
          route: (x: string) => x,
          signalRConnection,
        }}
      >
        <MigrationModeWrapper>
          <AccountRequiredFieldWrapper>
            <HashRouter hashType="noslash">
              <EmbeddedRouter />
            </HashRouter>
          </AccountRequiredFieldWrapper>
        </MigrationModeWrapper>
      </SessionContext.Provider>
    </LoadingWrapper>
  );
};
