import { useHistory } from "react-router-dom";
import { useMemo, useState } from "react";
import { SearchInput } from "../../common/forms/SearchInput/SearchInput";
import { LucideIcon } from "../../common/icon/LucideIcon";
import TableView, { TableViewLabel } from "../../common/panels/TableView/TableView";
import { ColumnsSettings } from "../../common/tables/ColumnsSelector/ColumnsSelector";
import { AlertModal } from "../../common/modals/AlertModal/AlertModal";
// import { showtoast } from "../../common/overlays/Toasts/showtoast";
import { MoreDropdown } from "../../common/buttons/MoreDropdown/MoreDropdown";
import { EntityTable } from "../../common/tables/EntityTable/EntityTable";
import {
  GenericVirtualizedTableCells,
  SortState,
} from "../../common/tables/GenericVirtualizedTable/GenericVirtualizedTableTypes";
import { EntityFilterIndicator } from "../../common/tables/EntityFilterIndicator/EntityFilterIndicator";
import { useTabStore } from "../../common/tables/Tabs/useTabStore";
import { TableTabsDict } from "../../common/tables/Tabs/TableTabsTypes";
import { OrganizationTag, OrganizationTagFilters, OrganizationTagFiltersTranslator } from "../../api/OrganizationTag";
import { OrganizationTagFilterBar } from "./OrganizationTagsFilterBar";
import { DateTimeRenderer } from "../../common/datetime/DateTimeFormatter";
// import { OrganizationTagFilterBar } from "./OrganizationTagsFilterBar";
import styles from "./OrganizationTagsTable.module.css";
import { NotSet } from "../../common/misc/UIconstants";
import { OrganizationFilterForm } from "../../Organizations/Table/OrganizationFilterBar";
import { GetPersons } from "../../common/misc/EntityRenders/EntityRenderer";
import {
  UseEntityTableDefaultProps,
  UseEntityTableProps,
  useGenericVirtualizedTable,
  useGenericVirtualizedTableTabs,
} from "../../common/tables/GenericVirtualizedTable/useGenericVirtualizedTable";
import { EntityTableProps } from "../../common/entity/EntityInterfaces";
import { EntityTableEditButton } from "../../common/entity/entityComponents/EntityTableEditButton";
import { EntityTableCloneButton } from "../../common/entity/entityComponents/EntityTableCloneButton";
import { EntityTableDeleteButton } from "../../common/entity/entityComponents/EntityTableDeleteButton";

export const switchOrganizationTagsDefaultSortState = (
  sortState: OrganizationTagFilters["orderBy"]
): SortState<OrganizationTagFilters["orderBy"]> => {
  switch (sortState) {
    case "ID_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "ID_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-id",
        orderBy: sortState,
      };
    case "NAME_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "NAME_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-name",
        orderBy: sortState,
      };
    case "CREATED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdOn",
        orderBy: sortState,
      };
    case "CREATED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "CREATED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-createdBy",
        orderBy: sortState,
      };
    case "MODIFIED_ON_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_ON_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedOn",
        orderBy: sortState,
      };
    case "MODIFIED_BY_ASC":
      return {
        sortDirection: "ASC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    case "MODIFIED_BY_DESC":
      return {
        sortDirection: "DESC",
        headerId: "default-modifiedBy",
        orderBy: sortState,
      };
    default:
      return {
        sortDirection: "ASC",
        headerId: "default-name",
        orderBy: sortState,
      };
  }
};
const defaultFilter: OrganizationTagFilters = { orderBy: "NAME_ASC" };

export const useOrganizationTagsTableDefaults = ({ fieldLabels }: UseEntityTableDefaultProps<"organizationTags">) => {
  const defaults: ColumnsSettings<OrganizationTag> = useMemo(
    () => ({
      "default-id": { pos: 0, active: false, header: fieldLabels.id, property: "id" },
      "default-name": { pos: 1, active: true, header: fieldLabels.name, property: "name" },
      "default-notes": { pos: 2, active: true, header: fieldLabels.notes, property: "notes" },
      "default-createdOn": { pos: 3, active: true, header: fieldLabels.createdOn, property: "createdOn" },
      "default-createdBy": { pos: 4, active: true, header: fieldLabels.createdBy, property: "createdBy" },
      "default-modifiedOn": {
        pos: 5,
        active: true,
        header: fieldLabels.modifiedOn,
        property: "modifiedOn",
      },
      "default-modifiedBy": {
        pos: 6,
        active: true,
        header: fieldLabels.modifiedBy,
        property: "modifiedBy",
      },
    }),
    [
      fieldLabels.createdBy,
      fieldLabels.createdOn,
      fieldLabels.id,
      fieldLabels.modifiedBy,
      fieldLabels.modifiedOn,
      fieldLabels.name,
      fieldLabels.notes,
    ]
  );
  const tabStoreDefaults: TableTabsDict<OrganizationTag, OrganizationTagFilters, OrganizationFilterForm> = useMemo(
    () => ({
      default: {
        tabId: "default",
        type: "fixed",
        label: "All",
        title: "All",
        icon: "house",
        align: "left",
        xPos: 0,
        settings: {
          columnSettings: {},
          columnWidths: {},
          filters: defaultFilter,
          sidebarFilters: {},
        },
        forcedSettings: {
          columnSettings: {},
          columnWidths: {},
          filters: {},
          sidebarFilters: {},
        },
      },
    }),
    []
  );

  return { defaults, tabStoreDefaults };
};

export const useOrganizationTagsTableColumns = ({
  entityConstants,
  fieldLabels,
  sort,
  setSort,
}: UseEntityTableProps<"organizationTags">) => {
  const columns = useMemo(() => {
    let headers: GenericVirtualizedTableCells<OrganizationTag> = [
      {
        id: "default-id",
        Header: fieldLabels.id,
        accessor: (row) => <span style={{ color: "var(--gray-400)" }}>{row.id}</span>,
        width: 120,
        minWidth: 120,
        align: "center",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "ID_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "ID_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "ID_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.name,
        id: "default-name",
        accessor: (row) => (
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              whiteSpace: "nowrap",
              marginRight: "5px",
            }}
          >
            <LucideIcon name={entityConstants.icon} color="var(--primary)" />
            <span> {row.name}</span>
          </div>
        ),
        minWidth: 150,
        width: 250,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "NAME_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "NAME_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "NAME_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        Header: fieldLabels.notes,
        id: "default-notes",
        accessor: (row) => (
          <div title={row.notes} className={styles.notes}>
            {row.notes || NotSet}
          </div>
        ),
        minWidth: 150,
        width: 800,
        align: "left",
      },
      {
        id: "default-createdOn",
        Header: fieldLabels.createdOn,
        accessor: (row) => <DateTimeRenderer date={row.createdOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-createdBy",
        Header: fieldLabels.createdBy,
        accessor: (row) => <GetPersons persons={row.createdBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "CREATED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "CREATED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedOn",
        Header: fieldLabels.modifiedOn,
        accessor: (row) => <DateTimeRenderer date={row.modifiedOn} includeElapsed={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_ON_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_ON_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
      {
        id: "default-modifiedBy",
        Header: fieldLabels.modifiedBy,
        accessor: (row) => <GetPersons persons={row.modifiedBy} createLinks={false} />,
        width: 200,
        align: "left",
        sortingFn: (id) => {
          if (sort.headerId === id) {
            if (sort.sortDirection === "ASC") {
              setSort((prev) => ({ ...prev, sortDirection: "DESC", orderBy: "MODIFIED_BY_DESC" }));
            } else {
              setSort((prev) => ({ ...prev, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" }));
            }
          } else {
            setSort({ headerId: id, sortDirection: "ASC", orderBy: "MODIFIED_BY_ASC" });
          }
        },
        sortDirection: (id) => (sort.headerId === id ? sort.sortDirection : undefined),
      },
    ];

    return headers;
  }, [
    entityConstants.icon,
    fieldLabels.createdBy,
    fieldLabels.createdOn,
    fieldLabels.id,
    fieldLabels.modifiedBy,
    fieldLabels.modifiedOn,
    fieldLabels.name,
    fieldLabels.notes,
    setSort,
    sort.headerId,
    sort.sortDirection,
  ]);
  return { columns };
};
export const OrganizationTagsTable = ({
  entityApi,
  entityConstants,
  fieldLabels,
  permissions,
  routes,
}: EntityTableProps<"organizationTags">) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { bulkDeleteMutationAsync: deleteOrganizationTag, isLoadingBulkDeleteMutation } = entityApi;

  const { defaults, tabStoreDefaults } = useOrganizationTagsTableDefaults({ fieldLabels });

  const { selection, resultsCount, selectionPermissions, onCountChange, onSelectionChange, onSelectionPermissions } =
    useGenericVirtualizedTable<OrganizationTag>();

  const {
    filters,
    sidebarFilters,
    forcedFilters,
    columnSetting,
    columnWidths,
    dispatchTabStore,
    currentTab,
    tabsLoading,
  } = useTabStore<OrganizationTag, OrganizationTagFilters, OrganizationFilterForm>({
    resource: entityConstants.resource,
    defaults: tabStoreDefaults,
  });

  const { functionRef, sort, setSort, searchValue, setSearchValue } = useGenericVirtualizedTableTabs({
    currentTab,
    tabsLoading,
    filters,
    switchSortState: switchOrganizationTagsDefaultSortState,
    dispatchTabStore,
  });

  const { columns } = useOrganizationTagsTableColumns({ fieldLabels, entityConstants, sort, setSort });

  const memoizedFilters = useMemo(() => ({ ...filters, ...forcedFilters }), [filters, forcedFilters]);

  return (
    <TableView>
      <TableView.Head>
        <TableView.Head.Label>
          <TableViewLabel entityConstants={entityConstants} resultsCount={resultsCount} />
        </TableView.Head.Label>
        <TableView.Head.Controls>
          <button
            className="btn btn-primary"
            title={`Add ${entityConstants.entitySingular}`}
            onClick={() => history.push(routes.getAddRoute)}
            disabled={!permissions.canCreate}
          >
            <LucideIcon name="plus" /> Add {entityConstants.entitySingular}
          </button>
        </TableView.Head.Controls>
      </TableView.Head>
      <TableView.Body>
        <TableView.Body.Sidebar>
          <OrganizationTagFilterBar
            initialValues={sidebarFilters}
            dispatchTabStore={dispatchTabStore}
            tabsLoading={tabsLoading}
            currentTab={currentTab}
          />
        </TableView.Body.Sidebar>
        <TableView.Body.Content>
          <EntityTable>
            <EntityTable.Controls
            // style={{
            //   borderTop: "0px",
            //   borderRadius: "0px",
            // }}
            >
              <EntityFilterIndicator<OrganizationTag, OrganizationTagFilters>
                filters={filters}
                excludeFilters={{}}
                translatorConsts={OrganizationTagFiltersTranslator}
              />
              <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholder="Search" />
              <EntityTableEditButton
                entityConstants={entityConstants}
                routes={routes}
                permissions={permissions}
                selection={selection}
                selectionPermissions={selectionPermissions}
              />
              <MoreDropdown drop="right" btn="btn btn-ghost-secondary">
                <li>
                  <EntityTableCloneButton
                    entityConstants={entityConstants}
                    routes={routes}
                    permissions={permissions}
                    selection={selection}
                  />
                </li>
                <li>
                  <EntityTableDeleteButton
                    entityConstants={entityConstants}
                    permissions={permissions}
                    selection={selection}
                    selectionPermissions={selectionPermissions}
                    onClick={() => setShowModal(true)}
                  />
                </li>
              </MoreDropdown>
              <AlertModal
                type="danger"
                showModal={showModal}
                setShowModal={setShowModal}
                title={`${
                  selection.size === 0
                    ? `Select ${entityConstants.entitySingular} to delete`
                    : selection.size === 1
                    ? `Delete the selected ${entityConstants.entitySingular}`
                    : `Delete the selected ${entityConstants.entityPlural}`
                }`}
                description="Proceeding will permanently delete the selected organization tags."
                proceedLabel="Delete"
                onProceed={async () => {
                  await deleteOrganizationTag({
                    ids: Array.from(selection),
                    goBackOnSuccess: false,
                    showToast: false,
                    entityName: entityConstants.resource,
                    params: { deletePermanently: true },
                  }).catch((e) => {});
                  functionRef.current?.resetSelection();
                  setShowModal(false);
                }}
                loading={isLoadingBulkDeleteMutation}
              />
            </EntityTable.Controls>
            <EntityTable.Body<OrganizationTag, OrganizationTagFilters>
              functionRef={functionRef}
              entityConstants={entityConstants}
              filters={memoizedFilters}
              columns={columns}
              columnSelect
              columnSetting={columnSetting}
              columnWidths={columnWidths}
              defaultColumnSettings={defaults}
              dispatchTabStore={dispatchTabStore}
              setResultsCount={onCountChange}
              onSelectionChange={onSelectionChange}
              onSelectionPermissions={onSelectionPermissions}
              showPermissionColumn
              loading={tabsLoading}
              fixedRowHeight={55}
            />
          </EntityTable>
        </TableView.Body.Content>
      </TableView.Body>
    </TableView>
  );
};
